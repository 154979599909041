import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setTimeFilterEndDate, setTimeFilterStartDate } from '../../../../../redux/TImeSlice'
import { options } from '../../../../../utils/helpers/date'
import { TodayDate } from './Todaydate.styled'

const Todaydate: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setTimeFilterStartDate(new Date()))
    dispatch(setTimeFilterEndDate(null))
  }, [startDate])

  return (
    <>
      <TodayDate>{new Date().toLocaleDateString('en-US', options)}</TodayDate>
    </>
  )
}

export default Todaydate