import React from 'react'
import { useNavigate } from 'react-router-dom'
import { expiredIcon } from '../../assets/images/expired'
import { ExpiredContainer, ExpiredImg, ExpiredNotice, ExpiredNoticeContainer, ExpiredTitle, ExpiredTitleSpan, ExpiredWrapper, LoginButton } from './Expired.styled'

const Expired = () => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/login')
  }
  return (
    <ExpiredContainer>
      <ExpiredWrapper>
        <ExpiredImg src={expiredIcon}></ExpiredImg>
        <ExpiredNoticeContainer>
          <ExpiredTitle>Your session has <ExpiredTitleSpan>expired!</ExpiredTitleSpan></ExpiredTitle>
          <ExpiredNotice>Please refresh the page. Don’t worry, we kept all of your filter and breakdown in place.</ExpiredNotice>
          <LoginButton onClick={() => handleNavigate()}>Log in</LoginButton>
        </ExpiredNoticeContainer>
      </ExpiredWrapper>
    </ExpiredContainer>
  )
}

export default Expired