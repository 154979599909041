import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IOtherDataModels, Iuser } from 'user'
import { serverGetUsers, serverLogin, serverGetUser, serverGetDataModels, serverLoginWithToken } from '../services/serverApi'
import { setLogin } from './AuthReducer'
import { getCustomers } from './CustomerSlice'
import { getDevices, getDeviceSupplier } from './DeviceSlice'
import { getDeviceTypes } from './DeviceTypeSlice'
import { IUser } from '../types/user.d'
import { getAttachedMaterial, getMaterial } from './MaterialSlice'
import { getFacilitiesGroups } from './FacilitiesGroupSlice'
import { getFacilities } from './Facilities'
import { getFacilityZones } from './FacilityZonesSlice'
import { getHarvest } from './HarvestSlice'
import { getRoles } from './RoleSlice'
import { getPermissions } from './PermissionSlice'
import { getAllDataModelPermissions } from './DataModelPermissionSlice'
import { IGroup } from 'notifications'
import { getAlert, getNotificationCondition, getNotificationEventType, getNotificationFrequency, getNotificationGroup } from './AlertSlice'
import { getFacilityZonesTypes } from './FacilityZoneTypesSlice'
import { getMonitoredUnits } from './MonitoredUnitsSlice'
import { getMonitoredGroup } from './MonitoredGroupSlice'

type loginObject = {
  email?: any
  password?: any
  token?: any
}

type UserState = {
  user: Iuser | {}
  users: (IUser & { roleName: string })[]
  otherDataModels: Array<IOtherDataModels>
  loading: boolean
  error: string | null
}

const initialState: UserState = {
  user: {},
  users: [],
  otherDataModels: [],
  loading: false,
  error: null
}

export const getUser = createAsyncThunk('user/getUser', async ({ email, password, token }: loginObject, thunkApi) => {
  try {
    const res = token ? await serverLoginWithToken(token) : await serverLogin(email, password)

    const { customerId, dataModelId } = res
    localStorage.setItem('user', JSON.stringify(res))
    await Promise.all([
      thunkApi.dispatch(getRoles()),
      thunkApi.dispatch(getPermissions()),
      thunkApi.dispatch(getAllDataModelPermissions()),
      thunkApi.dispatch(getCustomers()),
      thunkApi.dispatch(getUsers()),
      thunkApi.dispatch(getOtherDataModels()),
      thunkApi.dispatch(getFacilitiesGroups()),
      thunkApi.dispatch(getFacilities()),
      thunkApi.dispatch(getFacilityZones()),
      thunkApi.dispatch(getFacilityZonesTypes()),
      thunkApi.dispatch(getDevices(customerId)),
      thunkApi.dispatch(getDeviceTypes(dataModelId)),
      thunkApi.dispatch(getDeviceSupplier()),
      thunkApi.dispatch(getHarvest()),
      thunkApi.dispatch(getMaterial()),
      thunkApi.dispatch(getAttachedMaterial()),
      thunkApi.dispatch(getAlert()),
      thunkApi.dispatch(getNotificationCondition()),
      thunkApi.dispatch(getNotificationFrequency()),
      thunkApi.dispatch(getNotificationEventType()),
      thunkApi.dispatch(getNotificationGroup()),
      thunkApi.dispatch(getMonitoredUnits()),
      thunkApi.dispatch(getMonitoredGroup())
    ])
    if (res?.token) {
      thunkApi.dispatch(setLogin(true))
    }
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const getUsers = createAsyncThunk('user/getUsers', async (s, thunkApi) => {
  try {
    const res = await serverGetUsers()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const getUserDetails = createAsyncThunk('user/getUserDetails', async (s, thunkApi) => {
  try {
    const res = await serverGetUser()
    const user = { ...JSON.parse(localStorage.user), ...res }
    localStorage.setItem('user', JSON.stringify(user))
    // required to update user info on users page
    await thunkApi.dispatch(getUsers())
    return user
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const getOtherDataModels = createAsyncThunk('user/getDataModels', async (s, thunkApi) => {
  try {
    const res = await serverGetDataModels()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getUser.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.user = payload
      }),
      builder.addCase(getUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      builder.addCase(getUsers.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(getUsers.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.users = payload
      }),
      builder.addCase(getUsers.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
    // user details
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.user = payload
      }),
      builder.addCase(getUserDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
    // other data models
    builder.addCase(getOtherDataModels.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getOtherDataModels.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.otherDataModels = payload as IOtherDataModels[]
      }),
      builder.addCase(getOtherDataModels.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const userReducer = userSlice.reducer
export const userActions = userSlice.actions
