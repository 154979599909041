import React from 'react'
import { useTranslation } from 'react-i18next'
import { CancelButtonWrapper } from './CancleButton.styled'

interface CancelButtonProps {
  onClick: (e?: any) => void
  cy?: string
  style?: any
}

const CancelButton: React.FC<CancelButtonProps> = ({ onClick, cy, style }) => {
  const { t } = useTranslation()
  return (
    <CancelButtonWrapper data-cy={cy} onClick={onClick} style={style}>
      {t('general.cancel')}
    </CancelButtonWrapper>
  )
}

export default CancelButton
