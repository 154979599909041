import styled from 'styled-components'
import AppSubNavigationComponent from '../../AppSubNavigation/AppSubNavigation'

export const AppbarWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  display: grid;
  grid-template-columns: 250px 1fr;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-area: appbar;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }
`

export const MiddleWrapper = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-areas: 'locfilter locfilter';
  grid-template-columns: 50px auto;
  grid-template-rows: 1fr;
  grid-gap: 20px;
`

export const AppSubNavigationNew = styled(AppSubNavigationComponent)`
  grid-area: locfilter;
  width: 100%;
`

export const DateInfo = styled.div`
  font-size: 14px;
  color: black;
  font-weight: bold;
`

export const ApplocationWrapper = styled.div`
  grid-area: locfilter;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AppDateWrapper = styled.div`
  grid-area: date1;
  width: auto;
  height: 40px;
  display: flex;
  flex-direction: row;
`

export const LocationIcon = styled.img`
  margin-right: 20px;
  height: 50px;
`

export const AppBarNotificationWrapper = styled.div``
export const ProfileNotificationWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const SkeletonCss = {
  AppBarLogo: {
    borderRadius: '5px',
    width: '127px',
    height: '40px',
    marginLeft: '50px'
  },
  LocationIcon: {
    height: '42px',
    width: '50px',
    marginRight: '10px'
  },
  DateIcon: {
    marginRight: '15px',
    height: '35px',
    width: '35px',
    borderRadius: '30px',
    marginLeft: '5px'
  },
  AppbarLocationComponent: {
    height: '56px',
    width: '100%',
    borderRadius: '7px'
  },
  AppBarNotification: {
    borderRadius: '5px',
    width: '50px',
    height: '56px',
    marginLeft: '10px'
  },
  AppBarUser: {
    borderRadius: '5px',
    height: '56px',
    width: 'auto',
    minWidth: '70px',
    marginLeft: '10px'
  },
  AppDataFilter: {
    width: 'auto',
    height: '40px',
    minWidth: '320px',
    borderRadius: '7px'
  },
  CropdateDropdown: {
    width: '250px',
    borderRadius: '7px',
    height: '40px',
    marginLeft: '10px'
  },
  TodayDateContainer: {
    width: '225px',
    height: '40px',
    borderRadius: '7px',
    marginLeft: '10px'
  },
  CustomDateInfoWrapper: {
    width: '530px',
    height: '40px',
    borderRadius: '7px',
    marginLeft: '10px'
  }
}
