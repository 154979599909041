import React, { useCallback, Fragment, useEffect } from "react";
import { useSnackbar } from "notistack";
import eventBus from "../../utils/helpers/eventBus";
import { statusCodes, requestTypes, snackbarVariant, snackTypes } from "../../utils/consts/error";

const SnackBar = () => {
    const { enqueueSnackbar } = useSnackbar();

    const getSnackbarInfo = (type: string, statusCode: number,message?:string) => {
        if (type === requestTypes.delete && statusCode === statusCodes.Ok) {
            return message ?? snackTypes.deleteSuccess
        } else if (type === requestTypes.post && statusCode === statusCodes.Ok) {
            return message ?? snackTypes.addSuccess
        } else if (type === requestTypes.put && statusCode === statusCodes.Ok) {
            return message ?? snackTypes.updateSuccess
        } else if (statusCode === statusCodes.UnAuthorized) {
            return message ?? snackTypes.unAuthorized
        } else if (statusCode === statusCodes.InternalServerError) {
            return message ?? snackTypes.InternalServerError
        } else if (statusCode === statusCodes.Forbidden) {
            return message ?? snackTypes.unAuthorized
        }
    }

    const getSnackbarVariant: any = (statusCode: number) => {
        switch (statusCode) {
            case statusCodes.Ok:
                return snackbarVariant.success
            case statusCodes.InternalServerError:
                return snackbarVariant.error
            case statusCodes.UnAuthorized:
                return snackbarVariant.warning
            case statusCodes.Forbidden:
                return snackbarVariant.warning
            case statusCodes.BadRequest:
                return snackbarVariant.warning
            case statusCodes.Conflict:
                return snackbarVariant.warning
            default:
                return snackbarVariant.info
        }
    }

    useEffect(() => {
        eventBus.on('snackbar',(e:any) => handleClickWithAction(e))
    }, [])
  
    const handleClickWithAction = useCallback((e) => {
        enqueueSnackbar(getSnackbarInfo(e.type, e.statusCode, e?.message), {
        variant: getSnackbarVariant(e.statusCode),
        });
    }, []);

  return (
    <div >
    </div>
  );
};

export default SnackBar;
