import { shedIcon } from '../../assets/images/crop'
import { PoultryLogo, RuminTechLogo } from '../../assets/images/login'
import { addButtonIcon, ShedLogIcon, shedsHouseIcon } from '../../assets/images/sheds'
import { sensorsRuminTechColor, sensorsRuminTechImages } from './Sensors'
import { AddDeviceButtonIcon, AddDeviceDialogIcon, AddGatewayButton, AddGatewayDialogIcon } from '../../assets/images/devices/Rumintech'
import { FarmRuminTechIcon } from '../../assets/images/farms/RuminTech'
import { addShedRuminTechIcon, ExtensionIcon } from '../../assets/images/sheds/RuminTech'
import { HerdPrimaryIcon } from '../../assets/images/Herds'
import { LoginRuminTechLogo } from '../../assets/images/login/RuminTech'
import { LoginGasLogo } from '../../assets/images/login/Gas'
import { getGasSidebarData } from '../../components/Sidebar/Gas/GasRoutes'

enum EPermissionFeature {
  IOT = 'IOT',
  Heatmap = 'Heatmap',
  Farm = 'Farm',
  Shed = 'Shed',
  Silo = 'Silo',
  Material = 'Material',
  AttachMaterial = 'Attach Material',
  Device = 'Device',
  Crop = 'Crop',
  Event = 'Event',
  Alert = 'Alert',
  User = 'User',
  Customer = 'Customer',
  Role = 'Role',
  Efficiency = 'Efficiency',
  Sensor = 'Sensors',
  Report = 'Reports'
}

export const GasConfig: any = {
  logo: RuminTechLogo,
  sidebar: {
    routeData: getGasSidebarData()
  },
  logoLogin: LoginGasLogo,
  title: 'RUMINTECH',
  facilityGroup: {
    name: 'Farm',
    PrimaryIcon: FarmRuminTechIcon,
    SecondaryIcon: ''
  },
  facility: {
    name: 'Extension',
    PrimaryIcon: ExtensionIcon,
    SecondaryIcon: ExtensionIcon,
    WhiteIcon: shedIcon
  },
  facilityZones: {
    name: 'Zone',
    PrimaryIcon: '',
    SecondaryIcon: ''
  },
  monitoredUnit: {
    name: 'Animal',
    PrimaryIcon: HerdPrimaryIcon,
    SecondaryIcon: ''
  },
  device: {
    addGatewayButtonIcon: AddGatewayButton,
    addDeviceButtonIcon: AddDeviceButtonIcon,
    addDeviceDialogIcon: AddDeviceDialogIcon,
    addGatewayDialogIcon: AddGatewayDialogIcon
  },
  shedTable: {
    zoneLogsIcon: ShedLogIcon
  },
  sensors: {
    Images: sensorsRuminTechImages,
    color: sensorsRuminTechColor
  },
  material: {
    name: 'Material'
  },
  attachMaterial: {
    name: 'Attach Material'
  },
  EPermissionFeature
}
