import { DataModel } from '../../types/dataModel.d'
import { GetDataModelId } from './dataModel'

export const GETTRACKING_ID = () => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return 'G-R9DTGBZ65Q'
      break
    case DataModel.Bees:
      return 'G-M26XF1HP1P'
      break
    case DataModel.RuminTech:
      return 'G-CWNK9D7MV6'
      break
    default:
      return 'G-R9DTGBZ65Q'
      break
  }
}
