import styled from 'styled-components'

export const BackdropStyle = styled.div<{ isopen: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  // transition: 0.3s fadeout;
  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transition: 0.5s fadeout;
  z-index: 2;
  transform: ${({ isopen }) => (isopen ? 'translateY(0%)' : 'translateY(100%)')};
`
export const ModalStyle = styled.div``
export const DrawerWrapper = styled.div<{ isopen: boolean }>`
  width: 98%;
  position: absolute;
  top: 59%;
  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transition: 0.3s ease-in-out;
  transform: ${({ isopen }) => (isopen ? 'translateY(0%)' : 'translateY(100%)')};
`
export const DrawerContent = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.typography.colors.primary};
`
