import { DeviceTypes } from '../../types/device.d'
import { WaterIcon } from '../../assets/images/crop'
import { RadarIcon, TabletIcon, WeightIcon } from '../../assets/images/summary'
import { GasBulkIcon } from '../../assets/images/devices'
import { gatewayIcon } from '../../assets/images/gateway'

export const HydroIntelDevicesConfig = {
  HydroDevice: {
    name: 'Hydro Device',
    Icon: WaterIcon,
    deviceType: DeviceTypes.HydroSensor
  },
  Tablet: {
    name: 'Tablet',
    Icon: TabletIcon,
    deviceType: DeviceTypes.Tablet
  },
  GasBulk: {
    name: 'Gas Bulk',
    Icon: GasBulkIcon,
    deviceType: DeviceTypes.GasBulk
  }
}

export const gateWaysConfig = {
  Gateway_Type_A: {
    name: 'Gateway Type A',
    Icon: gatewayIcon,
    deviceType: DeviceTypes.Gateway
  },
  // Gateway_Type_B: {
  //   name: 'Gateway Type B',
  //   Icon: gatewayIcon,
  //   deviceType: DeviceTypes.Gateway
  // }

}


