import styled from 'styled-components'

export const AppBarUserWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border: ${({ theme }) => '1px solid ' + theme.colors.tertiary};
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: reltive;
  margin-left: 10px;
`

export const AppBarSpan = styled.span`
  color: ${({ theme }) => theme.typography.colors.white};
  font-size: 30px;
`
