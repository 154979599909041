import React from 'react'
import AppMenu from '../../../AppMenu/AppMenu'
import AppPolygon from '../../../AppPolygon/AppPolygon'
import './AppBarUser.scss'

interface AppbarUserProps {
  userName: string
}

const AppBarUser: React.FC<AppbarUserProps> = ({ userName }) => {
  return (
    <AppMenu
      menuButton={
        <div className="app-bar-user">
          <AppPolygon className="app-bar-user-polygon" backgroundColor="#FFFFFF">
            {<span>{userName.toUpperCase()}</span>}
          </AppPolygon>
        </div>
      }
    />
  )
}

export default AppBarUser
