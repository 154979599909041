import React from 'react'
import { io } from 'socket.io-client'
import { useReduxDispatch } from '../redux/index'
import { getDevices } from '../redux/DeviceSlice'
import eventBus from '../utils/helpers/eventBus'
// const SOCKET_ENDPOINT = 'ws://localhost:8888'
const SOCKET_ENDPOINT = 'https://socket.zeusint.com/'

export let socket: any

const useSockets = () => {
  const dispatch = useReduxDispatch()

  /**Start socket connection.*/
  const startSocketConnection = (endPoint: any) => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    if (user?.token) {
      //connect to socket.
      try {
        //if socket is already connected then we will listen to new event
        if (!socket) {
          socket = io(SOCKET_ENDPOINT, { transports: ['websocket', 'polling'], query: { token: user.token } })
        }
        //listen to the data event.
        socket.on(endPoint, (e: any) => handleSocketData(endPoint, e))
        // socket listener for notifications
        // socket.on('notifications', handleNotificationReceived)
      } catch (e) {
        console.log(e)
      }
    }
  }

  //update the devices after reciveing data from socket events.

  const handleSocketData = (endPoint: any, data: any) => {
    eventBus.dispatch(endPoint, data)
  }

  //fetch latest notifications
  // const handleNotificationReceived = () => {
  //   dispatch(getNotifications())
  // }

  const socketDisconnect = () => {
    socket?.disconnect()
  }
  return { startSocketConnection, socketDisconnect }
}

export default useSockets
