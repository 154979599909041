import React, { useState, useRef } from 'react'

const useGoogleMap = () => {
  const [_map, setMap] = useState(null)

  const onLoad = React.useCallback(function callback(map: any, _center: { lat: number; lng: number }) {
    if (window?.google?.maps) {
      const bounds = new window.google.maps.LatLngBounds(_center)
      var originalMaxZoom = map.maxZoom
      map.setOptions({ maxZoom: 3 })
      map.fitBounds(bounds)
      setMap(map)
      setTimeout(() => {
        map.setOptions({ maxZoom: originalMaxZoom })
        setMap(map)
      }, 2000)
    }
  }, [])

  //on unmount
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  return {
    _map,
    onUnmount,
    onLoad
  }
}

export default useGoogleMap
