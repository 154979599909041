import { serverGetFacilities, serverCreateFacility, serverGetFacilityZones, serverGetFacilityZoneTypeByCustomerId } from '../services/serverApi'
import { IFacilityZones, IFacilityZoneType } from 'facilityZones'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IZone } from 'facility'



type FacilityZonestate = {
    facilityZoneTypes: Array<IFacilityZoneType>
    loading: boolean
    error: string | null
  }
  
  const initialState: FacilityZonestate = {
    facilityZoneTypes: [],
    loading: false,
    error: null
  }
  
  /**Get FacilitiesGroup */
  export const getFacilityZonesTypes = createAsyncThunk('getFacilityZoneTypes', async (s, thunkApi) => {
    try {
      const res = await (serverGetFacilityZoneTypeByCustomerId())
      return res
    } catch (e) {
      return thunkApi.rejectWithValue(e as ErrorEventInit)
    }
  })
  
  /**Add device  */
  
  export const facilityZoneTypeSlice = createSlice({
    name: 'ZoneTypes',
    initialState,
    reducers: {},
    extraReducers: (builder): any => {
      builder.addCase(getFacilityZonesTypes.pending, (state) => {
        state.loading = true
      }),
        builder.addCase(getFacilityZonesTypes.fulfilled, (state, { payload }) => {
          state.error = null
          state.loading = false
          state.facilityZoneTypes = payload as IFacilityZoneType[]
        }),
        builder.addCase(getFacilityZonesTypes.rejected, (state, { payload }) => {
          state.loading = false
          state.error = (payload as ErrorEventInit).error
        })
    }
  })


export const facilityZoneTypeReducer = facilityZoneTypeSlice.reducer
export const facilityZoneTypeActions = facilityZoneTypeSlice.actions

