import { IDeviceTypes } from 'device'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootState, useReduxSelector } from '../../redux'
import { serverUpdateDeviceVersion } from '../../services/serverApi'

interface fieldErrMessage {
  deviceType?: string
  versionNumber?: string
  releaseNote?: string
  image?: string
}

const useAddDeviceVersion = (getDeviceUpdateVersion: any, setIsOpen: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [eventObject, setEventObject] = useState<IScheduleEvents | any>({
    deviceType: '',
    versionNumber: '',
    releaseNote: '• ',
    image: null
  })
  const { t } = useTranslation()
  const [fieldErrors, setFieldErrors] = useState<any>({}) // State to store field errors

  const deviceTypes: IDeviceTypes[] = useReduxSelector((state: RootState) => state.deviceTypes.deviceTypes)

  const menuItems = deviceTypes.map((deviceType) => {
    return { id: deviceType.id, name: deviceType.name! }
  })

  const isValidVersionSyntax = (version: string): boolean => {
    const semanticVersionRegex = /^(\d+)\.(\d+)\.(\d+)(-[0-9A-Za-z-]+(\.[0-9A-Za-z-]+)*)?(\+[0-9A-Za-z-]+(\.[0-9A-Za-z-]+)*)?$/
    return semanticVersionRegex.test(version)
  }

  const handleSubmit = async (values: any) => {
    try {
      const { deviceType, versionNumber, releaseNote }: { deviceType: number; versionNumber: string; releaseNote: string } = values

      const errors = {} as fieldErrMessage
      if (!deviceType) {
        errors.deviceType = 'deviceTypeError'
      }
      if (versionNumber?.trim() === '') {
        errors.versionNumber = 'versionNumberError'
      } else {
        if (!isValidVersionSyntax(versionNumber)) {
          errors.versionNumber = 'versionNumberSyntaxError'
        }
      }
      if (releaseNote?.trim() === '' || releaseNote === '• ') {
        errors.releaseNote = 'releaseNoteError'
      }
      // binary check
      if (eventObject.image === null) {
        errors.image = 'imageReqError'
      } else if (eventObject.image.type !== 'application/octet-stream') {
        errors.image = 'imageTypeError'
      }

      if (Object.keys(errors).length > 0) {
        setFieldErrors(t(`device-update.updateDialog.${errors}`))
        return
      }
      setIsLoading(true)
      const formData = new FormData()
      formData.append('deviceTypeId', deviceType.toString())
      formData.append('versionNumber', versionNumber as string)
      formData.append('binFile', eventObject.image as Blob)
      formData.append('releaseNote', releaseNote as string)
      try {
        const response = await serverUpdateDeviceVersion(formData)
        console.log(response)
      } catch (err) {
        console.log(err)
      }

      setIsLoading(false)
      handleClose()
      getDeviceUpdateVersion()
    } catch (err) {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setEventObject({
      deviceType: '',
      versionNumber: '',
      releaseNote: '• ',
      image: null
    })
    setFieldErrors({})
    setIsOpen(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setFieldErrors({ ...fieldErrors, [name]: '' })
    setEventObject({ ...eventObject, [name as keyof IScheduleEvents]: value })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0] as File
      setEventObject({ ...eventObject, image: file })
    }
  }

  const handleBulletPointClick = () => {
    setEventObject({ ...eventObject, releaseNote: eventObject.releaseNote + '\n• ' })
  }

  return {
    isLoading,
    handleSubmit,
    setIsLoading,
    handleClose,
    handleInputChange,
    eventObject,
    setEventObject,
    menuItems,
    fieldErrors,
    handleBulletPointClick,
    handleFileChange
  }
}

export default useAddDeviceVersion
