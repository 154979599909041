import styled from 'styled-components'

export const AppBarNotificationWrapper = styled.div`
  width: 50px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const AppBarNotificationIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const AppBarNotificationText = styled.div`
  font-size: 10px;
  color: white;
  height: 23px;
  width: 23px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 30px;
  margin-left: 30px;
  background-color: #ff5852;
  padding: 5px 7.5px;
  border-radius: 50%;
  background: red;
  color: white;
`
