import styled from 'styled-components'

export const AppLocationWrapper = styled.div`
  height: 60px;
  grid-area: locfilter;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding-left: ${({ theme }) => theme.spacing.md};
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`
export const AppSelectInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`
