import { DefaultTheme } from 'styled-components'

export const RuminTechTheme: DefaultTheme = {
  colors: {
    primary: '#1592D2',
    secondary: '#00609B',
    tertiary: '#062A41',
    forth: '#FFFFFF',
    white: '#FFFFFF'
  },
  statusColors: {
    danger: '#DC2121',
    dangerBG: '#FADEDE',
    warning: '#DD7020',
    warningBG: '#FBEBDE',
    ok: '#62BB47',
    okBG: '#D8E7D7'
  },
  typography: {
    size: { xs: '11px', sm: '15px', md: '18px', lg: '28px', xl: '35px' },
    colors: { primary: '#113252', secondary: '#274C6B', white: 'white', forth: '#CCCCCC' },
    bold: { sm: '400', md: '500', lg: '700', xs: '100' }
  },

  spacing: {
    xs: '6px',
    sm: '12px',
    md: '16px',
    lg: '20px'
  },
  borderRadius: {
    sm: '5px',
    md: '12px',
    lg: '18px'
  },
  breakpoints: {
    xs: '600px',
    sm: '800px',
    md: '1000px',
    lg: '1200px',
    xl: '1440px'
  },
  colorPallete: ['#5D7DFF', '#FFAD5D', '#CE77FF', '#00D6CF', '#B7F876', '#FC5A5A', '#00FF27']
}
