import styled from "styled-components";


export const CustomDatePickerWrapper = styled.div`
display: flex;
width: 590px;
height: 37px;
`;
export const StartDateWrapper = styled.div`
display: flex;
margin-left: 100px;
width: 265px;
height: 100%;
background-color: white;
border-radius: 5px;
@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
}
`;
export const StartDateImage = styled.img`
width: 20px;
height: 20px;
margin-left: 15px;
margin-top: 7px;
`;
export const StartDateText = styled.p`
padding-top: 7px;
margin-left: 35px;
color: black;
font-family: Open Sans;
`;
export const StartTimeText = styled.p`
padding-top: 5px;
margin-left: 10px;
`;
export const MiddleWrapper = styled.span`
margin-left: 20px;
margin-top: 7px;
font-family: Open Sans;

color: white;
`;
export const EndDateWrapper = styled(StartDateWrapper)`
margin-left: 25px;
`;
export const EndDateImage = styled(StartDateImage)``;
export const EndDateText = styled(StartDateText)``;
export const EndTimeText = styled(StartTimeText)``;
export const AnalysisDatePickerContainer = styled.div`
  height: 100%;
  width: 200px;
  display: flex;
  margin-left: -80px;
  border-radius: 7px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 210px;
  }
`;