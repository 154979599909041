import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './AuthReducer'
import { deviceReducer } from './DeviceSlice'
import { customerReducer } from './CustomerSlice'
import { facilityGroupReducer } from './FacilitiesGroupSlice'
import { facilityReducer } from './Facilities'
import { userReducer } from './UserReducer'
import { harvestReducer } from './HarvestSlice'
import { timeSliceReducer } from './TImeSlice'
import { locationSliceReducer } from './LocationSlice'
import { facilityZoneReducer } from './FacilityZonesSlice'
import { materialReducer } from './MaterialSlice'
import { facilityZoneTypeReducer } from './FacilityZoneTypesSlice'
import { siteReducer } from './SitesSlice'
import { deviceTypeReducer } from './DeviceTypeSlice'
import { roleReducer } from './RoleSlice'
import { permissionReducer } from './PermissionSlice'
import { dataModelPermissionsReducer } from './DataModelPermissionSlice'
import { alertReducer } from './AlertSlice'
import { iotSliceReducer } from './IotSlice'
import { monitoredUnitsReducer } from './MonitoredUnitsSlice'
import { monitoredGroupReducer } from './MonitoredGroupSlice'
import { harvestBrandReducer } from './BrandSlice'

const allReducers = combineReducers({
  auth: authReducer,
  customers: customerReducer,
  harvest: harvestReducer,
  user: userReducer,
  facilitiesGroup: facilityGroupReducer,
  facilities: facilityReducer,
  facilityZones: facilityZoneReducer,
  facilityZoneTypes: facilityZoneTypeReducer,
  material: materialReducer,
  devices: deviceReducer,
  deviceTypes: deviceTypeReducer,
  time: timeSliceReducer,
  iot: iotSliceReducer,
  location: locationSliceReducer,
  site: siteReducer,
  roles: roleReducer,
  permissions: permissionReducer,
  dataModelPermissions: dataModelPermissionsReducer,
  alert: alertReducer,
  monitoredUnits: monitoredUnitsReducer,
  monitoredGroup: monitoredGroupReducer,
  brand: harvestBrandReducer
})

const rootReducer = (state: any, action: any) => {
  if (!action?.payload?.isLoggedIn !== undefined) {
    if (action.type === '[USER] Set Login' && !action.payload.isLoggedIn) {
      return allReducers(undefined, action)
    } else {
      return allReducers(state, action)
    }
  } else {
    return allReducers(state, action)
  }
}

export default rootReducer
