import React, { memo, MouseEvent, useState } from 'react'
import {
  CustomDatePickerWrapper,
  EndDateWrapper,
  MiddleWrapper,
  StartDateImage,
  StartDateText,
  AnalysisDatePickerContainer,
  StartDateWrapper
} from './CustomDatePicker.styled'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/analog_time_picker'
import { DATE_RANGE } from '../../../../../utils/consts/timeFilter'
import { DateIconImg } from '../../../../../assets/images/DateFilterImages'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { setTimeFilterEndDate, setTimeFilterIsOnePicker, setTimeFilterStartDate } from '../../../../../redux/TImeSlice'
import { getReadableDate } from '../../../../../utils/helpers/date'
import { RootState, useReduxSelector } from '../../../../../redux'
import { selectedDateButton, selectedIotTime } from '../../../../../redux/IotSlice'
import moment from 'moment'
import { Interval } from '../../../../../utils/consts/analysis'

interface DateDefaultPickerProps {
  onClick: (e: MouseEvent<HTMLDivElement>) => void
  value: string
  clear: boolean
}
const DateDefaultPicker: React.FC<DateDefaultPickerProps> = ({ onClick, clear, value }) => {
  const { isOnePicker, interval, endDate, startDate } = useSelector((state: any) => state.time)
  const { time } = useReduxSelector((state: RootState) => state.iot)

  const dispatch = useDispatch()
  const location = useLocation()
  let date = new Date()
  const sDate = new Date(value[0])
  const selectedStartDate = getReadableDate(sDate)
  const lWeek = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)
  const DayBeforeDate = new Date(moment(date).utc().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z')
  const lastWeek = getReadableDate(lWeek)
  useEffect(() => {
    if (value[0] !== undefined) {
      if (isOnePicker) {
        const eDate = new Date(sDate)
        eDate.setHours(23, 59, 59, 999)
        dispatch(
          selectedIotTime({
            startDate: sDate,
            endDate: eDate
          })
        )
      }
    }
    if (!isOnePicker && value[0] === undefined) {
      if (interval.value === Interval.Raw) {
        dispatch(setTimeFilterStartDate(DayBeforeDate))
        dispatch(setTimeFilterEndDate(date))
      } else {
        dispatch(setTimeFilterStartDate(lWeek))
        dispatch(setTimeFilterEndDate(date))
      }
      dispatch(
        selectedDateButton({
          Custom: 3
        })
      )
    }
  }, [selectedStartDate, location, isOnePicker, interval])

  useEffect(() => {
    if (!isOnePicker && value[0] !== undefined) {
      if (
        new Date(moment(new Date(value[0])).utc().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z').getTime() <=
          new Date(endDate).getTime() &&
        interval.value === Interval.Hour
      ) {
        dispatch(setTimeFilterStartDate(sDate))
        dispatch(setTimeFilterEndDate(new Date(moment(new Date(value[0])).utc().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z')))
      } else {
        dispatch(setTimeFilterStartDate(sDate))
      }
      if (
        new Date(moment(new Date(value[0])).utc().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z').getTime() <=
          new Date(endDate).getTime() &&
        interval.value === Interval.Raw
      ) {
        dispatch(setTimeFilterStartDate(sDate))
        dispatch(setTimeFilterEndDate(new Date(moment(new Date(value[0])).utc().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z')))
      } else {
        dispatch(setTimeFilterStartDate(sDate))
      }
      const eDate = new Date(sDate)
      eDate.setHours(23, 59, 59, 999)
      dispatch(
        selectedDateButton({
          Custom: 3
        })
      )
    }
  }, [isOnePicker, selectedStartDate, interval, selectedDateButton])

  if (!value || clear) {
    return (
      <CustomDatePickerWrapper onClick={onClick as any}>
        <StartDateImage src={DateIconImg} />
        {isOnePicker ? (
          <StartDateText>{getReadableDate(time.startDate) ?? DATE_RANGE}</StartDateText>
        ) : (
          <StartDateText>{interval.value === Interval.Raw ? getReadableDate(DayBeforeDate) : lastWeek}</StartDateText>
        )}
      </CustomDatePickerWrapper>
    )
  }
  return (
    <CustomDatePickerWrapper onClick={onClick as any}>
      <StartDateImage src={DateIconImg} />
      {isOnePicker ? (
        <StartDateText>{getReadableDate(time.startDate) ?? DATE_RANGE}</StartDateText>
      ) : (
        <StartDateText>{getReadableDate(startDate)}</StartDateText>
      )}
    </CustomDatePickerWrapper>
  )
}
const DateDefaultPicker2: React.FC<DateDefaultPickerProps> = ({ onClick, clear, value }) => {
  const { isOnePicker, startDate, interval, endDate } = useSelector((state: any) => state.time)
  const dispatch = useDispatch()
  const eDate = new Date(value[0])
  const SelectedEndDate = getReadableDate(eDate)
  let date = new Date()
  const lastWeek = getReadableDate(date)
  useEffect(() => {
    if (value[0] !== undefined) {
      if (
        new Date(moment(startDate).utc().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z').getTime() <= new Date(eDate).getTime() &&
        interval.value === Interval.Hour
      ) {
        dispatch(setTimeFilterEndDate(new Date(moment(startDate).utc().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z')))
      } else if (
        new Date(moment(startDate).utc().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z').getTime() <= new Date(eDate).getTime() &&
        interval.value === Interval.Raw
      ) {
        dispatch(setTimeFilterEndDate(new Date(moment(startDate).utc().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z')))
      } else {
        dispatch(setTimeFilterEndDate(eDate))
      }
    }
  }, [SelectedEndDate, location, isOnePicker, interval])

  if (!value || clear) {
    return (
      <CustomDatePickerWrapper onClick={onClick as any}>
        <StartDateImage src={DateIconImg} />
        {isOnePicker ? <StartDateText>{DATE_RANGE}</StartDateText> : <StartDateText>{getReadableDate(endDate)}</StartDateText>}
      </CustomDatePickerWrapper>
    )
  }
  return (
    <CustomDatePickerWrapper onClick={onClick as any}>
      <StartDateImage src={DateIconImg} />
      <StartDateText>{getReadableDate(endDate)}</StartDateText>
    </CustomDatePickerWrapper>
  )
}

interface CustomDatesPickerProps {
  onChange?: (date: DateObject[]) => void
  clear: boolean
}
const CustomDatesPicker: React.FC<CustomDatesPickerProps> = ({ onChange, clear }) => {
  const { isOnePicker, startDate, endDate, interval } = useSelector((state: any) => state.time)
  const dispatch = useDispatch()
  const location = useLocation()
  const [maxDate, setMaxDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())
  useEffect(() => {
    if (location.pathname === '/Iot' || location.pathname === '/Iot/General' || location.pathname === '/Iot/Heatmap') {
      dispatch(setTimeFilterIsOnePicker(true))
    } else {
      dispatch(setTimeFilterIsOnePicker(false))
    }
  }, [location])
  useEffect(() => {
    if (interval.value === Interval.Raw) {
      setMaxDate(new Date(moment(startDate).utc().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'))
      setMinDate(new Date(moment(startDate).format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'))
    } else if (interval.value === Interval.Hour) {
      setMaxDate(new Date(moment(startDate).utc().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'))
      setMinDate(new Date(moment(startDate).format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'))
    } else {
      setMaxDate(new Date(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'))
      setMinDate(new Date(moment(endDate).utc().subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'))
    }
  }, [interval, startDate, endDate])

  return (
    <AnalysisDatePickerContainer>
      <StartDateWrapper data-cy="selectDate">
        <DatePicker
          // minDate={minDate}
          plugins={[<TimePicker hideSeconds position="right" />]}
          format="YYYY/MM/DD HH:mm"
          render={(value: string | string, open: any) => <DateDefaultPicker clear={clear} value={value} onClick={open} />}
          onChange={onChange}
          value={'2022-01-01T10:00'}
        />
      </StartDateWrapper>
      {!isOnePicker && <MiddleWrapper>To</MiddleWrapper>}
      {!isOnePicker && (
        <EndDateWrapper>
          <DatePicker
            maxDate={maxDate}
            minDate={minDate}
            plugins={[<TimePicker hideSeconds position="right" />]}
            format="YYYY/MM/DD HH:mm"
            render={(value: string | string, open: any) => <DateDefaultPicker2 clear={clear} value={value} onClick={open} />}
            onChange={onChange}
            value={'2022-01-01T10:00'}
          />
        </EndDateWrapper>
      )}
    </AnalysisDatePickerContainer>
  )
}

export default memo(CustomDatesPicker)
