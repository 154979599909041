import styled from 'styled-components'

export const InputWrapper = styled.div<{ hideLabel?: boolean }>`
  gap: 10px;
  /* margin-top: ${({ theme }) => theme.spacing.md}; */
  margin-top: ${({ hideLabel, theme }) => (hideLabel ? '0px' : theme.spacing.md)};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`

export const Lable = styled.span`
  font-size: 16px;
  color: #7b7b7b;
  margin-right: 10px;
  width: auto;
`
export const OptionHeading = styled.span`
  margin-left: 15px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height */

  color: ${({ theme }) => theme.colors.tertiary};
`
export const ListOptionWrapper = styled('div')`
  display: flex;
  justify-content: center;
`
