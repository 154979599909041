import React from 'react'
import { FacilityFields } from '../../../../utils/consts/facility'
import { AddFarmDimensionsInput, AddFarmLabel, FarmDimensionsWrapper, TagHeading, TagInputs } from './FarmDimension.styled'

interface Props {
    valueObject: any;
    onChangeHandler: React.ChangeEventHandler<any>;
}

const FarmDimension: React.FC<Props> = ({ valueObject, onChangeHandler }) => {
  return (
    <FarmDimensionsWrapper>
        <AddFarmLabel>{FacilityFields.Dimensions.label}</AddFarmLabel>
        <TagInputs>
        <TagHeading>{FacilityFields.width.label}</TagHeading>
        <AddFarmDimensionsInput
            type="number"
            name={FacilityFields.width.field}
            onChange={onChangeHandler}
            value={valueObject.width > 0 ? valueObject.width : 0}
        />
        <TagHeading>{FacilityFields.length.label}</TagHeading>
        <AddFarmDimensionsInput
            type="number"
            name={FacilityFields.length.field}
            onChange={onChangeHandler}
            value={valueObject.length > 0 ? valueObject.length : 0}
        />
        <TagHeading>{FacilityFields.height.label}</TagHeading>
        <AddFarmDimensionsInput
            type="number"
            name={FacilityFields.height.field}
            onChange={onChangeHandler}
            value={valueObject.height > 0 ? valueObject.height : 0}
        />
        </TagInputs>
    </FarmDimensionsWrapper>
  )
}

export default FarmDimension