import styled from 'styled-components'

export const AddFilterDialogWrapper = styled.div`
  width: auto;
  height: auto;
  border-radius: ${({ theme }) => theme.typography.size.sm};
  border: 1px solid #707070;
  background-color: #ffffff;
  display: flex;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing.lg};
  flex-direction: column;
  overflow: hidden;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`
export const InputFieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1fr, 4);
`
export const CustomerInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`
export const DateInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
`
export const ChartTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`
export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  .ColorPicker-MuiButtonBase-root {
    border-width: 0px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
`

export const HorizontalLine = styled.hr``
export const FilterIconImg = styled.img``
export const DropDownContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.sm};
  gap: ${({ theme }) => theme.spacing.lg};
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: ${({ theme }) => theme.spacing.lg};
    justify-content: center;
    background-color: #ffffff;
  }
`
export const CancleButton = styled.button`
  background: #ffffff;
  width: 140px;
  height: 48px;
  /* Gray */
  border: 1px solid #dcdcdc;
  border-radius: 8px;
`
export const ApplyButton = styled.button<{ isValid: any }>`
  width: 140px;
  height: 48px;
  border: none;
  color: ${({ theme }) => theme.colors.forth};

  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  cursor: ${({ isValid }) => (isValid ? 'pointer' : 'not-allowed')};
  pointer-events: ${({ isValid }) => (isValid ? 'block' : 'none')};
  opacity: ${({ isValid }) => (isValid ? 1 : 0.5)};
`

export const LabelContainer = styled.div`
  width: 280px;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 200px;
  }
`
export const Label = styled.span`
  width: 100%;
  white-space: nowrap;
  margin-top: 27px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 20px;
  }
`
