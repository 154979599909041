import { PoultryWeightsTheme } from './PoultryWeights/theme'
import { GetDataModelId } from '../utils/helpers/dataModel'
import { DataModel } from '../types/dataModel.d'
import { BeesTheme } from './Bees/theme'
import { RuminTechTheme } from './RuminTech/theme'
import { DefaultTheme } from 'styled-components'
import { BpacTheme } from './Bpac/theme'
import { GasTheme } from './Gas/theme'
import { RadarTheme } from './Radar/theme'

const themes = (): DefaultTheme => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return PoultryWeightsTheme
    case DataModel.Bees:
      return BeesTheme
    case DataModel.RuminTech:
      return RuminTechTheme
    case DataModel.Bpac:
      return BpacTheme
    case DataModel.hydroIntel:
      return PoultryWeightsTheme
    case DataModel.Gas:
      return GasTheme
    case DataModel.Radar:
      return RadarTheme
    default:
      return PoultryWeightsTheme
  }
}
export default themes
