import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverGetHarvestBrand } from '../services/serverApi'

type harvestBrandState = {
  harvestBrand: Array<any>
  loading: boolean
  error: string | null
}

const initialState: harvestBrandState = {
  harvestBrand: [],
  loading: false,
  error: null
}

/**Get Harvests */
export const getHarvestBrand = createAsyncThunk('harvest/getHarvestBrand', async (customerId, thunkApi) => {
  try {
    const res = await serverGetHarvestBrand()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

// /**Add Harvest  */
// export const addHarvest = createAsyncThunk('harvest/addHarvest', async (harvest: IHarvest, thunkApi) => {
//   try {
//     const res = await serverCreateHarvest(harvest)
//     thunkApi.dispatch(getHarvestBrand())
//   } catch (e) {
//     console.error(e)
//   }
// })

export const harvestBrandSlice = createSlice({
  name: 'harvestBrand',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getHarvestBrand.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getHarvestBrand.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.harvestBrand = payload as any[]
      }),
      builder.addCase(getHarvestBrand.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
    //   builder.addCase(addHarvest.fulfilled, (state, { payload }) => {
    //     state.error = null
    //     state.loading = false
    //   }),
    //   builder.addCase(addHarvest.pending, (state) => {
    //     state.loading = true
    //   }),
    //   builder.addCase(addHarvest.rejected, (state, { payload }) => {
    //     state.loading = false
    //     state.error = (payload as ErrorEventInit).error
    //   })
  }
})

export const harvestBrandReducer = harvestBrandSlice.reducer
export const harvestBrandActions = harvestBrandSlice.actions
