import { DeviceTypes } from '../../types/device.d'
import { GasBulkIcon2, GasMeterIcon, GasMultiIcon } from '../../assets/images/devices'

export const GasDevicesConfig = {
  GasBulk: {
    name: 'Gas Bulk',
    Icon: GasBulkIcon2,
    deviceType: DeviceTypes.GasBulk
  },
  GasMeter: {
    name: 'Gas Meter',
    Icon: GasMeterIcon,
    deviceType: DeviceTypes.GasMeter
  },
  GasMulti: {
    name: 'Gas Multi',
    Icon: GasMultiIcon,
    deviceType: DeviceTypes.GasMulti
  }
}
