import ReportImage from './ReportImage.svg'
import FilterImage from './FilterImage.svg'
import AddImage from './AddImage.svg'
import CloseImage from './CloseIcon.svg'
import HerdImage from './HerdIcon.svg'
import BolusImage from './BolusIcon.svg'
import BolusDeviceStatusOnline from './deviceTypeStatusOnline.svg'
import BolusDeviceStatusAlert from './deviceTypeStatusAlert.svg'
import BolusDeviceStatusError from './deviceTypeStatusError.svg'
import AddChartPlusImage from './AddChartPlusImage.svg'
import FavouriteImage from './FavouriteImage.svg'
import AddChartCircleImage from './AddChartCircleImage.svg'
import AddFavouriteCircleImage from './AddFavouriteCircleIcon.svg'
import FullScreenCircleImage from './FullScreenCircleIcon.svg'
import AddFilterIconImage from './AddFilterIconImage.svg'
import ExitFullScreenImage from './ExitFullScreenImage.svg'
import AnimalImage from './AnimalIcon.svg'
import CustomerImage from './CustomerIcon.svg'
import DateImage from './DateIcon.svg'
import ExtensionImage from './ExtensionIcon.svg'
import GroupImage from './GroupIcon.svg'
import HerdDetailesImage from './HerdDetailesIcon.svg'
import SplitImage from './SplitIcon.svg'
import SelectedSplitImage from './SelectedSplitIcon.svg'

export const SplitIcon = SplitImage
export const SelectedSplitIcon = SelectedSplitImage

export const ReportIcon = ReportImage
export const FilterdIcon = FilterImage
export const AddPlusIcon = AddImage
export const CloseIcon = CloseImage
export const BolusIcon = BolusImage
export const HerdIcon = HerdImage

export const AnimalIcon = AnimalImage
export const CustomerIcon = CustomerImage
export const DateIcon = DateImage
export const ExtensionIcon = ExtensionImage
export const GroupIcon = GroupImage
export const HerdDetailesIcon = HerdDetailesImage

export const BolusOnlineIcon = BolusDeviceStatusOnline
export const BolusAlertIcon = BolusDeviceStatusAlert
export const BolusErrorIcon = BolusDeviceStatusError
export const AddChartPlusIcon = AddChartPlusImage
export const FavouriteIcon = FavouriteImage
export const AddChartCirclePlusIcon = AddChartCircleImage
export const FavouriteCircleIcon = AddFavouriteCircleImage
export const FullScreenCircleIcon = FullScreenCircleImage
export const AddFilterIcon = AddFilterIconImage
export const ExitFullScreenIcon = ExitFullScreenImage
