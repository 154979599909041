import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serverGetCustomers, serverInsertCustomer } from '../services/serverApi';
import { ICustomer } from '../types/customers';
import { IPostCustomer } from '../types/customers.d';

type CustomersState = {
  customers: Array<ICustomer>;
  loading: boolean;
  error: string | null;
};

const initialState: CustomersState = {
  customers: [],
  loading: false,
  error: null
};

/**Get Roles */
export const getCustomers = createAsyncThunk('getCustomers', async (s, thunkApi) => {
  try {
    const res = await serverGetCustomers();
    return res;
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit);
  }
});

/**Add device  */
export const addCustomer = createAsyncThunk('addCustomer', async (customer: IPostCustomer, thunkApi) => {
  try {
    const res = await serverInsertCustomer(customer);
    thunkApi.dispatch(getCustomers());
  } catch (e) {
    console.error(e);
  }
});

export const customerSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getCustomers.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.customers = payload as ICustomer[];
      }),
      builder.addCase(getCustomers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      }),
      builder.addCase(addCustomer.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
      }),
      builder.addCase(addCustomer.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(addCustomer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      });
  }
});

export const customerReducer = customerSlice.reducer;
export const customerActions = customerSlice.actions;
