import styled from 'styled-components'

export const TagContainer = styled.div`
  border: none;
  background-color: transparent;
  white-space: nowrap;
  display: flex;
`

export const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TagParent = styled.div`
  font-size: ${({ theme }) => theme.typography.size.xs};
  padding-right: ${({ theme }) => theme.spacing.xs};
`

export const TagParentParent = styled.div`
  font-size: ${({ theme }) => theme.typography.size.xs};
  padding-right: ${({ theme }) => theme.spacing.xs};
`
