import moment from 'moment-timezone'
import { DATE_ONE_DAY } from '../helpers/date'
import { DeviceStatusTypes } from '../../types/device.d'
import { BeesIotImages } from '../../assets/images/iotImages/BeesIot'
import { BatteryIcon } from '../../assets/images/device'

export enum SensorType {
  weight,
  temperature,
  battery
}
export const DEVICE_SITE_DROP_DOWN = 'Select Site..'

export const deviceTypes = {
  weight: {
    unit: 'Kg',
    icon: BeesIotImages.iotWeightImage,
    field: 'weight'
  },
  temperature: {
    unit: '°c',
    icon: BeesIotImages.iotTemperatureSensorImage,
    field: 'temperature'
  },
  co2: {
    unit: 'ppm',
    icon: BeesIotImages.iotCO2CloudSensorImage,
    field: 'co2'
  },
  humidity: {
    unit: '%',
    icon: BeesIotImages.iotHumidityIcon,
    field: 'humidity'
  },
  battery: {
    unit: '%',
    icon: BatteryIcon,
    field: 'batteryChargeLevel'
  }
}

export const getDeviceStatusByLastKeepAlive = (lastKeepAlive: Date) => {
  let date = moment(lastKeepAlive)
  const now = moment()
  const diff = Math.abs(date.diff(now, 'milliseconds'))
  if (diff <= DATE_ONE_DAY) {
    return DeviceStatusTypes.ok
  } else if (diff > DATE_ONE_DAY && diff <= DATE_ONE_DAY * 2) {
    return DeviceStatusTypes.warning
  } else {
    return DeviceStatusTypes.error
  }
}
export const getSensorField = (sensor: SensorType) => {
  switch (sensor) {
    case SensorType.weight:
      return deviceTypes.weight.field
    case SensorType.temperature:
      return deviceTypes.temperature.field
    case SensorType.battery:
      return deviceTypes.battery.field
  }
}
