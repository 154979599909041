import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import {
  serverGetFacilityGroupEventForTaskManager,
  serverGetFacilityGroupEventTypesByDate,
  serverUpdateFacilityGroupEventStatusTM
} from '../../../../services/serverApi'
import { useReduxSelector } from '../../../../redux'
import { ITaskStatus } from '../../Dashboard/Dashboard.option'
import { useNavigate } from 'react-router-dom'
import { getUserLocation } from '../../../../utils/helpers/location'
import { useTranslation } from 'react-i18next'

const useDayWiseTask = (day?: any) => {
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<Dayjs>(day ? dayjs(day) : dayjs())
  const [dayTask, setDayTask] = useState<any>([])
  const [filteredTaskList, setFilteredTaskList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [typeList, setTypeList] = useState<any>([])
  const [userLocation, setUserLocation] = useState<{
    lat?: number
    long?: number
  }>({ lat: undefined, long: undefined })
  const [headerInputValue, setHeaderInputValue] = useState<{
    statusTypeId: number
    search: string
  }>({
    statusTypeId: 5,
    search: ''
  })

  const getData = async (newVal?: Dayjs) => {
    setIsLoading(true)
    try {
      const res = await serverGetFacilityGroupEventTypesByDate(newVal ? newVal.format('YYYY-MM-DD') : date.format('YYYY-MM-DD'))
      setFilteredTaskList(res)
      setDayTask(res)
    } catch (error) {
      console.error('Error fetching data', error)
    } finally {
      setIsLoading(false)
    }
  }

  const usersList = useReduxSelector((state) => state.user.users)

  const getTypeList = async () => {
    setIsLoading(true)
    try {
      const res = await serverGetFacilityGroupEventForTaskManager()
      setTypeList(res)
    } catch (error) {
      console.error('Error fetching type list', error)
    } finally {
      setIsLoading(false)
    }
  }
  const navigate = useNavigate()

  useEffect(() => {
    if (day) {
      getUserLocation()
        .then((res: any) => {
          setUserLocation(res)
        })
        .catch((err: any) => {
          console.log('error in the dialog:', err)
        })
      getData()
      getTypeList()
    } else {
      navigate('/task-manager/task')
    }
  }, [])

  const onMenuItemClicked = async (id: number, statusTypeId: any) => {
    try {
      setIsLoading(true)
      let resp
      if (statusTypeId === ITaskStatus.Complete) {
        resp = await serverUpdateFacilityGroupEventStatusTM({
          statusTypeId: statusTypeId,
          taskId: id,
          lat: userLocation.lat,
          long: userLocation.long
        })
      } else {
        resp = await serverUpdateFacilityGroupEventStatusTM({ statusTypeId: statusTypeId, taskId: id })
      }
      if (resp.success) {
        await getData()
      }
    } catch (error) {
      console.error('Error updating status', error)
    } finally {
      setIsLoading(false)
    }
  }

  const userSet = new Set()
  dayTask.map((item: any) => {
    const user = usersList.find((user: any) => user.id === item.addedBy)
    userSet.add(user?.id)
  })

  const { t } = useTranslation()

  const statusTypes = [
    {
      id: 5,
      name: t('task-manager.All')
    },
    {
      id: ITaskStatus.Pending,
      name: t('task-manager.Pending')
    },
    {
      id: ITaskStatus.inProgress,
      name: t('task-manager.InProgress')
    },
    {
      id: ITaskStatus.Complete,
      name: t('task-manager.Complete')
    },
    {
      id: ITaskStatus.Overdue,
      name: t('task-manager.Overdue')
    }
  ]

  const users = Array.from(userSet).map((id: any) => {
    const user = usersList.find((user: any) => user.id === id)
    return {
      id: user?.id,
      name: `${user?.firstName} ${user?.lastName}`
    }
  })

  const handlerClear = () => {
    handleInputChange({ target: { name: 'statusTypeId', value: '' } }, true)
  }

  const handleInputChange = (event: any, cleared?: boolean) => {
    const { name, value } = event.target
    setHeaderInputValue({ ...headerInputValue, [name]: value })
    const filteredData = dayTask.filter((item: any) => {
      const { statusTypeId, search } = headerInputValue
      const isStatusTypeMatched = cleared
        ? true
        : name == 'statusTypeId' && value
        ? value == 5
          ? true
          : item.statusTypeId === value
        : statusTypeId
        ? statusTypeId == 5
          ? true
          : item.statusTypeId === statusTypeId
        : true

      const isSearchMatched =
        name == 'search'
          ? item.name.toLowerCase().includes(value.toLowerCase())
          : search
          ? item.name.toLowerCase().includes(search.toLowerCase())
          : true
      return isStatusTypeMatched && isSearchMatched
    })
    setFilteredTaskList(filteredData)
  }

  return {
    open,
    setOpen,
    dayTask,
    statusTypes,
    setIsLoading,
    isLoading,
    users,
    date,
    setDate,
    onMenuItemClicked,
    handlerClear,
    typeList,
    handleInputChange,
    headerInputValue,
    filteredTaskList,
    getData
  }
}

export default useDayWiseTask
