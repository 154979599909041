import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { DatePickerStyle } from './DatePickerStyle'

const DateCalendarValue = ({ val, onDateChangeHandler, sx }: { val: any; onDateChangeHandler: any; sx?: any }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        value={val}
        onChange={onDateChangeHandler}
        sx={{
          ...DatePickerStyle,
          ...sx
        }}
      />
    </LocalizationProvider>
  )
}

export default DateCalendarValue
