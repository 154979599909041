import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MenuRighArrowtIconImg } from '../../assets/images/global'
import { RootState, useReduxSelector } from '../../redux'
import { serverGetDataModelDetail } from '../../services/serverApi'
import { menuItems } from './AppMenu.options'

import { BackButton, Divider, DropdownContainer, MenuItem, MenuItemText, ParentMenuContainer, StyledLink, SubMenu } from './AppMenu.styled'
import useAppMenu from './useAppMenu'

const NewAppMenu = ({ appMenuRenderingProps }: any) => {
  const {
    onMenuItemsClickGeneral,
    handleMenuMouseLeave,
    setActiveMenu,
    handleLoginClick,
    activeMenu,
    handleMenuItemClick,
    handleMenuMouseEnter,
    submenuTimeout
  } = appMenuRenderingProps

  const handleBackButtonClick = () => {
    setActiveMenu(null)
  }

  const { t } = useTranslation()

  return (
    <>
      <DropdownContainer ref={submenuTimeout}>
        {/* @ts-ignore */}
        <ParentMenuContainer active={activeMenu !== null}>
          {menuItems.map((menuItem, index) => (
            <Fragment key={menuItem.id}>
              {menuItem.submenuItems || menuItem.id === 'logout' || menuItem.id === 'settings' ? (
                <MenuItem
                  // @ts-ignore
                  active={activeMenu === menuItem.id}
                  onClick={() => {
                    onMenuItemsClickGeneral(menuItem.id)
                  }}
                  onMouseEnter={() => handleMenuMouseEnter(menuItem.id)}
                  onMouseLeave={handleMenuMouseLeave}
                >
                  <img src={menuItem.icon} alt="" />
                  <MenuItemText>{t(`appMenu.${menuItem.id}`)}</MenuItemText>
                  {menuItem.submenuItems && <img src={MenuRighArrowtIconImg} alt="" />}
                </MenuItem>
              ) : (
                <StyledLink to={menuItem.path || ''}>
                  {/* @ts-ignore */}
                  <MenuItem active={activeMenu === menuItem.id}>
                    <img src={menuItem.icon} alt="" />
                    <MenuItemText>{t(`appMenu.${menuItem.id}`)}</MenuItemText>
                  </MenuItem>
                </StyledLink>
              )}
              {index !== menuItems.length - 1 && <Divider />}
            </Fragment>
          ))}
        </ParentMenuContainer>
      </DropdownContainer>
      {menuItems.map(
        (menuItem) =>
          activeMenu === menuItem.id && (
            <SubMenu onMouseEnter={() => handleMenuMouseEnter(menuItem.id)} onMouseLeave={handleMenuMouseLeave}>
              {/* <BackButton onClick={handleBackButtonClick}>
                  <img src={MenuRighArrowtIconImg} alt="" />
                </BackButton> */}
              {menuItem.submenuItems &&
                menuItem.submenuItems.map((submenuItem, ind) => (
                  <Fragment key={submenuItem.id}>
                    {submenuItem.path ? (
                      <StyledLink to={submenuItem.path}>
                        <MenuItem onClick={() => handleMenuItemClick(submenuItem.id)}>
                          <img src={submenuItem.icon} alt="" />
                          <MenuItemText>{t(`appMenu.${submenuItem.id}`)}</MenuItemText>
                        </MenuItem>
                      </StyledLink>
                    ) : (
                      <MenuItem
                        onClick={
                          menuItem.id === 'products' ? () => handleLoginClick(submenuItem.onClickDataModelId) : () => console.log('clicked')
                        }
                      >
                        <img src={submenuItem.icon} alt="" />
                        <MenuItemText>{t(`appMenu.${submenuItem.id}`)}</MenuItemText>
                      </MenuItem>
                    )}
                    {ind !== menuItem.submenuItems!.length - 1 && <Divider />}
                  </Fragment>
                ))}
            </SubMenu>
          )
      )}
    </>
  )
}

export default NewAppMenu
