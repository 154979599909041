import { DeviceTypes } from '../../types/device.d'
import { WaterIcon } from '../../assets/images/crop'
import { RadarIcon, TabletIcon, WeightIcon } from '../../assets/images/summary'
import { gatewayIcon } from '../../assets/images/gateway'

export const RadarDevicesConfig = {
  RadarSensor: {
    name: 'Radar Sensor',
    Icon: RadarIcon,
    deviceType: DeviceTypes.RadarSensor
  },
  Laser: {
    name: 'Laser',
    Icon: RadarIcon,
    deviceType: DeviceTypes.Laser
  }
}

export const gateWaysConfig = {
  Gateway_Type_A: {
    name: 'Gateway Type A',
    Icon: gatewayIcon,
    deviceType: DeviceTypes.Gateway
  }
  // Gateway_Type_B: {
  //   name: 'Gateway Type B',
  //   Icon: gatewayIcon,
  //   deviceType: DeviceTypes.Gateway
  // }
}
