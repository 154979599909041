import styled from 'styled-components';

interface TabProps {
  selected?: boolean;
}

export const AnalysisTabsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.xs};
`;

export const AnalysisTab = styled.div<TabProps>`
  background-color: ${({ selected }) => (selected ? '#FFD500' : 'white')};
  gap: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #707070;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const AnalysisTabText = styled.span<TabProps>`
  font-weight: ${({ theme }) => theme.typography.bold.md};
  font-size: ${({ theme }) => theme.typography.size.sm};
  color: ${({ theme, selected }) => (selected ? theme.typography.colors.secondary : theme.typography.colors.primary)};
`;

export const AnalysisTabImg = styled.div`
  width: 30px;
  height: 30px;
`;
