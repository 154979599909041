import { uploadBGImg } from '../../assets/images/global'
import { UPLOAD_FILE } from '../../utils/consts/devicesVersion'
import { UploadDiv, UploadFileImg, UploadFileInput, UploadFileText } from './AppUpload.styled'

interface AppUploadFileProps {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dynId?: string
}

/**
 * A file upload component.
 *
 * @param {function} handleFileChange - Function to handle file changes.
 * @param {string} [dynId='fileUpload'] - Dynamic ID of the file input element. **Only provide a value when multiple file upload buttons exist on the same page**.
 */

const AppUploadFile = ({ handleFileChange, dynId = 'fileUpload' }: AppUploadFileProps) => {
  return (
    <UploadDiv onClick={() => document.getElementById(dynId)!.click()}>
      <UploadFileImg src={uploadBGImg} alt="Upload Button" />
      <UploadFileText>{UPLOAD_FILE}</UploadFileText>
      <UploadFileInput type="file" hidden onChange={handleFileChange} id={dynId} />
    </UploadDiv>
  )
}

export default AppUploadFile
