import React from 'react'
import { BackdropStyle, ModalStyle, ModalWrapper } from './ModalMobileView.styled'

interface ModelProps {
  show: boolean
}

const Modal: React.FC<ModelProps> = (props) => {
  return (
    <BackdropStyle>
      <ModalWrapper>
        <ModalStyle>{props.children}</ModalStyle>
      </ModalWrapper>
    </BackdropStyle>
  )
}
export default Modal
