import React, { useState } from 'react'
import { RootState, useReduxSelector } from '../../../redux'
import AppBarUser from './AppBarUser/AppBarUser'
import AppBarLogo from './AppBarLogo/AppBarLogo'
import AppBarNotification from './AppBarNotification/AppBarNotification'
import { locationIcon, timeIcon } from '../../../assets/images/appBar/index'
import AppDataFilter, { DateButtons } from './AppDateFilter/AppDateFilter'
import {
  AppbarWrapper,
  MiddleWrapper,
  DateInfoWrapper,
  CustomDateInfoWrapper,
  ApplocationWrapper,
  AppDateWrapper,
  LocationIcon,
  DateIcon,
  DateFilter,
  AppBarNotificationWrapper,
  ProfileNotificationWrapper,
  SkeletonCss
} from './Appbar.styled'
import AppbarLocationComponent from './AppLocationFilter/AppLocationFilter'
import Todaydate from './AppDateFilter/TodayDate/Todaydate'
import useAnalytics from '../../../hooks/useAnalysis'
import { DateType } from '../../../types/analysis.d'
import CustomDatesPicker from './AppDateFilter/CustomDate/CustomDatesPicker'
import { OnlyMobileContainer } from '../../../styles/PoultryWeights/global.styled'
import { MobileAppbar } from './MobileAppbar/MobileAppbar'
import PwNotificationDialog from '../../../dialogs/NotificationDialog/PoultryWeights/NotificationDialog'
import { Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import useNotification from '../../../hooks/useNotification'
import { GetuserName } from '../../../utils/helpers/user'

interface Props {
  serverLoading?: any
}

export const RadarAppbar: React.FC<Props> = ({ serverLoading }) => {
  const { unSeenNotificationData } = useNotification()
  const { selectedDateType, handleDateChange, handleChange } = useAnalytics()
  const [openNotificationDialog, setOpenNotificaitonDialog] = useState(false)
  const user: any = useReduxSelector((state: RootState) => state.user.user)
  const [selectedDateButton, setSelectedDateButton] = useState(DateButtons.Live)
  const [selectedIotDateButton, setSelectedIotDateButton] = useState(DateButtons.Live)
  const { isOnePicker } = useReduxSelector((state: RootState) => state.time)
  const handleDateButtonChange = (selectedButton: DateButtons) => setSelectedDateButton(selectedButton)
  const handleIotDateButtonChange = (selectedButton: DateButtons) => setSelectedIotDateButton(selectedButton)
  const location = useLocation()
  const hanldeNotificationClick = () => {
    setOpenNotificaitonDialog(!openNotificationDialog)
  }
  return (
    <>
      <AppbarWrapper>
        {serverLoading ? <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarLogo} /> : <AppBarLogo />}
        <MiddleWrapper>
          <ApplocationWrapper>
            {serverLoading ? <Skeleton variant="circular" sx={SkeletonCss.LocationIcon} /> : <LocationIcon src={locationIcon} />}
            {serverLoading ? (
              <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppbarLocationComponent} />
            ) : (
              <AppbarLocationComponent />
            )}

            <ProfileNotificationWrapper>
              {serverLoading ? (
                <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarNotification} />
              ) : (
                <AppBarNotificationWrapper
                  onClick={(e) => {
                    hanldeNotificationClick()
                  }}
                >
                  <AppBarNotification unSeenNotificaitonCount={unSeenNotificationData} />
                </AppBarNotificationWrapper>
              )}
              {openNotificationDialog && (
                <PwNotificationDialog open={openNotificationDialog} onSuccess={hanldeNotificationClick} onClose={hanldeNotificationClick} />
              )}
              {serverLoading ? (
                <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarUser} />
              ) : (
                <AppBarUser userName={GetuserName(user)} />
              )}
            </ProfileNotificationWrapper>
          </ApplocationWrapper>

          <DateFilter>
            {serverLoading ? <Skeleton variant="rectangular" sx={SkeletonCss.DateIcon} /> : <DateIcon src={timeIcon} />}
            <AppDateWrapper>
              {serverLoading ? (
                <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppDataFilter} />
              ) : (
                <AppDataFilter
                  selectedIotButton={selectedIotDateButton}
                  selectedButton={selectedDateButton}
                  showCustom={isOnePicker}
                  handleChange={handleDateButtonChange}
                  handleIotChange={handleIotDateButtonChange}
                />
              )}
            </AppDateWrapper>
            {location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap') ? (
              <DateInfoWrapper>
                <div>
                  {selectedIotDateButton === DateButtons.Today || DateButtons.Live === selectedIotDateButton ? (
                    serverLoading ? (
                      <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.TodayDateContainer} />
                    ) : (
                      <Todaydate />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {selectedIotDateButton === DateButtons.Custom ? (
                  serverLoading ? (
                    <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.CustomDateInfoWrapper} />
                  ) : (
                    <CustomDateInfoWrapper>
                      {
                        <CustomDatesPicker
                          clear={selectedDateType !== DateType.picker}
                          onChange={(date) => handleDateChange(date, DateType.picker, '')}
                        />
                      }
                    </CustomDateInfoWrapper>
                  )
                ) : (
                  <></>
                )}
              </DateInfoWrapper>
            ) : (
              <DateInfoWrapper>
                <div>
                  {selectedDateButton === DateButtons.Today || DateButtons.Live === selectedDateButton ? (
                    serverLoading ? (
                      <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.TodayDateContainer} />
                    ) : (
                      <Todaydate />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {selectedDateButton === DateButtons.Custom ? (
                  serverLoading ? (
                    <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.CustomDateInfoWrapper} />
                  ) : (
                    <CustomDateInfoWrapper>
                      {
                        <CustomDatesPicker
                          clear={selectedDateType !== DateType.picker}
                          onChange={(date) => handleDateChange(date, DateType.picker, '')}
                        />
                      }
                    </CustomDateInfoWrapper>
                  )
                ) : (
                  <></>
                )}
              </DateInfoWrapper>
            )}
          </DateFilter>
        </MiddleWrapper>
      </AppbarWrapper>
      <OnlyMobileContainer>
        <MobileAppbar />
      </OnlyMobileContainer>
    </>
  )
}
