import React from 'react'
import { BackdropStyle, ModalStyle, DrawerWrapper } from './Drawer.styled'

interface ModelProps {
  show: boolean
}

const Drawer: React.FC<ModelProps> = (props) => {
  return (
    <BackdropStyle isopen={props.show}>
      <DrawerWrapper isopen={props.show}>
        <ModalStyle>{props.children}</ModalStyle>
      </DrawerWrapper>
    </BackdropStyle>
  )
}
export default Drawer
