export const deviceVersionTableOptions = {
  maxBodyHeight: 'calc(100vh - 230px)',
  minBodyHeight: 'calc(40vh - 230px)',
  pageSize: 3,
  search: true,
  toolbar: true,
  showTextRowsSelected: false,
  showFirstLastPageButtons: false,
  paging: true,
  showTitle: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
  pageSizeOptions: [3],
  rowStyle: {
    color: '#7B7B7B',
    padding: '0 1rem',
    whiteSpace: 'noWrap'
  },
  headerStyle: {
    color: '#7B7B7B',
    padding: '0 1rem',
    fontWeight: '600'
  },
  cellStyle: { padding: '0.25rem 0.5rem', height: 55 }
}

export const installationTime = 240
export enum DeviceVersionStatus {
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  INSTALLING = 'installing',
  PENDING = 'pending'
}
