import React, { Suspense } from 'react'
import { GetDataModelId } from '../../utils/helpers/dataModel'
import { DataModel } from '../../types/dataModel.d'
import { PoultryWeightsAppbar } from './PoultryWeights/Appbar'
import { BeesAppbar } from './Bees/Appbar'
import { RuminTechAppbar } from './RuminTech/Appbar'
import { BpacAppbar } from './Bpac/Appbar'
import { RadarAppbar } from './Radar/Appbar'

interface Props {
  serverLoading?: any
}

const Appbar: React.FC<Props> = ({ serverLoading }) => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return <PoultryWeightsAppbar serverLoading={serverLoading} />
    case DataModel.RuminTech:
      return <RuminTechAppbar serverLoading={serverLoading} />
    case DataModel.Bpac:
      return <BpacAppbar serverLoading={serverLoading} />
    case DataModel.Radar:
      return <RadarAppbar serverLoading={serverLoading} />
    case DataModel.Bees:
      return <BeesAppbar />
    case DataModel.Gas:
      return <RuminTechAppbar serverLoading={serverLoading} />

    default:
      return <PoultryWeightsAppbar />
  }
}

export default Appbar
