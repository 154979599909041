import styled from 'styled-components'

export const TypeImage = styled.img`
  width: 20px;
  height: 20px;
`

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: -webkit-fill-available;
  background: white;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.md};
`

export const NotificationContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  gap: 5px;
`

export const MessageBox = styled.span`
  width: 172px;
  height: auto;
  white-space: normal;
  word-wrap: break-word;
`

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NotificationHeader = styled.h4`
  text-align: center;
  vertical-align: middle;
  display: flex;
  margin-left: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.primary};
`
export const CircleImg = styled.div`
  font-size: 10px;
  height: 10px;
  width: 10px;
  margin: 10px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
`
export const DateContainer = styled.div`
display: flex;
align-items: center;
gap: 10px;
`
