export const loginConsts = {
  WELCOME_TO_COLONY: 'Welcome to the Colony',
  SIGN_IN: 'Sign in',
  FORGOT_PASSWORD: 'Forgot Password?',
  RESET_PASSWORD: 'Reset Password',
  OTP_LOGIN: 'OTP Login',
  GET_OTP: 'Get OTP'
}

export enum LocationType {
  Customer = 0,
  FacilityGroup = 1,
  Facility = 2,
  Zone = 3
}
