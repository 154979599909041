import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const DropdownContainer = styled.div`
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25);
  gap: 12px;
  border: ${(props) => `1px solid rgba(177, 171, 171, 0.25)`};
`

export const ParentMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;

  &:hover {
    background-color: #f4f5f7;
  }

  ${(props) =>
    // @ts-ignore
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
    `}
`

export const MenuItemText = styled.div`
  width: 93px;
`

export const Divider = styled.div`
  background-color: #f5f5f5;
  height: 1px;
`

export const SubMenu = styled.div`
  position: absolute;
  right: 203%;
  top: 0;
  width: 100%;
  max-height: 300px;
  border-radius: 8px;
  background-color: #fff;
  border-radius: 8px;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  border: ${(props) => `1px solid rgba(177, 171, 171, 0.25)`};
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25);
`

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  :hover {
    text-decoration: none;
    color: inherit;
  }
`

export const AppMenuContainer = styled.div`
  position: relative;
`

export const MenuButton = styled.div`
  cursor: pointer;
`

export const DropdownMenu = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 11;
  border-radius: 8px;
`
