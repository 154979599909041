import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {serverGetRoles} from '../services/serverApi';
import { IRole } from '../types/roles.d';

type RolesState = {
    roles: Array<IRole>;
    loading: boolean;
    error: string | null;
};

const initialState: RolesState = {
    roles: [],
    loading: false,
    error: null
};

/**Get Roles */
export const getRoles = createAsyncThunk('getRoles', async (s, thunkApi) => {
    try {
        const res = await serverGetRoles();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
});


export const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder): any => {
        builder.addCase(getRoles.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(getRoles.fulfilled, (state, {payload}) => {
                state.error = null;
                state.loading = false;
                state.roles = payload as IRole[];
            }),
            builder.addCase(getRoles.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = (payload as ErrorEventInit).error;
            })
    },
});

export const roleReducer = roleSlice.reducer;
export const roleActions = roleSlice.actions;
