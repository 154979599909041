import { gateWaysConfig, BpacDevicesConfig } from './Devices'
import { BpacConfig } from './GlobleConfig'
import { SENSORS } from './Sensors'

export const Config = {
  global: BpacConfig,
  devices: BpacDevicesConfig,
  gateWays: gateWaysConfig,
  sensors: SENSORS,
  harvest: '',
  language: undefined
}
