import styled from 'styled-components'

export const AppBarWrapper = styled.div`
  border-radius: 5px;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const AppBarIcon = styled.img`
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
`
