import { gateWaysConfig, RadarDevicesConfig } from './Devices'
import { RadarConfig } from './GlobleConfig'
import { RadarLanguage } from './Language'
import { HARVEST, SENSORS } from './Sensors'

export const Config = {
  global: RadarConfig,
  devices: RadarDevicesConfig,
  gateWays: gateWaysConfig,
  sensors: SENSORS,
  harvest: HARVEST,
  language: RadarLanguage
}
