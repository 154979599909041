import { ICustomer, IPostCustomer } from '../types/customers.d'
import { AddDevice, Idevice, IDeviceParams, IDeviceSupplier, IDeviceTypes, IGetRssi } from '../types/device.d'
import { IConsumption, ICropReport, IHarvest, IHarvestMortality, IHarvestTarget } from '../types/harvest.d'
import { IotEvent, IotSiloData, IotSiloDataRequest, IWaterIntakeParams } from '../types/iot.d'
import { IPutUser, Iuser, IChangePassword, IUser, IdeleteUser } from '../types/user.d'
import { POULTRYWEIGHTS } from '../utils/consts/dataModel'
import { IFacilityGroup, IPutFacilitygroup, IotShedsInfo } from '../types/facilityGroup.d'
import { IFacilityGroupEvent, IFacilityGroupEventType } from 'facilityGroupEvents'
import { IInsertFacilityGroupEvent } from '../types/facilityGroupEvents.d'
import { IFacility } from '../types/facility.d'
import { IAttachedMaterial, IMaterialPost, IMaterialPut, IPostAttachedMaterial, IPutAttachedMaterial } from '../types/material.d'
import { IFacilityZones, IFacilityZoneType } from '../types/facilityZones.d'
import { IManualEvent } from '../types/manualEvents'
import { ISensorPageData } from 'sensor'
import { INotification } from 'notifications'
import dm, { GetDataModelId } from '../utils/helpers/dataModel'
import { IRole } from 'roles'
import { IAnalysis, IAnalysisEfficiency, IGetAnalysis } from 'analysis'
import { JoinIds } from '../utils/helpers/serverAPIHelper'
import { IMonitoredGroup, IMonitorSchedule, IPostMonitoredGroup } from '../types/monitoredGroup.d'
import { IMonitoredUnits } from '../types/monitoredUnits.d'
import { IAnalysisReports, IMonitoredDrinkingEvents } from '../types/analysisReports.d'
import {
  IIngredientCost,
  IInventoryIngredient,
  IInventoryRecipe,
  IInventoryRecipeIngredient,
  IMonitoredDistribution,
  IRecipeCost
} from 'inventory'
import eventBus from '../utils/helpers/eventBus'
import moment from 'moment-timezone'
import { Interval } from '../utils/consts/analysis'
import { sortBy } from 'cypress/types/lodash'
import { Field } from 'formik'
import { PaymentMethod } from '@stripe/stripe-js'

let serverUrl = 'https://api.zeusint.com/api/'
if (window.location.host.indexOf('localhost') > -1) {
  // serverUrl = 'http://localhost:3010/api/'
}

const errorCodes = [500, 400, 401, 409]

const serverRequest = async (
  url: string,
  command: string,
  data: any,
  token?: boolean | null,
  fileHandle?: boolean,
  isForm?: boolean,
  version?: string
) => {
  const headers: any = {
    // 'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*'
  }
  if (token) {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    headers.authorization = `Token ${user?.token}`
  }
  const params: any = {
    method: command,
    mode: 'cors',
    cache: 'no-cache',
    headers: headers
  }
  if (data && !isForm) {
    params.headers['Content-Type'] = 'application/json'
    params.body = JSON.stringify(data)
  } else if (isForm) {
    params.body = data
  }

  let resCode
  try {
    const response = await fetch(serverUrl + (version ?? 'v1') + url, params)

    let res = undefined
    if (!fileHandle) {
      res = await response.json()
    }

    if ((command === 'DELETE' || command === 'POST' || command === 'PUT') && '/users/login' !== url) {
      eventBus.dispatch('snackbar', { type: command, statusCode: response.status, message: res?.message })
    }

    resCode = response.status
    if (resCode === 401) {
      eventBus.dispatch('unAuthorized', { statusCode: response.status })
    }
    if (errorCodes.find((code) => code === response.status)) {
      throw res
    }
    return fileHandle ? response : res
  } catch (e) {
    if (resCode === 401) {
      localStorage.removeItem('user')
    }
    throw e
  }
}

export const serverLogin = async (email: string, password: string, dataModelCustom?: number) => {
  const dataModel = new dm()
  const dataModelId = dataModelCustom ?? GetDataModelId()
  const res = await serverRequest('/users/login', 'POST', {
    userName: email,
    password,
    dataModelId: dataModelId ? dataModelId : POULTRYWEIGHTS
  })
  return res
}

export const serverLoginWithToken = async (token: string) => {
  const dataModel = new dm()
  const dataModelId = GetDataModelId() ?? POULTRYWEIGHTS

  const res = await serverRequest('/users/loginWithToken', 'POST', { token, dataModelId })
  return res
}
export const serverValidateToken = async (token: string | undefined) => {
  const res = await serverRequest('/users/signup/validateToken', 'POST', { token })
  return res
}
export const serverGetDataModels = async () => {
  const res = await serverRequest('/users/getDataModels', 'GET', null, true)
  return res
}

export const serverEditUser = async (user: IPutUser) => {
  const res = await serverRequest(`/users`, 'PUT', user, true)
  return res
}
export const serverEditUserDetails = async (user: IPutUser) => {
  const res = await serverRequest(`/users/editUserDetails`, 'PUT', user, true)
  return res
}
export const serverInviteUser = async (email: string, roleId: number, customCustomerId?: number) => {
  const res = await serverRequest(`/users/sendInvitation`, 'POST', { email, roleId, customCustomerId }, true)
  return res
}

export const serverInviteUserByCreating = async (
  firstName: string,
  lastName: string,
  email: string,
  roleId: number,
  customCustomerId: number,
  customPassowrd: string
) => {
  const res = await serverRequest(
    `/users/createAndInviteUser`,
    'POST',
    { firstName, lastName, email, roleId, customCustomerId, customPassowrd },
    true
  )
  return res
}
export const serverReInviteUser = async (userId: number) => {
  const res = await serverRequest(`/users/reSendInvitation`, 'POST', { userId }, true)
  return res
}
export const serverUpdateProfile = async (user: IPutUser) => {
  const res = await serverRequest(`/users/updateProfile`, 'PUT', user, true)
  return res
}

export const serverChangePassword = async (userPass: IChangePassword) => {
  const res = await serverRequest(`/users/changePassword`, 'PUT', userPass, true)
  return res
}

export const serverForgotPassword = async (email: string, dataModelId: number) => {
  const res = await serverRequest(`/users/forgotPassword`, 'POST', { email: email, dataModelId: dataModelId }, true)
  return res
}
export const serverResetPassword = async ({ password, token }: { password: string; token: string }) => {
  const res = await serverRequest(`/users/resetPassword/${token}`, 'POST', { password }, true)
  return res
}
export const serverGetOTP = async (email: string, dataModelId: number, type: string) => {
  const res = await serverRequest(`/users/getOTP`, 'POST', { email: email, dataModelId: dataModelId, type: type }, true)
  return res
}
export const serverloginWithOTP = async (email: string, dataModelId: number, token: string, otp: number) => {
  const res = await serverRequest(`/users/loginWithOTP`, 'POST', { email: email, dataModelId: dataModelId, token: token, otp: otp }, true)
  return res
}
// Roles
export const serverGetRoles = async () => {
  const res = await serverRequest(`/roles`, 'GET', null, true)
  return res
}

export const serverAddRole = async (role: IRole) => {
  return serverRequest(`/roles`, 'POST', role, true)
}
export const serverUpdateRole = async (role: IRole) => {
  return serverRequest(`/roles`, 'PUT', role, true)
}
export const serverDeleteRole = async (role: IRole) => {
  return serverRequest(`/roles`, 'DELETE', role, true)
}
// getting all assigned permissions to user
export const serverGetPermissions = async () => {
  const res = await serverRequest(`/permissions`, 'GET', null, true)
  return res
}

export const serverGetPermissionsByDataModelId = async () => {
  const res = await serverRequest(`/permissions/getByDataModelId`, 'GET', null, true)
  return res
}
export const serverGetUsers = async (): Promise<(IUser & { roleName: string })[]> => {
  const res = await serverRequest(`/users`, 'GET', null, true)
  return res
}

// delete user
export const serverDeleteUser = async (id: number) => {
  const res = await serverRequest(`/users/${id}`, 'DELETE', null, true)
  return res
}

// get current user details
export const serverGetUser = async (): Promise<Iuser[]> => {
  const res = await serverRequest(`/users/getUser`, 'GET', null, true)
  return res
}

/***Facility groups */
export const serverCreateFacilityGroup = async (facilityGroup: IFacilityGroup) => {
  return serverRequest('/facilitygroup', 'POST', facilityGroup, true)
}

export const serverGetFacilitiesGroups = async (customerId?: number): Promise<IFacilityGroup[] | undefined> => {
  let url = `/facilitygroup`
  if (customerId) {
    url += `?customerId=${customerId}`
  }
  try {
    const res = await serverRequest(url, 'GET', null, true)
    return res
  } catch (e) {
    console.error(e)
  }
}

export const serverEditFacilityGroup = async (facilityGroupEditFields: IPutFacilitygroup) => {
  const res = await serverRequest(`/facilitygroup`, 'PUT', facilityGroupEditFields, true)
  return res
}

/******* Facilities *****/
export const serverGetFacilities = async (): Promise<IFacility[] | undefined> => {
  let url = `/facility`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const serverCreateFacility = async (facility: IFacility): Promise<[{ newId: number }]> => {
  let url = `/facility`
  const res = await serverRequest(url, 'POST', facility, true)
  return res
}
/*****Harvests *******/

export const serverGetHarvests = async (): Promise<IHarvest[]> => {
  const res = await serverRequest(`/harvest`, 'GET', null, true)
  return res
}

export const serverGetMortalityByHarvestId = async (harvestIds: number[]) => {
  const res = await serverRequest(`/harvest/getMortalityByHarvestId?harvestIds=${harvestIds}`, 'GET', null, true)
  return res
}

export const serverCreateHarvest = async (harvest: IHarvest | undefined) => {
  return serverRequest(`/harvest`, 'POST', harvest, true)
}

export const serverCloseHarvest = async (harvestId: number) => {
  return serverRequest(`/harvest/close/` + harvestId, 'PUT', {}, true)
}

export const serverGetTargetByHarvestId = async (harvestId: number, startDate: Date | string) => {
  const res = await serverRequest(`/harvest/getMetricTargetByHarvestId?harvestId=${harvestId}&startDate=${startDate}`, 'GET', null, true)
  return res
}
export const serverGetHarvestMetricTarget = async (harvestId: number) => {
  const res = await serverRequest(`/harvest/getHarvestMetricTarget?harvestId=${harvestId}`, 'GET', null, true)
  return res
}
export const serverGetMetricTargetByStartDate = async (harvestId: number, startDate: number, endDate: number) => {
  const res = await serverRequest(
    `/harvest/GetMetricTargetByStartDate?harvestId=${harvestId}&startDate=${startDate}&endDate=${endDate}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetHarvestSummary = async (harvestIds: number[] | null) => {
  const res = await serverRequest(`/harvest/getHarvestSummary?harvestIds=${harvestIds}`, 'GET', null, true)
  return res
}

/********* Harvest mortality *********/
export const serverCreateHarvestMortality = async (harevstMortality: IHarvestMortality) => {
  const res = await serverRequest(`/harvest/mortality`, 'POST', harevstMortality, true)
  return res
}

export const serverUpdateHarvestMortality = async (harevstMortality: IHarvestMortality) => {
  const res = await serverRequest(`/harvest/mortality`, 'PUT', harevstMortality, true)
  return res
}

export const serverDeleteHarvestMortality = async (harevstMortalityId: number) => {
  const res = await serverRequest(`/harvest/mortality/${harevstMortalityId}`, 'Delete', {}, true)
  return res
}

/** Devices */

export const serverCreateDevice = async (device: AddDevice) => {
  const res = await serverRequest('/regdevice', 'POST', device, true)
  return res
}

export const serverGetDevice = async (): Promise<Idevice[]> => {
  const res = await serverRequest(`/devices`, 'GET', null, true)
  return res
}

export const serverGetDeviceVersion = async (deviceTypeId: number) => {
  const res = await serverRequest(`/devices/deviceVersion?deviceTypeId=${deviceTypeId}`, 'GET', null, true)
  return res
}
export const serverGetDeviceVersionHistory = async (deviceId: number) => {
  const res = await serverRequest(`/devices/version/history?deviceId=${deviceId}`, 'GET', null, true)
  return res
}

export const serverGetDeviceUpdateVersion = async () => {
  const res = await serverRequest('/devices/deviceUpdateVersion', 'GET', null, true)
  return res
}

export const serverDeleteDeviceUpdateVersion = async (id: number) => {
  const res = await serverRequest(`/devices/deviceUpdateVersion/${id}`, 'DELETE', null, true)
  return res
}

export const serverInsertUpdateVersion = async (data: any) => {
  const res = await serverRequest('/devices/updateVersion', 'POST', data, true)
  return res
}

export const serverGetDeviceTypes = async (): Promise<IDeviceTypes[]> => {
  const res = await serverRequest(`/devices/types`, 'GET', null, true)
  return res
}
export const serverGetDeviceDataUsage = async (data: any): Promise<any[]> => {
  const res = await serverRequest(
    `/devices/simUsage?simId=${data.simId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    'GET',
    null,
    true
  )
  return res
}

// Add Device
export const serverAddDevice = async (device: AddDevice) => {
  return serverRequest('/devices', 'POST', device, true)
}

// Download gateWay certificate
export const serverDownloadCertificate = async (deviceId: number, fileType: string) => {
  const res = await serverRequest(`/devices/certificate?deviceId=${deviceId}&fileType=${fileType}`, 'GET', null, true, true)
  return res
}
export const serverGetLogsByDeviceId = async (deviceId: number, startDate: number, endDate: number) => {
  const res = await serverRequest(`/devices/logs?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}`, 'GET', null, true)
  return res
}
// Update Device
export const serverUpdateDevice = async (deviceId: number, device: Idevice) => {
  return serverRequest('/devices/' + deviceId, 'PUT', device, true)
}

export const serverUpdateDeviceVersion = async (data: any) => {
  const res = await serverRequest(`/devices/version/`, 'POST', data, true, false, true)
}

// delete device
export const serverDeleteDevice = async (deviceId: number) => {
  const res = await serverRequest('/devices/' + deviceId, 'DELETE', {}, true)
  return res
}

// delete customer
export const serverDeleteCustomer = async (customerId: number) => {
  const res = await serverRequest('/customers/' + customerId, 'DELETE', {}, true)
  return res
}

// delete facility
export const serverDeleteFacility = async (facilityId: number) => {
  const res = await serverRequest('/facility/' + facilityId, 'DELETE', {}, true)
  return res
}

// delete facilitygroup
export const serverDeleteFacilityGroup = async (facilityGroupId: number) => {
  const res = await serverRequest('/facilityGroup/' + facilityGroupId, 'DELETE', {}, true)
  return res
}

// delete facilityZone
export const serverDeleteFacilityZone = async (facilityZoneId: number) => {
  const res = await serverRequest('/facilityZone/' + facilityZoneId, 'DELETE', {}, true)
  return res
}

// delete api key
export const serverDeleteApiKey = async (apiKeyId: number) => {
  const res = await serverRequest('/users/accessKey/' + apiKeyId, 'DELETE', {}, true)
  return res
}

// update facility
export const serverUpdateFacility = async (facilityId: number, facility: IFacility) => {
  return serverRequest('/facility/' + facilityId, 'PUT', facility, true)
}

// Link Device to site
export const serverLinkDeviceToSite = async (deviceId: number, siteId: number) => {
  return serverRequest('/devices/link/' + deviceId, 'PUT', { facilityGroupId: siteId }, true)
}

export const serverGetCustomers = async (): Promise<ICustomer[]> => {
  const res = await serverRequest(`/customers`, 'GET', null, true)
  return res
}

export const serverInsertCustomer = async (customer: IPostCustomer): Promise<any> => {
  const res = await serverRequest(`/customers`, 'POST', customer, true)
  return res
}

export const serverGetLatestIotEvents = async (facilityGroupId: number) => {
  const res = await serverRequest(`/iot/latest?facilityGroupId=${facilityGroupId}`, 'GET', null, true)
  return res
}

export const serverGetNotifications = async (): Promise<INotification[]> => {
  const res = await serverRequest(`/notifications`, 'GET', null, true)
  return res
}

export const serverMarkNotificationAsSeen = async (notification: number) => {
  const res = await serverRequest('/notifications/markNotificationAsSeen', 'POST', { notificationId: notification }, true)
  return res
}

export const getIotEvents = async (
  serialNumber: string,
  startDate: Date,
  endDate: Date,
  isDailyAggregate: boolean
): Promise<IotEvent[]> => {
  let url = `/iot?serialNumber=${serialNumber}&startDate=${startDate}&endDate=${endDate}`
  if (isDailyAggregate) url += `&isDailyAggregate=true`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const getIotEfficiency = async ({
  startDate,
  endDate,
  interval,
  facilityId,
  facilityGroupId
}: {
  startDate: number
  endDate: number
  interval: number
  facilityId?: number
  facilityGroupId?: number
}): Promise<IotEvent[]> => {
  let url = `/iot/efficiency?startDate=${startDate}&endDate=${endDate}&interval=${interval}`
  if (facilityId) url += `&facilityId=${facilityId}`
  if (facilityGroupId) url += `&facilityGroupId=${facilityGroupId}`
  const res = await serverRequest(url, 'GET', null, true, false, false, 'v2')
  return res
}

export const serverGetIotCustomerEvents = async (interval: string) => {
  const _interval = interval === '24H' ? 1 : 7
  const res = await serverRequest(`/iot/facilitiesgroup?interval=${_interval}`, 'GET', null, true)
  return res
}

export const serverGetIotCustomerEventsByDeviceId = async (startDate: any, endDate: any, deviceId: number) => {
  const res = await serverRequest(
    `/iot/iotEvents/byDeviceId?startDate=${startDate}&endDate=${endDate}&deviceId=${deviceId}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetAnalysis = async (data: IGetAnalysis): Promise<any> => {
  const facilities = data.facilityId ? (Array.isArray(data.facilityId) ? data.facilityId : [data.facilityId]) : []
  const facilityGroups = data.facilityGroupId ? (Array.isArray(data.facilityGroupId) ? data.facilityGroupId : [data.facilityGroupId]) : []
  const monitoredGroups = data.monitoredGroupId
    ? Array.isArray(data.monitoredGroupId)
      ? data.monitoredGroupId
      : [data.monitoredGroupId]
    : []
  const facilityZones = data.facilityZoneId ? (Array.isArray(data.facilityZoneId) ? data.facilityZoneId : [data.facilityZoneId]) : []
  const devices = data.deviceId ? (Array.isArray(data.deviceId) ? data.deviceId : [data.deviceId]) : []
  const customers = data.customerId ? (Array.isArray(data.customerId) ? data.customerId : [data.customerId]) : []
  let url = `/iot/analysis?startDate=${data.startDate}&endDate=${data.endDate}&sensors=${data.sensors}&interval=${
    data.interval ?? undefined
  }`
  if (customers?.length) {
    url += `&customerId=${JoinIds(customers)}`
  }
  if (facilities?.length) {
    url += `&facilityId=${JoinIds(facilities)}`
  }
  if (facilityGroups?.length) {
    url += `&facilityGroupId=${JoinIds(facilityGroups)}`
  }
  if (monitoredGroups?.length) {
    url += `&monitoredGroupId=${JoinIds(monitoredGroups)}`
  }
  if (devices?.length) {
    url += `&deviceId=${JoinIds(devices)}`
  }
  if (facilityZones?.length) {
    url += `&facilityZoneId=${JoinIds(facilityZones)}`
  }
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const serverGetAnalysisForHeatMap = async (data: IGetAnalysis): Promise<any> => {
  const facilities = data.facilityId ? (Array.isArray(data.facilityId) ? data.facilityId : [data.facilityId]) : []
  const facilityGroups = data.facilityGroupId ? (Array.isArray(data.facilityGroupId) ? data.facilityGroupId : [data.facilityGroupId]) : []
  const facilityZones = data.facilityZoneId && Array.isArray(data.facilityZoneId) ? data.facilityZoneId : []
  const devices = data.deviceId ? (Array.isArray(data.deviceId) ? data.deviceId : [data.deviceId]) : []
  let url = `/iot/analysis?includeZoneInfo=${data.includeZoneInfo ? 1 : 0}&startDate=${data.startDate}&endDate=${data.endDate}&sensors=${
    data.sensors
  }&interval=${data.interval ?? 10}`
  if (facilities?.length) {
    url += `&facilityId=${JoinIds(facilities)}`
  }
  if (facilityGroups?.length) {
    url += `&facilityGroupId=${JoinIds(facilityGroups)}`
  }
  if (devices?.length) {
    url += `&deviceId=${JoinIds(devices)}`
  }
  if (facilityZones?.length) {
    url += `&facilityZoneId=${JoinIds(facilityZones)}`
  }
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const serverGetIotCustomerEventsBySiteId = async (startDate: any, endDate: any, siteId: number) => {
  const res = await serverRequest(`/iot/iotEvents/bySiteId?startDate=${startDate}&endDate=${endDate}&siteId=${siteId}`, 'GET', null, true)
  return res
}

export const serverGetIotCustomerCustomizedEventsByDeviceId = async (startDate: any, endDate: any, deviceId: number) => {
  const res = await serverRequest(
    `/iot/iotEvents/customized/byDeviceId?startDate=${startDate}&endDate=${endDate}&deviceId=${deviceId}`,
    'GET',
    null,
    true
  )
  return res
}
export const serverGetRssiByDeviceId = async (data: IGetRssi) => {
  const res = await serverRequest(
    `/devices/deviceRssi?startDate=${data.startDate}&endDate=${data.endDate}&deviceId=${data.deviceId}&interval=${data.interval}`,
    'GET',
    null,
    true,
    false,
    false,
    'v2'
  )
  return res
}

export const serverGetDeviceCloudProfiles = async () => {
  const res = await serverRequest(`/devices/lora/profiles`, 'GET', null, true)
  return res
}
// Facility group events
export const serverGetFacilityGroupEvents = async (facilityGroupId: number): Promise<IFacilityGroupEvent[]> => {
  const res = await serverRequest(`/facilityGroupEvents?facilityGroupId=${facilityGroupId}`, 'GET', null, true)
  return res
}

export const serverGetOnlyFacilityGroupEvent = async () => {
  return serverRequest(`/facilityGroupEvents/all`, 'GET', null, true)
}

export const serverUpdateFacilityGroupEvent = async (eventsId: number, facilityGroupEvent: IFacilityGroupEvent) => {
  const res = serverRequest(`/facilityGroupEvents/events/${eventsId}`, 'PUT', facilityGroupEvent, true)
}

export const serverUpdateFacilityGroupEventTM = async (taskId: number, facilityGroupEvent: IFacilityGroupEvent) => {
  const res = serverRequest(`/facilityGroupEvents/events-tm/${taskId}`, 'PUT', facilityGroupEvent, true)
}

export const serverUpdateFacilityGroupEventStatusTM = async (data: {
  statusTypeId: number
  lat?: number
  long?: number
  taskId: number
}): Promise<any> => {
  const res = serverRequest(`/facilityGroupEvents/events-status`, 'PUT', data, true)
  return res
}
// events-status

export const serverGetFacilityGroupEventStatusType = async () => {
  const res = serverRequest(`/facilityGroupEvents/statusType`, 'GET', null, true)
  return res
}

export const serverDeleteFacilityGroupEvent = async (eventsId: number) => {
  return serverRequest(`/facilityGroupEvents/events/${eventsId}`, 'DELETE', null, true)
}

export const serverGetFacilityGroupEventTypes = async (): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(`/facilityGroupEvents/types`, 'GET', null, true)
  return res
}

export const serverGetFacilityGroupEventForTaskManager = async (): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(`/facilityGroupEvents/task-events`, 'GET', null, true)
  return res
}

export const serverGetFacilityGroupEventTypesByDateRange = async (data: {
  startDate: Date | string
  endDate: Date | string
}): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(
    `/facilityGroupEvents/task-events/daterange?startDate=${data.startDate}&endDate=${data.endDate}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetFacilityGroupEventTypesByDate = async (date: Date | string): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(`/facilityGroupEvents/task-events/daywise?date=${date}`, 'GET', null, true)
  return res
}

export const serverPostFacilityGroupEventTypes = async (data: {
  name: string
  description: string
  tag: string
}): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(`/facilityGroupEvents/task-events`, 'POST', data, true)
  return res
}

export const serverPutFacilityGroupEventTypes = async (data: {
  id: number
  name: string
  description: string
  tag: string
}): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(`/facilityGroupEvents/task-events`, 'PUT', data, true)
  return res
}

export const serverDeleteFacilityGroupEventTypes = async (id: number): Promise<IFacilityGroupEventType[]> => {
  const res = await serverRequest(`/facilityGroupEvents/task-events`, 'DELETE', { id: id }, true)
  return res
}

export const serverGetFacilityGroupUpcomingEvent = async (facilityGroupId: number): Promise<IFacilityGroupEvent[]> => {
  const res = await serverRequest(`/facilityGroupEvents/upcoming?facilityGroupId=${facilityGroupId}`, 'GET', null, true)
  return res
}

export const serverAddFacilityGroupEvent = async (facilityGroupEvent: IInsertFacilityGroupEvent): Promise<{ success: boolean }> => {
  const res = await serverRequest(`/facilityGroupEvents`, 'POST', facilityGroupEvent, true)
  return res
}

export const serverGetFacilityGroupEvent = async (): Promise<any> => {
  const res = await serverRequest(`/facilityGroupEvents/task-manager`, 'GET', null, true)
  return res
}

// facilities
export const serverGetFacilitiesHarvestSummary = async (
  customerId: number,
  facilityGroupIds: number[],
  facilityIds: number[],
  zoneIds: number[],
  type: number,
  startDate: Date | string,
  endDate: Date | string | number | undefined,
  isLive?: Boolean
): Promise<IotShedsInfo[]> => {
  let url = `/iot/summary?customerId=${customerId}&facilityGroupIds=${facilityGroupIds}&facilityIds=${facilityIds}&zoneIds=${zoneIds}&type=${type}&startDate=${startDate}`
  if (isLive) url += `&isLive=true`
  if (endDate) url += `&endDate=${endDate}`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

// facilityGroup

export const serverGetIotFacilityGroup = async (
  facilityGroupId: number,
  startDate: number,
  endDate: number,
  isLive?: boolean
): Promise<any> => {
  let endPoint = `/iot/facilityGroup/telemetery?facilityGroupId=${facilityGroupId}&startDate=${startDate}&endDate=${endDate}`

  if (isLive) {
    endPoint += `&isLive=true`
  }
  const res = await serverRequest(endPoint, 'GET', null, true)
  return res
}

// facility

export const serverGetIotFacility = async (
  facilityIds: number[],
  startDate: number,
  endDate: number,
  isHarvest?: boolean,
  isLive?: boolean
): Promise<any> => {
  let url = `/iot/facility/telemetery?facilityId=${facilityIds}&startDate=${startDate}&endDate=${endDate}`
  if (isHarvest) url += `&isHarvest=${isHarvest}`
  if (isLive) url += `&isLive=${isLive}`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const getIotEventsSummary = async (
  startDate: Date | string,
  endDate: Date | string | number | undefined,
  type: number,
  facilityGroupIds?: number[],
  facilityIds?: number[],
  zoneIds?: number[]
): Promise<IotShedsInfo[]> => {
  let url = `/iot/summary?facilityIds=${facilityIds}&zoneIds=${zoneIds}&type=${type}&startDate=${startDate}`
  if (endDate) url += `&endDate=${endDate}`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}
export const serverGetFacilityByGroupId = async (facilitygroupid: number) => {
  const res = await serverRequest(`/facility?facilitygroupid=${facilitygroupid}`, 'GET', null, true)
  return res
}

export const serverGetFacilityZones = async (type?: string) => {
  let url = `/facilityzone`
  if (type) url += `?type=${type}`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const serverCreateFacilityZones = async (faclityZones: IFacilityZones[]) => {
  const res = await serverRequest('/facilityZone/createFacilityZone', 'POST', faclityZones, true)
  return res
}

export const serverGetSiloData = async (
  siloId: number,
  startDate: Date | number | string,
  endDate?: Date | number | string,
  getLatestEventsOnly?: boolean
): Promise<IotSiloData[]> => {
  let url = `/facility/getSilosDetails/${siloId}?startDate=${startDate}`
  if (endDate) {
    url += `&endDate=${endDate}`
  }
  if (getLatestEventsOnly) {
    url += '&getLatestEventsOnly=true'
  }
  const res = await serverRequest(url, 'GET', null, true)
  return res
}
//get SiloData
export const serverOldGetSilosData = async (zoneId: number, interval: number, startDate: number, endDate: number) => {
  const res = await serverRequest(
    `/iot/siloData?ZoneId=${zoneId}&Interval=${interval}&startDate=${startDate}&endDate=${endDate}`,
    'GET',
    null,
    true
  )
  return res
}
export const serverGetHarvestMortality = async () => {
  const res = await serverRequest(`/harvest/getMortality`, 'GET', null, true)
  return res
}
export const serverGetPreviousHarvestMortality = async () => {
  const res = await serverRequest(`/harvest/previous`, 'GET', null, true)
  return res
}
export const serverGetHarvestBrands = async () => {
  const res = await serverRequest(`/harvest/getBrands`, 'GET', null, true)
  return res
}

// Sensors
export const serverGetSensorsDataByHarvestId = async (harvestId: number): Promise<ISensorPageData> => {
  const res = await serverRequest(`/harvest/getSensorsDataByHarvestId?harvestId=${harvestId}`, 'GET', null, true)
  return res
}
/**********Materials ************/

export const serverGetMaterials = async () => {
  const res = await serverRequest(`/materials`, 'GET', null, true)
  return res
}
export const serverAddMaterial = async (material: IMaterialPost) => {
  const res = await serverRequest(`/materials`, 'POST', material, true)
  return res
}

export const serverEditMaterial = async (material: IMaterialPut) => {
  const res = await serverRequest(`/materials`, 'PUT', material, true)
  return res
}

export const serverDeleteMaterial = async (materialId: number) => {
  const res = await serverRequest(`/materials?id=${materialId}`, 'DELETE', null, true)
  return res
}
/*************Attached Material  *****************/
export const serverGetAttachedMaterials = async (): Promise<IAttachedMaterial[]> => {
  const res = await serverRequest(`/materials/attached`, 'GET', null, true)
  return res
}
export const serverAddAttachedMaterial = async (material: IPostAttachedMaterial) => {
  const res = await serverRequest(`/materials/attached`, 'POST', material, true)
  return res
}

export const serverEditAttachedMaterial = async (material: IPutAttachedMaterial) => {
  const res = await serverRequest(`/materials/attached`, 'PUT', material, true)
  return res
}

export const serverDeleteAttachedMaterial = async (materialAttachedId: number) => {
  const res = await serverRequest(`/materials/attached?id=${materialAttachedId}`, 'DELETE', null, true)
  return res
}

/*******Faciklity Zone ******************************/
export const serverGetFacilityZoneTypeByCustomerId = async (type?: string) => {
  let url = `/facilityZone/types`
  if (type) url += `?type=${type}`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

// update facilityZone
export const serverUpdateFacilityZone = async (facilityZone: IFacilityZones[], facilityId: number) => {
  const res = await serverRequest(`/facilityZone/${facilityId}`, 'PUT', facilityZone, true)
  return res
}

export const serverCreateZoneType = async (types: any): Promise<any> => {
  const res = await serverRequest('/facilityZone/createType', 'POST', types, true)
  return res
}
/**
 * Manual Events
 */
export const serverGetManualEventsByZoneId = async (zoneId?: number): Promise<IManualEvent[]> => {
  return serverRequest(`/manualEvents?zoneId=${zoneId}`, 'GET', null, true)
}
/**
 * Manual Events
 */
export const serverCreateManualEvents = async (manualEvent: IManualEvent): Promise<IManualEvent> => {
  return serverRequest(`/manualEvents`, 'POST', manualEvent, true)
}

export const serverUpdateManualEvents = async (manualEvent: IManualEvent): Promise<IManualEvent> => {
  return serverRequest(`/manualEvents`, 'PUT', manualEvent, true)
}

export const serverDeleteManualEvents = async (eventId: number): Promise<IManualEvent> => {
  return serverRequest(`/manualEvents`, 'DELETE', { id: eventId }, true)
}

export const serverGetDeviceSuppliers = async (): Promise<IDeviceSupplier[]> => {
  return serverRequest(`/devices/supplier`, 'GET', null, true)
}

export const serverInsertCropReport = async (cropReport: ICropReport): Promise<ICropReport> => {
  return serverRequest(`/harvest/report`, 'POST', cropReport, true)
}

export const serverGetCropReport = async (startDate: number): Promise<ICropReport[]> => {
  let url = `/harvest/report`
  if (startDate) {
    url += `?startDate=${startDate}`
  }
  return serverRequest(url, 'GET', null, true)
}

// get Avg Food Consumtion	and Total Food Consumption
export const serverGetFoodConsumtion = async (facilityId: number, harvestId: number): Promise<any[]> => {
  const url = `/iot/foodCunsumption?facilityId=${facilityId}&harvestId=${harvestId}`
  return serverRequest(url, 'GET', null, true)
}

// Update Roles Details
export const serverUpdateCustomers = async (customerId: number, customer: IPostCustomer) => {
  return serverRequest('/customers/' + customerId, 'PUT', customer, true)
}
export const serverGetDataModelId = async (url?: number, devPort?: number) => {
  let requestUrl = `/dataModel`
  let query = []

  if (url) {
    query.push(`url=${url}`)
  }
  if (devPort) {
    query.push(`devPort=${devPort}`)
  }
  const res = await serverRequest(`${requestUrl}?${query.join('&')}`, 'GET', null, true)
  return res
}

export const serverGetDataModelDetail = async (id: number) => {
  const res = await serverRequest(`/dataModel/getDataModel/${id}`, 'GET', null, true)
  return res
}

// Notification Api
export const serverGetNotificationCondition = async () => {
  const res = serverRequest(`/notifications/condition`, 'GET', null, true)
  return res
}

export const serverGetNotificationFrequency = async () => {
  const res = serverRequest(`/notifications/frequency`, 'GET', null, true)
  return res
}

export const serverGetNotificationSettings = async () => {
  const res = serverRequest(`/notifications/settings`, 'GET', null, true)
  return res
}

export const serverGetNotificationEventType = async () => {
  const res = serverRequest(`/notifications/eventtypes`, 'GET', null, true)
  return res
}
export const serverGetNotificationGroup = async () => {
  const res = serverRequest(`/notifications/group`, 'GET', null, true)
  return res
}

export const serverInsertNotificationSettings = async (notification: any) => {
  const res = serverRequest(`/notifications/settings`, 'POST', notification, true)
  return res
}

export const serverDeleteNotificationSettings = async (settingsId: number) => {
  const res = serverRequest(`/notifications/settings/${settingsId}`, 'DELETE', null, true)
  return res
}

export const serverUpdateNotificationSettings = async (settingsId: number, notification: any) => {
  const res = serverRequest(`/notifications/settings/${settingsId}`, 'PUT', notification, true)
  return res
}

export const serverGetUpdateFacility = async () => {
  const res = serverRequest('/notifciaton/setting', 'PUT', {}, true)
}

//Get AccessKey Data

export const serverInsertAccessKeyData = async (accessKey: any) => {
  const res = serverRequest(`/users/accessKey`, 'POST', accessKey, true)
}

//Get Only Harvest Data
export const serverGetOnlyHarvestData = async (facilityIds: number[]) => {
  const res = await serverRequest(`/harvest/getHarvestData?facilityIds=${facilityIds}`, 'GET', null, true)
  return res
}

export const serverGetAccessKeyData = async () => {
  const res = await serverRequest('/users/accessKey', 'GET', null, true)
  return res
}

export const serverEditAccessKey = async (accessKey: any) => {
  const res = await serverRequest(`/users/accessKey`, 'PUT', accessKey, true)
  return res
}

//Delete Harvest
export const serverDeleteHarvest = async (harvestId?: number) => {
  const res = await serverRequest(`/harvest/${harvestId}`, 'DELETE', null, true)
  return res
}

// get HarvestBrand
export const serverGetHarvestBrand = async () => {
  const res = await serverRequest('/harvest/brand', 'GET', null, true)
  return res
}

//get SiloData
export const serverGetSilosData = async (siloFilterDetails: IotSiloDataRequest) => {
  let uri = `/iot/events/silo?siloId=${siloFilterDetails.zoneId}&interval=${siloFilterDetails.interval}&startDate=${siloFilterDetails.startDate}&endDate=${siloFilterDetails.endDate}`
  if (siloFilterDetails.limit) {
    uri += `&limit=${siloFilterDetails.limit}`
  }
  if (siloFilterDetails.sort) {
    uri += `&sort=${siloFilterDetails.sort}`
  }
  const res = await serverRequest(uri, 'GET', null, true, false, false, 'v2')
  return res
}

// Get MonitoredGroup
export const serverGetMonitoredGroup = async (): Promise<IMonitoredGroup[]> => {
  const res = await serverRequest(`/monitoredGroup`, 'GET', null, true)
  return res
}

// Create MonitoredGroup
export const serverCreateMonitoredGroup = async (monitoredGroup: IPostMonitoredGroup) => {
  return serverRequest('/monitoredGroup', 'POST', monitoredGroup, true)
}

// Update MonitoredGroup
export const serverUpdateMonitoredGroup = async (monitoredGroupId: number, monitoredGroup: IPostMonitoredGroup) => {
  return serverRequest(`/monitoredGroup/${monitoredGroupId}`, 'PUT', monitoredGroup, true)
}

// Delete MonitoredGroup
export const serverDeleteMonitoredGroup = async (monitoredGroupId: number) => {
  return serverRequest(`/monitoredGroup/${monitoredGroupId}`, 'DELETE', null, true)
}

// Get MonitoredUnits
export const serverGetMonitoredUnits = async (): Promise<IMonitoredUnits[]> => {
  const res = await serverRequest(`/monitoredUnit`, 'GET', null, true)
  return res
}

// update MonitoredUnits
export const serverUpdateMonitoredUnits = async (monitoredUnitId: number, monitoredUnit: IMonitoredUnits): Promise<IMonitoredUnits> => {
  const res = await serverRequest(`/monitoredUnit/${monitoredUnitId}`, 'PUT', monitoredUnit, true)
  return res
}

// Delete MonitoredUnits
export const serverDeleteMonitoredUnits = async (monitoredUnitId: number) => {
  return serverRequest(`/monitoredUnit/${monitoredUnitId}`, 'DELETE', null, true)
}

// Create MonitoredUnits
export const serverCreateMonitoredUnit = async (monitoredUnit: IMonitoredUnits) => {
  return serverRequest('/monitoredUnit', 'POST', monitoredUnit, true)
}

export const serverGetAnalysisReports = async (data: IAnalysisReports): Promise<any> => {
  const facilityGroupIds = data.facilityGroupId ? (Array.isArray(data.facilityGroupId) ? data.facilityGroupId : [data.facilityGroupId]) : []
  const facilityIds = data.facilityId ? (Array.isArray(data.facilityId) ? data.facilityId : [data.facilityId]) : []
  const monitoredGroupIds = data.monitoredGroupId
    ? Array.isArray(data.monitoredGroupId)
      ? data.monitoredGroupId
      : [data.monitoredGroupId]
    : []
  const monitoredUnitIds = data.monitoredUnitId ? (Array.isArray(data.monitoredUnitId) ? data.monitoredUnitId : [data.monitoredUnitId]) : []
  let url = `/analysisReports?&isRow=${data?.isRow ? data.isRow : false}&sensors=${data.sensors}&interval=${
    data.interval ?? 10
  }&facilityGroupId=${JoinIds(facilityGroupIds)}&facilityId=${JoinIds(facilityIds)}&monitoredUnitId=${JoinIds(
    monitoredUnitIds
  )}&monitoredGroupId=${JoinIds(monitoredGroupIds)}&startDate=${data.startDate}&endDate=${data.endDate}`

  if (data.interval === Interval.Day || data.interval === Interval.Week) {
    const location = moment.tz.guess()
    url += `&location=${location}`
  }

  const res = await serverRequest(url, 'GET', null, true)

  return res
}
export const serverGetMonitoredDrinkingEvents = async (data: IMonitoredDrinkingEvents): Promise<any> => {
  let url = `/analysisReports/events/drinking?&monitoredUnitId=${data.monitoredUnitId}&startDate=${data.startDate}&endDate=${data.endDate}`

  const res = await serverRequest(url, 'GET', null, true)

  return res
}
// get chartDuration
export const serverGetchartDuration = async () => {
  const res = await serverRequest('/analysisReports/duration', 'GET', null, true)
  return res
}

//Inventory Recipe
export const serverGetInventoryRecipe = async () => {
  const res = await serverRequest('/inventory', 'GET', null, true)
  return res
}

export const serverDeleteInventoryRecipe = async (Id?: number) => {
  const res = await serverRequest(`/inventory?Id=${Id}`, 'DELETE', null, true)
  return res
}

export const serverInsertInventoryRecipe = async (data: IInventoryRecipe) => {
  const res = await serverRequest('/inventory', 'POST', data, true)
  return res
}

export const serverUpdateInventoryRecipe = async (data: IInventoryRecipe) => {
  const res = await serverRequest('/inventory', 'PUT', data, true)
  return res
}

//Inventory Ingredient
export const serverGetInventoryIngredient = async () => {
  const res = await serverRequest('/inventory/ingredient', 'GET', null, true)
  return res
}

export const serverInsertInventoryIngredient = async (data: IInventoryIngredient) => {
  const res = await serverRequest('/inventory/ingredient', 'POST', data, true)
  return res
}

export const serverUpdateInventoryIngredient = async (data: IInventoryIngredient) => {
  const res = await serverRequest('/inventory/ingredient', 'PUT', data, true)
  return res
}

export const serverDeleteInventoryIngredient = async (code?: string) => {
  const res = await serverRequest(`/inventory/ingredient?code=${code}`, 'DELETE', null, true)
  return res
}

export const serverGetInventoryRecipeIngredient = async () => {
  const res = await serverRequest('/inventory/RecipeIngredient', 'GET', null, true)
  return res
}

export const serverGetInventoryRecipeIngredientByRecipeId = async (recipeId: number) => {
  const res = await serverRequest(`/inventory/RecipeIngredient/RecipeId?recipeId=${recipeId}`, 'GET', null, true)
  return res
}

// Create Consumption
export const serverCreateConsumption = async (data: IConsumption) => {
  const res = await serverRequest('/harvest/consumption', 'POST', data, true)
  return res
}

export const serverUpdateConsumption = async (data: IConsumption) => {
  const res = await serverRequest('/harvest/consumption', 'PUT', data, true)
  return res
}

export const serverDeleteConsumption = async (id?: number) => {
  const res = await serverRequest(`/harvest/consumption?id=${id}`, 'DELETE', null, true)
  return res
}

export const serverGetConsumption = async (harvestIds: number[]) => {
  const res = await serverRequest(`/harvest/consumption?harvestIds=${harvestIds}`, 'GET', null, true)
  return res
}

export const serverGetTodayConsumption = async (harvestId: number, facilityId: number, date: any) => {
  const res = await serverRequest(
    `/harvest/consumption/cropConsumption?harvestId=${harvestId}&facilityId=${facilityId}&date=${date}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetWaterIntake = async (data: IWaterIntakeParams) => {
  let url = `/iot/waterIntake?startDate=${data.startDate}&endDate=${data.endDate}`
  if (data.facilityIds) url += `&facilityIds=${data.facilityIds}`
  if (data.monitorGroupIds) url += `&monitorGroupIds=${data.monitorGroupIds}`
  const res = await serverRequest(url, 'GET', null, true)
  return res
}

export const serverGetGasData = async (facilityGroupId: number[], startDate: number, endDate: number) => {
  const res = await serverRequest(
    `/iot/gas?facilityGroupIds=${facilityGroupId}&startDate=${startDate}&endDate=${endDate}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetIotMoniteredFacilityGroupData = async (
  facilityGroupId: number,
  startDate: number,
  endDate: number,
  isLive: boolean
) => {
  const res = await serverRequest(
    `/iot/monitoredUnits?facilityGroupId=${facilityGroupId}&startDate=${startDate}&endDate=${endDate}&isLive=${isLive}`,
    'GET',
    null,
    true
  )
  return res
}

//Calibrate device
export const serverCalibrateDevice = async (deviceId: number, calibrationValue?: number, isZeroing?: boolean, isCalibrate?: boolean) => {
  let url = `/devices/calibrate?deviceId=${deviceId}`

  if (calibrationValue) {
    url += `&calibrationValue=${calibrationValue}`
  }
  if (isCalibrate) {
    url += `&isCalibrate=true`
  } else if (isZeroing) {
    url += `&isZeroing=true`
  }

  return serverRequest(url, 'PUT', null, true)
}

export const serverGetIotMoniteredFacilityData = async (facilityId: number, startDate: number, endDate: number, isLive: boolean) => {
  const res = await serverRequest(
    `/iot/monitoredUnits/facility?facilityId=${facilityId}&startDate=${startDate}&endDate=${endDate}&isLive=${isLive}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetDeviceById = async (deviceId: number): Promise<Idevice[]> => {
  return serverRequest('/devices/' + deviceId, 'GET', null, true)
}

export const serverGetIotMoniteredMoniterGroupData = async (
  moniterGroupId: number,
  startDate: number,
  endDate: number,
  isLive: boolean
) => {
  const res = await serverRequest(
    `/iot/monitoredUnits/moniterGroup?moniterGroupId=${moniterGroupId}&startDate=${startDate}&endDate=${endDate}&isLive=${isLive}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetMoniteredUnitMovement = async (moniteredUnitId: number) => {
  const res = await serverRequest(`/monitoredUnit/movement?moniteredUnitId=${moniteredUnitId}`, 'GET', null, true)
  return res
}

// user Filter
export const serverCreateUserFilter = async (data: any) => {
  const res = await serverRequest(`/analysisReports/userFilter`, 'POST', data, true)
  return res
}

export const serverGetUserFilter = async () => {
  const res = await serverRequest(`/analysisReports/userFilter`, 'GET', null, true)
  return res
}

// chart Filter
export const serverGetChartFilter = async (userFilterId: number) => {
  const res = await serverRequest(`/analysisReports/chartFilter?userFilterId=${userFilterId}`, 'GET', null, true)
  return res
}

export const serverDeleteUserFilter = async (userFilterId: number) => {
  const res = await serverRequest(`/analysisReports/userFilter/${userFilterId}`, 'DELETE', null, true)
  return res
}

//Monitor Schedule
export const serverGetMonitorSchedule = async (isActive?: boolean) => {
  const res = await serverRequest(`/monitoredGroup/MonitorSchedule?isActive=${isActive ?? false}`, 'GET', null, true)
  return res
}

export const serverInsertMonitorSchedule = async (data: IMonitorSchedule) => {
  const res = await serverRequest(`/monitoredGroup/MonitorSchedule/create`, 'POST', data, true)
  return res
}

export const serverUpdateMonitorSchedule = async (data: IMonitorSchedule) => {
  const res = await serverRequest(`/monitoredGroup/MonitorSchedule/update`, 'PUT', data, true)
  return res
}

export const serverDeleteMonitorSchedule = async (data: IMonitorSchedule) => {
  const res = await serverRequest(
    `/monitoredGroup/MonitorSchedule/delete?scheduleId=${data.id}&monitoredGroupId=${data.monitoredGroupId}`,
    'DELETE',
    null,
    true
  )
  return res
}

export const serverGetMonitorScheduleByGroupId = async () => {
  const res = await serverRequest(`/monitoredGroup/MonitorSchedule/byGroupId`, 'GET', null, true)
  return res
}

// Distributions all api
export const serverInsertDistribution = async (data: IMonitoredDistribution) => {
  const res = await serverRequest(`/inventory/distribution`, 'POST', data, true)
  return res
}

export const serverInsertRecipeDistribution = async (data: IMonitoredDistribution) => {
  const res = await serverRequest(`/inventory/recipe/distribution`, 'POST', data, true)
  return res
}

export const serverUpdateDistribution = async (data: IMonitoredDistribution) => {
  const res = await serverRequest(`/inventory/distribution`, 'PUT', data, true)
  return res
}

export const serverUpdateRecipeDistribution = async (data: IMonitoredDistribution) => {
  const res = await serverRequest(`/inventory/recipe/distribution`, 'PUT', data, true)
  return res
}

export const serverDeleteDistribution = async (id?: number, recipeId?: number) => {
  const res = await serverRequest(`/inventory/distribution?id=${id}&recipeId=${recipeId}`, 'DELETE', null, true)
  return res
}

export const serverDeleteRecipeDistribution = async (id?: number) => {
  const res = await serverRequest(`/inventory/recipe/distribution?id=${id}`, 'DELETE', null, true)
  return res
}

export const serverGetDistribution = async (data?: any) => {
  const facilityGroupIds = data?.facilityGroupId
    ? Array.isArray(data.facilityGroupId)
      ? data.facilityGroupId
      : [data.facilityGroupId]
    : []
  const facilityIds = data?.facilityId ? (Array.isArray(data.facilityId) ? data.facilityId : [data.facilityId]) : []
  const monitoredGroupIds = data?.monitoredGroupId
    ? Array.isArray(data.monitoredGroupId)
      ? data.monitoredGroupId
      : [data.monitoredGroupId]
    : []
  const res = await serverRequest(
    `/inventory/distribution?&facilityGroupIds=${JoinIds(facilityGroupIds)}&facilityIds=${JoinIds(facilityIds)}&monitoredGroupIds=${JoinIds(
      monitoredGroupIds
    )}&startDate=${data.startDate}&endDate=${data.endDate}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverGetRecipeDistribution = async () => {
  const res = await serverRequest(`/inventory/recipe/distribution`, 'GET', null, true)
  return res
}

export const serverGetConsumptionByDistribution = async (field: string, id: number, startDate: number, endDate: number) => {
  const res = await serverRequest(
    `/iot/consumption/distribution?field=${field}&id=${id}&startDate=${startDate}&endDate=${endDate}`,
    'GET',
    null,
    true
  )
  return res
}
export const serverInsertReturnDistribution = async (data: IInventoryRecipeIngredient) => {
  const res = await serverRequest(`/inventory/distribution/return`, 'POST', data, true)
  return res
}

export const serverGetReturnDistributionByRecipeId = async (recipeId: number) => {
  const res = await serverRequest(`/inventory/distribution/return/recipeId?recipeId=${recipeId}`, 'GET', null, true)
  return res
}

export const serverUpdateReturnDistribution = async (data: IInventoryRecipeIngredient) => {
  const res = await serverRequest(`/inventory/distribution/return`, 'PUT', data, true)
}
// Ingredient cost
export const serverGetIngredientCost = async () => {
  const res = await serverRequest(`/inventory/ingredient/cost`, 'GET', null, true)
  return res
}

export const serverInsertIngredientCost = async (data: IIngredientCost) => {
  const res = await serverRequest(`/inventory/ingredient/cost`, 'POST', data, true)
  return res
}

export const serverUpdateIngredientCost = async (data: IIngredientCost) => {
  const res = await serverRequest(`/inventory/ingredient/cost`, 'PUT', data, true)
  return res
}

export const serverDeleteIngredientCost = async (id?: number) => {
  const res = await serverRequest(`/inventory/ingredient/cost?id=${id}`, 'DELETE', null, true)
  return res
}

export const serverGetRecipeCost = async () => {
  const res = await serverRequest(`/inventory/recipe/cost`, 'GET', null, true)
  return res
}

export const serverInsertRecipeCost = async (data: IRecipeCost) => {
  const res = await serverRequest(`/inventory/recipe/cost`, 'POST', data, true)
  return res
}

export const serverUpdateRecipeCost = async (data: IRecipeCost) => {
  const res = await serverRequest(`/inventory/recipe/cost`, 'PUT', data, true)
  return res
}

export const serverDeleteRecipeCost = async (id?: number) => {
  const res = await serverRequest(`/inventory/recipe/cost?id=${id}`, 'DELETE', null, true)
  return res
}

export const serverGetDeviceData = async (data: IDeviceParams) => {
  let url = `/devices?start=${data.start}&end=${data.end}&search=${data.search}`
  if (data.deviceTypeId) url += `&deviceTypeId=${data.deviceTypeId}`
  // if (data.facilityId) url += `&facilityId=${data.facilityId}`
  // if (data.facilityGroupId) url += `&facilityGroupId=${data.facilityGroupId}`
  // if (data.customerId) url += `&paramsCustomerId=${data.customerId}`
  if (data.max) url += `&max=${data.max}`
  if (data.sortBy) url += `&sortBy=${data.sortBy}`
  if (data.orderDirection) url += `&orderDirection=${data.orderDirection}`
  if (data.customerIds) url += `&customerIds=${data.customerIds}`
  if (data.facilityGroupIds) url += `&facilityGroupIds=${data.facilityGroupIds}`
  if (data.facilityIds) url += `&facilityIds=${data.facilityIds}`
  if (data.facilityZoneIds) url += `&facilityZoneIds=${data.facilityZoneIds}`
  const res = await serverRequest(url, 'GET', null, true, false, false, 'v2')

  return res
}

export const serverGetNotification = async (start: number, end: number, search: string, orderBy: string, orderDirection: string) => {
  const res = await serverRequest(
    `/notifications?start=${start}&end=${end}&search=${search}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
    'GET',
    null,
    true,
    false,
    false,
    'v2'
  )
  return res
}

export const serverGetUnSeenNotification = async () => {
  const res = await serverRequest('/notifications/unseen', 'GET', null, true, false, false, 'v2')
  return res
}

export const serverGetDeviceSummary = async (deviceTypeIds: number[], field: string, fieldData: number[]) => {
  let url = `/devices/summary?deviceTypeIds=${deviceTypeIds}`
  if (field === 'customerId') url += `&customerIds=${fieldData}`
  if (field === 'facilityGroupId') url += `&facilityGroupIds=${fieldData}`
  if (field === 'facilityId') url += `&facilityIds=${fieldData}`
  if (field === 'facilityZoneId') url += `&facilityZoneIds=${fieldData}`
  const res = await serverRequest(url, 'GET', null, true, false, false, 'v2')
  return res
}

export const serverGetIotEvents = async (sourceDeviceTypeId: number, startDate: number, endDate: number, isLive?: boolean) => {
  const res = await serverRequest(
    `/iot?sourceDeviceTypeId=${sourceDeviceTypeId}&startDate=${startDate}&endDate=${endDate}&isLive=${isLive}`,
    'GET',
    null,
    true,
    false,
    false,
    'v2'
  )
  return res
}

export const serverGetDeviceLogs = async (start: number, end: number, startDate: number, endDate: number, deviceId: number) => {
  const res = await serverRequest(
    `/devices/logs?start=${start}&end=${end}&startDate=${startDate}&endDate=${endDate}&deviceId=${deviceId}`,
    'GET',
    null,
    true,
    false,
    false,
    'v2'
  )
  return res
}

export const serverGetDeviceLogsByDeviceId = async (startDate: any, endDate: any, deviceId: number, start: number, end: number) => {
  const res = await serverRequest(
    `/devices/logs/customized/byDeviceId?startDate=${startDate}&endDate=${endDate}&deviceId=${deviceId}&start=${start}&end=${end}`,
    'GET',
    null,
    true,
    false,
    false,
    'v2'
  )
  return res
}

export const serverGetDeviceEventsBySiteId = async (startDate: any, endDate: any, siteId: number, start: number, end: number) => {
  const res = await serverRequest(
    `/devices/logs/bySiteId?startDate=${startDate}&endDate=${endDate}&siteId=${siteId}&start=${start}&end=${end}`,
    'GET',
    null,
    true,
    false,
    false,
    'v2'
  )
  return res
}

export const serverGetMonitoredUnitsData = async (
  start: number,
  end: number,
  search: string,
  sortBy: string,
  orderDirection: string,
  max?: number
) => {
  let url = `/monitoredUnit?start=${start}&end=${end}&search=${search}`
  if (sortBy) url += `&sortBy=${sortBy}`
  if (orderDirection) url += `&orderDirection=${orderDirection}`
  if (max) url += `&max=${max}`
  const res = await serverRequest(url, 'GET', null, true, false, false, 'v2')
  return res
}

export const serverInsertAlert = async (data: any) => {
  const res = await serverRequest(`/notifications/settings`, 'POST', data, true, false, false, 'v2')
  return res
}

export const serverDeleteAlert = async (id: number) => {
  const res = await serverRequest(`/notifications/settings?id=${id}`, 'DELETE', null, true, false, false, 'v2')
  return res
}

export const serverGetAlert = async () => {
  const res = await serverRequest(`/notifications/settings`, 'GET', null, true, false, false, 'v2')
  return res
}

export const serverUpdateAlert = async (data: any) => {
  const res = await serverRequest('/notifications/settings', 'PUT', data, true, false, false, 'v2')
  return res
}

export const serverCreateMaterialCell = async (data: any) => {
  const res = await serverRequest(`/facilityZone/materialCell`, 'POST', data, true)
  return res
}

export const serverUpdateMaterialCell = async (data: any) => {
  const res = await serverRequest(`/facilityZone/materialCell`, 'PUT', data, true)
  return res
}

export const serverGetEfficiency = async (data: IAnalysisEfficiency) => {
  let url = `/iot/efficiency?startDate=${data.startDate}&endDate=${data.endDate}&interval=${data.interval}`
  if (data.facilityGroupId) url += `&facilityGroupId=${data.facilityGroupId}`
  if (data.facilityId) url += `&facilityId=${data.facilityId}`
  if (data.facilityIds) url += `&facilityIds=${data.facilityIds}`
  const res = await serverRequest(url, 'GET', null, true, false, false, 'v2')
  return res
}

export const serverGetHarvestMatericTarget = async (harvestId?: number) => {
  const res = await serverRequest(`/harvest/target?harvestId=${harvestId}`, 'GET', null, true)
  return res
}

export const serverGetharvestTargetBrand = async () => {
  const res = await serverRequest(`/harvest/harvestTarget/brand`, 'GET', null, true)
  return res
}

export const serverDeleteHarvestTargetBrand = async (id: number) => {
  const res = await serverRequest(`/harvest/harvestTarget/brand/${id}`, 'DELETE', null, true)
  return res
}

export const serverPostHarvestTargetBrand = async (data: { customerId: number; brand: string }) => {
  const res = await serverRequest(`/harvest/harvestTarget/brand`, 'POST', data, true)
  return res
}

export const serverGetHarvestTarget = async (metricTargetId?: number, brandId?: number, groupTypeId?: number) => {
  const res = await serverRequest(
    `/harvest/harvestTarget?metricTargetId=${metricTargetId}&brandId=${brandId}&groupTypeId=${groupTypeId}`,
    'GET',
    null,
    true
  )
  return res
}

export const serverInsertHarvestTarget = async (data: {
  metricTypeId: number
  groupTypeId: number
  harvestDay: number
  value: number
  minValue: number
  maxValue: number
  brandTypeId: number
}) => {
  const res = await serverRequest(`/harvest/harvestTarget`, 'POST', data, true)
  return res
}

export const serverGetHarvestMetricTypes = async () => {
  const res = await serverRequest(`/harvest/getMetricType`, 'GET', null, true)
  return res
}

export const serverGetHarvestGroupTypes = async () => {
  const res = await serverRequest(`/harvest/groupType`, 'GET', null, true)
  return res
}

export const serverUpdateHarvestTarget = async (data: {
  id: number
  harvestDay: number
  metricTypeId: number
  value: number
  groupTypeId: number
  minValue: number
  maxValue: number
  brandTypeId: number
}) => {
  const res = await serverRequest(`/harvest/harvestTarget`, 'PUT', data, true)
  return res
}

export const serverUpdateHarvestTargetBrand = async (data: { id: number; customerId: number; brand: string }) => {
  const res = await serverRequest(`/harvest/harvestTarget/brand`, 'PUT', data, true)
  return res
}

export const serverGetBrandDetailsByBrandId = async (brandId: number) => {
  const res = await serverRequest(`/harvest/brandDetails?brandId=${brandId}`, 'GET', null, true)
  return res
}

export const serverGetCropGroupDataByFacilityGroupId = async (facilityGroupId: number) => {
  const res = await serverRequest(`/harvest/group?facilityGroupId=${facilityGroupId}`, 'GET', null, true)
  return res
}
/** Payments  */
export const serverAddNewCardForCustomer = async (data: { paymentMethodId: PaymentMethod | string; customerId: string | null }) => {
  const res = serverRequest('/payments/save-card', 'POST', data, true)
  return res
}
export const serverGetCustomerCards = async (customerId: string) => {
  const res = await serverRequest(`/payments/CustomerCards?customerId=${customerId}`, 'GET', null, true)
  return res
}

export const serverGetIntentCustomer = async () => {
  const res = serverRequest('/payments/intent', 'POST', null, true)
  return res
}

export const serverRemoveCardForCustomer = async (data: { cardId: string; customerId: string }) => {
  const res = serverRequest('/payments/removeCard', 'DELETE', data, true)
  return res
}
export const serverGetCustomerByUserId = async (userId: string) => {
  const res = await serverRequest(`/payments/customer-by-user/${userId}`, 'GET', null, true)
  return res
}

export const serverGetAllSubProducts = async () => {
  const res = await serverRequest('/payments/listProducts', 'GET', null, true)
  return res
}
export const serverGetCustomerSubscription = async (customerId: string) => {
  const res = await serverRequest(`/payments/subscriptions/${customerId}`, 'GET', null, true)
  return res
}

export const serverCancelCustomerSubscription = async (customerId: string) => {
  const res = await serverRequest(`/payments/subscriptions/cancel/${customerId}`, 'DELETE', null, true)
  return res
}

export const serverCreateSubscriptionForCustomer = async (data: {
  priceId: string
  customerId: string
  paymentMethod: string
  quantity: number
}) => {
  const res = serverRequest('/payments/subscriptions', 'POST', data, true)
  return res
}

export const serverCreateCheckoutSession = async (data: { priceId: string; customerId: string }) => {
  const res = serverRequest('/payments/checkout/sessions', 'POST', data, true)
  return res
}

export const serverGetCustomerInfo = async (customerId: string) => {
  const res = await serverRequest(`/payments/customerInfo?customerId=${customerId}`, 'GET', null, true)
  return res
}

export const serverGetCustomerInvoices = async (customerId: string) => {
  const res = await serverRequest(`/payments/customerInvoices?customerId=${customerId}`, 'GET', null, true)
  return res
}

export const serverUpdateCustomerInfo = async (data: any) => {
  const res = await serverRequest('/payments/updateCustomerInfo', 'PUT', data, true)
  return res
}
