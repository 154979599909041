import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverGetFacilities, serverCreateFacility, serverGetFacilityZones } from '../services/serverApi'
import { IFacilityZones } from 'facilityZones'
import { IZone } from 'facility'

type FacilityZonestate = {
  facilityZones: Array<IZone>
  loading: boolean
  error: string | null
}

const initialState: FacilityZonestate = {
  facilityZones: [],
  loading: false,
  error: null
}

/**Get FacilitiesGroup */
export const getFacilityZones = createAsyncThunk('getFacilityZone', async (s, thunkApi) => {
  try {
    const res = await serverGetFacilityZones()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add device  */

export const facilityZoneSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getFacilityZones.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getFacilityZones.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.facilityZones = payload as IZone[]
      }),
      builder.addCase(getFacilityZones.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const facilityZoneReducer = facilityZoneSlice.reducer
export const facilityZoneActions = facilityZoneSlice.actions
