export default class DataModel {
  dataModelId: number | null

  constructor() {
    this.dataModelId = null
  }

  get() {
    // return 6 // for hydroIntel
    return (window as any).dataModelId
  }

  set(dataModelId: number) {
    ;(window as any).dataModelId = dataModelId
    localStorage.setItem('dataModelId', dataModelId.toString())
    // this.dataModelId = dataModelId
  }
}

export const GetDataModelId = () => {
  const dataModelId = localStorage.getItem('dataModelId')
  if (dataModelId) {
    return parseInt(dataModelId)
  }
  return null
}
