//@ts-nocheck
import { BrowserRouter, HashRouter } from 'react-router-dom'
import './App.scss'
import React, { useCallback, useState } from 'react'
import { Provider } from 'react-redux'
import Main from './views/Main/Main'
import store from './redux'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles/PoultryWeights/global.styled'
import { useEffect } from 'react'
import { serverGetDataModelId } from './services/serverApi'
import DataModel from './utils/helpers/dataModel'
import themes from './styles'
import { DataModel as DataModelIds } from './types/dataModel.d'
import { getDataModelFaviconTitle } from './utils/consts/dataModel'

const App = () => {
  const theme = themes()
  const [loading, setLoading] = useState<boolean>()
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )

  const url = isLocalhost ? undefined : window.location.hostname
  const port = isLocalhost ? Number(window.location.port) : undefined

  const getFaviconByDataModelId = (dataModelId: number) => {
    switch (dataModelId) {
      case DataModelIds.Bees:
        return 'https://zeus-client-assets.s3.amazonaws.com/assets/favicons/beesLogo.png'
      case DataModelIds.hydroIntel:
        return 'https://zeus-client-assets.s3.amazonaws.com/assets/favicons/HydroIntelFavicon.png'
      case DataModelIds.PoultryWeights:
        return 'https://zeus-client-assets.s3.amazonaws.com/assets/favicons/poultryWeightsFavicon.png'
      case DataModelIds.RuminTech:
        return 'https://zeus-client-assets.s3.amazonaws.com/assets/favicons/Rumintech.png'
      case DataModelIds.Gas:
        return 'https://zeus-client-assets.s3.amazonaws.com/assets/favicons/gasFavicon.png'
      case DataModelIds.Radar:
        return 'https://zeus-client-assets.s3.amazonaws.com/assets/favicons/radarFavicon.png'
    }
  }

  function getFaviconEl() {
    return document.getElementById('favicon')
  }

  /**
   * Initial the dataModel when the app mount.
   * Initial the class of the datamodel with the set and get functions.
   */
  const initialDataModel = useCallback(async () => {
    try {
      setLoading(true)
      //Create new instance of the dataModel.
      const dm = new DataModel()
      //Initial the global instance.
      window.dm = dm
      //Get the dataModel obj from server by url or port.

      const dataModel = await serverGetDataModelId(url, port)

      //Validate the data model response.
      if (dataModel && Array.isArray(dataModel) && dataModel.length) {
        //set the DataModel instance with the
        dm.set(dataModel[0].id)
        const favicon = getFaviconEl() // Accessing favicon element
        favicon.href = getFaviconByDataModelId(dataModel[0].id)
        document.title = getDataModelFaviconTitle(dataModel[0].id)
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    initialDataModel()
  }, [])
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <HashRouter>{<Main serverLoading={loading} />}</HashRouter>
        </Provider>
      </ThemeProvider>
    </>
  )
}

export default App
