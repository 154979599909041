import { MenuItem, Select, InputLabel, FormControl, FormLabel, TextField, IconButton, InputAdornment, Checkbox } from '@mui/material'
import { InputWrapper, Lable, ListOptionWrapper, OptionHeading } from './InputDropDown.styled'
import Autocomplete from '@mui/material/Autocomplete'
import ClearIcon from '@mui/icons-material/Clear'
import themes from '../../styles'
interface InputDropdownProps {
  value: number | Array<any>
  label: string
  inputName: string
  onChangeHandler: React.ChangeEventHandler<any>
  data: { id: number | undefined; name: string }[]
  required?: boolean
  disabled?: boolean
  variant?: any
  sx?: any
  disableUnderline?: any
  alignCenter?: boolean
  multiple?: boolean
  defaultValue?: any
  renderValue?: any
  LabelStyle?: any
  CustomDropdownArrow?: any
  isAutoComplete?: boolean
  autoCompleteSx?: any
  defaultValueAutoComplete?: any
  handleClear?: any
  placeholder?: string
  hideLabel?: boolean
}

const InputDropdown: React.FC<React.PropsWithChildren<InputDropdownProps>> = (props) => {
  const {
    value,
    label,
    onChangeHandler,
    data,
    inputName,
    required,
    disabled,
    variant,
    sx,
    disableUnderline,
    alignCenter,
    multiple,
    defaultValue,
    renderValue,
    isAutoComplete,
    LabelStyle,
    hideLabel,
    defaultValueAutoComplete,
    autoCompleteSx,
    CustomDropdownArrow,
    handleClear
  } = props

  if (isAutoComplete) {
    return (
      <InputWrapper>
        <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }} size="small" fullWidth>
          <Autocomplete
            options={data}
            getOptionLabel={(option) => option?.name}
            value={data.find((d) => d.id === value)}
            defaultValue={defaultValueAutoComplete}
            renderInput={(params) => (
              <TextField
                {...params}
                name={inputName}
                label={label}
                InputLabelProps={{
                  sx: LabelStyle
                }}
                variant={variant ?? 'standard'}
                sx={autoCompleteSx}
                required={required}
                disabled={disabled}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: disableUnderline ?? false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" sx={{ p: 0 }}>
                        <CustomDropdownArrow />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
            onChange={(event: any, value: any) => onChangeHandler({ target: { name: inputName, value: value?.id } } as any)}
          />
        </FormControl>
      </InputWrapper>
    )
  } else {
    return (
      <InputWrapper hideLabel={hideLabel}>
        {!hideLabel && (
          <InputLabel sx={{ ...LabelStyle, color: themes().colors.primary, fontSize: '14px', fontWeight: '500' }}>{label}</InputLabel>
        )}
        <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }} size="small" fullWidth>
          <Select
            required={required}
            value={value}
            onChange={(event: any) => {
              onChangeHandler(event)
            }}
            name={inputName}
            disabled={disabled}
            defaultValue={defaultValue}
            renderValue={renderValue}
            variant={variant}
            sx={{
              ...sx,
              '& .MuiSelect-select': {
                paddingRight: value && handleClear ? 40 : 0
              }
            }}
            disableUnderline={disableUnderline}
            multiple={multiple ? multiple : false}
            IconComponent={!handleClear || (handleClear && !value) ? CustomDropdownArrow : null}
            endAdornment={
              value &&
              handleClear && (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small">
                    <ClearIcon fontSize="small" onClick={handleClear} />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {data.map((item, index) => {
              if (alignCenter) {
                return (
                  <MenuItem key={index} value={item.id} disabled={disabled}>
                    <ListOptionWrapper>
                      <OptionHeading>{item.name}</OptionHeading>
                    </ListOptionWrapper>
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem key={index} value={item.id} disabled={disabled}>
                    {item.name}
                  </MenuItem>
                )
              }
            })}
          </Select>
        </FormControl>
      </InputWrapper>
    )
  }
}

export default InputDropdown
