import styled from 'styled-components'
export const BackdropStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`
export const ModalStyle = styled.div`
  color: #000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`
export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #7b7b7b;
  background-color: #ffffff;
  border: 1px solid #707070;
`
export const ModalTopWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  height: 7%;
  background-color: ${({ theme }) => theme.typography.colors.white};
`
export const ModalContent = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.typography.colors.primary};
`
