import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAlert } from "alert";
import { IFacilityGroup } from "facilityGroup";
import { ICondition, IEventsType, IGroup } from "notifications";
import { any } from "prop-types";
import thunk from "redux-thunk";
import { serverGetNotificationCondition, serverGetNotificationEventType, serverGetNotificationFrequency, serverGetNotificationGroup, serverGetNotificationSettings } from "../services/serverApi";

type AlertState = {
    alerts: Array<IAlert>;
    condition: Array<ICondition>;
    frequency: Array<IFacilityGroup>;
    eventType: Array<IEventsType>;
    notificationGroup: Array<IGroup>;
    loading: boolean;
    error: string | null;  
}

const initialState: AlertState = {
    alerts: [],
    condition: [],
    frequency: [],
    eventType: [],
    notificationGroup: [],
    loading: false,
    error: null
}

/* Get Alert */
export const getAlert = createAsyncThunk('getAlert', async (s, thunkApi) => {
    try {
        const res = await serverGetNotificationSettings();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
});

/* Get Notification Condition */
export const getNotificationCondition = createAsyncThunk('getNotificationCondition', async (s, thunkApi) => {
    try {
        const res = await serverGetNotificationCondition();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
});

/* Get Notification Frequency */
export const getNotificationFrequency = createAsyncThunk('getNotificationFrequency', async (s, thunkApi) => {
    try {
        const res = await serverGetNotificationFrequency();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
})

/* Get Notification EventType */
export const getNotificationEventType = createAsyncThunk('getNotificationEventType', async (s, thunkApi) => {
    try {
        const res = await serverGetNotificationEventType();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
})

/* Get Notification Group */
export const getNotificationGroup = createAsyncThunk('getNotificationGroup', async (s, thunkApi) => {
    try {
        const res = await serverGetNotificationGroup();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
})

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {},
    extraReducers: (builder): any => {
        builder.addCase(getAlert.pending, (state) => {
            state.loading = true;
        }),
        builder.addCase(getAlert.fulfilled, (state, {payload}) => {
            state.error = null;
            state.loading = false;
            state.alerts = payload as IAlert[];
        }),
        builder.addCase(getAlert.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = (payload as ErrorEventInit).error;
        }),

        builder.addCase(getNotificationCondition.pending, (state) => {
            state.loading = true;
        }),
        builder.addCase(getNotificationCondition.fulfilled, (state, {payload}) => {
            state.error = null;
            state.loading = false;
            state.condition = payload as ICondition[];
        }),
        builder.addCase(getNotificationCondition.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = (payload as ErrorEventInit).error;
        }),

        builder.addCase(getNotificationFrequency.pending, (state) => {
            state.loading = true;
        }),
        builder.addCase(getNotificationFrequency.fulfilled, (state, {payload}) => {
            state.error = null;
            state.loading = false;
            state.frequency = payload as IFacilityGroup[];
        }),
        builder.addCase(getNotificationFrequency.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = (payload as ErrorEventInit).error;
        }),

        builder.addCase(getNotificationEventType.pending, (state) => {
            state.loading = true;
        }),
        builder.addCase(getNotificationEventType.fulfilled, (state, {payload}) => {
            state.error = null;
            state.loading = false;
            state.eventType = payload as IEventsType[];
        }),
        builder.addCase(getNotificationEventType.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = (payload as ErrorEventInit).error;
        }),

        builder.addCase(getNotificationGroup.pending, (state) => {
            state.loading = true;
        }),
        builder.addCase(getNotificationGroup.fulfilled, (state, {payload}) => {
            state.error = null;
            state.loading = false;
            state.notificationGroup = payload as IGroup[];
        }),
        builder.addCase(getNotificationGroup.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = (payload as ErrorEventInit).error;
        })
    },
})

export const alertReducer = alertSlice.reducer;
export const alertActions = alertSlice.actions;