import { ITreeNode } from 'locationFilter'
import { HirarchyTypes } from '../../components/Appbar/PoultryWeights/AppLocationFilter/AppLocationFilter'
import { IFacilityGroup } from 'facilityGroup'
import { IFacility } from 'facility'

/**
 * Build the tree from a hirrachy of all types array.
 * @param data Tree Node lists, with parentId and id.
 * @param rootId
 * @returns
 */
export const list2tree = (data: ITreeNode[], rootId: number, noCustomer?: boolean): ITreeNode[] => {
  function getNode(id?: number) {
    if (!id) return
    var node = []

    for (var i = 0; i < data.length; i++) {
      if (data[i].parentId == id) {
        if (data[i]?.id) {
          data[i].label = data[i].name
          data[i].value = data[i].id ?? 0
        }
        const _node = getNode(data[i].id)
        if (_node) {
          data[i].children = _node
        }
        node.push(data[i])
      }
    }
    if (node.length == 0) {
      return
    } else {
      return node
    }
  }
  if (noCustomer) {
    //getting only tree after facility Group
    const facilityGroupData = data.filter((f) => f.type === HirarchyTypes.FaciltyGroup)
    const treeData = facilityGroupData.map((m) => {
      return {
        ...m,
        children: getNode(m.id) ?? []
      }
    })

    return treeData
  }

  return getNode(rootId) as ITreeNode[]
}

export const filterFacilityGroupByCustomerIds = (customerIds: number[], facilitiyGroup: IFacilityGroup[]) => {
  const filteredFacilityGroup = facilitiyGroup?.filter((facilityGroup) => customerIds.includes(facilityGroup.customerId))
  return filteredFacilityGroup
}
export const filteredZonesByFacilityIds = (facilitiesIds: number[], facilities: IFacility[]) => {
  const filteredZonesByFacility = facilities
    .filter((facility) => facilitiesIds.includes(facility.id))
    .map((facility) =>
      facility.zones.map((zone) => {
        return {
          ...zone,
          name: zone.name ?? 'Zone' + zone.zoneIndex.toString()
        }
      })
    )
    .flat()
  return filteredZonesByFacility
}
