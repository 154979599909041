import WeightImg from '../../assets/images/IotFacilityImages/Group 346.svg'
import Co2Img from '../../assets/images/IotFacilityImages/Group 353.svg'
import HumidityImg from '../../assets/images/IotFacilityImages/Group 350.svg'
import LuxImg from '../../assets/images/IotFacilityImages/Group 349.svg'
import PressureImg from '../../assets/images/IotFacilityImages/Group 369.svg'
import TempImg from '../../assets/images/IotFacilityImages/Group 351.svg'
import ThiImg from '../../assets/images/IotFacilityImages/Group 348.svg'
import { WaterMeterIcon } from '../../assets/images/iotImages/RuminTechIot'
export enum SENSORS {
  'humidity',
  'temperature',
  'thi',
  'airPressure',
  'co2Level',
  'waterIntake'
}

export const sensorsBpacColor: any = {
  co2Level: '#0153a5',
  humidity: '#017745',
  thi: '#eda701',
  airPressure: '#017745',
  waterIntake: '#22c0d9',
  temperature: '#c65c20'
}

export const sensorsBpacImages = [
  {
    name: 'airPressure',
    img: PressureImg,
    symbol: 'kpa'
  },

  {
    name: 'humidity',
    img: HumidityImg,
    symbol: '%'
  },
  {
    name: 'lux',
    img: LuxImg,
    symbol: 'lx'
  },
  {
    name: 'thi',
    img: ThiImg,
    symbol: 'THI'
  },
  {
    name: 'temperature',
    img: TempImg,
    symbol: '°c'
  },
  {
    name: 'co2Level',
    img: Co2Img,
    symbol: 'ppm'
  },
  {
    name: 'weight',
    img: WeightImg,
    symbol: 'kg'
  },
  {
    name: 'waterIntake',
    img: WaterMeterIcon,
    symbol: 'L'
  }
]
