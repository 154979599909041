//@ts-nocheck
import React, { useMemo } from 'react'
import { GoogleMap, StandaloneSearchBox, Marker, Polygon, LoadScript, OverlayView } from '@react-google-maps/api'
import useGoogleMap from './useAppGoogleMap'
import { useEffect, useRef, useState } from 'react'
import './AppGoogleMap.scss'
import MapBuildingIcon from './MapBuildingIcon/MapBuildingIcon'

interface AppGoogleMapProps {
  width: string | number
  height: string | number
  markers?: { lat: number; lng: number }[]
  devices?: { lat: number; lng: number }[]
  onClick?: (point: any) => void
  search?: boolean
  center?: { lat: number; lng: number }
}

const AppGoogleMap: React.FC<React.PropsWithChildren<AppGoogleMapProps>> = ({ width, devices, center, height, onClick, search }) => {
  const { onLoad, onUnmount } = useGoogleMap()
  const [_center, setCenter] = useState(center)
  const [mapLoad, setLoadMap] = useState(false)
  const mapRef = useRef(null)
  const [zoomLevel, setZoomLevel] = useState(1)
  useEffect(() => {
    setZoomLevel(18)
    setCenter(center)
  }, [center])

  const onZoomChange = () => {
    if (mapRef.current) {
      setZoomLevel(mapRef.current.getZoom())
    }
  }
  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyDcgcFs3_dm8fO2gFCtVdAaQH9u4YOVTts" libraries={['places', 'geometry']}>
        {
          <GoogleMap
            options={{
              fullscreenControl: true,
              zoomControl: false,
              panControl: false,
              rotateControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              mapTypeId: window.google?.maps?.MapTypeId?.HYBRID
            }}
            onClick={onClick}
            zoom={zoomLevel}
            mapContainerStyle={{ width: width, height: height }}
            center={_center}
            onLoad={(map: any) => {
              mapRef.current = map
              setTimeout(() => {
                setLoadMap(true)
              }, 1000)
              onLoad(map, _center)
            }}
            onZoomChanged={onZoomChange}
            onUnmount={onUnmount}
          >
            {mapLoad &&
              devices.map(({ lat, lng, name, units }: any, index) => {
                return (
                  <>
                    <OverlayView key={index} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={{ lat, lng: lng }}>
                      <MapBuildingIcon zoom={zoomLevel} BuildingName={name} score={8} />
                    </OverlayView>
                  </>
                )
              })}
          </GoogleMap>
        }
      </LoadScript>
    </div>
  )
}

export default React.memo(AppGoogleMap)
