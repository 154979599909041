import styled from 'styled-components'

export const AppDataFilterWrapper = styled.div`
  width: auto;
  height: 36px;
  grid-area: date1;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-template-rows: 1fr;
  grid-gap: 1px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.typography.colors.white};
  margin-right: 10px;
  button:last-child {
    border-radius: 0 5px 5px 0px;
  }
  button:first-child {
    border-radius: 5px 0px 0px 5px;
  }
`

export const AppDataFilterOptions = styled.button<{ isActive: boolean }>`
  width: 100%;
  border: none;
  font: open sans;
  font-size: 14px;
  font-weight: 900;
  background-color: ${({ isActive, theme }) => (isActive ? 'white' : theme.colors.primary)};
  color: ${({ isActive, disabled }) => (isActive ? '#113252' : disabled ? 'grey' : 'white')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 12px;
  }
`
export const AppLiveIcon = styled.div<{ isActive: boolean; disabled: boolean }>`
  height: 15px;
  width: 15px;
  /* background-color: ${({ isActive }) => (isActive ? '#113252' : 'white')}; */

  height: 13px;
  width: 13px;
  background-color: ${({ isActive, disabled }) => (isActive ? 'red' : disabled ? 'grey' : 'white')};
  border-radius: 50%;
  position: relative;
  margin-right: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 10px;
    width: 10px;
  }
`

export const AppLiveTitle = styled.div``
