import triangle from './TriangleIcon.svg'
import edit from './EditIcon.svg'
import deleteicon from './DeleteIcon.svg'
import circle from './CircleIcon.svg'
import button from './ButtonIcon.svg'
import BpacZoneImage from './BpacZoneIcon.svg'
import LocationImage from './LocationIcon.svg'
import polygonSelectImg from './polygonSelectIcon.svg'
import polygonImg from './polygonIcon.svg'

export const TriangleIcon = triangle
export const EditIcon = edit
export const DeleteIcon = deleteicon
export const CircleIcon = circle
export const ButtonIcon = button
export const BpacZoneIcon = BpacZoneImage
export const LocationIcon = LocationImage
export const polygonSelectIcon = polygonSelectImg;
export const polygonIcon = polygonImg;
