export const materailTableOptions = {
  maxBodyHeight: 'calc(100vh - 350px)',
  minBodyHeight: 0,
  pageSize: 100,
  search: true,
  toolbar: true,
  columnsButton: true,
  exportButton: true,
  // exportAllData: true,
  showTextRowsSelected: false,
  showFirstLastPageButtons: false,
  paging: true,
  showTitle: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
  rowStyle: {
    color: '#7B7B7B',
    whiteSpace: 'noWrap'
  },
  headerStyle: {
    color: '#7B7B7B',
    fontWeight: '600'
  }
}
