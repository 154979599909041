import { crmIcon, crmSelectedIcon } from '../../../assets/images/sidebar/RuminTech'
import {
  closeArrowIcon,
  customerManagmentIcon,
  customerManagmentSelectIcon,
  naviCustomerManagementIcon,
  naviMyFarmIcon,
  openArrowIcon,
  devicesSelectIcon,
  devicesIcon
} from '../../../assets/images/sideMenu/index'
import { closeArrowRuminTechIcon, openArrowRuminTechIcon, openArrowWhiteIcon } from '../../../assets/images/sideMenu/RuminTech'

export const getGasSidebarData = () => {
  return [
    {
      title: 'Devices',
      path: '/Devices',
      // image: devicesIcon,
      image: devicesSelectIcon,
      navicon: naviMyFarmIcon,
      selectImage: devicesSelectIcon,
      iconClosed: closeArrowRuminTechIcon,
      iconOpened: openArrowWhiteIcon,
      subNav: [
        {
          title: 'Summary',
          path: '/Devices/summary'
        },
        {
          title: 'Device Version',
          path: '/Devices/device-version'
        }
      ]
    },
    {
      title: 'CRM',
      path: '/CRM',
      navicon: crmSelectedIcon,
      // image: customerManagmentIcon,
      image: crmSelectedIcon,
      selectImage: crmSelectedIcon,

      iconClosed: closeArrowRuminTechIcon,
      iconOpened: openArrowWhiteIcon,

      subNav: [
        {
          title: 'Users',
          path: '/CRM/users'
        },
        {
          title: 'Customer',
          path: '/CRM/customer'
        },
        {
          title: 'Roles',
          path: '/CRM/roles'
        }
      ]
    }
  ]
}
