
import header1Icon from './header1Icon.svg';
import body1Icon from './body1Icon.svg';
import weightIcon from './weightIcon.svg';
import radarIcon from './radarIcon.svg';
import alertHeaderIcon from './alertHeaderIcon.svg';
import rightHeaderIcon from './rightHeaderIcon.svg';
import wrongHeaderIcon from './wrongHeaderIcon.svg';
import weightSensorIconNew from './WeightSensorsIcon.svg';
import tabletIcon from './tabletIcon.svg';
import geteWaysIcon from './gateWays.svg';


export const Header1Icon = header1Icon;
export const Body1Icon = body1Icon;
export const WeightIcon = weightIcon;
export const RadarIcon = radarIcon;
export const AlertHeaderIcon = alertHeaderIcon;
export const RightHeaderIcon = rightHeaderIcon;
export const WrongHeaderIcon = wrongHeaderIcon;
export const weightSensorIcon = weightSensorIconNew;
export const TabletIcon = tabletIcon;
export const GeteWaysIcon = geteWaysIcon;