import React, { useState } from 'react'
import { RootState, useReduxSelector } from '../../../redux'
import AppBarUser from './AppBarUser/AppBarUser'
import AppBarLogo from './AppBarLogo/AppBarLogo'
import AppBarNotification from './AppBarNotification/AppBarNotification'
import { locationIcon, timeIcon } from '../../../assets/images/appBar/index'
import {
  AppbarWrapper,
  MiddleWrapper,
  ApplocationWrapper,
  LocationIcon,
  AppBarNotificationWrapper,
  ProfileNotificationWrapper,
  SkeletonCss
} from './Appbar.styled'
import AppbarLocationComponent from './AppLocationFilter/AppLocationFilter'
import useAnalytics from '../../../hooks/useAnalysis'
import { OnlyMobileContainer } from '../../../styles/PoultryWeights/global.styled'
import { MobileAppbar } from './MobileAppbar/MobileAppbar'
import PwNotificationDialog from '../../../dialogs/NotificationDialog/PoultryWeights/NotificationDialog'
import { Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import useNotification from '../../../hooks/useNotification'
import { GetuserName } from '../../../utils/helpers/user'

interface Props {
  serverLoading?: any
}

export const PoultryWeightsAppbar: React.FC<Props> = ({ serverLoading }) => {
  const { unSeenNotificationData } = useNotification()
  const [openNotificationDialog, setOpenNotificaitonDialog] = useState(false)
  const user: any = useReduxSelector((state: RootState) => state.user.user)
  const hanldeNotificationClick = () => {
    setOpenNotificaitonDialog(!openNotificationDialog)
  }
  return (
    <>
      <AppbarWrapper>
        {serverLoading ? <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarLogo} /> : <AppBarLogo />}
        <MiddleWrapper>
          <ApplocationWrapper>
            {serverLoading ? <Skeleton variant="circular" sx={SkeletonCss.LocationIcon} /> : <LocationIcon src={locationIcon} />}
            {serverLoading ? (
              <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppbarLocationComponent} />
            ) : (
              <AppbarLocationComponent />
            )}

            <ProfileNotificationWrapper>
              {serverLoading ? (
                <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarNotification} />
              ) : (
                <AppBarNotificationWrapper
                  onClick={(e) => {
                    hanldeNotificationClick()
                  }}
                >
                  <AppBarNotification unSeenNotificaitonCount={unSeenNotificationData} />
                </AppBarNotificationWrapper>
              )}
              {openNotificationDialog && (
                <PwNotificationDialog open={openNotificationDialog} onSuccess={hanldeNotificationClick} onClose={hanldeNotificationClick} />
              )}
              {serverLoading ? (
                <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarUser} />
              ) : (
                <AppBarUser userName={GetuserName(user)} />
              )}
            </ProfileNotificationWrapper>
          </ApplocationWrapper>
        </MiddleWrapper>
      </AppbarWrapper>
      <OnlyMobileContainer>
        <MobileAppbar />
      </OnlyMobileContainer>
    </>
  )
}
