import styled from 'styled-components'

export const DevicesHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.lg};
`
export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: ${({ theme }) => theme.spacing.lg};
`
export const DevicesImage = styled.img``
export const DevicesContainer = styled.div`
  height: 100%;
  width: 100%;
  /* padding-bottom: 120px; */
  /* overflow: auto; */
`
export const BoluseContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.primary};
`
export const BoluseImage = styled.img``
export const EartagContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.primary};
`
export const EarTagImage = styled.img``

export const GateWaysContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const GatewayImage = styled.img``
export const DeviceHeading = styled.span`
  margin-top: ${({ theme }) => theme.spacing.lg};
`
export const ChartContainer = styled.div`
  display: flex;
`
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const TableContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.tertiary};
`
export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ShedsBackTitleIcon = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
  padding: 10px;
`
export const SummaryBackIcon = styled.div``
export const ArrowImg = styled.img`
  width: 9px;
  margin: -3px 0 0 10px;
`
export const ShedsBackTitle = styled.span`
  margin-left: ${({ theme }) => theme.typography.size.xs};
`
export const RowHeadingComponent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 10px;
  }
`
export const TableData = styled.div`
  width: 120px;
  color: #113252;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.size.md};
  font-weight: 900;
  img {
    margin-bottom: 5px;
    margin-right: 5px;
  }
`
export const MobileViewWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: unset;
    height: 500px;
    width: 100%;
    overflow: auto;
  }
`
