import React, { ErrorInfo, useCallback, useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import AppButton from '../../components/AppButton/AppButton'
import './MessageDialog.scss'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { DeleteMessage } from './MessageDialog.styled'
import { DataModel as DataModelIds } from '../../types/dataModel.d'
import { GetDataModelId } from '../../utils/helpers/dataModel'
import { useTranslation } from 'react-i18next'

interface MessageDialogProps {
  title: string
  message: string
  showCancelButton?: boolean
  okButtonText?: string
  cancelButtonText?: string
  open: boolean
  onClose: any
  onSuccess: any
  loading?: boolean
}

const MessageDialog: React.FC<MessageDialogProps> = ({
  title,
  message,
  showCancelButton,
  okButtonText,
  cancelButtonText,
  open,
  onClose,
  onSuccess,
  loading
}) => {
  const handleClose = () => {
    onClose?.()
  }
  const handleSuccess = () => {
    onSuccess?.()
  }

  const DataModelId = GetDataModelId()
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'body'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ background: showCancelButton ? '#113252' : '#ffbb33', color: '#fff', fontWeight: 'bold' }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
        <DeleteMessage style={{ paddingTop: '10px' }}>{message}</DeleteMessage>
      </DialogContent>
      <DialogActions>
        {showCancelButton && (
          <AppButton onClick={handleClose} className="btn">
            {cancelButtonText || t('general.no')}
          </AppButton>
        )}
        <AppButton cy={'okButton'} isWarning={showCancelButton} onClick={handleSuccess} className="btn">
          {loading ? <Spinner animation="border" /> : okButtonText || 'Ok'}
        </AppButton>
      </DialogActions>
    </Dialog>
  )
}

export default MessageDialog
