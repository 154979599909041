import styled from 'styled-components'
import AppSubNavigationComponent from '../../../AppSubNavigation/AppSubNavigation'

export const MobileAppbarWrapper = styled.div`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: 190px;
  grid-area: appbar;
`
export const HamWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SidebarWrapper = styled.div``

export const DateWrapper = styled.div<{ open: boolean }>`
  width: 95%;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xs};
  margin-left: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px dashed ${({ theme }) => theme.typography.colors.white};
`
export const LastWrapper = styled.div`
  display: flex;
`
export const AppSubNavigationNew = styled(AppSubNavigationComponent)`
  width: 100%;
`
export const CustomerHeading = styled.span<{ isActive: boolean }>`
  width: 120px;
  color: ${({ theme, isActive }) => (isActive ? theme.typography.colors.primary : theme.typography.colors.white)};
  font-size: ${({ theme }) => theme.typography.size.sm};
  margin-right: ${({ theme }) => theme.spacing.xs};
`
export const ModelHeading = styled.span`
  color: ${({ theme }) => theme.typography.colors.white};
  font-size: ${({ theme }) => theme.typography.size.md};
  margin-left: ${({ theme }) => theme.spacing.xs};
`
export const TimeHeading = styled.span`
  color: ${({ theme }) => theme.typography.colors.white};
  font-size: ${({ theme }) => theme.typography.size.xs};
`
export const FarmHeading = styled(CustomerHeading)<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.typography.colors.primary : theme.typography.colors.white)};
`

export const ShedHeading = styled(CustomerHeading)``

export const Wrapper = styled.div`
  display: flex;
  width: 90%;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.typography.colors.secondary};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f2ddb8;
    border: none;
  }
  margin-left: ${({ theme }) => theme.spacing.sm};
`

export const ApplocationWrapper = styled.div<{ isActive: boolean }>`
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  border: 1px dashed ${({ theme }) => theme.typography.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ isActive, theme }) => (isActive ? theme.typography.colors.white : theme.colors.primary)};
`
export const AppFarmWrapper = styled(ApplocationWrapper)`
  margin-left: ${({ theme }) => theme.spacing.sm};
`
export const AppShedWrapper = styled(ApplocationWrapper)`
  margin-left: ${({ theme }) => theme.spacing.sm};
`

export const LocationIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacing.xs};
  margin-left: ${({ theme }) => theme.spacing.xs};
  height: 20px;
  width: 14px;
`
export const FarmIcon = styled(LocationIcon)``

export const ShedIcon = styled(LocationIcon)``

export const ModalIcon = styled.img`
  margin-left: ${({ theme }) => theme.spacing.sm};
`
export const TimeIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacing.sm};
  margin-left: ${({ theme }) => theme.spacing.sm};
`

export const DateIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacing.sm};
  height: 35px;
`
export const ContentTopWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing.lg};
  height: 11%;
`
export const SelectCustomerContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 80%;
  background-color: ${({ theme }) => theme.typography.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`
export const CloseImg = styled.img`
  position: relative;
  float: right;
`
export const TableWrapper = styled.ul`
  width: 100%;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-left: 0;
  margin: 0;
`
export const TableList = styled.li<{ isActive: boolean }>`
  width: 100%;
  height: 40px;
  list-style-type: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#375878' : '#ffffff')};
`
export const CustomerId = styled.span<{ isActive: boolean }>`
  font-size: ${({ theme }) => theme.typography.size.md};
  font-family: open sans;
  font-weight: bold;
  color: ${({ theme, isActive }) => (isActive ? theme.typography.colors.white : theme.colors.primary)};
  margin-left: ${({ theme }) => theme.spacing.lg};
`
export const CustomerName = styled.span<{ isActive: boolean }>`
  font-size: ${({ theme }) => theme.typography.size.md};
  font-family: open sans;
  margin-left: ${({ theme }) => theme.spacing.md};
  color: ${({ theme, isActive }) => (isActive ? theme.typography.colors.white : theme.colors.primary)};
`
export const FarmId = styled(CustomerId)``
export const FarmName = styled(CustomerName)``
export const ShedId = styled(CustomerId)``
export const ShedName = styled(CustomerName)``
export const CropId = styled(CustomerId)``
export const CropDate = styled(CustomerName)``
export const CustomDateIcon = styled.img`
  baclground-color: ${({ theme }) => theme.typography.colors.white};
  height: 40px;
  width: 40px;
`
export const CustomDateHeading = styled.span`
  font-family: open sans;
  font-size: ${({ theme }) => theme.typography.size.md};
  margin-left: ${({ theme }) => theme.spacing.lg};
`
export const CustomDateWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  color: ${({ theme }) => theme.typography.colors.white};
`
