import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverGetFacilities, serverCreateFacility } from '../services/serverApi'
import { IFacility } from 'facility';

type FacilityState = {
  facilities: Array<IFacility>
  loading: boolean
  error: string | null
}

const initialState: FacilityState = {
  facilities: [],
  loading: false,
  error: null
}

/**Get FacilitiesGroup */
export const getFacilities = createAsyncThunk('getFacility', async (s, thunkApi) => {
  try {
    const res = await serverGetFacilities()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add device  */
export const addFacility = createAsyncThunk('addFacility', async (facility: IFacility, thunkApi) => {
  try {
    const res = await serverCreateFacility(facility)
    thunkApi.dispatch(getFacilities())
    return res
  } catch (e) {
    console.error(e)
  }
})

export const facilitySlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getFacilities.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getFacilities.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.facilities = payload as IFacility[]
      }),
      builder.addCase(getFacilities.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      builder.addCase(addFacility.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
      }),
      builder.addCase(addFacility.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addFacility.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const facilityReducer = facilitySlice.reducer
export const facilityActions = facilitySlice.actions
