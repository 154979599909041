import styled from 'styled-components'

export const DialogActionWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
`

export const LabelContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
`

export const Form = styled.form``

export const AddEventDialogContainer = styled.div`
  width: 350px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`

export const DatePickerWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  gap: 10px;
`
