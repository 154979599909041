import WeightImg from '../../assets/images/IotFacilityImages/Group 346.svg'
import Co2Img from '../../assets/images/IotFacilityImages/Group 353.svg'
import HumidityImg from '../../assets/images/IotFacilityImages/Group 350.svg'
import LuxImg from '../../assets/images/IotFacilityImages/Group 349.svg'
import PressureImg from '../../assets/images/IotFacilityImages/Group 369.svg'
import TempImg from '../../assets/images/IotFacilityImages/Group 351.svg'
import ThiImg from '../../assets/images/IotFacilityImages/Group 348.svg'
import LitersImg from '../../assets/images/IotFacilityImages/Group 364.svg'

export enum SENSORS {
  'humidity',
  'lux',
  'thi',
  'temperature',
  'weight',
  'co2Level',
  'waterIntake'
}

export enum HARVEST {
  'noOfBirds' = 10,
  'dead' = 11,
  'legCulls' = 12,
  'otherCulls' = 13
}

export const sensorsPourtryColor: any = {
  weight: '#665bfe',
  co2Level: '#0153a5',
  humidity: '#017745',
  thi: '#eda701',
  lux: '#a5a61a',
  waterFlow: '#22c0d9',
  temperature: '#c65c20',
  waterIntake: '#22c0d9'
}

export const sensorsPoultryImages = [
  {
    name: 'airPressure',
    img: PressureImg,
    symbol: 'kpa'
  },

  {
    name: 'humidity',
    img: HumidityImg,
    symbol: '%'
  },
  {
    name: 'lux',
    img: LuxImg,
    symbol: 'lx'
  },
  {
    name: 'thi',
    img: ThiImg,
    symbol: 'THI'
  },
  {
    name: 'temperature',
    img: TempImg,
    symbol: '°c'
  },
  {
    name: 'co2Level',
    img: Co2Img,
    symbol: 'ppm'
  },
  {
    name: 'weight',
    img: WeightImg,
    symbol: 'kg'
  },
  {
    name: 'waterIntake',
    img: LitersImg,
    symbol: 'L'
  }
]
