export const calculateCenterusingLatLong = (coordinates: { lat: number; lng: number }[]) => {
  if (coordinates?.length === 0) {
    return null
  }

  let sumLat = 0
  let sumLng = 0

  coordinates?.forEach((coordinate) => {
    sumLat += coordinate.lat
    sumLng += coordinate.lng
  })

  const avgLat = sumLat / coordinates.length
  const avgLng = sumLng / coordinates.length

  return { lat: avgLat, lng: avgLng }
}
