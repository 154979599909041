import styled from 'styled-components'

export const AppTableWrapper = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-radius: 0;
    height: 100%;
  }
  // overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.typography.colors.white};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.typography.colors.primary};
    border: none;
  }
`

export const SmallAppTableWrapper = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-radius: 0;
    height: 100%;
  }
  // overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.typography.colors.white};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.typography.colors.primary};
    border: none;
  }
`

export const TableContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.typography.colors.white};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.typography.colors.primary};
      border: none;
    }
  }
`
