import React, { useCallback, useEffect, useMemo } from 'react'
import { useState } from 'react'
import { getDateByIsraelTimeZone } from '../../../utils/helpers/date'
import { deleteIcon, editIcon } from '../../../assets/images/global'
import { nofiticationTypeImages } from '../../../assets/images/notificationTypeImages'
import { NotificationType } from '../../../utils/consts/notifications'
import { TypeImage, NotificationContainer, MessageBox, CircleImg, DateContainer } from './NotificationDialog.styled'
import { INotification } from '../../../types/notifications.d'
import { RootState, useReduxSelector } from '../../../redux'
import { serverGetNotification, serverMarkNotificationAsSeen } from '../../../services/serverApi'
import { useDispatch } from 'react-redux'
import { GetDataModelId } from '../../../utils/helpers/dataModel'
import { DataModel } from '../../../types/dataModel.d'
import { useTranslation } from 'react-i18next'
// import { getNotifications } from '../../../redux/NotificationSlice'

const useNotifications = () => {
  const { facilitiesGroups } = useReduxSelector((state: RootState) => state.facilitiesGroup)
  const { devices } = useReduxSelector((state: RootState) => state.devices)
  const [pageSizeData, setPageSizeData] = useState<number>(0)
  const [pageData, setPageData] = useState<number>(0)
  const dataModelId = GetDataModelId()
  const { t } = useTranslation()

  const getFacilitiesGroupName = (facilityGroupId: number | undefined) => {
    const facilityGroup = facilitiesGroups.find(({ id }) => id === facilityGroupId)
    return facilityGroup?.name
  }

  const getNotificationData = async (page: number, pageSize: number, search: string, orderBy: any, orderDirection: string) => {
    const start = page * pageSize
    const end = pageSize
    setPageData(page)
    setPageSizeData(pageSize)
    const res = await serverGetNotification(
      start,
      end,
      search,
      orderBy ? orderBy?.field : 'createDate',
      orderDirection ? orderDirection : 'asc'
    )
    const notificationsSortData = [...res].sort((a, b) => {
      if (a.markedAsSeenDate !== null) {
        return 1
      }

      if (b.markedAsSeenDate !== null) {
        return -1
      }

      if (a.markedAsSeenDate !== b.markedAsSeenDate) {
        return 0
      }

      return a < b ? -1 : 1
    })
    return {
      data: notificationsSortData,
      page: page,
      totalCount: notificationsSortData[0]?.totalCount
    }
  }

  const dispatch = useDispatch()

  const getDeviceName = (deviceId?: number) => {
    const res = devices?.find(({ id }) => id === deviceId)
    return res?.name
  }

  const notificationHeader = useMemo(
    () =>
      [
        {
          title: t('notification.dateNTime'),
          field: 'createDate',
          render: (notification: INotification) => (
            <DateContainer>
              {notification.markedAsSeenDate === null || notification.markedAsSeenDate === undefined ? <CircleImg></CircleImg> : ''}
              {getDateByIsraelTimeZone(notification.createDate as string)}
            </DateContainer>
          )
        },
        {
          title: t('notification.type'),
          field: 'type',
          render: (notification: INotification) => {
            switch (notification.notificationTypeId) {
              case NotificationType.WARNING:
                return (
                  <NotificationContainer>
                    <TypeImage src={nofiticationTypeImages.warningImage} alt="warning" />
                    <span>{t('notification.warning')}</span>
                  </NotificationContainer>
                )
              case NotificationType.ALERT:
                return (
                  <NotificationContainer>
                    <TypeImage src={nofiticationTypeImages.alertImage} alt="alert" />
                    <span>{t('notification.alert')}</span>
                  </NotificationContainer>
                )
              case NotificationType.INFO:
                return (
                  <NotificationContainer>
                    <TypeImage src={nofiticationTypeImages.infoImage} alt="info" />
                    <span>{t('notification.info')}</span>
                  </NotificationContainer>
                )
            }
          }
        },
        {
          title: dataModelId === DataModel.Bees ? t('notification.Sensor') : t('notification.Device'),
          field: 'deviceId',
          render: (notification: INotification) => getDeviceName(notification.deviceId)
        },
        {
          title: t('notification.FacilityGroup'),
          field: 'facilityGroupId',
          render: (notification: INotification) => getFacilitiesGroupName(notification?.facilityGroupId)
        },
        {
          title: t('notification.Message'),
          field: 'message',
          searchable: true,
          render: (rowData) => <MessageBox>{rowData.message}</MessageBox>,
          width: '800px'
        }
      ] as header[],
    [facilitiesGroups]
  )

  const notificationActions = useMemo(
    () => [
      {
        icon: () => <img src={deleteIcon} />,
        tooltip: t('notification.deleteNotification'),
        onClick: (event: any, notification: INotification) => console.log(notification)
      }
    ],
    []
  )

  const onRowClick = async (event: any, rowData: INotification, tableRef: any) => {
    if (rowData?.markedAsSeenDate === undefined || rowData?.markedAsSeenDate === null) {
      await serverMarkNotificationAsSeen(rowData?.id)
      // getNotificationData(pageData, pageSizeData);
      tableRef.current && tableRef.current.onQueryChange()
      // dispatch(getNotifications())
    } else {
      // getNotificationData(pageData, pageSizeData);
    }
  }

  return { notificationHeader, notificationActions, onRowClick, getNotificationData }
}
export default useNotifications
