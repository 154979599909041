import { ICustomer } from 'customers'
import { IFacility } from 'facility'
import { IFacilityGroup } from 'facilityGroup'
import React from 'react'
import { useState } from 'react'
import { FarmMobileSecondaryIcon, locationIcon, locationSecondaryIcon } from '../../../../../../assets/images/appBar'
import { CloseIconImg } from '../../../../../../assets/images/UsersImages'
import { RootState, useReduxSelector } from '../../../../../../redux'
import Modal from '../../../../../Model/ModalMobileView'
import { ModalContent, ModalTopWrapper } from '../../../../../Model/ModalMobileView.styled'
import {
  CloseImg,
  ContentTopWrapper,
  ModalIcon,
  ModelHeading,
  SelectCustomerContainer,
  ShedId,
  ShedName,
  TableList,
  TableWrapper
} from '../../MobileAppbar.styled'

interface IModalProps {
  handleChange: any
  farmName: string | undefined
  customerName: string | undefined
  farmId: number | undefined
  shedId: number | undefined
  isOpen: { [key: string]: boolean }
  setIsOpen: (isOpen: { [key: string]: boolean }) => void
}

const ShedModal: React.FC<IModalProps> = ({ handleChange, farmId, shedId, isOpen, setIsOpen, farmName, customerName }) => {
  const { facilities } = useReduxSelector((state: RootState) => state.facilities)

  return (
    <>
      {isOpen.facility && (
        <Modal show={isOpen.facility}>
          <ModalTopWrapper>
            <ModalIcon src={locationSecondaryIcon} />
            {customerName}
            <ModalIcon src={FarmMobileSecondaryIcon} />
            {farmName}
            <CloseImg src={CloseIconImg} onClick={() => setIsOpen({ ...isOpen, facility: !isOpen.facility })} />
          </ModalTopWrapper>
          <ModalContent>
            <ContentTopWrapper>
              <ModalIcon src={locationIcon} />
              <ModelHeading>Select Shed</ModelHeading>
            </ContentTopWrapper>
            <SelectCustomerContainer>
              {facilities
                .filter((shed: IFacility) => shed.facilityGroupId === farmId)
                .map((shed: IFacility) => {
                  return (
                    <TableWrapper key={shed.id}>
                      <TableList
                        isActive={shedId === shed.id}
                        onClick={() => handleChange({ lable: 'facility', id: shed.id, name: shed.name })}
                      >
                        <ShedId isActive={shedId === shed.id}>{shed.id}</ShedId>
                        <ShedName isActive={shedId === shed.id}>{shed.name}</ShedName>
                      </TableList>
                    </TableWrapper>
                  )
                })}
            </SelectCustomerContainer>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default ShedModal
