import React from 'react'
import { BeesLogo } from '../../../../assets/images/appBar/BeesAppbar'
import './AppBarLogo.scss'
const AppBarLogo = () => {
  return (
    <div className="app-bar-logo-container">
      <img src={BeesLogo} alt="" className="inner-image" />
    </div>
  )
}

export default AppBarLogo
