import totalIcon from './totalIcon.svg';
import deathIcon from './deathIcon.svg';
import legIcon from './legIcon.svg';
import endCropIcon from './endCropIcon.svg';
import dailyReportIcon from './dailyReportIcon.svg';
import dateIcon from './dateIcon.svg';
import editButton from './editButtonIcon.svg';
import weightIcon from './weightIcon.svg';
import waterIcon from './waterIcon.svg';
import feedIcon from './feedIcon.svg';
import otherCullsIcon from './otherCullsIcon.svg';
import cropSparrowIcon from './cropSparrowIcon.svg';
import cropSunIcon from './cropSunicon.svg';
import cropDateIcon from './cropDateIcon.svg';
import ChickHead from './chickHead.svg';
import daySun from './daySun.svg';
import chickWhite from './chickWhite.svg';
import chickDead from './chickDead.svg';
import cullWhite from './cullWhite.svg';
import chickBlue  from './chickBlue.svg';
import calendar  from './calendar.svg';
import eyeImg from './eyeIcon.svg';
import shedImg from './shedIcon.svg';
import totalDeathImg from './totalDeathIcon.svg'
import totalLegCullsImg from './totalLegCullsIcon.svg';
import totalOtherCullsImg from './totalOtherCullsIcon.svg';
import mortalityHistoryImg from './mortalityHistoryIcon.svg';
import todaycalendarImg from './todayCalendar.svg'
import ClimateSensorImg from './ClimateSensorIcon.svg';
import upExpandeImg from './upExpandeIcon.svg';
import downExpandeImg from './downExpandeIcon.svg';
import expandeImg from './expandeIcon.svg';

export const TotalIcon = totalIcon;
export const DeathIcon = deathIcon;
export const LegIcon = legIcon;
export const EndCropIconImg = endCropIcon;
export const DailyReportIconImg = dailyReportIcon;
export const DateIconImg = dateIcon;
export const EditButtonIcon = editButton;
export const WeightIcon = weightIcon;
export const WaterIcon = waterIcon;
export const FeedIcon = feedIcon;
export const OtherCullsIcon = otherCullsIcon;
export const CropSparrowIcon = cropSparrowIcon;
export const CropSunIcon = cropSunIcon;
export const CropDateIcon = cropDateIcon;
export const ChickHeadImage = ChickHead;
export const daySunImage = daySun;
export const chickWhiteImage = chickWhite;
export const chickDeadImage  = chickDead;
export const cullWhiteImage = cullWhite;
export const chickBlueImage = chickBlue;
export const calendarImage = calendar;
export const eyeIcon = eyeImg;
export const shedIcon = shedImg;
export const totalDeathIcon = totalDeathImg;
export const totalLegCullsIcon = totalLegCullsImg;
export const totalOtherCullsIcon = totalOtherCullsImg;
export const mortalityHistoryIcon = mortalityHistoryImg;
export const todaycalendarIcon = todaycalendarImg;
export const ClimateSensorIcon = ClimateSensorImg;
export const upExpandeIcon = upExpandeImg;
export const downExpandeIcon = downExpandeImg;
export const expandeIcon = expandeImg;