import {
  MenuAccountIconImg,
  MenuBeesIconImg,
  MenuBPACIconImg,
  MenuGASIconImg,
  MenuHydroIconImg,
  MenuLogoutIconImg,
  MenuNevatimIconImg,
  MenuPoultryIconImg,
  MenuProductIconImg,
  MenuRadarIconImg,
  MenuRuminTechIconImg,
  MenuSettingIconImg,
  MenuRighArrowtIconImg
} from '../../assets/images/global'
import { DataModel } from '../../types/dataModel.d'

export interface MenuItem {
  id: string
  icon: string | any
  text: string
  path?: string
  onClick?: string
  submenuItems?: SubMenuItem[]
}

export interface SubMenuItem extends MenuItem {
  onClickDataModelId?: number
}

export interface TransitionState {
  state: 'entering' | 'entered' | 'exiting' | 'exited'
}

export const menuItems: MenuItem[] = [
  {
    id: 'products',
    icon: MenuProductIconImg,
    text: 'Products',
    submenuItems: [
      { id: 'GAS', icon: MenuGASIconImg, text: 'GAS', onClickDataModelId: DataModel.PoultryWeights },
      {
        id: 'Radar',
        icon: MenuRadarIconImg,
        text: 'Radar',
        onClickDataModelId: DataModel.PoultryWeights
      },
      {
        id: 'Bees',
        icon: MenuBeesIconImg,
        text: 'Bees',
        onClickDataModelId: DataModel.Bees
      },
      {
        id: 'Poultry',
        icon: MenuPoultryIconImg,
        text: 'Poultry',
        onClickDataModelId: DataModel.PoultryWeights
      },
      {
        id: 'Hydro',
        icon: MenuHydroIconImg,
        text: 'Hydro',
        onClickDataModelId: DataModel.hydroIntel
      },
      {
        id: 'RuminTech',
        icon: MenuRuminTechIconImg,
        text: 'Rumin Tech',
        onClickDataModelId: DataModel.RuminTech
      },
      { id: 'Nevatim', icon: MenuNevatimIconImg, text: 'Nevatim', onClickDataModelId: DataModel.PoultryWeights },
      {
        id: 'BPAC',
        icon: MenuBPACIconImg,
        text: 'BPAC',
        path: '/products/bpac',
        onClickDataModelId: DataModel.Bpac
      }
    ]
  },
  {
    id: 'settings',
    icon: MenuSettingIconImg,
    text: 'Settings',
    path: '/settings'
  },
  {
    id: 'logout',
    icon: MenuLogoutIconImg,
    text: 'Logout',
    path: '/logout'
  }
]
