import React, { FC, useEffect, useState } from 'react'
import { RootState, useReduxSelector } from '../../../redux'
import './Appbar.scss'
import AppBarUser from './AppBarUser/AppBarUser'
import AppBarLogo from './AppBarLogo/AppBarLogo'
import AppBarNotification from './AppBarNotification/AppBarNotification'
import useNotification from '../../../hooks/useNotification'
import PwNotificationDialog from '../../../dialogs/NotificationDialog/PoultryWeights/NotificationDialog'
import { GetuserName } from '../../../utils/helpers/user'

export const BeesAppbar: FC = () => {
  const { unSeenNotificationData } = useNotification()
  const [openNotificationDialog, setOpenNotificaitonDialog] = useState(false)
  const user: any = useReduxSelector((state: RootState) => state.user.user)

  const hanldeNotificationClick = () => {
    setOpenNotificaitonDialog(!openNotificationDialog)
  }

  return (
    <div className="app-bar">
      <div>
        <AppBarLogo />
      </div>
      <div>
        <div
          onClick={(e) => {
            hanldeNotificationClick()
          }}
        >
          <AppBarNotification unSeenNotificaitonCount={unSeenNotificationData} />
        </div>
        <AppBarUser userName={GetuserName(user)} />
        {openNotificationDialog && (
          <PwNotificationDialog open={openNotificationDialog} onSuccess={hanldeNotificationClick} onClose={hanldeNotificationClick} />
        )}
      </div>
    </div>
  )
}
