import emptyGasTank from './nofiticationTypeGasTankEmpty.svg';
import user from './nofiticationTypeUser.svg';
import notificationBell from './notificationBell.svg';
import notificationSearch from './notificationSearch.svg';
import notificationDarkBell from './notificationBellDark.svg';
import alert from './alert.svg';
import info from './info.svg';
import warning from './warning.svg';
import notificationBlueCircle from './notificationBlueCircle.svg';

export const nofiticationTypeImages = {
    nofiticationTypeGasTankEmpty: emptyGasTank,
    nofiticationTypeUser: user,
    notificationBellImage: notificationBell,
    notificationSearchImage: notificationSearch,
    notificationDarkBellImage: notificationDarkBell,
    alertImage: alert,
    infoImage: info,
    warningImage: warning,
    markeAsSeenCircle: notificationBlueCircle
}