import { Idevice } from 'device'

type IotEvent = {
  id: number
  sourceDeviceId: number
  FLOAT1: number
  FLOAT2: number
  FLOAT3: number
  FLOAT4: number
  weight: number
  captureDate: Date
  name: string
  customerId: number
  facilityGroupId: number
  facilityId: number
}

type sensorType = {
  icon: string
  unit: string
  field: string
}

type IotCustomerEvents = {
  facilityGroupName: string
  lastUpdate: Date
  hiveLevel: number
  lastKeepAlive: Date
  id: number
  name: string
  total24HoursWeight: Array<{ x: number; y: number }>
  value24hAgo: number
  weight: number
  temperature?: number
  co2?: number
  humidity?: number
  numberOfDevices?: number
  parentId?: number
}

type IotSiloData = {
  avgHeight: number
  captureDate: Date
  captureTime?: Date
  coneVolume: number
  cylinderVolume: number
  height: number
  height2: number
  maxCapacityVolume: number
  maxCapacityWeight: number
  percent: number
  totalVolume: number
  weight: number
  zoneId: number
  name?: string
  material?: string
}

export enum IotTrend {
  Declining,
  Trending
}

export enum CustomIotViewId {
  Dvira = 1,
  Other = null
}

export enum SourceDeviceType {
  Laser = 44
}

export enum SocketEndPoint {
  RaderData = 'radarData',
  LaserData = 'laserData',
  firmwareUpdate = 'firmwareUpdate'
}

export interface firmwareUpdatePacket {
  deviceId: number
  complete: boolean
  captureDate: string
  downloading: boolean
  percent: number
}

type Currency = {
  symbol: string
  name: string
  symbol_native: string
  decimal_digits: number
  rounding: number
  code: string
  name_plural: string
}
export type CurrencyMap = { [key: string]: Currency }

type IWaterIntakeParams = {
  facilityIds?: number[]
  monitorGroupIds?: number[]
  startDate?: number
  endDate?: number
}

type LaserData = {
  avgHeight: number
  totalWeight: number
  data: { calibratedHeight: number; laser: number; weight: number }[]
  zoneId?: number
  captureDate?: Date
}

type IotSiloDataRequest = {
  zoneId: number
  interval: number
  startDate: number
  endDate: number
  limit?: number
  sort?: 'desc' | 'asc'
}
