export const updateDeviceVersionTableOptions = {
  maxBodyHeight: '100%',
  minBodyHeight: 'calc(40vh - 230px)',
  pageSize: 10,
  search: true,
  toolbar: true,
  showTextRowsSelected: false,
  showFirstLastPageButtons: false,
  paging: true,
  showTitle: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
  pageSizeOptions: [5, 10, 20, 50],
  selection: true,
  rowStyle: {
    color: '#7B7B7B',
    padding: '0 1rem',
    whiteSpace: 'noWrap'
  },
  headerStyle: {
    color: '#7B7B7B',
    padding: '0 1rem',
    fontWeight: '600'
  },
  cellStyle: { padding: '0.25rem 0.5rem', height: 55 }
}

export const whatsNew = `What's new:`
