import React, { FC, useEffect, useState } from 'react'
import { RootState, useReduxSelector } from '../../../../redux'
import AppBarUser from './AppBarUser/AppBarUser'
import AppBarNotification from './AppBarNotification/AppBarNotification'
import {
  CustomeDateSecondaryMobileIcon,
  FarmMobileIcon,
  locationIcon,
  removeIcon,
  ShedMobileIcon,
  TimeMobileIcon
} from '../../../../assets/images/appBar/index'
import MobileSidebar from '../../../Sidebar/PoultryWeights/MobileSidebar/MobileSidebar'
import './Calendar.css'
import {
  MobileAppbarWrapper,
  LastWrapper,
  ApplocationWrapper,
  LocationIcon,
  DateWrapper,
  HamWrapper,
  TimeIcon,
  CustomerHeading,
  TimeHeading,
  FarmIcon,
  FarmHeading,
  AppFarmWrapper,
  AppShedWrapper,
  ShedIcon,
  ShedHeading,
  Wrapper,
  SidebarWrapper,
  CustomDateIcon,
  CustomDateWrapper,
  CustomDateHeading
} from './MobileAppbar.styled'
import MobileDateFilter, { DateButtons } from './MobileDateFilter/MobileDateFilter'
import { IHarvest } from 'harvest'
import Todaydate from './TodayDate/Todaydate'
import LocationModal from './Modal/LocationModal/LocationModal'
import FarmModal from './Modal/FarmModal/FarmModal'
import ShedModal from './Modal/ShedModal/ShedModal'
import CropModal from './Modal/CropModal/CropModal'
import Calendar from 'react-calendar'
import { getFormatStandardDate, getReadableDate, getWeekAgoDate, options } from '../../../../utils/helpers/date'
import CalendarDrawer from './Drawer/CalendarDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { setCropData, setLocationData } from '../../../../redux/LocationSlice'
import { CloseImg } from './MobileDateFilter/MobileDataFilter.styled'
import { ITreeNode } from 'locationFilter'
import { INotification } from 'notifications'
import { CloseIconImg } from '../../../../assets/images/UsersImages'
import PwNotificationDialog from '../../../../dialogs/NotificationDialog/PoultryWeights/NotificationDialog'
import { setTimeFilterEndDate, setTimeFilterStartDate } from '../../../../redux/TImeSlice'

enum HirarchyTypes {
  Customer,
  FaciltyGroup,
  Facility,
  Zone
}

enum TopBarButtons {
  customer = 'customer',
  facilityGroup = 'facilityGroup',
  facility = 'facility',
  drawer = 'drawer'
}

export const MobileAppbar: FC = () => {
  const { customers } = useSelector((state: RootState) => state.customers)
  const { facilitiesGroups } = useSelector((state: RootState) => state.facilitiesGroup)
  const { facilities } = useSelector((state: RootState) => state.facilities)
  const { facilityZones } = useSelector((state: RootState) => state.facilityZones)
  const [unSeenNotificaitonCount, setUnSeenNotifications] = useState(0)
  const [openNotificationDialog, setOpenNotificaitonDialog] = useState(false)
  const user: any = useReduxSelector((state: RootState) => state.user.user)
  const userName = user?.firstName ? user.firstName[0] + user.lastName[0] : ''
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
    facilityGroup: false,
    facility: false,
    customer: false,
    crop: false,
    drawer: false,
    dateDrawer: false
  })
  const [selectedDateButton, setSelectedDateButton] = useState(DateButtons.Live)
  const [dateState, setDateState] = useState<any>(new Date())
  const [startDate, setStartDate] = useState<Date>(getWeekAgoDate())
  const [endDate, setEndDate] = useState<Date | undefined>(new Date())
  const [object, setObject] = useState<any>({})
  const [selectedData, setSelectedData] = useState<any>([])
  const [mainData, setMainData] = useState<any>({})
  const [selectedId, setSelectedId] = useState<number>()
  const [harvestObject, setHarvestObject] = useState<IHarvest | undefined>()
  const dispatch = useDispatch()
  const changeDate = (e: any) => {
    setDateState(e)
  }
  useEffect(() => {
    setStartDate(dateState[0])
    setEndDate(dateState[1])
  }, [dateState])

  useEffect(() => {
    if (window.innerWidth < 600) {
      dispatch(setTimeFilterStartDate(startDate ?? getWeekAgoDate()))
      dispatch(setTimeFilterEndDate(endDate ?? new Date()))
    }
  }, [startDate, endDate, window.innerWidth])

  useEffect(() => {
    if (!isOpen.customer && !isOpen.crop && !isOpen.facilityGroup && !isOpen.facility && !isOpen.drawer && !isOpen.dateDrawer) {
      document.body.style.overflow = 'auto'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }, [isOpen])

  const handleChange = (inputObject: { lable: string; id: number; name: string }) => {
    setSelectedId(inputObject.id)
    if (inputObject.lable === 'customer') {
      setObject({
        ...object,
        [inputObject.lable + 'Id']: inputObject.id,
        [inputObject.lable + 'Name']: inputObject.name,
        facilityGroupId: '',
        facilityGroupName: '',
        facilityId: '',
        facilityName: ''
      })
    } else if (inputObject.lable === 'facilityGroup') {
      setObject({
        ...object,
        [inputObject.lable + 'Id']: inputObject.id,
        [inputObject.lable + 'Name']: inputObject.name,
        facilityId: '',
        facilityName: ''
      })
    } else {
      setObject({ ...object, [inputObject.lable + 'Id']: inputObject.id, [inputObject.lable + 'Name']: inputObject.name })
    }
    setIsOpen({ ...isOpen, [inputObject.lable]: false })
    switch (true) {
      case inputObject.lable === 'customer':
        setSelectedData(createCustomerTreeNodeObject())
        break
      case inputObject.lable === 'facilityGroup':
        setSelectedData(createFacilitiesGroupTreeNodeObject())
        break
      case inputObject.lable === 'facility':
        setSelectedData(createFacilityTreeNodeObject())
        break
    }
  }
  useEffect(() => {}, [selectedData])
  const openHandler = (type: string) => {
    setIsOpen({ ...isOpen, [type]: !isOpen[type] })
  }

  const handleDateButtonChange = (selectedDateButton: DateButtons) => {
    setSelectedDateButton(selectedDateButton)
    if (selectedDateButton === DateButtons.Crop) {
      setIsOpen({ ...isOpen, crop: true, drawer: false, dateDrawer: false })
    }
    if (selectedDateButton === DateButtons.Custom) {
      setIsOpen({ ...isOpen, dateDrawer: true, drawer: false })
    }
    if (selectedDateButton === DateButtons.Live || selectedDateButton === DateButtons.Today) {
      setIsOpen({ ...isOpen, drawer: false, dateDrawer: false })
    }
  }
  const hanldeNotificationClick = () => {
    setOpenNotificaitonDialog(!openNotificationDialog)
  }
  const harvestHandler = (harvest: IHarvest) => {
    setHarvestObject(harvest)
    setIsOpen({ ...isOpen, crop: false })
  }
  useEffect(() => {
    dispatch(
      setCropData([
        {
          cropId: harvestObject?.idx,
          harvestId: harvestObject?.id,
          startDate: harvestObject?.startDate,
          closeDate: harvestObject?.closeDate || null,
          facilityGroupId: harvestObject?.facilityGroupId
        }
      ])
    )
  }, [harvestObject])

  const getFacilityZonesIds = (facilityId: number) => {
    return facilityZones?.filter((zone) => zone.facilityId === facilityId).map(({ id }) => id)
  }

  const getFacilityGroupZones = (facilityGroupId: number) => {
    const zones: number[] = []
    const facilitiesGroup = facilitiesGroups?.find(({ id }) => facilityGroupId === id)
    facilities.forEach(({ id, facilityGroupId }) => {
      if (facilityGroupId === facilitiesGroup?.id) zones.push(...getFacilityZonesIds(id))
    })
    return zones
  }

  const getCustomerZones = (id?: number) => {
    if (!id) return []
    const zones: number[] = []
    const customerFacilitiesGroups = facilitiesGroups?.filter(({ customerId }) => customerId === id)
    customerFacilitiesGroups?.forEach(({ id }) => {
      zones.push(...getFacilityGroupZones(id))
    })
    return zones
  }

  const createCustomerTreeNodeObject = (): ITreeNode[] => {
    return customers?.map((customer, index) => {
      const customerParent = customers?.find(({ id }) => customer.creatorCustomerId === id)
      const cusomerParentParent = customers.find(({ id }) => customerParent?.creatorCustomerId === id)
      const facilitiesGroup = facilitiesGroups?.filter(({ customerId }) => customerId === customer.id)
      const zonesIds = getCustomerZones(customer?.id)
      const customerFacilities = facilities?.filter(({ customerId }) => customerId === customer.id)
      return {
        checked: !index ? true : false,
        id: customer.id as number,
        parentId: customer.creatorCustomerId,
        parentParentId: cusomerParentParent?.id,
        parentName: customerParent?.name ?? '',
        parentParentName: cusomerParentParent?.name ?? '',
        label: customer.name,
        value: customer.id as number,
        name: customer.name,
        facilitiesGroupIds: facilitiesGroup.map(({ id }) => id),
        facilitiesIds: customerFacilities.map(({ id }) => id),
        zonesIds: zonesIds,
        children: [],
        type: HirarchyTypes.Customer
      }
    })
  }

  const createFacilitiesGroupTreeNodeObject = (): ITreeNode[] => {
    return facilitiesGroups?.map((facilityGroup) => {
      const customer = customers.find(({ id }) => facilityGroup.customerId === id)
      const cusomerParent = customers.find(({ id }) => customer?.creatorCustomerId === id)
      const facilityGroupFacilities = facilities.filter(({ facilityGroupId }) => facilityGroupId === facilityGroup.id)
      return {
        id: facilityGroup.id as number,
        name: facilityGroup.name,
        label: facilityGroup.name,
        value: facilityGroup.id as number,
        parentId: facilityGroup.customerId,
        parentName: customer?.name ?? '',
        parentParentId: cusomerParent?.id,
        parentParentName: cusomerParent?.name ?? '',
        facilitiesIds: facilityGroupFacilities.map(({ id }) => id),
        facilitiesGroupIds: [facilityGroup.id],
        zonesIds: getFacilityGroupZones(facilityGroup.id),
        children: [],
        type: HirarchyTypes.FaciltyGroup
      }
    })
  }

  const createFacilityTreeNodeObject = (): ITreeNode[] => {
    return facilities.map((facility) => {
      const facilityGroup = facilitiesGroups.find(({ id }) => facility.facilityGroupId === id)
      const customer = customers.find(({ id }) => facility.customerId === id)

      return {
        id: facility.id,
        name: facility.name,
        parentId: facilityGroup?.id as number,
        parentParentId: facility.customerId,
        parentName: facilityGroup?.name ?? '',
        parentParentName: customer?.name ?? '',
        value: facility.id as number,
        label: facility.name,
        children: [],
        zonesIds: facility.zones.map(({ id }) => id),
        facilitiesIds: [facility.id],
        type: HirarchyTypes.Facility
      }
    })
  }

  useEffect(() => {
    {
      selectedData.map((data: any) => {
        if (data.id === selectedId) {
          setMainData(data)
        }
      })
    }
  }, [selectedData])

  useEffect(() => {
    dispatch(
      setLocationData([
        {
          checked: mainData.checked,
          children: mainData.children,
          facilitiesGroupIds: mainData.facilitiesGroupIds,
          facilitiesIds: mainData.facilitiesIds,
          id: mainData.id,
          label: mainData.label,
          name: mainData.name,
          parentId: mainData.parentId,
          parentName: mainData.parentName,
          parentParentId: mainData.parentParentId,
          parentParentName: mainData.parentParentName,
          type: mainData.type,
          value: mainData.value,
          zonesIds: mainData.zonesIds
        }
      ])
    )
  }, [mainData])

  return (
    <>
      <MobileAppbarWrapper>
        <HamWrapper>
          <SidebarWrapper>
            <MobileSidebar />
          </SidebarWrapper>
          <LastWrapper>
            <div
              onClick={(e) => {
                hanldeNotificationClick()
              }}
            >
              <AppBarNotification unSeenNotificaitonCount={unSeenNotificaitonCount} />
            </div>
            {openNotificationDialog && (
              <PwNotificationDialog open={openNotificationDialog} onSuccess={hanldeNotificationClick} onClose={hanldeNotificationClick} />
            )}
            <AppBarUser userName={userName} />
          </LastWrapper>
        </HamWrapper>
        <Wrapper>
          <ApplocationWrapper isActive={false} onClick={() => openHandler(TopBarButtons.customer)}>
            <LocationIcon src={locationIcon} />
            <CustomerHeading isActive={false}>{object.customerName ? object.customerName : customers[0]?.name}</CustomerHeading>
          </ApplocationWrapper>
          {object.customerName && (
            <AppFarmWrapper isActive={false}>
              <FarmIcon src={FarmMobileIcon} />
              <FarmHeading onClick={() => openHandler(TopBarButtons.facilityGroup)} isActive={false}>
                {object.facilityGroupName ? object.facilityGroupName : 'Farm'}
              </FarmHeading>
              <FarmIcon
                src={removeIcon}
                onClick={() => {
                  setObject({ ...object, facilityGroupName: null })
                }}
              />
            </AppFarmWrapper>
          )}
          {object.facilityGroupId && (
            <AppShedWrapper isActive={false}>
              <ShedIcon src={ShedMobileIcon} />
              <ShedHeading onClick={() => openHandler(TopBarButtons.facility)} isActive={false}>
                {object.facilityName ? object.facilityName : 'Shed'}
              </ShedHeading>
              <FarmIcon
                src={removeIcon}
                onClick={() => {
                  setObject({ ...object, facilityName: null })
                }}
              />
            </AppShedWrapper>
          )}
        </Wrapper>
        <DateWrapper onClick={() => openHandler(TopBarButtons.drawer)} open={isOpen.drawer}>
          <TimeIcon src={TimeMobileIcon} />
          <TimeHeading>
            <div>{(selectedDateButton === DateButtons.Today || DateButtons.Live === selectedDateButton) && <Todaydate />}</div>
            {selectedDateButton === DateButtons.Custom && (
              <>
                {startDate && endDate && (
                  <>
                    {startDate.toLocaleDateString('en-US', options)}-{endDate.toLocaleDateString('en-US', options)}
                  </>
                )}
              </>
            )}
            {selectedDateButton === DateButtons.Crop && (
              <>
                {harvestObject?.id && harvestObject?.startDate && harvestObject?.closeDate && (
                  <>
                    Crop {harvestObject?.id}-{getFormatStandardDate(harvestObject?.startDate)}-
                    {getFormatStandardDate(harvestObject?.closeDate)}
                  </>
                )}
              </>
            )}
          </TimeHeading>
        </DateWrapper>
        <LocationModal handleChange={handleChange} customerId={object.customerId} isOpen={isOpen} setIsOpen={setIsOpen} />
        <FarmModal
          handleChange={handleChange}
          farmId={object.facilityGroupId}
          customerName={object?.customerName}
          customerId={object?.customerId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <ShedModal
          handleChange={handleChange}
          shedId={object.facilityId}
          farmId={object.facilityGroupId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          farmName={object.facilityGroupName}
          customerName={object.customerName}
        />
        <CropModal harvestHandler={harvestHandler} harvestId={harvestObject?.id} isOpen={isOpen} setIsOpen={setIsOpen} />
        <MobileDateFilter
          isOpen={isOpen}
          onClick={() => setIsOpen({ ...isOpen, drawer: !isOpen.drawer })}
          selectedDateButton={selectedDateButton}
          handleDateButtonChange={handleDateButtonChange}
        />
      </MobileAppbarWrapper>
      <CalendarDrawer show={isOpen.dateDrawer}>
        <CustomDateWrapper>
          <CustomDateIcon src={CustomeDateSecondaryMobileIcon} />
          <CustomDateHeading>Custom dates</CustomDateHeading>
          <CloseImg src={CloseIconImg} onClick={() => setIsOpen({ ...isOpen, dateDrawer: !isOpen.dateDrawer })} />
        </CustomDateWrapper>
        <Calendar value={dateState} onChange={changeDate} selectRange={true} />
      </CalendarDrawer>
    </>
  )
}
