import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/he'
import 'dayjs/locale/ar'
import 'dayjs/locale/th'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: ['en', 'he', 'ar', 'th'],

    debug: true,
    interpolation: {
      escapeValue: false
    }
  })
  .then(() => {
    // Load translation files
    return i18n.addResourceBundle('en', 'translation', require('../../locales/en.json'))
  })
  .then(() => {
    return i18n.addResourceBundle('he', 'translation', require('../../locales/he.json'))
  })
  .then(() => {
    return i18n.addResourceBundle('ar', 'translation', require('../../locales/ar.json'))
  })
  .then(() => {
    return i18n.addResourceBundle('th', 'translation', require('../../locales/th.json'))
  })
  .then(() => {
    dayjs.locale(i18n.language)
  })

export default i18n
