export const ERROR_MESSAGE_TEXT = 'Something Went Wrong'

export enum statusCodes {
    InternalServerError = 500,
    BadRequest = 400,
    UnAuthorized = 401,
    Conflict = 409,
    Forbidden = 403,
    Ok = 200
}

export const snackTypes = {
    updateSuccess : "Updated Successfully",
    deleteSuccess : "Deleted Successfully",
    addSuccess : "Added Successfully",
    unAuthorized : "UnAuthorized",
    InternalServerError: "Internal Server Error"
}

export const requestTypes = {
    delete : "DELETE",
    post : "POST",
    put : "PUT",
    get : "GET",
}

export const snackbarVariant = {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info"
}