import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serverGetPermissions } from '../services/serverApi';
import { IPermission } from '../types/permission.d';

type RolesState = {
    permissions: Array<IPermission>; //user assigned permissions
    allPermissions: Array<IPermission>;
    loading: boolean;
    error: string | null;
};

const initialState: RolesState = {
    permissions: [],
    allPermissions: [],
    loading: false,
    error: null
};

/**Get User assigned permissions */
export const getPermissions = createAsyncThunk('getPermissions', async (s, thunkApi) => {
    try {
        const res = await serverGetPermissions();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
});



export const permissionSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {},
    extraReducers: (builder): any => {
        builder.addCase(getPermissions.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(getPermissions.fulfilled, (state, {payload}) => {
                state.error = null;
                state.loading = false;
                state.permissions = payload as IPermission[];
            }),
            builder.addCase(getPermissions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = (payload as ErrorEventInit).error;
            })

    },
});

export const permissionReducer = permissionSlice.reducer;
export const permissionActions = permissionSlice.actions;
