import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState: any = {
  time: { startDate: new Date(), endDate: new Date() },
  selectedButtonIot: {
    isLive: 0,
    Today: 1,
    Crop: 2,
    Custom: 3
  }
}

export const iotSlice = createSlice({
  name: 'time',
  initialState: initialState,
  reducers: {
    selectedIotTime: (state: any, action: any) => {
      state.time = action.payload
    },
    selectedDateButton: (state: any, action: any) => {
      state.selectedButtonIot = action.payload
    }
  }
})

export const iotSliceReducer = iotSlice.reducer
export const { selectedIotTime, selectedDateButton } = iotSlice.actions
