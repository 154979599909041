import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverGetMonitoredUnits, serverCreateMonitoredUnit } from '../services/serverApi'
import { IMonitoredUnits } from '../types/monitoredUnits'

type MonitoredUnitsState = {
  monitoredUnits: Array<IMonitoredUnits>
  loading: boolean
  error: string | null
}

const initialState: MonitoredUnitsState = {
  monitoredUnits: [],
  loading: false,
  error: null
}

/**Get FacilitiesGroup */
export const getMonitoredUnits = createAsyncThunk('getMonitoredUnits', async (s, thunkApi) => {
  try {
    const res = await serverGetMonitoredUnits()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add device  */
export const addMonitoredUnits = createAsyncThunk('addMonitoredUnits', async (monitoredUnit: IMonitoredUnits, thunkApi) => {
  try {
    const res = await serverCreateMonitoredUnit(monitoredUnit)
    thunkApi.dispatch(getMonitoredUnits())
  } catch (e) {
    console.error(e)
  }
})

export const monotoredUnitsSlice = createSlice({
  name: 'MonitoredUnits',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getMonitoredUnits.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getMonitoredUnits.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.monitoredUnits = payload as IMonitoredUnits[]
      }),
      builder.addCase(getMonitoredUnits.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      builder.addCase(addMonitoredUnits.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
      }),
      builder.addCase(addMonitoredUnits.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addMonitoredUnits.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const monitoredUnitsReducer = monotoredUnitsSlice.reducer
export const monitoredUnitsActions = monotoredUnitsSlice.actions
