import { bellIcon } from '../../../../assets/images/appBar'
import AppPolygon from '../../../AppPolygon/AppPolygon'
import './AppbarNotification.scss'

interface AppBarNotificationProps {
  unSeenNotificaitonCount: number
}
const AppBarNotification: React.FC<AppBarNotificationProps> = ({ unSeenNotificaitonCount }) => {
  return (
    <div className="app-bar-notification-container">
      <AppPolygon className="notification-polygon">
        <img src={bellIcon} alt="" />
      </AppPolygon>
      {unSeenNotificaitonCount > 0 && (
        <AppPolygon className="badge-notification">
          <span className="">{unSeenNotificaitonCount > 99 ? '99+' : unSeenNotificaitonCount}</span>
        </AppPolygon>
      )}
    </div>
  )
}

export default AppBarNotification
