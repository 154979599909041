import { DataModel } from '../../types/dataModel.d'
import InputDropdown from '../../components/AppInputDropDown/InputDropDown'
import InputField from '../../components/AppInputLable/AppInputLable'
import {
  facilityBpacDailog,
  facilityHydroDialog,
  facilityPoultryDailog,
  facilityRuminTechDialog,
  FieldTypes,
  FieldVariant,
  MaterialCellDialog
} from '../consts/facility'
import FarmDimension from '../../dialogs/FarmDialogs/AddShedDialog/FarmDimension/FarmDimension'
import { GetDataModelId } from './dataModel'
import { facilityGroupHydroDialog, facilityGroupPoultryDialog, facilityGroupRuminTechDialog } from '../consts/facilityGroup'
import { customSiloPoultryDialog, siloHeadPoultryDialog } from '../consts/SiloHead'
import { EventRuminTechDialog } from '../consts/events'
import { RootState, useReduxSelector } from '../../redux'
import { addDeviceVersionFields } from '../consts/devicesVersion'
import themes from '../../styles'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'

interface Props {
  metaData: any
  data: any[] | undefined
  onChangeHandler: React.ChangeEventHandler<any>
  valueObject: any
  multiline?: boolean
  rows?: number
  hideLabel?: boolean
  disabled?: boolean
  handleClear?: any
  defaultValue?: any
}

export const GetFieldComponent: React.FC<Props> = ({
  data,
  onChangeHandler,
  metaData,
  valueObject,
  defaultValue,
  hideLabel,
  multiline,
  rows,
  disabled,
  handleClear
}) => {
  switch (metaData.type) {
    case FieldTypes.Input:
      return (
        <InputField
          label={metaData.label}
          hideLabel={hideLabel}
          multiline={metaData?.multiline}
          rows={metaData?.rows || 1}
          inputName={metaData.field}
          value={valueObject ? valueObject[metaData?.field] : ''}
          placeholder={metaData.placeholder}
          onChangeHandler={onChangeHandler}
          type={metaData.variant ? metaData.variant : 'text'}
          disabled={metaData.isUpdateDisabled}
        />
      )
    case FieldTypes.DropDown:
      return (
        <InputDropdown
          defaultValue={defaultValue}
          label={metaData.label}
          inputName={metaData.field}
          data={data ?? []}
          value={valueObject[metaData?.field]}
          onChangeHandler={onChangeHandler}
          hideLabel={hideLabel}
          placeholder={metaData.placeholder}
          disabled={disabled}
          {...(handleClear ? { handleClear: handleClear } : {})}
        />
      )
    case FieldTypes.Custom:
      return <FarmDimension valueObject={valueObject} onChangeHandler={onChangeHandler} />
    default:
      return <></>
  }
}

export const getFacilityDialogDataFields = () => {
  const { t } = useTranslation()
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return facilityPoultryDailog
    case DataModel.hydroIntel:
      return facilityHydroDialog
    case DataModel.Bpac:
      return facilityBpacDailog
    case DataModel.RuminTech:
      return facilityRuminTechDialog
    default:
      return facilityPoultryDailog
  }
}

export const getFacilityGroupDialogDataFields = () => {
  const { t } = useTranslation()
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      const translatedPoultryFields = facilityGroupPoultryDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.facilityGroupPoultryDialog.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.facilityGroupPoultryDialog.${field.placeholder}`)
        }
      })
      return translatedPoultryFields
    case DataModel.Bpac:
      const translatedBpacFields = facilityGroupPoultryDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.facilityGroupPoultryDialog.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.facilityGroupPoultryDialog.${field.placeholder}`)
        }
      })
      return translatedBpacFields
    case DataModel.hydroIntel:
      const translatedHydroFields = facilityGroupHydroDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.facilityGroupHydroDialog.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.facilityGroupHydroDialog.${field.placeholder}`)
        }
      })
      return translatedHydroFields
    case DataModel.RuminTech:
      const translatedRuminTechFields = facilityGroupRuminTechDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.facilityGroupRuminTechDialog.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.facilityGroupRuminTechDialog.${field.placeholder}`)
        }
      })
      return translatedRuminTechFields
    default:
      const translatedDefaultFields = facilityGroupPoultryDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.facilityGroupPoultryDialog.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.facilityGroupPoultryDialog.${field.placeholder}`)
        }
      })
      return translatedDefaultFields
  }
}

export const getSiloHeadDialogDataFields = () => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return siloHeadPoultryDialog
    default:
      return facilityPoultryDailog
  }
}

export const getCustomSiloDialogDataFields = () => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return customSiloPoultryDialog
    default:
      return customSiloPoultryDialog
  }
}

export const getEventDialogDataFields = () => {
  const dataModelId = GetDataModelId()
  const { t } = useTranslation()
  switch (dataModelId) {
    case DataModel.RuminTech:
      const translatedRuminTechFields = EventRuminTechDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.schedule.${field.translationKey.label}`)
        }
      })
      return translatedRuminTechFields as any
    default:
      const translatedDefaultFields = EventRuminTechDialog.map((field) => {
        return {
          ...field,
          label: t(`myFarm.schedule.${field.translationKey.label}`)
        }
      })
      return translatedDefaultFields as any
  }
}

export const getMaterialCellDialogDataFields = () => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.RuminTech:
      return MaterialCellDialog
    default:
      return MaterialCellDialog
  }
}

export const getDeviceVersionDialogDataFields = () => {
  const dataModelId = GetDataModelId()
  const { t } = useTranslation()

  switch (dataModelId) {
    case DataModel.RuminTech:
      const translatedRuminTechFields = addDeviceVersionFields.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.addDeviceVersionFields.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.addDeviceVersionFields.${field.placeholder}`)
        }
      })
      return translatedRuminTechFields
    default:
      const translatedDefaultFields = addDeviceVersionFields.map((field) => {
        return {
          ...field,
          label: t(`myFarm.farms.AddFarmDialogFields.addDeviceVersionFields.${field.label}`),
          placeholder: t(`myFarm.farms.AddFarmDialogFields.addDeviceVersionFields.${field.placeholder}`)
        }
      })
      return translatedDefaultFields
  }
}

export const ValidationCheck = (data: any, valueObject: any) => {
  const { customers } = useReduxSelector((state: RootState) => state.customers)
  const validationData = data?.filter((item: any) => item?.isValidCheck === true)
  const filterValidationData = customers?.length > 1 ? validationData : validationData?.filter((item: any) => item?.field !== 'customerId')
  const isValidate = filterValidationData?.map((item: any) => {
    const value = valueObject ? valueObject[item.field] : null
    if (value) {
      return true
    } else {
      return false
    }
  })
  const isValidation = isValidate.every((element: boolean) => element === true)
  return isValidation
}
