import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { GetDataModelId } from '../../utils/helpers/dataModel'
import { AddEditButtonWrapper } from './AddEditButton.styled'

interface AddEditButtonProps {
  titleChanged?: any
  onClick?: any
  loading?: boolean
  type?: string
  isValid?: boolean
  cy?: string
  style?: any
}

const dataModelId = GetDataModelId()

const AddEditButton: React.FC<AddEditButtonProps> = ({ titleChanged, onClick, loading, type, isValid, cy, style }) => {
  return (
    <AddEditButtonWrapper isValid={isValid ?? true} isModelId={dataModelId} type="submit" onClick={onClick} data-cy={cy} style={style}>
      {loading ? <Spinner animation="border" /> : titleChanged}
    </AddEditButtonWrapper>
  )
}

export default AddEditButton
