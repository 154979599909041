import { useEffect, useMemo, useState } from 'react'
import { RootState, useReduxSelector } from '../../../redux'
var utc = require('dayjs/plugin/utc')
import dayjs, { Dayjs } from 'dayjs'
import {
  serverAddFacilityGroupEvent,
  serverGetFacilityGroupEventForTaskManager,
  serverUpdateFacilityGroupEventTM
} from '../../../services/serverApi'
import { getUserLocation } from '../../../utils/helpers/location'

const useAddTask = ({
  setIsOpen,
  getTaskList,
  editObject,
  resetForm
}: {
  setIsOpen: any
  getTaskList: any
  editObject: any
  resetForm: any
}) => {
  const [eventObject, setEventObject] = useState<any>({
    taskName: '',
    taskType: '',
    group: '',
    extension: '',
    farm: '',
    startDate: '',
    endDate: '',
    assignTo: '',
    description: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [taskTypeMenu, setTaskTypeMenu] = useState<any>([])
  const [locationMenuItems, setlocationMenuItems] = useState<any>()
  const facilityZones = useReduxSelector((state: RootState) => state.facilityZones.facilityZones)
  const facilities = useReduxSelector((state: RootState) => state.facilities.facilities)
  const monitoredGrp = useReduxSelector((state: RootState) => state.monitoredGroup.monitoredGroup)
  const [userLocation, setUserLocation] = useState<{
    lat: number | null
    long: number | null
  }>({ lat: null, long: null })

  useEffect(() => {
    if (editObject?.id) {
      setEventObject({
        taskName: editObject.name,
        taskType: editObject.type,
        group: editObject.monitoredGroupId,
        extension: editObject.facilityId,
        farm: editObject.facilityGroupId,
        startDate: dayjs(editObject.schedule),
        endDate: dayjs(editObject.endTime),
        assignTo: editObject.assignTo,
        description: editObject.comments
      })
    }
    getUserLocation()
      .then((res: any) => {
        setUserLocation(res)
      })
      .catch((err: any) => {
        console.log('error in the dialog:', err)
      })
    getTaskType()
  }, [editObject])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'farm') {
      const extension = facilities.filter((facility: any) => facility.facilityGroupId === value)
      setlocationMenuItems({ ...locationMenuItems, extension: extension })
      setEventObject((prevState: any) => ({
        ...prevState,
        [name]: value,
        extension: '',
        group: ''
      }))
      return
    } else if (name === 'extension') {
      const group = monitoredGrp.filter((zone: any) => zone.facilityId === value)
      setlocationMenuItems({ ...locationMenuItems, group: group })
      setEventObject((prevState: any) => ({
        ...prevState,
        [name]: value,
        group: ''
      }))
    }
    setEventObject((prevState: any) => ({
      ...prevState,
      [name]: value
    }))
  }

  const submitHandler = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const zoneId = facilityZones.find((zone: any) => zone.facilityId === eventObject.extension)?.id

    const UTCConvert = (date: Date) => {
      const utcDate = new Date(date).toUTCString()
      return new Date(new Date(utcDate).toUTCString())
    }

    const data = {
      ...editObject,
      ...(editObject.id && { id: editObject.id }),
      name: eventObject.taskName,
      type: eventObject.taskType,
      schedule: UTCConvert(eventObject.startDate),
      endTime: UTCConvert(eventObject.endDate),
      comments: eventObject.description,
      assignTo: eventObject.assignTo,
      facilityGroupId: eventObject.farm,
      facilityId: eventObject.extension,
      monitoredGroupId: eventObject.group,
      zoneId: zoneId,
      lat: editObject.lat || userLocation.lat || undefined,
      long: editObject.long || userLocation.long || undefined
    } as any

    if (editObject?.id) {
      data.id = editObject.id
      try {
        await serverUpdateFacilityGroupEventTM(data.id, data)
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await serverAddFacilityGroupEvent(data)
      } catch (error) {
        console.log(error)
      }
    }
    await getTaskList()
    resetForm()
    setEventObject({
      taskName: '',
      taskType: '',
      group: '',
      extension: '',
      farm: '',
      startDate: '',
      endDate: '',
      assignTo: '',
      description: ''
    })
    setIsLoading(false)
  }

  const users: any = useReduxSelector((state: RootState) =>
    state.user.users.map((user) => ({ name: `${user.firstName} ${user.lastName}`, id: user.id }))
  )
  const monitoredGroup: any = useReduxSelector((state: RootState) => state.monitoredGroup.monitoredGroup)
  const facilityId: any = useReduxSelector((state: RootState) => state.facilities.facilities)
  const facilityGroup: any = useReduxSelector((state: RootState) => state.facilitiesGroup.facilitiesGroups)
  const getTaskType = async () => {
    const res = await serverGetFacilityGroupEventForTaskManager()
    setTaskTypeMenu(res)
  }

  const handleClose = () => {
    setEventObject({
      taskName: '',
      taskType: '',
      group: '',
      extension: '',
      farm: '',
      startDate: '',
      endDate: '',
      assignTo: '',
      description: ''
    })
    resetForm()
    setIsOpen(false)
  }

  const menuItems = (field: any) => {
    if (field === 'assignTo') return users
    if (field === 'farm') return facilityGroup
    if (field === 'extension') return locationMenuItems?.extension
    if (field === 'group') return locationMenuItems?.group
    if (field === 'taskType') return taskTypeMenu
  }

  return {
    eventObject,
    menuItems,
    handleInputChange,
    submitHandler,
    setEventObject,
    handleClose,
    isLoading
  }
}

export default useAddTask
