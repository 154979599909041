import { DataModel } from '../../types/dataModel.d'
import styled from 'styled-components'
import { BeesBackgroundImg } from '../../assets/images/global/index'
import { useLocation } from 'react-router-dom'

export const MainContainer = styled.div<{ isLoggedIn: boolean; dataModelId: number | null; location: any }>`
  overflow: hidden;
  height: 100vh;
  direction: ltr;

  display: ${({ isLoggedIn }) => (isLoggedIn ? 'grid' : 'block')};
  background: ${({ isLoggedIn, dataModelId, location }) =>
    dataModelId === DataModel.Bees
      ? location.pathname === '/MyFarm/device-version'
        ? '#E8E8E3'
        : 'url(' + BeesBackgroundImg + ')'
      : dataModelId === DataModel.RuminTech
      ? '#EDF5F6'
      : !isLoggedIn
      ? 'white'
      : '#DFE4EA'};
  grid-template-areas: ${({ isLoggedIn, dataModelId }) =>
    isLoggedIn && dataModelId !== DataModel.RuminTech && dataModelId !== DataModel.Gas
      ? `'appbar appbar appbar'
    'sideMenu main main'`
      : (isLoggedIn && dataModelId === DataModel.RuminTech) || (isLoggedIn && dataModelId === DataModel.Gas)
      ? `'sidebar appbar appbar'
      'sidebar main main'`
      : 'main'};
  grid-template-rows: ${({ isLoggedIn, dataModelId }) =>
    (isLoggedIn && dataModelId === DataModel.RuminTech) || (isLoggedIn && dataModelId === DataModel.Gas)
      ? '77px 1fr'
      : isLoggedIn && dataModelId === DataModel.Radar
      ? '167px 1fr'
      : isLoggedIn && dataModelId !== DataModel.Bees
      ? '80px 1fr'
      : dataModelId === DataModel.Bees
      ? '60px calc(100% - 60px)'
      : '100vh'};
  grid-template-columns: ${({ isLoggedIn, dataModelId }) =>
    (isLoggedIn && dataModelId === DataModel.RuminTech) || (isLoggedIn && dataModelId === DataModel.Gas)
      ? 'auto 1fr'
      : isLoggedIn && dataModelId !== DataModel.Bees
      ? '226px 1fr'
      : dataModelId === DataModel.Bees
      ? '240px 1fr 1fr'
      : '100vw'};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-template-areas: ${({ dataModelId }) =>
      dataModelId === DataModel.Bees
        ? `'appbar'
      'main'
      'bottomTabs'`
        : `'appbar'
        'main'`};
    grid-template-rows: ${({ dataModelId }) =>
      dataModelId === DataModel.Bees ? '70px calc(100vh - 160px) 100px' : '190px calc(100vh - 160px) 100px'};
    grid-template-columns: 100%;
  }
`
export const BottomTabsContainer = styled.div`
  grid-area: bottomTabs;
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: unset;
  }
`
export const MainAreaContainer = styled.div<{ dataModelId: number | null }>`
  grid-area: main;
  height: calc(100% - 10px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: ${({ dataModelId }) => (dataModelId !== DataModel.RuminTech ? '10px' : '0px')};

  overflow-y: hidden; /**Do not change it never ever!!!@#@#@#@#@!#!@#!@#*/

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    border: none;
    margin: 0;
  }
`
export const AppbarContainer = styled.div<{ isLoggedIn: boolean }>`
  grid-area: ${({ isLoggedIn }) => (isLoggedIn ? 'appbar' : 'none')};
  display: ${({ isLoggedIn }) => (!isLoggedIn ? 'none' : 'unset')};
`
export const SkeletonCss = {
  Stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  NavIcon: {
    width: '30px',
    height: '30px'
  },
  RightArrowIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '10px'
  },
  Title: {
    width: '120px',
    height: '25px',
    borderRadius: '7px',
    marginLeft: '10px'
  },
  SubTitle: {
    width: '120px',
    height: '25px',
    borderRadius: '7px',
    marginLeft: '10px'
  }
}
