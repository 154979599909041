import { IPermission } from 'permission'
import { INavbar, ISidebarMenu } from 'sidebarMenu'
import {
  IotIcon,
  FarmIcon,
  CustomerIcon,
  NutritionIcon,
  AnalysisIcon,
  AiIcon,
  ReportIcon,
  DevicesIcon
} from '../../../../assets/images/MobileSideMenu/index'
import {
  aiSelectIcon,
  closeArrowIcon,
  naviAiIcon,
  naviTaskManeger,
  naviTaskManegerSelect,
  openArrowIcon
} from '../../../../assets/images/sideMenu'
import { checkPermissions } from '../../../../utils/helpers/permissionHelper'
import { aiIcon } from '../../../../assets/images/sideMenu/index'
import { GetConfig } from '../../../../config/config'
import { InventoryIcon } from '../../../../assets/images/inventory'
const config = GetConfig()?.global

export const SidebarData = [
  {
    title: 'IoT Sensors',
    path: '/Iot',
    image: IotIcon,
    subNav: [
      {
        title: 'General',
        path: '/Iot/General'
      },
      {
        title: 'Heatmap',
        path: '/Iot/Heatmap'
      }
    ]
  },
  {
    title: 'My Farm',
    path: '/MyFarm',
    image: FarmIcon,

    subNav: [
      {
        title: 'Farms',
        path: '/MyFarm/Farms'
      },
      {
        title: 'Sheds',
        path: '/MyFarm/Sheds'
      },
      {
        title: 'Silo Head',
        path: '/MyFarm/Silos'
      },
      {
        title: 'Material',
        path: '/MyFarm/Material'
      },
      {
        title: 'Crop',
        path: `${config?.harvest?.route}`
      },
      {
        title: 'Manual Events',
        path: '/MyFarm/ManualEvents'
      },
      {
        title: 'Controller',
        path: '/MyFarm/Controller'
      },
      { title: 'Brand', path: '/MyFarm/Brand' }
    ]
  },
  {
    title: 'Devices',
    path: '/devices',
    image: DevicesIcon,
    subNav: [
      {
        title: 'Devices',
        path: '/Devices/Summary'
      },
      {
        title: 'Devices version',
        path: '/Devices/device-version'
      }
    ]
  },
  {
    title: 'Nutrition',
    path: '/Nutrition',
    image: InventoryIcon,
    subNav: [
      {
        title: 'Recipe',
        path: '/Nutrition/Recipe'
      },
      {
        title: 'Ingredient',
        path: '/Nutrition/Ingredient'
      },
      {
        title: 'Distribution',
        path: '/Nutrition/Distribution'
      },
      {
        title: 'Cost',
        path: '/Nutrition/cost'
      }
    ]
  },
  {
    title: 'Customer Mgmt',
    path: '/customerMgmt',
    image: CustomerIcon,

    subNav: [
      {
        title: 'Users',
        path: '/customerMgmt/users'
      },
      {
        title: 'Customer',
        path: '/customerMgmt/customer'
      }
    ]
  },
  {
    title: 'Analysis',
    path: '/Analysis',
    image: AnalysisIcon,

    subNav: [
      {
        title: 'Efficiency',
        path: '/analysis/Efficiency'
      },
      {
        title: 'Sensors',
        path: '/Analysis/Sensors'
      },
      {
        title: 'Comparison',
        path: '/Analysis/Comparison'
      }
    ]
  },

  {
    title: 'Reports',
    path: '/managerReports',
    image: ReportIcon,
    subNav: [
      {
        title: 'Crop Sequences',
        path: '/managerReports/CropSequences'
      }
    ]
  },
  {
    title: 'Task Manager',
    path: '/task-manager',
    navicon: naviTaskManeger,
    image: naviTaskManeger,
    selectImage: naviTaskManegerSelect,

    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,

    subNav: [
      {
        title: 'Dashboard',
        path: '/task-manager/dashboard'
      },
      {
        title: 'Task',
        path: '/task-manager/task'
      },
      {
        title: 'Event',
        path: '/task-manager/event'
      }
    ]
  },
  {
    title: 'Ai',
    path: '/ai',
    navicon: naviAiIcon,
    image: aiIcon,
    selectImage: aiSelectIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,
    subNav: [
      {
        title: 'Ai Charts',
        path: '/ai/charts'
      }
    ]
  },
  {
    title: 'API',
    path: '/api',
    navicon: naviAiIcon,
    image: aiIcon,
    selectImage: aiSelectIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,
    subNav: [
      {
        title: 'Key',
        path: '/api/Key'
      }
    ]
  }
]

export const getSidebarData = (routesdata: ISidebarMenu[], permissions: IPermission[]) => {
  if (permissions.length === 0) {
    return routesdata
  }
  routesdata.forEach((route: ISidebarMenu, index: number) => {
    route.subNav = route.subNav.filter((sb: INavbar) => {
      switch (sb.path) {
        case '/Iot/General':
          return checkPermissions('view-iot', permissions)
          break
        case '/Iot/Heatmap':
          return checkPermissions('view-heatmap', permissions)
          break
        case '/MyFarm/Farms':
          return checkPermissions('view-farm', permissions)
          break
        case '/MyFarm/Sheds':
          return checkPermissions('view-shed', permissions)
          break
        case '/MyFarm/Silos':
          return checkPermissions('view-silo', permissions)
          break
        case '/MySite/Sites':
          return checkPermissions('view-site', permissions)
          break
        case '/MySite/Pool':
          return checkPermissions('view-pool', permissions)
          break
        case '/task-manager/dashboard':
          return true
        case '/task-manager/task':
          return true
        case '/task-manager/event':
          return true
        case '/MyFarm/Material':
          return checkPermissions('view-material', permissions)
          break
        case '/Devices/Summary':
          return checkPermissions('view-device', permissions)
          break
        case '/Devices/device-version':
          return true
          break
        case '/MySite/Summary':
          return checkPermissions('view-device', permissions)
          break
        case '/MySite/Harvest':
          return checkPermissions('view-crop', permissions)
          break
        case '/MySite/Harvest':
          return checkPermissions('view-crop', permissions)
          break
        case `${config?.harvest?.route}`:
          return checkPermissions('view-crop', permissions)
          break
        case '/MyFarm/ManualEvents':
          return checkPermissions('view-event', permissions)
          break
        case '/MyFarm/Alert':
          return checkPermissions('view-alert', permissions)
          break

        case '/MySite/Alert':
          return checkPermissions('view-alert', permissions)
          break

        case '/MyFarm/Brand':
          return true
          break

        case '/customerMgmt/users':
          return checkPermissions('view-user', permissions)
          break
        case '/customerMgmt/customer':
          return checkPermissions('view-customer', permissions)
          break
        case '/customerMgmt/roles':
          return checkPermissions('view-role', permissions)
          break
        case '/Analysis/Efficiency':
          return checkPermissions('view-efficiency', permissions)
          break
        case '/Analysis/Sensors':
          return checkPermissions('view-sensor', permissions)
          break
        case '/Analysis/Comparison':
          return checkPermissions('view-sensor', permissions)
          break
        case '/managerReports/CropSequences':
          return checkPermissions('view-report', permissions)
          break
        case '/MyFarm/Controller':
          return true
          break
        case '/Nutrition/Recipe':
          return true
          break
        case '/Nutrition/Ingredient':
          return true
          break
        case '/ai/charts':
          return true
          break
        case '/api':
          return true
          break
        case '/api/key':
          return true
        case '/Nutrition/Distribution':
          return true
          break
        case '/Nutrition/cost':
          return true
          break
      }
    })
    if (route.subNav.length === 0) {
      SidebarData.splice(index, 1)
    }
  })
  return routesdata
}
