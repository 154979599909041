import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 80vw;
`

export const CircularProgressWithLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
`

export const CircularProgressLable = styled.h6`
  /* style={{ zIndex: 1, fontSize: '1rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }} */
  z-index: 1;
  font-size: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`
