import { InventoryIcon, selectInventoryIcon } from '../../../assets/images/inventory'
import { analysisIcon, analysisSelectedIcon, crmIcon, crmSelectedIcon, farmIcon, farmSelectedIcon, inventoryIcon, inventorySelectedIcon, iotIcon, iotSelectedIcon, reportIcon, reportSelectedIcon } from '../../../assets/images/sidebar/RuminTech'
import {
  // analysisIcon,
  analysisSelectIcon,
  closeArrowIcon,
  customerManagmentIcon,
  customerManagmentSelectIcon,
  devicesIcon,
  devicesSelectIcon,
  // iotIcon,
  iotSelectIcon,
  managerReportIcon,
  managerReportSelectIcon,
  myFarmIcon,
  myFarmSelectIcon,
  navDeviceIcon,
  naviAnalysisIcon,
  naviCustomerManagementIcon,
  naviIotIcon,
  naviManagerReportIcon,
  naviMyFarmIcon,
  openArrowIcon
} from '../../../assets/images/sideMenu/index'

export const SidebarData = [
  {
    title: 'IoT Sensors',
    path: '/Iot',
    image: iotIcon,
    navicon: iotIcon,
    selectImage: iotSelectedIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,
    subNav: [
      {
        title: 'General',
        path: '/Iot/General'
      }
      // {
      //   title: 'Fish',
      //   path: '/Iot/Fish'
      // },
      // {
      //   title: 'Heatmap',
      //   path: '/Iot/Heatmap'
      // }
    ]
  },
  {
    title: 'My Site',
    path: '/MySite',
    image: farmIcon,
    navicon: farmIcon,
    selectImage: farmSelectedIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,

    subNav: [
      {
        title: 'Sites',
        path: '/MySite/Sites'
      },
      {
        title: 'Pool',
        path: '/MySite/Pool'
      },
      // {
      //   title: 'Material',
      //   path: '/MyFarm/Material'
      // },

      // {
      //   title: 'Devices',
      //   path: '/MySite/Summary'
      // },
      {
        title: 'Harvest',
        path: '/MySite/Harvest'
      },
      // {
      //   title: 'Manual Events',
      //   path: '/MyFarm/ManualEvents'
      // },
      {
        title: 'Alert',
        path: '/MySite/Alert'
      }
    ]
  },
  {
    title: 'Devices',
    path: '/Devices',
    image: devicesIcon,
    navicon: navDeviceIcon,
    selectImage: devicesSelectIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,
    subNav: [
      {
        title: 'Devices',
        path: '/Devices/Summary'
      },
      {
        title: 'Devices version',
        path: '/Devices/device-version'
      }
    ]
  },
  {
    title: 'Inventory',
    path: '/Inventory',
    image: inventoryIcon,
    navicon: inventoryIcon,
    selectImage: inventorySelectedIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,
    subNav: [
      {
        title: 'Recipe',
        path: '/Inventory/Recipe'
      },
      {
        title: 'Ingredient',
        path: '/Inventory/Ingredient'
      }
    ]
  },
  {
    title: 'Customer Mgmt',
    path: '/customerMgmt',
    navicon: crmIcon,
    image: crmIcon,
    selectImage: crmSelectedIcon,

    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,

    subNav: [
      {
        title: 'Users',
        path: '/customerMgmt/users'
      },
      {
        title: 'Customer',
        path: '/customerMgmt/customer'
      },
      {
        title: 'Roles',
        path: '/customerMgmt/roles'
      }
    ]
  },
  // {
  //   title: 'Nutritions',
  //   path: '/nutritions',
  //   navicon: naviNutritionsIcon,
  //   image: nutritionsIcon,

  //   selectImage: nutritionsSelectIcon,

  //   iconClosed: closeArrowIcon,
  //   iconOpened: openArrowIcon,

  //   subNav: []
  // },
  {
    title: 'Analysis',
    path: '/Analysis',
    navicon: analysisIcon,
    image: analysisIcon,
    selectImage: analysisSelectedIcon,

    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,

    subNav: [
      {
        title: 'Efficiency',
        path: '/Analysis/Efficiency'
      },
      {
        title: 'Sensors',
        path: '/Analysis/Sensors'
      },
      {
        title: 'Comparison',
        path: '/Analysis/Comparison'
      }
    ]
  },

  {
    title: 'Reports',
    path: '/managerReports',
    navicon: reportIcon,
    image: reportIcon,
    selectImage: reportSelectedIcon,
    iconClosed: closeArrowIcon,
    iconOpened: openArrowIcon,
    subNav: [
      {
        title: 'Crop Sequences',
        path: '/managerReports/CropSequences'
      }
    ]
  }
]
