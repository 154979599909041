import { DeviceTypes } from '../../types/device.d'
import { ClimateSensorIcon, WaterIcon } from '../../assets/images/crop'
import { RadarIcon, TabletIcon, WeightIcon } from '../../assets/images/summary'
import { gatewayIcon } from '../../assets/images/gateway'

export const PoultryDevicesConfig = {
  WeightSensor: {
    name: 'Weight Sensor',
    Icon: WeightIcon,
    deviceType: DeviceTypes.WeightSensor
  },
  RadarSensor: {
    name: 'Radar Sensor',
    Icon: RadarIcon,
    deviceType: DeviceTypes.RadarSensor
  },
  WaterMeter: {
    name: 'Water Meter',
    Icon: WaterIcon,
    deviceType: DeviceTypes.WaterMeter
  },
  Topco: {
    name: 'Topco',
    Icon: RadarIcon,
    deviceType: DeviceTypes.Topco
  },
  Laser: {
    name: 'Laser',
    Icon: RadarIcon,
    deviceType: DeviceTypes.Laser
  },
  ClimateStick: {
    name: 'Climate Sensors',
    Icon: ClimateSensorIcon,
    deviceType: DeviceTypes.ClimateStick
  }
}

export const gateWaysConfig = {
  Gateway_Type_A: {
    name: 'Gateway Type A',
    Icon: gatewayIcon,
    deviceType: DeviceTypes.Gateway
  }
  // Gateway_Type_B: {
  //   name: 'Gateway Type B',
  //   Icon: gatewayIcon,
  //   deviceType: DeviceTypes.Gateway
  // }
}
