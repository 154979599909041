import { Checkbox, Input, InputLabel } from '@mui/material'
import themes from '../../styles'
import { InputWrapper, Lable } from './AppInputLable.styled'
import { FieldVariant } from '../../utils/consts/facility'
import { any } from 'prop-types'
import { CheckBox } from '@material-ui/icons'

interface AppInputLableProps {
  label: string
  value?: any
  inputName?: string
  required?: boolean
  onChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  disabled?: boolean
  type?: any
  sx?: any
  hideLabel?: boolean
  multiline?: boolean
  rows?: number
  inputProps?: any
  labelStyle?: any
}

const InputField: React.FC<React.PropsWithChildren<AppInputLableProps>> = (props) => {
  const { label, value, inputName, required, onChangeHandler, placeholder, disabled, type, sx, multiline, rows, inputProps, hideLabel } =
    props

  if (type === FieldVariant.Checkbox) {
    console.log('selected', value)
    return (
      <InputWrapper row>
        <Checkbox
          name={inputName}
          inputProps={inputProps}
          value={value}
          checked={value}
          disabled={disabled}
          onChange={onChangeHandler}
          required={required}
          placeholder={placeholder}
          sx={sx}
        />
        <InputLabel
          sx={{
            color: themes().colors.primary,
            fontSize: '14px',
            fontWeight: '500'
          }}
        >
          {label}
        </InputLabel>
      </InputWrapper>
    )
  }

  return (
    <InputWrapper hideLabel={hideLabel}>
      {!hideLabel && (
        <InputLabel
          sx={{
            color: themes().colors.primary,
            fontSize: '14px',
            fontWeight: '500'
          }}
        >
          {label}
        </InputLabel>
      )}
      <Input
        name={inputName}
        multiline={multiline || false}
        inputProps={inputProps}
        rows={rows || 1}
        value={value}
        disabled={disabled}
        onChange={onChangeHandler}
        required={required}
        placeholder={placeholder}
        type={type}
        sx={sx}
      />
    </InputWrapper>
  )
}

export default InputField
