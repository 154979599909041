import { DataModel } from '../../types/dataModel.d'

export const POULTRYWEIGHTS = 1
export const BEES = 8

export const DATA_MODEL_TITLES = {
  POULTRY: 'Poultry Weights',
  BEES: 'Bees',
  HYDRO_INTEL: 'HydroIntel',
  RUMIN_TECH: 'RuminTech',
  GAS: 'Gas',
  Radar: 'RadarInt'
}

export const getDataModelFaviconTitle = (dataModelId: number) => {
  switch (dataModelId) {
    case DataModel.Bees:
      return DATA_MODEL_TITLES.BEES
    case DataModel.PoultryWeights:
      return DATA_MODEL_TITLES.POULTRY
    case DataModel.hydroIntel:
      return DATA_MODEL_TITLES.HYDRO_INTEL
    case DataModel.RuminTech:
      return DATA_MODEL_TITLES.RUMIN_TECH
    case DataModel.Gas:
      return DATA_MODEL_TITLES.GAS
    case DataModel.Radar:
      return DATA_MODEL_TITLES.Radar
    default:
      return 'ZeusAI'
  }
}
