import {
  aiIcon,
  aiSelectIcon,
  // analysisIcon,
  analysisSelectIcon,
  apiIcon,
  apiSelectIcon,
  closeArrowIcon,
  customerManagmentIcon,
  customerManagmentSelectIcon,
  devicesIcon,
  devicesSelectIcon,
  // iotIcon,
  iotSelectIcon,
  managerReportIcon,
  managerReportSelectIcon,
  myFarmIcon,
  myFarmSelectIcon,
  naviAiIcon,
  naviAnalysisIcon,
  naviApiIcon,
  naviCustomerManagementIcon,
  naviIotIcon,
  naviManagerReportIcon,
  naviMyFarmIcon,
  naviNutritionsIcon,
  nutritionsIcon,
  nutritionsSelectIcon,
  openArrowIcon
} from '../../../assets/images/sideMenu/index'
import { RootState, useReduxSelector } from '../../../redux'
import { IPermission } from 'permission'
import { INavbar, ISidebarMenu } from 'sidebarMenu'
import { checkPermissions } from '../../../utils/helpers/permissionHelper'
import { InventoryIcon, selectInventoryIcon } from '../../../assets/images/inventory'
import { analysisIcon, analysisSelectedIcon, crmIcon, crmSelectedIcon, farmIcon, farmSelectedIcon, iotIcon, iotSelectedIcon } from '../../../assets/images/sidebar/RuminTech'

export const RadarSidebarData = () => {
  return [
    {
      title: 'IoT Sensors',
      path: '/Iot',
      image: iotIcon,
      navicon: iotIcon,
      selectImage: iotSelectedIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'General',
          path: '/Iot/General'
        }
      ]
    },
    {
      title: 'My Farm',
      path: '/MyFarm',
      image: farmIcon,
      navicon: farmIcon,
      selectImage: farmSelectedIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Farms',
          path: '/MyFarm/Farms'
        },
        {
          title: 'Sheds',
          path: '/MyFarm/Sheds'
        },
        {
          title: 'Silo Head',
          path: '/MyFarm/Silos'
        },
        {
          title: 'Material',
          path: '/MyFarm/Material'
        },
        {
          title: 'Alert',
          path: '/MyFarm/Alert'
        },
        {
          title: 'Material Cell',
          path: '/MyFarm/MaterialCell'
        }
      ]
    },
    {
      title: 'Devices',
      path: '/Devices',
      image: devicesIcon,
      navicon: devicesIcon,
      selectImage: devicesSelectIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Summary',
          path: '/Devices/Summary'
        },
        {
          title: 'Devices versions',
          path: '/Devices/device-version'
        }
      ]
    },

    {
      title: 'Customer Mgmt',
      path: '/customerMgmt',
      navicon: crmIcon,
      image: crmIcon,
      selectImage: crmSelectedIcon,

      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,

      subNav: [
        {
          title: 'Users',
          path: '/customerMgmt/users'
        },
        {
          title: 'Customer',
          path: '/customerMgmt/customer'
        },
        {
          title: 'Roles',
          path: '/customerMgmt/roles'
        }
      ]
    },
    {
      title: 'Analysis',
      path: '/Analysis',
      navicon: analysisIcon,
      image: analysisIcon,
      selectImage: analysisSelectedIcon,

      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,

      subNav: [
        {
          title: 'Graph',
          path: '/Analysis/Efficiency'
        }
      ]
    },
    {
      title: 'API',
      path: '/api',
      navicon: naviApiIcon,
      image: apiIcon,
      selectImage: apiSelectIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Key',
          path: '/api/key'
        }
      ]
    }
  ]
}

export const getSidebarData = (routesdata: ISidebarMenu[], permissions: IPermission[]) => {
  if (permissions.length === 0) {
    return routesdata
  }
  ;[...routesdata].forEach((route: ISidebarMenu, index: number) => {
    route.subNav = route.subNav.filter((sb: INavbar) => {
      switch (sb.path) {
        case '/ai/charts':
          return true
        case '/Iot/General':
          return checkPermissions('view-iot', permissions)
          break
        case '/Iot/Heatmap':
          return checkPermissions('view-heatmap', permissions)
          break
        case '/MyFarm/Farms':
          return checkPermissions('view-farm', permissions)
          break
        case '/MyFarm/Sheds':
          return checkPermissions('view-shed', permissions)
          break
        case '/MyFarm/Silos':
          return checkPermissions('view-silo', permissions)
          break
        case '/MySite/Sites':
          return checkPermissions('view-site', permissions)
          break
        case '/MySite/Pool':
          return checkPermissions('view-pool', permissions)
          break
        case '/MyFarm/Material':
          return checkPermissions('view-material', permissions)
          break
        case '/Devices/Summary':
          return checkPermissions('view-device', permissions)
          break
        case '/MyFarm/device-version':
          return true
          break
        case '/MySite/Summary':
          return checkPermissions('view-device', permissions)
          break
        case '/MySite/Harvest':
          return checkPermissions('view-crop', permissions)
          break
        case '/MySite/Harvest':
          return checkPermissions('view-crop', permissions)
          break

        case '/MyFarm/Crop':
          return checkPermissions('view-crop', permissions)
          break
        case '/MyFarm/ManualEvents':
          return checkPermissions('view-event', permissions)
          break
        case '/MyFarm/Alert':
          return checkPermissions('view-alert', permissions)
          break

        case '/MySite/Alert':
          return checkPermissions('view-alert', permissions)
          break
        case '/MyFarm/accessKey':
          return checkPermissions('view-material', permissions)
          break
        case '/MyFarm/Controller':
          return true
          break

        case '/customerMgmt/users':
          return checkPermissions('view-user', permissions)
          break
        case '/customerMgmt/customer':
          return checkPermissions('view-customer', permissions)
          break
        case '/customerMgmt/roles':
          return checkPermissions('view-role', permissions)
          break
        case '/Analysis/Efficiency':
          return checkPermissions('view-efficiency', permissions)
          break
        case '/Analysis/Sensors':
          return checkPermissions('view-sensor', permissions)
          break
        case '/Analysis/Comparison':
          return checkPermissions('view-sensor', permissions)
          break
        case '/managerReports/CropSequences':
          return checkPermissions('view-report', permissions)
          break
        case '/Inventory/Recipe':
          return true
          break
        case '/Inventory/Ingredient':
          return true
          break
        case '/ai':
          return true
          break
        case '/api':
          return true
          break
        case '/api/key':
          return true
      }
    })
    if (route.subNav.length === 0) {
      RadarSidebarData().splice(index, 1)
    }
  })
  return routesdata
}
