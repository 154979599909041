import { DefaultTheme } from 'styled-components'

export const BeesTheme: DefaultTheme = {
  colors: {
    primary: '#7b7b7b',
    secondary: '#ffd500',
    tertiary: '#3b3b3b',
    forth: '#B6B6B6',
    white: '#FFFFFF'
  },
  statusColors: {
    danger: '#DC2121',
    dangerBG: '#FADEDE',
    warning: '#DD7020',
    warningBG: '#FBEBDE',
    ok: '#62BB47',
    okBG: '#D8E7D7'
  },
  typography: {
    size: { sm: '14px', md: '20px', lg: '28px', xl: '35px' },
    colors: { primary: '#7B7B7B', secondary: 'white' },
    bold: { sm: '400', md: '500', lg: '700' }
  },
  spacing: {
    xs: '6px',
    sm: '12px',
    md: '18px',
    lg: '25px'
  },
  borderRadius: {
    sm: '8px',
    md: '12px',
    lg: '18px'
  },
  breakpoints: {
    xs: '600px',
    sm: '800px',
    md: '1000px',
    lg: '1200px',
    xl: '1400px'
  },
  colorPallete: [
    '#5D7DFF',
    '#FFAD5D',
    '#CE77FF',
    '#00D6CF',
    '#B7F876',
    '#FC5A5A',
    '#00FF27',
    '#e0c5df',
    '#666e96',
    '#5aabe8',
    '#354754',
    '#615f0a',
    '#5D7DFF',
    '#FFAD5D',
    '#CE77FF',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF'
  ]
}
