import React, { MouseEvent, MouseEventHandler } from 'react'
import { AnalysisTab, AnalysisTabImg, AnalysisTabsContainer, AnalysisTabText } from './AnalysisTabs.styled'
import { DEVICES_TAB, SEASONS_TAB, SITES_TAB } from '../../../../utils/consts/analysis'
import SiteTab from './tabs/SiteTab'
import SeasonsTab from './tabs/SeasonsTab'
import DevicesTab from './tabs/DevicesTab'
import { useTranslation } from 'react-i18next'

export enum AnalysisTabsOptions {
  Sites,
  Seasons,
  Devices
}

interface AnalysisTabsProps {
  selectedTab: AnalysisTabsOptions
  onChange: (tab: AnalysisTabsOptions) => void
}

const AnalysisTabs: React.FC<AnalysisTabsProps> = ({ selectedTab, onChange }) => {
  const handleTabChange = (tab: AnalysisTabsOptions) => onChange(tab)

  const { t } = useTranslation()

  return (
    <AnalysisTabsContainer>
      <AnalysisTab
        onClick={() => handleTabChange(AnalysisTabsOptions.Devices)}
        data-name={AnalysisTabsOptions.Devices}
        selected={selectedTab === AnalysisTabsOptions.Devices}
      >
        <DevicesTab selected={selectedTab === AnalysisTabsOptions.Devices} />
        <AnalysisTabText selected={selectedTab === AnalysisTabsOptions.Devices}>{t(`bees.analysis.devices`)}</AnalysisTabText>
      </AnalysisTab>
      <AnalysisTab onClick={() => handleTabChange(AnalysisTabsOptions.Seasons)} selected={selectedTab === AnalysisTabsOptions.Seasons}>
        <SeasonsTab selected={selectedTab === AnalysisTabsOptions.Seasons} />
        <AnalysisTabText selected={selectedTab === AnalysisTabsOptions.Seasons}>{t(`bees.analysis.seasons`)}</AnalysisTabText>
      </AnalysisTab>
      <AnalysisTab onClick={() => handleTabChange(AnalysisTabsOptions.Sites)} selected={selectedTab === AnalysisTabsOptions.Sites}>
        <SiteTab selected={selectedTab === AnalysisTabsOptions.Sites} />
        <AnalysisTabText selected={selectedTab === AnalysisTabsOptions.Sites}>{t(`bees.analysis.sites`)}</AnalysisTabText>
      </AnalysisTab>
    </AnalysisTabsContainer>
  )
}

export default AnalysisTabs
