import DetailsIcon from './DetailsImage.svg'
import DeteteIcon from './DeleteImage.svg'
import DropDownIcon from './DropDownImage.svg'
import InviteIcon from './InviteUserImage.svg'
import ResendIcon from './ResendImage.svg'
import CloseIcon from './Close.svg'
import SendMailIcon from './SendMail.svg'
import UsersImage from './users.svg'
export const DetailsIconImg = DetailsIcon
export const DeteteIconImg = DeteteIcon
export const DropDownIconImg = DropDownIcon
export const InviteIconImg = InviteIcon
export const ResendIconImg = ResendIcon
export const CloseIconImg = CloseIcon
export const SendMailIconImg = SendMailIcon
export const UsersImageImg = UsersImage

