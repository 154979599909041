import { ICustomer } from 'customers'
import { IDeviceTypes } from 'device'
import { createRef, useEffect, useState } from 'react'
import { RootState, useReduxSelector } from '../../redux'
import { serverGetDeviceData, serverGetDeviceVersion, serverInsertUpdateVersion } from '../../services/serverApi'
import { IupdateDeviceVersionDia } from 'deviceVersion.d'
import { useTranslation } from 'react-i18next'

interface fieldErrMessage {
  deviceType?: string
  versionNumber?: string
  releaseNote?: string
  general?: string
}

const useUpdateDeviceVersion = (getDeviceUpdateVersion: any, setIsOpen: any, allDeviceUpdateData: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [selectedRows, setSelectedRows] = useState<{
    allSelectedRow: any[]
    rowClicked: any
  }>()
  const [realesedFeatures, setRealesedFeatures] = useState([])
  const [updateDeviceData, setUpdateDeviceData] = useState<any>([])
  const [eventObject, setEventObject] = useState<any>({
    deviceType: '',
    versionNumber: '',
    site: '',
    customer: ''
  })
  const [fieldErrors, setFieldErrors] = useState<any>({}) // State to store field errors
  const [versionMenuItem, setVersionMenuItem] = useState<any>([])

  const deviceTypes: IDeviceTypes[] = useReduxSelector((state: RootState) => state.deviceTypes.deviceTypes)
  const customers: ICustomer[] = useReduxSelector((state: RootState) => state.customers.customers)
  const { sites } = useReduxSelector((state: RootState) => state.site)

  const { t } = useTranslation()

  const tableRef: any = createRef()

  const getDeviceData = async ({ page, pageSize, deviceTypeId, search, customerId, facilityGroupId }: any) => {
    if (!deviceTypeId) {
      return {
        data: [],
        page: page,
        totalCount: 0
      }
    }
    const deviceParamsData = {
      start: page * pageSize,
      end: pageSize,
      deviceTypeId: deviceTypeId,
      customerIds: customerId,
      facilityGroupIds: facilityGroupId,
      search: search
    }
    const data = await serverGetDeviceData(deviceParamsData)
    setUpdateDeviceData(data)
    return {
      data: data,
      page: page,
      totalCount: data[0]?.totalCount
    }
  }

  const getHeader = () => {
    const headerData = [
      {
        title: t('device-update.updateDialog.deviceName'),
        field: 'name',
        render: (data: IupdateDeviceVersionDia) => data?.name
      },
      {
        title: t('device-update.updateDialog.serialNumber'),
        field: 'serialNumber',
        render: (data: IupdateDeviceVersionDia) => data?.serialNumber
      },
      {
        title: t('device-update.updateDialog.currentVersionNumber'),
        field: 'versionNumber',
        render: (data: IupdateDeviceVersionDia) => data?.version
      }
    ]

    return headerData
  }

  const getDeviceVersions = async (deviceTypeId: number) => {
    const data = await serverGetDeviceVersion(deviceTypeId)
    return data
  }

  const deviceTypeMenuItems = deviceTypes.map((deviceType) => {
    return { id: deviceType.id, name: deviceType.name! }
  })

  const customerMenuItems = customers.map((customer) => {
    return { id: customer.id, name: customer.name! }
  })

  const siteMenuItems = sites.map((site) => {
    return { id: site.id, name: site.name! }
  })

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      let devicesIds
      if (!selectedRows?.rowClicked && selectedRows?.allSelectedRow.length) {
        const allDevices = await getDeviceData({
          page: 0,
          pageSize: 1000,
          deviceTypeId: eventObject.deviceType,
          search: '',
          customerId: eventObject.customer,
          facilityGroupId: eventObject.site
        })
        devicesIds = allDevices.data.map((device: any) => device.id)
      } else {
        devicesIds = selectedRows?.allSelectedRow.map((device: any) => device.id)
      }
      setIsLoading(false)

      const { versionNumber, deviceType }: { deviceType: string; versionNumber: string } = values

      const errors = {} as fieldErrMessage
      if (versionNumber === '' || !versionNumber) {
        errors.versionNumber = 'versionNumberError'
      }

      if (deviceType === '') {
        errors.deviceType = 'deviceTypeError'
      }

      if (Object.keys(errors).length > 0) {
        setFieldErrors(errors)
        setIsLoading(false)
        return
      }

      if (!devicesIds || devicesIds.length === 0) {
        setFieldErrors({ general: 'selectAtleastOneDevice' })
        setShowSnackbar(true)
        return
      }

      try {
        const res = await serverInsertUpdateVersion({
          versionId: versionNumber,
          deviceId: devicesIds
        })
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
      handleClose()
      getDeviceUpdateVersion()
    } catch (err) {
      setIsLoading(false)
    }
  }
  const resetForm = () => {
    setEventObject({
      deviceType: '',
      versionNumber: '',
      site: '',
      customer: ''
    })
    setVersionMenuItem([])
    setFieldErrors({})
    setUpdateDeviceData([])
  }

  const handleClose = () => {
    resetForm()
    setIsOpen(false)
  }

  const clearDropDown = (fieldName: string) => {
    setEventObject((prevState: any) => {
      prevState[fieldName] = ''
      return {
        ...prevState
      }
    })
    setFieldErrors((prevState: any) => {
      prevState[fieldName] = ''
      return {
        ...prevState
      }
    })
  }

  const compareVersionNames = (a: any, b: any) => {
    const versionA = a.name.split('.').map(Number)
    const versionB = b.name.split('.').map(Number)

    for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
      const numA = versionA[i] || 0
      const numB = versionB[i] || 0

      if (numA > numB) return -1
      if (numA < numB) return 1
    }
    return 0
  }

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setEventObject({ ...eventObject, [name]: value })

    if (fieldErrors[name]) {
      setFieldErrors((prevState: any) => {
        delete prevState[name]
        return {
          ...prevState
        }
      })
    }

    tableRef.current && tableRef.current.onQueryChange()
    if (name === 'deviceType') {
      setVersionMenuItem([])
      setEventObject((prevState: any) => {
        delete prevState['versionNumber']
        return {
          ...prevState,
          [name]: value
        }
      })

      const deviceVersions = await getDeviceVersions(parseInt(value))
      setVersionMenuItem(deviceVersions.sort(compareVersionNames))
    }
    if (name === 'versionNumber') {
      const featureList = versionMenuItem.find((version: { id: number; name: string }) => version?.id === parseInt(value))?.releaseNote
      // find • in string and split it if not found then return the string
      const features = featureList?.split('•').filter((feature: string) => feature !== '')
      setRealesedFeatures(features)
    }
  }

  const isRowDisabled = (rowClicked: any) => {
    return !eventObject?.versionNumber
      ? true
      : rowClicked?.version ===
          versionMenuItem?.find((version: { id: number; name: string }) => version?.id === eventObject?.versionNumber)?.['name']
  }

  const isDefaultCheck = (rowData: any) => {
    return selectedRows?.allSelectedRow.find((row: any) => row?.id === rowData?.id) ? true : false
  }

  useEffect(() => {
    resetForm()
  }, [])

  return {
    isLoading,
    handleSubmit,
    setIsLoading,
    clearDropDown,
    handleClose,
    handleInputChange,
    eventObject,
    setEventObject,
    deviceTypeMenuItems,
    customerMenuItems,
    versionMenuItem,
    isDefaultCheck,
    siteMenuItems,
    fieldErrors,
    isRowDisabled,
    getDeviceData,
    getHeader,
    getDeviceVersions,
    setSelectedRows,
    tableRef,
    realesedFeatures,
    showSnackbar,
    setShowSnackbar,
    updateDeviceData
  }
}

export default useUpdateDeviceVersion
