import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, InputLabel, TextField } from '@mui/material'
import CancelButton from '../../../components/DialogCancelButton/CancelButton'
import AddEditButton from '../../../components/AddEditButton/AddEditButton'
import { useTranslation } from 'react-i18next'
import { AddEventDialogContainer, DatePickerWrapper, DialogActionWrapper, FlexWrapper, Form, LabelContainer } from './AddTaskDialog.styled'
import { GetFieldComponent } from '../../../utils/helpers/dialog'
import { addBrandFields1, addBrandFields2, addBrandFields3 } from './AddTask.option'
import useAddTask from './useAddTask'
import { Fragment } from 'react'
import { FieldTypes } from '../../../utils/consts/facility'
import themes from '../../../styles'
import dayjs from 'dayjs'
import DateTimeInputMUI from '../../DateTimeInputMUI'

interface Props {
  open: boolean
  setIsOpen: any
  getTaskList: any
  editObject: any
  resetForm?: any
}

const AddTaskDialog: React.FC<Props> = ({ open, setIsOpen, getTaskList, editObject, resetForm }) => {
  const { t, i18n } = useTranslation()
  const { eventObject, menuItems, handleInputChange, submitHandler, setEventObject, handleClose, isLoading } = useAddTask({
    setIsOpen,
    getTaskList,
    resetForm,
    editObject
  })

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{editObject?.id ? t('task-manager.editTask') : t('task-manager.addTask')}</DialogTitle>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault()
          }}
        >
          <DialogContent dividers={true}>
            <AddEventDialogContainer id="scroll-dialog-description" tabIndex={-1}>
              {addBrandFields1.map((e: any) => (
                <Fragment key={e.field}>
                  <GetFieldComponent
                    metaData={{ ...e, label: t(`task-manager.${e.field}`) }}
                    onChangeHandler={handleInputChange}
                    valueObject={eventObject}
                    data={menuItems(e.field) || []}
                    multiline={e?.multiline}
                    rows={e?.rows}
                  />

                  {/* <FormHelperText error>{'error message'}</FormHelperText> */}
                </Fragment>
              ))}

              <LabelContainer
                style={{
                  fontWeight: '600',
                  fontSize: '14px',
                  marginTop: '25px'
                }}
              >
                {t('task-manager.Location')}
              </LabelContainer>
              <GetFieldComponent
                metaData={{
                  label: t('task-manager.Farm'),
                  field: 'farm',
                  variant: null,
                  isUpdateVisible: true,
                  isUpdateDisabled: false,
                  isValidCheck: true,
                  type: FieldTypes.DropDown,
                  placeholder: 'Enter Brand Name'
                }}
                onChangeHandler={handleInputChange}
                valueObject={eventObject}
                data={menuItems('farm') || []}
              />
              <FlexWrapper>
                {addBrandFields2.map((e: any) => (
                  <div
                    style={{
                      width: '47%'
                    }}
                    key={e.field}
                  >
                    <GetFieldComponent
                      metaData={{ ...e, label: t(`task-manager.${e.field}`) }}
                      onChangeHandler={handleInputChange}
                      valueObject={eventObject}
                      data={menuItems(e.field) || []}
                      multiline={e?.multiline}
                      rows={e?.rows}
                      disabled={
                        e.field === 'extension' ? eventObject.farm === '' : e.field === 'group' ? eventObject.extension === '' : false
                      }
                    />

                    {/* <FormHelperText error>{'error message'}</FormHelperText> */}
                  </div>
                ))}
              </FlexWrapper>

              {/* <FormHelperText error>{'error message'}</FormHelperText> */}
              <LabelContainer
                style={{
                  fontWeight: '600',
                  fontSize: '14px',
                  marginTop: '25px'
                }}
              >
                {t('task-manager.Deadline')}
              </LabelContainer>
              <DatePickerWrapper>
                <InputLabel
                  sx={{
                    color: themes().colors.primary,
                    fontSize: '14px',
                    fontWeight: '500'
                  }}
                >
                  {t(`task-manager.startDate`)}
                </InputLabel>
                <DateTimeInputMUI
                  onDateChangeHandler={(newValue: any) => {
                    handleInputChange({
                      target: {
                        name: 'startDate',
                        value: dayjs(newValue).locale(i18n.language)
                      }
                    })
                  }}
                  val={eventObject.startDate}
                  sx={{
                    paddingRight: '5px',
                    '.MuiOutlinedInput-input': {
                      fontSize: '14px',
                      height: '0px'
                    },
                    '.MuiOutlinedInput-root': {
                      borderBottom: '1px solid #000'
                    }
                  }}
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <InputLabel
                  sx={{
                    color: themes().colors.primary,
                    fontSize: '14px',
                    fontWeight: '500'
                  }}
                >
                  {t(`task-manager.endDate`)}
                </InputLabel>
                <DateTimeInputMUI
                  onDateChangeHandler={(newValue: any) => {
                    handleInputChange({
                      target: {
                        name: 'endDate',
                        value: dayjs(newValue).locale(i18n.language)
                      }
                    })
                  }}
                  val={eventObject.endDate}
                  sx={{
                    paddingRight: '5px',
                    '.MuiOutlinedInput-input': {
                      fontSize: '14px',
                      height: '0px'
                    },
                    '.MuiOutlinedInput-root': {
                      borderBottom: '1px solid #000'
                    }
                  }}
                />
              </DatePickerWrapper>
              <GetFieldComponent
                metaData={{
                  label: t('task-manager.assignTo'),
                  field: 'assignTo',
                  variant: null,
                  isUpdateVisible: true,
                  isUpdateDisabled: false,
                  isValidCheck: true,
                  type: FieldTypes.DropDown,
                  placeholder: 'Enter Brand Name'
                }}
                onChangeHandler={handleInputChange}
                valueObject={eventObject}
                data={menuItems('assignTo') || []}
              />
              <GetFieldComponent
                metaData={{
                  label: t('task-manager.Description'),
                  field: 'description',
                  variant: null,
                  isUpdateVisible: true,
                  isUpdateDisabled: false,
                  isValidCheck: true,
                  multiline: true,
                  rows: 4,
                  type: FieldTypes.Input,
                  placeholder: 'Type your details here....'
                }}
                onChangeHandler={handleInputChange}
                valueObject={eventObject}
                data={[]}
              />

              {/* <FormHelperText error>{'error message'}</FormHelperText> */}
            </AddEventDialogContainer>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={handleClose} />
            <AddEditButton
              titleChanged={t('general.submit')}
              type="submit"
              cy="Submit"
              isValid={
                eventObject.taskName &&
                eventObject.taskType &&
                eventObject.group &&
                eventObject.extension &&
                eventObject.farm &&
                eventObject.date &&
                eventObject.time &&
                eventObject.assignTo &&
                eventObject.description
              }
              onClick={submitHandler}
              loading={isLoading}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default AddTaskDialog
