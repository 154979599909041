import bell from './bell.svg'
import bellP from './bellP.svg'
import remove from './LocationFilter/remove.svg'
import location from './location.svg'
import locationSecondary from './LocationSecondary.svg'
import time from './time.svg'
import TimeIcon from '././timeFilter/MobileTimeIcon.svg'
import FarmIcon from './FarmIcon.svg'
import FarmIconSecondary from './FarmSecondaryIcon.svg'
import ShedIcon from './ShedIcon.svg'
import TodayIcon from './TodayIcon.svg'
import CropIcon from './CropIcon.svg'
import CustomDateIcon from './CustomDateIcon.svg'
import CustomDateSecondaryIcon from './CustomDateSecondaryIcon.svg'
import CropModalIcon from './CropModalIcon.svg'
import RadarWhiteLogo from './RadarWhiteLogo.svg'
export const bellIcon = bell
export const bellPIcon = bellP
export const removeIcon = remove
export const locationIcon = location
export const locationSecondaryIcon = locationSecondary
export const timeIcon = time
export const TimeMobileIcon = TimeIcon
export const FarmMobileIcon = FarmIcon
export const FarmMobileSecondaryIcon = FarmIconSecondary
export const ShedMobileIcon = ShedIcon
export const TodayMobileIcon = TodayIcon
export const CropMobileIcon = CropIcon
export const CustomDateMobileIcon = CustomDateIcon
export const CropModalMobileIcon = CropModalIcon
export const CustomeDateSecondaryMobileIcon = CustomDateSecondaryIcon
export const RadarWhiteLogoIcon = RadarWhiteLogo
