import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverGetFacilitiesGroups, serverCreateFacilityGroup } from '../services/serverApi'
import { IFacilityGroup } from '../types/facilityGroup.d'

type SitesState = {
  sites: Array<IFacilityGroup>
  loading: boolean
  error: string | null
}

const initialState: SitesState = {
  sites: [],
  loading: false,
  error: null
}

/**Get Sites */
export const getSites = createAsyncThunk('getSite', async (s, thunkApi) => {
  try {
    const res = await serverGetFacilitiesGroups()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add device  */
export const addSite = createAsyncThunk('addSite', async (facilityGroup: IFacilityGroup, thunkApi) => {
  try {
    const res = await serverCreateFacilityGroup(facilityGroup)
    thunkApi.dispatch(getSites())
  } catch (e) {
    console.error(e)
  }
})

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getSites.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getSites.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.sites = payload as IFacilityGroup[]
      }),
      builder.addCase(getSites.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      builder.addCase(addSite.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
      }),
      builder.addCase(addSite.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addSite.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const siteReducer = siteSlice.reducer
export const siteActions = siteSlice.actions
