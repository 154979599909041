import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../Sidebar/Bees/Sidebar/routes'
import './AppBottomTabs.scss'
interface AppBottomTabsProps {
  currentRoute: string
}
const AppBottomTabs: React.FC<AppBottomTabsProps> = ({ currentRoute }) => {
  return (
    <div className="bottom-tabs-container">
      {routes.map(({ path, name, image }) => {
        const isCurrentRoute = currentRoute === path
        return (
          <div key={name} className={`${isCurrentRoute && 'selected-item'} nav-item`}>
            <Link to={path} className="nav-item-link nav-link">
              <div className="image-container">
                <img src={image} alt="IOT" className="sidebarIcon" />
              </div>
              <span className="ms-1  d-sm-inline sidebarText">{name}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}
export default AppBottomTabs
