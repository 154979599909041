import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serverGetPermissionsByDataModelId } from '../services/serverApi';
import { IPermission } from '../types/permission.d';

type RolesState = {
    dataModelPermissions: Array<IPermission>;
    loading: boolean;
    error: string | null;
};

const initialState: RolesState = {
    dataModelPermissions: [],
    loading: false,
    error: null
};

export const getAllDataModelPermissions = createAsyncThunk('getAllDataModelPermissions', async (s, thunkApi) => {
    try {
        const res = await serverGetPermissionsByDataModelId();
        return res;
    } catch (e) {
        return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
});


export const dataModelPermissionsSlice = createSlice({
    name: 'dataModelPermissions',
    initialState,
    reducers: {},
    extraReducers: (builder): any => {
        builder.addCase(getAllDataModelPermissions.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(getAllDataModelPermissions.fulfilled, (state, {payload}) => {
                state.error = null;
                state.loading = false;
                state.dataModelPermissions = payload as IPermission[];
            }),
            builder.addCase(getAllDataModelPermissions.rejected, (state, {payload}) => {
                state.loading = false;
                state.error = (payload as ErrorEventInit).error;
            })
    },
});

export const dataModelPermissionsReducer = dataModelPermissionsSlice.reducer;
export const dataModelPermissionsActions = dataModelPermissionsSlice.actions;
