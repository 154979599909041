import iot from './iot.svg'
import iotSelect from './selected/iotSelect.svg'
import customerManagment from './customerManagment.svg'
import customerManagmentSelect from './selected/customerManagmentSelect.svg'
import managerReport from './managerReport.svg'
import managerReportSelect from './selected/managerReportSelect.svg'
import api from './api.svg'
import apiSelected from './selected/apiSelected.svg'
import ai from './ai.svg'
import devices from './devices.svg'
import aiSelect from './selected/aiSelect.svg'
import analysis from './analysis.svg'
import analysisSelect from './selected/analysisSelect.svg'
import nutritions from './nutritions.svg'
import nutritionsSelect from './selected/nutritionsSelect.svg'
import myFarm from './myFarm.svg'
import myFarmSelect from './selected/myFarmSelect.svg'
import closeArrow from './closeArrow.svg'
import openArrow from './openArrow.svg'
import naviIot from './navigation/naviIot.svg'
import naviMyFarm from './navigation/naviMyFarm.svg'
import navZoneImage from './navigation/NavZoneIcon.svg'
import devicesSelect from './selected/devicesSelect.svg'
import devicesWhite from './devicesWhite.svg'

import naviApi from './navigation/naviApi.svg'
import naviCustomerManagement from './navigation/naviCustomerManagement.svg'
import naviNutritions from './navigation/naviNutritions.svg'
import naviAnalysis from './navigation/naviAnalysis.svg'
import naviAi from './navigation/naviAi.svg'
import naviManagerReport from './navigation/naviManagerReport.svg'
import backIcon from './BackIcon.svg'
import CloseIcon from './Close.svg'
import ZoneSidebarImage from './ZoneSidebarIcon.svg'
import navDeviceBpac from './navDevicesBpac.svg'
import navTaskManager from './navTaskManager.svg'
import navTaskManagerSelect from './navTaskManagerSelect.svg'

export const NavZoneIcon = navZoneImage
export const zoneSidebarIcon = ZoneSidebarImage
export const BackIcon = backIcon
export const iotIcon = iot
export const iotSelectIcon = iotSelect
export const closeArrowIcon = closeArrow
export const openArrowIcon = openArrow
export const customerManagmentIcon = customerManagment
export const aiIcon = ai
export const devicesIcon = devices
export const devicesIconBpac = devices
export const managerReportIcon = managerReport
export const managerReportSelectIcon = managerReportSelect
export const apiIcon = api
export const apiSelectIcon = apiSelected
export const analysisIcon = analysis
export const nutritionsIcon = nutritions
export const nutritionsSelectIcon = nutritionsSelect
export const myFarmIcon = myFarm
export const aiSelectIcon = aiSelect
export const analysisSelectIcon = analysisSelect
export const customerManagmentSelectIcon = customerManagmentSelect
export const myFarmSelectIcon = myFarmSelect
export const devicesSelectIcon = devicesSelect
export const devicesWhiteIcon = devicesWhite

export const naviIotIcon = naviIot
export const naviMyFarmIcon = naviMyFarm
export const naviApiIcon = naviApi
export const naviCustomerManagementIcon = naviCustomerManagement
export const naviNutritionsIcon = naviNutritions
export const naviAnalysisIcon = naviAnalysis
export const naviTaskManeger = navTaskManager
export const naviTaskManegerSelect = navTaskManagerSelect
export const naviAiIcon = naviAi
export const naviManagerReportIcon = naviManagerReport
export const CloseIconImage = CloseIcon
export const navDeviceIcon = devicesIcon
export const navDeviceBpacIcon = navDeviceBpac
