import triangle from './TriangleIcon.svg'
import edit from './EditIcon.svg'
import deleteicon from './DeleteIcon.svg'
import circle from './CircleIcon.svg'
import battery from './BatteryIcon.svg'
import read from './ReadIcon.svg'
import statusright from './StatusRightIcon.svg'
import statuswrong from './StatusWrongIcon.svg'
import statusalert from './StatusAlertIcon.svg'
import triangleRight from './TriangleRightIcon.svg'
import adddevicebutton from './AddDeviceButton.svg'
import addGatewayIcon from './AddGatewayButton.svg'
import uploadImg from './UploadIcon.svg'
import DeviceImg from './DeviceIcon.svg'
import GatewayImg from './GatewayIcon.svg'
import RefreshButtonImg from './RefreshButtonIcon.svg'

export const TriangleIcon = triangle
export const EditIcon = edit
export const DeleteIcon = deleteicon
export const CircleIcon = circle
export const BatteryIcon = battery
export const ReadIcon = read
export const StatusRightIcon = statusright
export const StatusWrongIcon = statuswrong
export const StatusAlertIcon = statusalert
export const TriangleRightIcon = triangleRight
export const addDeviceButton = adddevicebutton
export const addGatewayButton = addGatewayIcon
export const uploadIcon = uploadImg
export const DeviceIcon = DeviceImg;
export const GatewayIcon = GatewayImg;
export const RefreshButtonIcon = RefreshButtonImg;
