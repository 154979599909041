import { totalDeathIcon, totalLegCullsIcon, totalOtherCullsIcon } from '../../assets/images/crop'
import { addDeviceButton, addGatewayButton } from '../../assets/images/device'
import { newDeviceIcon } from '../../assets/images/dialog'
import { facilityPoolImage, fishDeadImage, fishFoodImage, fishImage, fishWeightImage } from '../../assets/images/fish/IOT'
import { HydroIntelLogo } from '../../assets/images/fish/IOT/Appbar'
import { cumulativeIcon, fishCountIcon, FIshHistoryIcon } from '../../assets/images/fish/IOT/harvest'
import { gatewayIcon } from '../../assets/images/gateway'
import { SidebarData } from '../../components/Sidebar/PoultryWeights/hydroRoutes'
import { sensorsHydroColor, sensorsHydroImages } from './Sensors'

enum EPermissionFeature {
  IOT = 'IOT',
  Heatmap = 'Heatmap',
  Pool = 'Pool',
  Device = 'Device',
  Crop = 'Crop',
  Alert = 'Alert',
  User = 'User',
  Customer = 'Customer',
  Role = 'Role',
  Efficiency = 'Efficiency',
  Sensor = 'Sensors',
  Report = 'Reports'
}
export const HydroIntelConfig = {
  logo: HydroIntelLogo,
  sidebar: {
    routeData: SidebarData
  },
  title: 'HYDROINTEL',
  logoLogin: HydroIntelLogo,
  facilityGroup: {
    name: 'Site',
    PrimaryIcon: facilityPoolImage,
    SecondaryIcon: '',
    WhiteIcon: facilityPoolImage
  },
  facility: {
    name: 'Pool',
    PrimaryIcon: facilityPoolImage,
    SecondaryIcon: facilityPoolImage
  },
  facilityZones: {
    name: 'Zone',
    PrimaryIcon: '',
    SecondaryIcon: ''
  },
  harvest: {
    name: 'Batch',
    unitName: 'Fish',
    TotalUnitIcon: fishImage,
    DeathUnitIcon: fishDeadImage,
    cumulativeDeathIcon: cumulativeIcon,
    countIcon: fishCountIcon,
    FeedIcon: fishFoodImage,
    route: '/MySite/Harvest',
    totalDeathIcon: totalDeathIcon,
    totalLegCullsIcon: totalLegCullsIcon,
    totalOtherCullsIcon: totalOtherCullsIcon,
    weightIcon: fishWeightImage,
    mortalityIcon: FIshHistoryIcon
  },
  shedTable: {
    zoneLogsIcon: facilityPoolImage
  },
  sensors: {
    Images: sensorsHydroImages,
    color: sensorsHydroColor
  },
  device: {
    addDeviceButtonIcon: addDeviceButton,
    addGatewayButtonIcon: addGatewayButton,
    addGatewayDialogIcon: gatewayIcon,
    addDeviceDialogIcon: newDeviceIcon
  },
  material: {
    name: 'Material'
  },
  attachMaterial: {
    name: 'Attach Material'
  },
  EPermissionFeature
}
