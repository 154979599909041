import React, { useEffect, useState } from 'react'
import { TodayDate, TodayDateContainer } from './Todaydate.styled'

const Todaydate: React.FC = () => {
  const options: any = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  return (
    <TodayDateContainer>
      <TodayDate>{new Date().toLocaleDateString('en-US', options)}</TodayDate>
    </TodayDateContainer>
  )
}

export default Todaydate
