import styled from 'styled-components'

export const TodayDate = styled.p`
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.size.sm};

  color: ${({ theme }) => theme.typography.colors.white};
`
