import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  serverGetMonitoredUnits,
  serverCreateMonitoredUnit,
  serverGetMonitoredGroup,
  serverCreateMonitoredGroup
} from '../services/serverApi'
import { IMonitoredGroup, IPostMonitoredGroup } from '../types/monitoredGroup.d'
import { colorPallete } from '../utils/helpers/analysisHelper'
import { RuminTechTheme } from '../styles/RuminTech/theme'

type MonitoredGroupState = {
  monitoredGroup: Array<IMonitoredGroup>
  loading: boolean
  error: string | null
}

const initialState: MonitoredGroupState = {
  monitoredGroup: [],
  loading: false,
  error: null
}

/**Get MonitoredGroup */
export const getMonitoredGroup = createAsyncThunk('getMonitoredGroup', async (s, thunkApi) => {
  try {
    const res = await serverGetMonitoredGroup()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add MonitoredGroup  */
export const addMonitoredGroup = createAsyncThunk('addMonitoredGroup', async (monitoredGroup: IPostMonitoredGroup, thunkApi) => {
  try {
    const res = await serverCreateMonitoredGroup(monitoredGroup)
    thunkApi.dispatch(getMonitoredGroup())
  } catch (e) {
    console.error(e)
  }
})

export const monotoredGroupSlice = createSlice({
  name: 'MonitoredGroup',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getMonitoredGroup.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getMonitoredGroup.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        const finalData = payload.map((item: IMonitoredGroup, index: number) => {
          const colorIndex = index % RuminTechTheme.colorPallete.length
          return {
            ...item,
            color: RuminTechTheme.colorPallete[colorIndex]
          }
        })
        state.monitoredGroup = finalData as IMonitoredGroup[]
      }),
      builder.addCase(getMonitoredGroup.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      builder.addCase(addMonitoredGroup.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
      }),
      builder.addCase(addMonitoredGroup.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addMonitoredGroup.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const monitoredGroupReducer = monotoredGroupSlice.reducer
export const monitoredGroupActions = monotoredGroupSlice.actions
