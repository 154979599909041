import styled from 'styled-components'

export const AddEventDialogContainer = styled.div`
  width: 350px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
`

export const UploadDiv = styled.div`
  border: 2px dashed ${({ theme }) => theme.typography.colors.forth};
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
`
export const Form = styled.form``
