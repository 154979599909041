import { TemperatureIcon } from '../../assets/images/analysis/Hydro/sensors'

export enum HYDOSENSORS {
  'do%',
  'doMg/l',
  'doPpm',
  'ph',
  'ph.mv',
  'conductivity.ms',
  'conductivity.us',
  'tds',
  'salinity',
  'resistivityK/cm'
}

export const sensorsHydroColor: any = {
  'do%': '#665bfe',
  'doMg/l': '#0153a5',
  doPpm: '#017745',
  ph: '#eda701',
  'ph.mv': '#a5a61a',
  'conductivity.ms': '#5D7DFF',
  'conductivity.us': '#c65c20',
  tds: '#22c4db',
  salinity: '#29adac',
  'resistivityK/cm': '#f33c5a'
}

export const sensorsHydroImages = [
  {
    name: 'do%',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'doMg/l',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'doPpm',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'ph',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'ph.mv',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'conductivity.ms',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'conductivity.us',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'tds',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'salinity',
    img: TemperatureIcon,
    symbol: ''
  },
  {
    name: 'resistivityK/cm',
    img: TemperatureIcon,
    symbol: ''
  }
]
