import styled from 'styled-components'

export const BackdropStyle = styled.div<{ isopen: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transform: ${({ isopen }) => (isopen ? 'translateY(0%)' : 'translateY(100%)')};
  z-index: 2;
`
export const ModalStyle = styled.div`
  color: #000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`
export const DrawerWrapper = styled.div<{ isopen: boolean }>`
  width: 95%;
  height: 40%;
  position: relative;
  margin-left: 10px;
  top: 57%;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  color: #7b7b7b;
  background-color: #ffffff;
  border-radius: 10px;
  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transition: 0.3s ease-in-out;
  transform: ${({ isopen }) => (isopen ? 'translateY(0%)' : 'translateY(100%)')};
`
