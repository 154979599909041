import styled from "styled-components";



export const AppBarUserWrapper = styled.div`
background-color: ${({ theme }) => theme.typography.colors.white};
border-radius: 5px;
height: 56px;
justify-content: center;
align-items: center;
position: reltive;
width: auto;
min-width: 70px;
margin-left : 10px
`;


export const AppBarSpan = styled.span`
color: Black;
font-size: 30px;
padding:30%;
`;
