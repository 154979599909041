import styled from "styled-components";

export const CancelButtonWrapper = styled.button`
width: 143px;
height: 66px;
color: #878787;
border-radius: ${({ theme }) => theme.borderRadius.md};
border: 1px solid ${({ theme }) => theme.colors.forth};
margin-right: 15px;
@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  width: 100px;
  height: 50px;
}`