import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { RootState, useReduxSelector } from '../../redux'
// import RequireAuth from '../../components/RequireAuth/RequireAuth'
const RequireAuth = React.lazy(() => import('../../components/RequireAuth/RequireAuth'))
// import Customers from '../Customers/Customers'
const Customers = React.lazy(() => import('../Customers/Customers'))
// import Devices from '../Devices/Devices'
const Devices = React.lazy(() => import('../Devices/Devices'))
// import DevicesSummary from '../Devices/PoultryWeights/DevicesSummary/DevicesSummary'
const DevicesSummary = React.lazy(() => import('../Devices/RuminTech/DevicesSummary/DevicesSummary'))
// import Farms from '../Farms/Farms'
const Farms = React.lazy(() => import('../Farms/Farms'))
// import Iot from '../IOT/IOT'
const Iot = React.lazy(() => import('../IOT/IOT'))
// import Login from '../Login/Login'
const Login = React.lazy(() => import('../Login/Login'))
// import ManagerReports from '../ManagerReports/ManagerReports'
const ManagerReports = React.lazy(() => import('../ManagerReports/ManagerReports'))
// import Alerts from '../MyFarm/Alerts/Alerts'
const Alerts = React.lazy(() => import('../MyFarm/bees/Alerts/Alerts'))
// import ResetPassword from '../ResetPassword/ResetPassword'
const ResetPassword = React.lazy(() => import('../ResetPassword/ResetPassword'))
// import Roles from '../Roles/Roles'
const Roles = React.lazy(() => import('../Roles/Roles'))
// import Sheds from '../Sheds/Sheds'
const Sheds = React.lazy(() => import('../Sheds/Sheds'))
// import Users from '../Users/Users'
const Users = React.lazy(() => import('../Users/Users'))
// import LoginWithToken from '../LoginWithToken/LoginWithToken'
const LoginWithToken = React.lazy(() => import('../LoginWithToken/LoginWithToken'))
// import Ai from '../Ai/Ai'
const Ai = React.lazy(() => import('../Ai/Ai'))
// import UserData from '../UserData/UserData'
const UserData = React.lazy(() => import('../UserData/UserData'))
// import Animals from '../Animals/Animals'
const Animals = React.lazy(() => import('../Animals/Animals'))
// import Groups from '../Groups/Groups'
const Groups = React.lazy(() => import('../Groups/Groups'))
// import RuminTechGateways from '../Devices/RuminTech/Gateways/Gateways'
const RuminTechGateways = React.lazy(() => import('../Devices/RuminTech/Gateways/Gateways'))
// import Recipe from '../Recipe/Recipe'
const Recipe = React.lazy(() => import('../Recipe/Recipe'))
// import Ingredient from '../Ingredient/Ingredient'
const Ingredient = React.lazy(() => import('../Ingredient/Ingredient'))
// import OverView from '../GraphOverView/OverView'
const OverView = React.lazy(() => import('../GraphOverView/OverView'))
// import Schedule from '../Schedule/Schedule'
const Schedule = React.lazy(() => import('../Schedule/Schedule'))
// import InventoryDistribution from '../InventoryDistribution/InventoryDistribution'
const InventoryDistribution = React.lazy(() => import('../InventoryDistribution/InventoryDistribution'))
// import IngredientCost from '../IngredientCost/IngredientCost'
const IngredientCost = React.lazy(() => import('../IngredientCost/IngredientCost'))
// import DeviceInfo from '../Devices/DeviceInfo/DeviceInfo'
const DeviceInfo = React.lazy(() => import('../Devices/DeviceInfo/DeviceInfo'))
// import SignUp from '../Signup/RuminTech/SignUp'
const SignUp = React.lazy(() => import('../Signup/RuminTech/SignUp'))

import Loader from '../../components/Loader/Loader'
import DeviceVersion from '../DeviceVersion/DeviceVersion'
import Expired from '../Expired/Expired'

const GasRoutes = () => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<Loader size={80} />}>
            {/* <Signup /> */}
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path="/reset"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/login/token"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <LoginWithToken />
          </Suspense>
        }
      />
      <Route
        path="/expired"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Expired />
          </Suspense>
        }
      />

      <Route
        path="/Devices/Device"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceInfo />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/summary"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DevicesSummary />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/device-version"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceVersion />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/CRM/users"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Users />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/CRM/Customer"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Customers />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/CRM/Roles"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Roles />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/api/key"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <UserData />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/Devices"
        element={
          <Suspense fallback={'Loading...'}>
            <RequireAuth>
              <Devices />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/Device"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceInfo />
            </RequireAuth>
          </Suspense>
        }
      />
    </Routes>
  )
}

export default GasRoutes
