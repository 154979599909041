import { ICustomer } from 'customers'
import React from 'react'
import { useState } from 'react'
import { locationIcon } from '../../../../../../assets/images/appBar'
import { CloseIconImg } from '../../../../../../assets/images/UsersImages'
import { RootState, useReduxSelector } from '../../../../../../redux'
import Modal from '../../../../../Model/ModalMobileView'
import { ModalContent, ModalTopWrapper } from '../../../../../Model/ModalMobileView.styled'
import {
  CloseImg,
  ContentTopWrapper,
  CustomerId,
  CustomerName,
  ModalIcon,
  ModelHeading,
  SelectCustomerContainer,
  TableList,
  TableWrapper
} from '../../MobileAppbar.styled'

interface IModalProps {
  handleChange: any
  customerId: number | undefined
  isOpen: { [key: string]: boolean }
  setIsOpen: (isOpen: { [key: string]: boolean }) => void
}

const LocationModal: React.FC<IModalProps> = ({ handleChange, customerId, isOpen, setIsOpen }) => {
  const { customers } = useReduxSelector((state: RootState) => state.customers)
  return (
    <>
      {isOpen.customer && (
        <Modal show={isOpen.customer}>
          <ModalTopWrapper>
            <CloseImg src={CloseIconImg} onClick={() => setIsOpen({ ...isOpen, customer: !isOpen.customer })} />
          </ModalTopWrapper>
          <ModalContent>
            <ContentTopWrapper>
              <ModalIcon src={locationIcon} />
              <ModelHeading>Select Customer</ModelHeading>
            </ContentTopWrapper>
            <SelectCustomerContainer>
              {customers.map((customer: ICustomer) => {
                return (
                  <TableWrapper key={customer.id}>
                    <TableList
                      isActive={customerId === customer.id}
                      onClick={() => handleChange({ lable: 'customer', id: customer.id, name: customer.name })}
                    >
                      <CustomerId isActive={customerId === customer.id}>{customer.id}</CustomerId>
                      <CustomerName isActive={customerId === customer.id}>{customer.name}</CustomerName>
                    </TableList>
                  </TableWrapper>
                )
              })}
            </SelectCustomerContainer>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default LocationModal
