import { GetDataModelId } from '../utils/helpers/dataModel'
import { DataModel } from '../types/dataModel.d'
import { Config as PoultryWeights } from './PoultryWeights'
import { Config as hydroIntel } from './HydroIntel'
import { Config as Bees } from './Bees'
import { Config as RuminTech } from './RuminTech'
import { Config as Bpac } from './Bpac'
import { Config as Gas } from './Gas'
import { Config as Radar } from './Radar'

export const GetConfig = () => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return PoultryWeights
    case DataModel.RuminTech:
      return RuminTech
    case DataModel.hydroIntel:
      return hydroIntel
    case DataModel.Bees:
      return Bees
    case DataModel.Bpac:
      return Bpac
    case DataModel.Gas:
      return Gas
    case DataModel.Radar:
      return Radar
    default:
      return PoultryWeights
  }
}
