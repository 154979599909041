import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IntervalType } from '../types/analysis.d'
import { IDuration, TimeFilter } from '../types/timeFilter.d'
import { Interval } from '../utils/consts/analysis'
import { serverGetchartDuration } from '../services/serverApi'
import { ICustomer } from 'customers'

const initialState: TimeFilter = {
  startDate: new Date(),
  endDate: new Date(),
  isDisabledCrop: false,
  isDisabledLive: false,
  isOnePicker: false,
  isDisabledToday: false,
  interval: {
    id: IntervalType.Day,
    value: Interval.Day
  },
  duration: [],
  loading: false,
  error: null
}

/* Get Alert */
export const getDuration = createAsyncThunk('getDuration', async (s, thunkApi) => {
  try {
    const res = await serverGetchartDuration()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const timeSlice = createSlice({
  name: 'time',
  initialState: initialState,
  reducers: {
    setTimeFilterStartDate: (state: TimeFilter, action: any) => {
      state.startDate = action.payload
    },
    setTimeFilterEndDate: (state: TimeFilter, action: any) => {
      state.endDate = action.payload
    },
    setTimeFilterIsDisabledCrop: (state: TimeFilter, action: any) => {
      state.isDisabledCrop = action.payload
    },
    setTimeFilterIsDisabledLive: (state: TimeFilter, action: any) => {
      state.isDisabledLive = action.payload
    },
    setTimeFilterIsOnePicker: (state: TimeFilter, action: any) => {
      state.isOnePicker = action.payload
    },
    setTimeFilterIsDisabledToday: (state: TimeFilter, action: any) => {
      state.isDisabledToday = action.payload
    },
    setTimeInterval: (state: TimeFilter, action: any) => {
      state.interval = action.payload
    }
  },
  extraReducers: (builder): any => {
    builder.addCase(getDuration.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getDuration.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.duration = payload as IDuration[]
      }),
      builder.addCase(getDuration.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const timeSliceReducer = timeSlice.reducer
export const {
  setTimeFilterEndDate,
  setTimeFilterIsDisabledCrop,
  setTimeFilterIsDisabledLive,
  setTimeFilterIsDisabledToday,
  setTimeFilterIsOnePicker,
  setTimeFilterStartDate,
  setTimeInterval
} = timeSlice.actions
