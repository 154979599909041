import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SidebarSubLink, SubTitle } from './Sidebar.styled'

interface SubNavProps {
  item: {
    title: string
    path: string
  }
  setOpen: (isOpen: boolean) => void
}

const SubNav: React.FC<SubNavProps> = ({ item, setOpen }) => {
  const { pathname } = useLocation()

  return (
    <>
      <SidebarSubLink isActive={item.path.includes(pathname) || pathname === item.path}>
        <Link to={item.path}>
          <SubTitle onClick={() => setOpen(!setOpen)}>{item.title}</SubTitle>
        </Link>
      </SidebarSubLink>
    </>
  )
}

export default SubNav
