import React, { ReactElement, useEffect, useRef, useState } from 'react'
import SettingsDialog from '../../dialogs/SettingsDialog/SettingsDialog'
import NewAppMenu from './NewAppMenu'
import { AppMenuContainer, DropdownMenu, MenuButton } from './AppMenu.styled'
import useAppMenu from './useAppMenu'

interface AppMenuProps {
  menuButton: ReactElement
}

const AppMenu: React.FC<React.PropsWithChildren<AppMenuProps>> = ({ menuButton }) => {
  const {
    handleAccountClick,
    onMenuItemsClickGeneral,
    handleMenuMouseLeave,
    setActiveMenu,
    handleLogout,
    handleLoginClick,
    activeMenu,
    handleMenuItemClick,
    handleMenuMouseEnter,
    submenuTimeout,
    openMenu,
    setOpenMenu
  } = useAppMenu()

  const appMenuRenderingProps = {
    handleAccountClick,
    onMenuItemsClickGeneral,
    handleMenuMouseLeave,
    setActiveMenu,
    handleLogout,
    handleLoginClick,
    activeMenu,
    handleMenuItemClick,
    handleMenuMouseEnter,
    submenuTimeout
  }

  const menuRef = useRef<HTMLDivElement>(null)

  const handleMenuToggle = () => {
    setOpenMenu((isOpen) => !isOpen)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <>
      <AppMenuContainer ref={menuRef}>
        <MenuButton onClick={handleMenuToggle}>{menuButton}</MenuButton>
        {openMenu && (
          <DropdownMenu>
            <NewAppMenu appMenuRenderingProps={appMenuRenderingProps} />
          </DropdownMenu>
        )}
      </AppMenuContainer>
    </>
  )
}

export default AppMenu
