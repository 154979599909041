import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { RootState, useReduxSelector } from '../../redux'
// import RequireAuth from '../../components/RequireAuth/RequireAuth'
const RequireAuth = React.lazy(() => import('../../components/RequireAuth/RequireAuth'))
// import Analysis from '../Analysis/Analysis'
const Analysis = React.lazy(() => import('../Analysis/Analysis'))
// import Customers from '../Customers/Customers'
const Customers = React.lazy(() => import('../Customers/Customers'))
// import Devices from '../Devices/Devices'
const Devices = React.lazy(() => import('../Devices/Devices'))
// import DevicesSummary from '../Devices/PoultryWeights/DevicesSummary/DevicesSummary'
const DevicesSummary = React.lazy(() => import('../Devices/RuminTech/DevicesSummary/DevicesSummary'))
// import Farms from '../Farms/Farms'
const Farms = React.lazy(() => import('../Farms/Farms'))
// import Gateways from '../Gateways/Gateways'
const Gateways = React.lazy(() => import('../Devices/RuminTech/Gateways/Gateways'))
// import Iot from '../IOT/IOT'
const Iot = React.lazy(() => import('../IOT/IOT'))
// import HeatMap from '../IOT/PoultryWeights/HeatMap/HeatMap'
const HeatMap = React.lazy(() => import('../IOT/PoultryWeights/HeatMap/HeatMap'))
// import Login from '../Login/Login'
const Login = React.lazy(() => import('../Login/Login'))
// import ManagerReports from '../ManagerReports/ManagerReports'
const ManagerReports = React.lazy(() => import('../ManagerReports/ManagerReports'))
// import ManualEvents from '../ManualEvents/ManualEvents'
const ManualEvents = React.lazy(() => import('../ManualEvents/ManualEvents'))
// import Material from '../Material/Material'
const Material = React.lazy(() => import('../Material/Material'))
// import Alerts from '../MyFarm/Alerts/Alerts'
const Alerts = React.lazy(() => import('../Alert/Alert'))
const ResetPassword = React.lazy(() => import('../ResetPassword/ResetPassword'))
// import Roles from '../Roles/Roles'
const Roles = React.lazy(() => import('../Roles/Roles'))
// import Sensors from '../Sensors/Sensors'
const Sensors = React.lazy(() => import('../Sensors/Sensors'))
// import Sheds from '../Sheds/Sheds'
const Sheds = React.lazy(() => import('../Sheds/Sheds'))
// import Silos from '../Silos/Silos'
const Silos = React.lazy(() => import('../Silos/Silos'))
// import Users from '../Users/Users'
const Users = React.lazy(() => import('../Users/Users'))
// import LoginWithToken from '../LoginWithToken/LoginWithToken'
const LoginWithToken = React.lazy(() => import('../LoginWithToken/LoginWithToken'))
// import Ai from '../Ai/Ai'
const Ai = React.lazy(() => import('../Ai/Ai'))
// import UserData from '../UserData/UserData'
const UserData = React.lazy(() => import('../UserData/UserData'))
const DeviceInfo = React.lazy(() => import('../Devices/DeviceInfo/DeviceInfo'))
// import SignUp from '../Signup/RuminTech/SignUp'
const SignUp = React.lazy(() => import('../Signup/RuminTech/SignUp'))
// import MaterialCell from '../MaterialCell/MaterialCell'
const MaterialCell = React.lazy(() => import('../MaterialCell/MaterialCell'))

import Loader from '../../components/Loader/Loader'
import { PiticCustomer } from '../../types/customers.d'
import { GetConfig } from '../../config/config'
import DeviceVersion from '../DeviceVersion/DeviceVersion'
import Expired from '../Expired/Expired'

const RadarRoutes = () => {
  const location = useLocation()
  const config = GetConfig()?.global
  const { customers } = useReduxSelector((state: RootState) => state.customers)
  const IsPitic = customers.find((data) => data.id === PiticCustomer.PititcId)
  const IsPoultry = customers.find((data) => data.id === PiticCustomer.PoultryId)

  return (
    <Routes location={location}>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<Loader size={80} />}>
            {/* <Signup /> */}
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path="/reset"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/login/token"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <LoginWithToken />
          </Suspense>
        }
      />
      <Route
        path="/expired"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Expired />
          </Suspense>
        }
      />

      <Route
        path="/Iot/General"
        element={
          // <Suspense fallback={<Loader size={80} />}>
          <RequireAuth>
            <>
              <Iot />
            </>
          </RequireAuth>
          // </Suspense>
        }
      />

      <Route
        path="/MyFarm/Farms"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Farms />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyFarm/Sheds"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Sheds />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyFarm/Silos"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Silos />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/summary"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DevicesSummary />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/device-version"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceVersion />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/Devices"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Devices />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/Device"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceInfo />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyFarm/material"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Material />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyFarm/Alert"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Alerts />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/Gateways"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Gateways />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/customerMgmt/users"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Users />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/customerMgmt/Customer"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Customers />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/customerMgmt/Roles"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Roles />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Analysis/Efficiency"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Analysis />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/api/key"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <UserData />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyFarm/MaterialCell"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <MaterialCell />
            </RequireAuth>
          </Suspense>
        }
      />
    </Routes>
  )
}

export default RadarRoutes
