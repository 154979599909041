import { SidebarData } from '../../components/Sidebar/PoultryWeights/routes'
import {
  chickDeadImage,
  chickWhiteImage,
  DeathIcon,
  FeedIcon,
  mortalityHistoryIcon,
  shedIcon,
  totalDeathIcon,
  TotalIcon,
  totalLegCullsIcon,
  totalOtherCullsIcon
} from '../../assets/images/crop'
import { newFarmIcon } from '../../assets/images/dailog'
import { pwLogo } from '../../assets/images/global'
import { PoultryLogo } from '../../assets/images/login'
import { addButtonIcon, ShedLogIcon, shedsHouseIcon } from '../../assets/images/sheds'
import { sensorsPoultryImages, sensorsPourtryColor } from './Sensors'
import WeightImg from '../../assets/images/IotFacilityImages/Group 346.svg'
import { newDeviceIcon } from '../../assets/images/dialog'
import { gatewayIcon } from '../../assets/images/gateway'
import { addDeviceButton, addGatewayButton } from '../../assets/images/device'

enum EPermissionFeature {
  IOT = 'IOT',
  Heatmap = 'Heatmap',
  Farm = 'Farm',
  Shed = 'Shed',
  Silo = 'Silo',
  Material = 'Material',
  AttachMaterial = 'Attach Material',
  Device = 'Device',
  Crop = 'Crop',
  Event = 'Event',
  Alert = 'Alert',
  User = 'User',
  Customer = 'Customer',
  Role = 'Role',
  Efficiency = 'Efficiency',
  Sensor = 'Sensors',
  Report = 'Reports'
}

export const PoultryConfig: any = {
  logo: PoultryLogo,
  sidebar: {
    routeData: SidebarData
  },
  logoLogin: PoultryLogo,
  title: 'POULTRYWEIGHTS NETWORKS',
  facilityGroup: {
    name: 'Farm',
    PrimaryIcon: newFarmIcon,
    SecondaryIcon: ''
  },
  device: {
    addDeviceButtonIcon: addDeviceButton,
    addGatewayButtonIcon: addGatewayButton,
    addGatewayDialogIcon: gatewayIcon,
    addDeviceDialogIcon: newDeviceIcon
  },

  facility: {
    name: 'Shed',
    PrimaryIcon: shedsHouseIcon,
    SecondaryIcon: addButtonIcon,
    WhiteIcon: shedIcon
  },
  facilityZones: {
    name: 'Zone',
    PrimaryIcon: '',
    SecondaryIcon: ''
  },
  harvest: {
    name: 'Crop',
    unitName: 'Chicks',
    TotalUnitIcon: TotalIcon,
    DeathUnitIcon: DeathIcon,
    cumulativeDeathIcon: chickDeadImage,
    countIcon: chickWhiteImage,
    FeedIcon: FeedIcon,
    route: '/MyFarm/Crop',
    totalDeathIcon: totalDeathIcon,
    totalLegCullsIcon: totalLegCullsIcon,
    totalOtherCullsIcon: totalOtherCullsIcon,
    weightIcon: WeightImg,
    mortalityIcon: mortalityHistoryIcon
  },
  shedTable: {
    zoneLogsIcon: ShedLogIcon
  },
  sensors: {
    Images: sensorsPoultryImages,
    color: sensorsPourtryColor
  },
  material: {
    name: 'Material'
  },
  attachMaterial: {
    name: 'Attach Material'
  },
  EPermissionFeature
}
