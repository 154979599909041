import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serverCreateHarvest, serverGetHarvests } from '../services/serverApi';
import { IHarvest } from '../types/harvest.d';

type harvestState = {
  harvests: Array<IHarvest>;
  loading: boolean;
  error: string | null;
};

const initialState: harvestState = {
  harvests: [],
  loading: false,
  error: null
};

/**Get Harvests */
export const getHarvest = createAsyncThunk('harvest/getHarvest', async (customerId, thunkApi) => {
  try {
    const res = await serverGetHarvests();
    return res;
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit);
  }
});

/**Add Harvest  */
export const addHarvest = createAsyncThunk('harvest/addHarvest', async (harvest: IHarvest, thunkApi) => {
  try {
    const res = await serverCreateHarvest(harvest);
    thunkApi.dispatch(getHarvest());
  } catch (e) {
    console.error(e);
  }
});

export const harvestSlice = createSlice({
  name: 'harvest',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getHarvest.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getHarvest.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.harvests = payload as IHarvest[];
      }),
      builder.addCase(getHarvest.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      }),
      builder.addCase(addHarvest.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
      }),
      builder.addCase(addHarvest.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(addHarvest.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      });
  }
});

export const harvestReducer = harvestSlice.reducer;
export const harvestActions = harvestSlice.actions;
