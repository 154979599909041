import { LinearProgress } from '@mui/material'
import { DataModel } from '../../types/dataModel.d'
import styled from 'styled-components'
import themes from '../../styles'
export const DeviceVersionContainer = styled.div`
  height: fit-content;
  width: 100%;
`
export const DeviceVersionHeaderContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid ${(props) => themes().typography.colors.forth};
  display: flex;
  flex-direction: column;
`
export const DeviceVersionBodyContainer = styled.div`
  height: 320px;
`
export const DeviceVersionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`
export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => themes().colors.primary};
  font-weight: bold;
`

export const FlexColumnWrapper = styled.div<{ dataModelId: number | null }>`
  margin-top: ${(props) => (props.dataModelId === DataModel.Bees ? '1.5rem' : null)};
  display: flex;
  gap: 1.25vh;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem 0 0;
`

export const TableColumnWrapper = styled.div`
  padding: 0px 10px;
`

export const ProgressBarColumnWrapper = styled.div`
  padding: 0px 5px;
  width: 100%;
`

export const ProgressValWrapper = styled.div`
  margin-top: 5px;
`

export const ProgressValuePercent = styled.div`
  font-size: 12px;
  color: ${(props) => themes().typography.colors.primary};
`

export const InstallingTimerIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: ${(props) => themes().typography.colors.primary};
  gap: 0.25rem;
`

export const InstallingTimer = styled.div`
  font-size: 12px;
  color: ${(props) => themes().typography.colors.primary};
`

export const DeviceTitle = styled.p`
  margin-bottom: 0px;
  font-size: 14.5px;
`

export const DeviceSRNumber = styled.p`
  margin-bottom: 0px;
  font-size: 10px;
`

export const StyledLinearProgress = styled(LinearProgress)`
  .MuiLinearProgress-bar {
    transition: transform 1s linear;
  }
`

export const VersionWrapper = styled.div`
  display: flex;
  gap: 0.45rem;
`
