import React from 'react'
import AppMenu from '../../../../AppMenu/AppMenu'
import { AppBarUserWrapper, AppBarSpan } from './AppBarUser.styled'

interface AppbarUserProps {
  userName: string
}

const AppBarUser: React.FC<React.PropsWithChildren<AppbarUserProps>> = ({ userName }) => {
  return (
    <AppBarUserWrapper>
      <AppMenu menuButton={<AppBarSpan>{userName.toUpperCase()}</AppBarSpan>} />
    </AppBarUserWrapper>
  )
}

export default AppBarUser
