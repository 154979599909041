import React, { useState } from 'react'
import { CloseImage, StyledBurger, StyledMenu } from './Sidebar.styled'
import { getSidebarData, SidebarData } from './routes'
import Submenu from './Submenu'
import { CloseIconImage } from '../../../../assets/images/sideMenu'
import { GetConfig } from '../../../../config/config'
import { RootState, useReduxSelector } from '../../../../redux'

const MobileSidebar = () => {
  const [open, setOpen] = useState<boolean>(false)
  const config = GetConfig()?.global
  const {permissions} = useReduxSelector((state: RootState) => state.permissions);
  const SidebarData = getSidebarData(config?.sidebar?.routeData ? (config?.sidebar?.routeData as any):[], permissions);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
        <CloseImage src={CloseIconImage} open={open} />
      </StyledBurger>
      <StyledMenu isActive={open}>
        {SidebarData.map((item:any, index) => {
          return <Submenu item={item} key={index} setOpen={setOpen} />
        })}
      </StyledMenu>
    </>
  )
}

export default MobileSidebar
