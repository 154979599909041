import React from 'react'
import { GetDataModelId } from '../../utils/helpers/dataModel'
import { DataModel } from '../../types/dataModel.d'

const PoultryIot = React.lazy(() => import('./PoultryWeights/IOT'))
const HydroIntel = React.lazy(() => import('./fish/IOT'))
const BeesIOT = React.lazy(() => import('./Bees/IOT'))
const RuminTechIot = React.lazy(() => import('./RuminTech/IOT'))
const BpacIot = React.lazy(() => import('./Bpac/Iot'))
const RadarIot = React.lazy(() => import('./Radar/IOT'))

const Iot = () => {
  const dataModelId = GetDataModelId()
  switch (dataModelId) {
    case DataModel.PoultryWeights:
      return <PoultryIot />
    case DataModel.hydroIntel:
      return <HydroIntel />
    case DataModel.Bees:
      return <BeesIOT />
    case DataModel.RuminTech:
      return <RuminTechIot />
    case DataModel.Bpac:
      return <BpacIot />
    case DataModel.Radar:
      return <RadarIot />
    default:
      return <PoultryIot />
  }
}

export default Iot
