import React, { forwardRef } from 'react'
import './AppTable.scss'
import MaterialTable, { MTableCell } from '@material-table/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { materailTableOptions } from './AppTable.options'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { AppTableContainer } from './AppTable.styled'
import { exportCSV, exportPDF } from '../../utils/helpers/user'
import { useTranslation } from 'react-i18next'

interface AppTableProps {
  rows: any
  headers: header[]
  actions?: any
  options?: any
  handleRowClick?: any
  parentChildData?: any
  onSelectionChange?: any
  isLoading?: boolean
  title?: any
  handleDetailsPanel?: any
  getData?: any
  tableRef?: any
  rowsPerPageOptions?: number[]
  editable?: any
  exportablePDF?: boolean
}

const AppTable: React.FC<React.PropsWithChildren<AppTableProps>> = ({
  rows,
  isLoading,
  actions,
  parentChildData,
  handleRowClick,
  options,
  headers,
  onSelectionChange,
  handleDetailsPanel,
  title,
  getData,
  tableRef,
  exportablePDF,
  rowsPerPageOptions,
  editable
}) => {
  const { t } = useTranslation()
  return (
    <AppTableContainer>
      <MaterialTable
        title={title}
        tableRef={tableRef}
        editable={editable}
        style={{
          maxHeight: '100%',
          scrollbarWidth: 'none'
          // paddingBottom: 55
        }}
        components={{
          Container: (props) => <div {...props} />,
          Cell: (props) => {
            if (props.columnDef.colSpan === 0 && props.rowData.parentId) {
              return <></>
            }
            return <MTableCell colSpan={props.columnDef.colSpan && props.rowData.parentId ? props.columnDef.colSpan : 1} {...props} />
          }
        }}
        icons={{
          SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon {...props} ref={ref} />),
          DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
          ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />)
        }}
        options={{
          ...materailTableOptions,
          ...options,
          exportMenu: [
            {
              label: t('general.exportCSV'),
              exportFunc: (cols: any, datas: any) => exportCSV(cols, datas)
            },
            exportablePDF && {
              label: t('general.exportPDF'),
              exportFunc: (cols: any, datas: any) => exportPDF(cols, datas)
            }
          ].filter(Boolean)
        }}
        columns={headers}
        actions={actions}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        onSelectionChange={onSelectionChange}
        data={
          getData
            ? (query) => getData(query.page, query.pageSize, query.search, query.orderBy, query.orderDirection)
            : JSON.parse(JSON.stringify(rows))
        }
        parentChildData={parentChildData}
        detailPanel={handleDetailsPanel}
        localization={{
          header: {
            actions: t('general.actions')
          },
          toolbar: {
            searchPlaceholder: t('general.searchPlaceholder'),
            searchTooltip: t('general.search'),
            exportTitle: t('general.export'),
            showColumnsTitle: t('general.showColumns')
          }
        }}
      />
      {/* <button
            onClick={() => tableRef.current.onQueryChange()}
          >
            ok
          </button> */}
    </AppTableContainer>
  )
}

export default AppTable
