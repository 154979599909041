import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Snackbar } from '@mui/material'
import CancelButton from '../../components/DialogCancelButton/CancelButton'
import AddEditButton from '../../components/AddEditButton/AddEditButton'
import {
  DeviceVersionBodyContainer,
  DropDownWrapper,
  Feature,
  Form,
  ListWrapper,
  NoNote,
  ReleaseNoteWrapper,
  TableWrapper,
  TitleWrapper,
  UpdateEventDialogContainer,
  VersionHeader
} from './UpdateDeviceVersionDialog.styled'
import { editDeviceVersionFields, UPDATE_VERSION } from '../../utils/consts/devicesVersion'
import useUpdateDeviceVersion from './useUpdateDeviceVersion'
import { GetFieldComponent } from '../../utils/helpers/dialog'
import InputDropdown from '../../components/AppInputDropDown/InputDropDown'
import { LabelContainer } from '../../views/ManagerReports/RuminTech/AddReportDetailesDialog/AddReportDialog.styled'
import { FieldTypes } from '../../utils/consts/facility'
import AppTable from '../../components/AppTable/AppTable'
import { Fragment, useMemo } from 'react'
import { updateDeviceVersionTableOptions, whatsNew } from './UpdateDeviceDialog.options'
import { Color } from 'three'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  setIsOpen: any
  getDeviceUpdateVersion?: any
  allDeviceUpdateData?: any
}

const UpdateDeviceVersionDialog: React.FC<Props> = ({ open, setIsOpen, getDeviceUpdateVersion, allDeviceUpdateData }) => {
  const {
    handleSubmit,
    isLoading,
    handleClose,
    setShowSnackbar,
    showSnackbar,
    eventObject,
    handleInputChange,
    deviceTypeMenuItems,
    customerMenuItems,
    siteMenuItems,
    fieldErrors,
    versionMenuItem,
    getDeviceData,
    updateDeviceData,
    isRowDisabled,
    isDefaultCheck,
    setSelectedRows,
    tableRef,
    clearDropDown,
    getHeader,
    realesedFeatures
  } = useUpdateDeviceVersion(getDeviceUpdateVersion, setIsOpen, allDeviceUpdateData)

  const deviceHeader = useMemo(() => getHeader() as header[], [])

  const fieldMenuItems: any = {
    deviceType: deviceTypeMenuItems,
    customer: customerMenuItems,
    versionNumber: versionMenuItem,
    site: siteMenuItems
  }

  const dynWidth = eventObject.versionNumber && eventObject.versionNumber !== '' ? '85%' : '65%'
  const { t } = useTranslation()

  const translatedEditDeviceVersionFields = editDeviceVersionFields.map((e: any) => {
    return {
      ...e,
      label: t(`device-update.updateDialog.${e.tranlationKey?.label}`),
      placeholder: t(`device-update.updateDialog.${e.tranlationKey?.placeholder}`)
    }
  })

  console.log(translatedEditDeviceVersionFields, 'translatedEditDeviceVersionFields')

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: '65%', minWidth: dynWidth, height: '650px', maxHeight: '650px' } }}
      >
        <DialogTitle id="scroll-dialog-title">{t('device-update.updateDialog.chooseDevicesToUpdate')}</DialogTitle>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(eventObject)
          }}
        >
          <DialogContent dividers={true}>
            <UpdateEventDialogContainer id="scroll-dialog-description" tabIndex={-1}>
              {eventObject.versionNumber && (
                <ReleaseNoteWrapper>
                  <TitleWrapper>{t('device-update.updateDialog.whatsNew')}</TitleWrapper>
                  <VersionHeader>
                    {t('device-update.updateDialog.V')}{' '}
                    {versionMenuItem?.find((version: { id: number; name: string }) => version?.id === eventObject?.versionNumber)?.['name']}
                  </VersionHeader>
                  {/* list of bullet points */}
                  {realesedFeatures?.length && Array.isArray(realesedFeatures) ? (
                    <ListWrapper>
                      {realesedFeatures?.map((f: any, ind: any) => (
                        <Feature key={ind}>{f}</Feature>
                      ))}
                    </ListWrapper>
                  ) : (
                    <NoNote>{t('device-update.updateDialog.noReleaseNote')}</NoNote>
                  )}
                </ReleaseNoteWrapper>
              )}
              <DropDownWrapper>
                {translatedEditDeviceVersionFields.map((e: any) => {
                  return (
                    <Fragment key={e.field}>
                      <LabelContainer style={{ width: '100%' }}>
                        <GetFieldComponent
                          metaData={e}
                          onChangeHandler={handleInputChange}
                          valueObject={eventObject}
                          data={fieldMenuItems[e.field] || []}
                          multiline={e?.multiline}
                          rows={e?.rows}
                          disabled={e.field !== 'deviceType' && eventObject.deviceType === ''}
                          handleClear={() => {
                            clearDropDown(e.field)
                          }}
                        />
                      </LabelContainer>
                      {fieldErrors[e.field] && (
                        <FormHelperText error>{t(`device-update.updateDialog.${fieldErrors[e.field]}`)}</FormHelperText>
                      )}
                    </Fragment>
                  )
                })}
              </DropDownWrapper>
              <TableWrapper>
                <DeviceVersionBodyContainer>
                  <AppTable
                    options={{
                      ...updateDeviceVersionTableOptions,
                      selectionProps: (rowData: any) => ({
                        disabled: isRowDisabled(rowData),
                        checked: isDefaultCheck(rowData)
                      })
                    }}
                    isLoading={isLoading}
                    rows={updateDeviceData}
                    onSelectionChange={(_: any[], selectedRows: any) => {
                      setSelectedRows({ allSelectedRow: _, rowClicked: selectedRows })
                    }}
                    headers={deviceHeader}
                    getData={(page: number, pageSize: number, search: string) =>
                      getDeviceData({
                        page: page,
                        pageSize: pageSize,
                        deviceTypeId: eventObject.deviceType,
                        search: search,
                        customerId: eventObject.customer,
                        facilityGroupId: eventObject.site
                      })
                    }
                    tableRef={tableRef}
                  />
                </DeviceVersionBodyContainer>
              </TableWrapper>
            </UpdateEventDialogContainer>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={() => handleClose()} />
            <AddEditButton titleChanged={t('general.submit')} type="submit" loading={isLoading} cy="Submit" isValid={true} />
          </DialogActions>
        </Form>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={3000}
        key={'bottom' + 'right'}
      >
        <Alert severity="error">{t(`device-update.updateDialog.${fieldErrors.general}`)}</Alert>
      </Snackbar>
    </>
  )
}

export default UpdateDeviceVersionDialog
