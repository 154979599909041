import { gateWaysConfig, RuminTechDevicesConfig } from './Devices'
import { RuminTechConfig } from './GlobleConfig'
import { RumIntechLanguage } from './Language'
import { HARVEST, SENSORS } from './Sensors'

export const Config = {
  global: RuminTechConfig,
  devices: RuminTechDevicesConfig,
  gateWays: gateWaysConfig,
  sensors: SENSORS,
  harvest: '',
  language: RumIntechLanguage
}
