import { FieldTypes } from '../../../utils/consts/facility'

export const addBrandFields1 = [
  {
    label: 'Task Name',
    field: 'taskName',
    select: true,
    variant: null,
    placeholder: 'Enter task name',
    required: false,
    type: FieldTypes.Input
  },
  {
    label: 'Task Type',
    field: 'taskType',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    placeholder: 'Enter Brand Name'
  }
]

export const addBrandFields2 = [
  {
    label: 'Extension',
    field: 'extension',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    placeholder: 'Enter Brand Name'
  },
  {
    label: 'Group',
    field: 'group',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    placeholder: 'Enter Brand Name'
  }
]

export const addBrandFields3 = [
  {
    label: 'Schedule',
    field: 'schedule',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.Input,
    placeholder: 'Enter Brand Name'
  },
  {
    label: 'Assign To',
    field: 'assignTo',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    placeholder: 'Enter Brand Name'
  }
]
