import styled from 'styled-components'

export const HexagonNameContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

export const HexagonEventsContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 60px;
  background-color: transparent;
`

export const HexagonContainer = styled.div<{ zoom?: number }>`
  transform: ${({ zoom }) => {
    if (zoom && zoom < 15) {
      return 'scale(0.5)'
    } else {
      return 'scale(1.5)'
    }
  }};
`
export const HexagonName = styled.span`
  font-size: 22px;
  font-weight: 600;
  white-space: nowrap;
  color: white;
  position: absolute;
  top: -25px;
  text-align: center;
  width: 100px;
`
