interface Idevice {
  id: number
  customerId: number
  facilityGroupId: number
  facilityZoneId: number | null
  lat: number
  long: number
  activateDate: Date
  disactivateDate: Date
  lastKeepAlive: Date
  lastCalibrationDate: Date
  lastZeroingDate: Date
  name: string
  deviceTypeId: number
  serialNumber: string
  baseStationDeviceId: number
  identifier: string
  deviceSupplierId: number
  updateDate: Date
  comments: string
  version: string
  inZonePositionId: null | number
  externalRefId: number
  connectionTypeId: number
  lastBatteryChargeLevel: string
  applicationEui: string
  applicationKey: string
  monitoredUnitId: number
  facilityId: number
  deviceTypeName: string
  isBaseStation: boolean
  needsCalibration: boolean
  placementTypeId: number
  connectorSettings: string
  preset: string
  deviceSensors: Array<any>
  deviceMonitoredUnits: Array<any>
  parentId: number
  iotEvents?: any
  port?: string
  port1?: string
  port2?: string
  frequency: string
  rssi?: number
  lastWeight?: any
  totalCount?: number
  status?: string
  monitoredGroupId?: number | null
  isCalibrate?: boolean
  successCalibrationValue?: number
  simId?: number
}

export type SummaryData = {
  id: number
  name: string
  icon: string
  device: number
  online: number
  error: number
  alert: number
  parentId: number
  path: string
  find(predicate: (value: T, index: number, obj: Array<T>) => boolean, thisArg?: any): T | undefined
}

export type AddDevice = {
  customerId: number
  deviceSupplierId: number
  facilityGroupId: number
  name: string
  serialNumber: string
  deviceTypeId: number
  connectionTypeId: number
  facilityZoneId?: number | null
  lat?: number
  long?: number
  comments?: string
  activateDate: Date
  disactivateDate?: Date
  applicationEui: string
  applicationKey?: string
  baseStationDeviceId: null
  identifier: string
  port?: string
  port1?: string
  port2?: string
}

export enum DeviceStatusTypes {
  'ok',
  'warning',
  'error'
}

export enum DeviceTypes {
  WeightSensor = 1,
  RadarSensor = 15,
  Gateway = 2,
  WaterMeter = 13,
  Tablet = 39,
  HydroSensor = 40,
  Topco = 41,
  Bolus = 11,
  GasBulk = 31,
  GasMeter = 33,
  GasMulti = 38,
  CalfWeightUnit = 18,
  ClimateStick = 43,
  BeesUnit = 36,
  Laser = 44
}

export enum ButtonTypes {
  Device = 'Device',
  Gateway = 'Gateway'
}
export interface IDeviceTypes {
  id?: number
  dataModelTypeId?: number
  name?: string
  description?: string
  needsCalibration?: number
  placementTypeId?: number
  unitHardwareType?: number
  isBaseStation?: number
  sensorsSettings?: string
  externalDeviceTypeId?: number
  availableVersions?: number
  defaultCalibration?: number
  metricsCollected?: string
  powerSupplySettings?: string
  connectorSettings?: string
  preset?: string
  supportAlerts?: string
  log_headers?: string
}
interface IDeviceSupplier {
  id: number
  name: string
}

export enum DeviceSupplier {
  Andromeda = 1
}

export interface statusOfDevice {
  active: number
  error: number
  alert: number
  total: number
}

export interface DeviceStatus {
  danger: string
  warning: string
  ok: string
}

export interface StatusType {
  color: keyof DeviceStatus
  name: string
  icon: any
  key: string
}

export interface IDeviceParams {
  start: number
  end: number
  sortBy?: any
  facilityIds?: number[]
  facilityGroupIds?: number[]
  customerIds?: number[]
  facilityZoneIds?: number[]
  deviceTypeId?: number
  max?: number
  search?: string
  orderDirection?: string
}
export interface IGetRssi {
  startDate: number
  endDate: number
  interval: number
  deviceId: number
}
export interface IdeviceProfile {
  id: number
  name: string
}
