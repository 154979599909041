import styled from 'styled-components'

export const MobileDateFilterWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`

export const MobileDateFilterOptions = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  height: 70px;
  padding-left: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.typography.size.md};
  font-family: open sans;
  display: flex;
  align-items: center;
  border: none;
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.secondary : theme.typography.colors.white)};
  color: ${({ theme, isActive }) => (isActive ? theme.typography.colors.white : theme.typography.colors.primary)};
`
export const AppLiveIcon = styled.div<{ isActive: boolean }>`
  height: 35px;
  width: 35px;
  background-color: ${({ isActive }) => (isActive ? 'white' : '#113252')};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing.lg};
`
export const TodayIcon = styled.img<{ isActive: boolean }>`
  height: 40px;
  width: 40px;
  margin-right: ${({ theme }) => theme.spacing.lg};
`
export const CropIcon = styled(TodayIcon)``
export const CustomDateIcon = styled(TodayIcon)``
export const CloseImg = styled.img`
  float: right;
  margin-right: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`
