import styled from 'styled-components'

export const InputWrapper = styled.div<{ row?: boolean; hideLabel?: boolean }>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  margin-top: ${({ hideLabel }) => (hideLabel ? '0' : '35px')};
  gap: 0.25rem;
  align-items: ${({ row }) => (row ? 'center' : undefined)};
`

export const Lable = styled.span`
  font-size: 16px;
  color: #7b7b7b;
  //width: 80px;
`
