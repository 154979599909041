import React from 'react'
import { AppDataFilterWrapper, AppDataFilterOptions, AppLiveIcon, AppLiveTitle } from './AppDataFilter.styled'
import { useLocation } from 'react-router-dom'
import {
  setTimeFilterEndDate,
  setTimeFilterIsDisabledCrop,
  setTimeFilterIsDisabledLive,
  setTimeFilterIsDisabledToday,
  setTimeFilterIsOnePicker,
  setTimeFilterStartDate
} from '../../../../redux/TImeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { RootState, useReduxSelector } from '../../../../redux'
import { memo } from 'react'
import { GetConfig } from '../../../../config/config'
import { setMultipleSelect, setType } from '../../../../redux/LocationSlice'
import { selectedDateButton, selectedIotTime } from '../../../../redux/IotSlice'
interface Props {
  handleChange: any
  showCustom: Boolean
  selectedButton: DateButtons
  handleIotChange: any
  selectedIotButton: DateButtons
}
export enum DateButtons {
  Live,
  Today,
  Crop,
  Custom
}

const AppDataFilter: React.FC<Props> = ({ handleChange, handleIotChange, selectedButton, selectedIotButton }) => {
  const config = GetConfig()?.global
  const { isDisabledCrop, isOnePicker, startDate, isDisabledLive, isDisabledToday } = useSelector((state: RootState) => state.time)
  const { time } = useSelector((state: RootState) => state.iot)
  const location = useLocation()
  const dispatch = useDispatch()
  const search = useLocation().search
  const type = new URLSearchParams(search).get('type')
  useEffect(() => {
    if (location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')) {
      dispatch(setTimeFilterIsDisabledCrop(true))
      dispatch(setTimeFilterIsDisabledLive(false))
      dispatch(setTimeFilterIsDisabledToday(false))
      dispatch(setTimeFilterIsOnePicker(true))
    } else if (location.pathname.includes(`${config?.harvest?.route}`)) {
      dispatch(setTimeFilterIsDisabledLive(true))
      dispatch(setTimeFilterIsDisabledToday(true))
      dispatch(setTimeFilterIsOnePicker(true))
      handleChange(DateButtons.Crop)
      dispatch(
        selectedDateButton({
          Crop: 2
        })
      )
    } else if (
      location.pathname.includes('/Analysis/Sensors') ||
      location.pathname.includes('/Analysis/Efficiency') ||
      location.pathname.includes('/Analysis/Sensors/Detailes')
    ) {
      dispatch(setTimeFilterIsDisabledCrop(false))
      dispatch(setTimeFilterIsDisabledLive(true))
      dispatch(setTimeFilterIsDisabledToday(false))
      dispatch(setTimeFilterIsOnePicker(false))
      dispatch(setMultipleSelect(true))
      if (type === null && location.pathname.includes('/Analysis/Sensors/Detailes')) {
        handleChange(DateButtons.Custom)
        dispatch(
          selectedDateButton({
            Custom: 3
          })
        )
      }
    } else if (location.pathname.includes('/managerReports/CropSequences')) {
      handleChange(DateButtons.Custom)
      dispatch(
        selectedDateButton({
          Custom: 3
        })
      )
    } else {
      dispatch(setTimeFilterIsDisabledCrop(false))
      dispatch(setTimeFilterIsDisabledLive(false))
      dispatch(setTimeFilterIsDisabledToday(false))
      dispatch(setTimeFilterIsOnePicker(false))
    }
  }, [location])

  useEffect(() => {
    let date = new Date()
    const startOfDay = new Date()
    startOfDay.setHours(0, 0, 0, 0)
    const endOfDay = new Date()
    endOfDay.setHours(23, 59, 59, 999)
    if (location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')) {
      if (selectedIotButton === DateButtons.Live) {
        dispatch(
          selectedIotTime({
            startDate: date,
            endDate: null
          })
        ),
          dispatch(
            selectedDateButton({
              isLive: 0
            })
          )
      }
      if (selectedIotButton === DateButtons.Today) {
        dispatch(
          selectedIotTime({
            startDate: startOfDay,
            endDate: new Date()
          })
        ),
          dispatch(
            selectedDateButton({
              Today: 1
            })
          )
      }
      if (selectedIotButton === DateButtons.Custom) {
        if (isOnePicker) {
          const startOfDay = new Date(time.startDate)
          startOfDay.setHours(0, 0, 0, 0)
          const endOfDay = new Date(startOfDay)
          endOfDay.setHours(23, 59, 59, 999)
          dispatch(
            selectedIotTime({
              startDate: startOfDay,
              endDate: endOfDay
            })
          ),
            dispatch(
              selectedDateButton({
                Custom: 3
              })
            )
        }
      }
    } else {
      if (selectedButton === DateButtons.Live) {
        dispatch(setTimeFilterStartDate(date))
        dispatch(setTimeFilterEndDate(null)),
          dispatch(
            selectedDateButton({
              isLive: 0
            })
          )
      }
      if (selectedButton === DateButtons.Today) {
        dispatch(setTimeFilterStartDate(startOfDay))
        dispatch(setTimeFilterEndDate(new Date())),
          dispatch(
            selectedDateButton({
              Today: 1
            })
          )
      }
      if (selectedButton === DateButtons.Crop) {
        dispatch(
          selectedDateButton({
            Crop: 2
          })
        )
      }
      if (selectedButton === DateButtons.Custom) {
        if (isOnePicker) {
          const startOfDay = new Date(startDate)
          startOfDay.setHours(0, 0, 0, 0)
          const endOfDay = new Date(startOfDay)
          endOfDay.setHours(23, 59, 59, 999)
          dispatch(setTimeFilterStartDate(startOfDay))
          dispatch(setTimeFilterEndDate(endOfDay)),
            dispatch(
              selectedDateButton({
                Custom: 3
              })
            )
        }
      }
    }
  }, [location, selectedButton, selectedIotButton])
  useEffect(() => {
    if (selectedButton === DateButtons.Crop && location.pathname.includes('/Analysis/Efficiency')) {
      dispatch(setMultipleSelect(false))
      dispatch(
        selectedDateButton({
          Crop: 2
        })
      )
      dispatch(
        setType({
          customer: false,
          facility: true,
          facilityGroup: false,
          facilityZone: false
        })
      )
    }
    if (selectedButton !== DateButtons.Crop && location.pathname.includes('/Analysis/Efficiency')) {
      dispatch(setMultipleSelect(true))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: false,
          facilityZone: false
        })
      )
    }
  }, [selectedButton, location])

  return (
    <>
      <AppDataFilterWrapper>
        <AppDataFilterOptions
          isActive={
            location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
              ? selectedIotButton === DateButtons.Live
              : selectedButton === DateButtons.Live
          }
          onClick={
            location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
              ? () => handleIotChange(DateButtons.Live)
              : () => handleChange(DateButtons.Live)
          }
          disabled={isDisabledLive}
        >
          <AppLiveIcon
            isActive={
              location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
                ? selectedIotButton === DateButtons.Live
                : selectedButton === DateButtons.Live
            }
            disabled={isDisabledLive}
          />
          <AppLiveTitle>LIVE</AppLiveTitle>
        </AppDataFilterOptions>
        <AppDataFilterOptions
          isActive={
            location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
              ? selectedIotButton === DateButtons.Today
              : selectedButton === DateButtons.Today
          }
          onClick={
            location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
              ? () => handleIotChange(DateButtons.Today)
              : () => handleChange(DateButtons.Today)
          }
          disabled={isDisabledToday}
        >
          Today
        </AppDataFilterOptions>
        <AppDataFilterOptions
          isActive={
            location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
              ? selectedIotButton === DateButtons.Custom
              : selectedButton === DateButtons.Custom
          }
          onClick={
            location.pathname.includes('/Iot/General') || location.pathname.includes('/Iot/Heatmap')
              ? () => handleIotChange(DateButtons.Custom)
              : () => handleChange(DateButtons.Custom)
          }
          data-cy="customDate"
        >
          Custom Date
        </AppDataFilterOptions>
      </AppDataFilterWrapper>
    </>
  )
}

export default memo(AppDataFilter)
