import React, { useEffect, useState } from 'react'
import { serverGetUnSeenNotification } from '../services/serverApi';

const useNotification = () => {
    const [unSeenNotificationData, setUnSeenNotificationData] = useState<number>(0)

    const getUnSeenNotificationData = async () => {
        const res = await serverGetUnSeenNotification();
        setUnSeenNotificationData(res[0]?.UnSeeenNotificationLength)
    }

    useEffect(() => {
        getUnSeenNotificationData()
    }, [])

  return {
    unSeenNotificationData
  }
}

export default useNotification