import React, { useState } from 'react'
import { RootState, useReduxSelector } from '../../../redux'
import {
  AppBarNotificationWrapper,
  AppbarWrapper,
  AppNavContainer,
  AppNavigationWrapper,
  AppNavTitle,
  AppSubNavTitle,
  LastGroupWrapper,
  SkeletonCss
} from './Appbar.styled'
import { Skeleton, Stack } from '@mui/material'
import AppBarNotification from './AppBarNotification/AppBarNotification'
import PwNotificationDialog from '../../../dialogs/NotificationDialog/PoultryWeights/NotificationDialog'
import AppBarUser from './AppBarUser/AppBarUser'
import { AppNavigationArrowIcon } from '../../../assets/images/appBar/RumintechAppbar'
import { GetConfig } from '../../../config/config'
import { useLocation } from 'react-router-dom'
import useNotification from '../../../hooks/useNotification'
import { IUser } from 'user'
import { GetuserName } from '../../../utils/helpers/user'
import { GetDataModelId } from '../../../utils/helpers/dataModel'
import { useTranslation } from 'react-i18next'
import { AccountSidebarData } from '../../../utils/consts/Accounts'

interface Props {
  serverLoading?: any
}

export const RuminTechAppbar: React.FC<Props> = ({ serverLoading }) => {
  const { unSeenNotificationData } = useNotification()
  const { t } = useTranslation()
  const config = GetConfig()?.global
  const location = useLocation()
  const [openNotificationDialog, setOpenNotificaitonDialog] = useState(false)
  const user: any = useReduxSelector((state: RootState) => state.user.user)
  const hanldeNotificationClick = () => {
    setOpenNotificaitonDialog(!openNotificationDialog)
  }
  const data = location.pathname
  const path = data.split('/')
  const Title = `/${path[1]}`
  const SubTitle = path[2]
  const subnavdata = []
  subnavdata.push(config?.sidebar?.routeData?.find((o: any) => o.path === Title))
  const dataModelId = GetDataModelId()

  return (
    <>
      <AppbarWrapper dataModelId={dataModelId}>
        <AppNavigationWrapper>
          <h4>
            {subnavdata.map((item: any, index) => {
              return serverLoading ? (
                <Stack sx={SkeletonCss.Stack} key={index}>
                  <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.Title} />
                  <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.SubTitle} />
                </Stack>
              ) : (
                <AppNavContainer key={index}>
                  {/* <AppNavTitle>{item?.title}</AppNavTitle> */}
                  <AppNavTitle>{t(`appBar.ruminTech.${Title}`)}</AppNavTitle>
                  <img src={AppNavigationArrowIcon}></img>
                  {/* <AppSubNavTitle data-cy={'subnavigate'}>{SubTitle}</AppSubNavTitle> */}
                  <AppSubNavTitle data-cy={'subnavigate'}>{t(`appBar.ruminTech.${SubTitle}`)}</AppSubNavTitle>
                </AppNavContainer>
              )
            })}
          </h4>
        </AppNavigationWrapper>
        <LastGroupWrapper>
          {serverLoading ? (
            <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarNotification} />
          ) : (
            <AppBarNotificationWrapper
              onClick={(e) => {
                hanldeNotificationClick()
              }}
            >
              <AppBarNotification unSeenNotificaitonCount={unSeenNotificationData} />
            </AppBarNotificationWrapper>
          )}
          {openNotificationDialog && (
            <PwNotificationDialog open={openNotificationDialog} onSuccess={hanldeNotificationClick} onClose={hanldeNotificationClick} />
          )}
          {serverLoading ? (
            <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.AppBarUser} />
          ) : (
            <AppBarUser userName={GetuserName(user)} />
          )}
        </LastGroupWrapper>
      </AppbarWrapper>
    </>
  )
}
