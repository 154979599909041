import { facilityPoolImage } from '../../assets/images/fish/IOT'
import { HydroIntelLogo } from '../../assets/images/fish/IOT/Appbar'
import { beesLogo } from '../../assets/images/login'
import { BeesLogo } from '../../assets/images/appBar/BeesAppbar'

enum EPermissionFeature {
  IOT = 'IOT',
  User = 'User',
  Device = 'Device',
  Alert = 'Alert',
  Site = 'Site',
  Crop = 'Crop',
  Analysis = 'Analysis',
  Customer = 'Customer',
  WorkPlan = 'WorkPlan',
  Role = 'Role'
}
export const BeesConfig = {
  logo: beesLogo,
  logoLogin: BeesLogo,
  title: 'Bees',
  sidebar: {
    routeData: null
  },
  facilityGroup: {
    name: 'Pool Water',
    PrimaryIcon: facilityPoolImage,
    SecondaryIcon: 'pool'
  },
  facility: {
    name: 'Pool Water',
    PrimaryIcon: 'pool',
    SecondaryIcon: 'pool'
  },
  harvest: {
    name: '',
    unitName: '',
    TotalUnitIcon: '',
    DeathUnitIcon: '',
    cumulativeDeathIcon: '',
    countIcon: '',
    FeedIcon: '',
    weightIcon: '',
    mortalityIcon: ''
  },
  shedTable: {
    zoneLogsIcon: null
  },
  sensors: {
    Images: '',
    color: ''
  },
  material: {
    name: 'Material'
  },
  attachMaterial: {
    name: 'Attach Material'
  },
  EPermissionFeature
}
