import { useState } from 'react'
import { DateObject, toDateObject } from 'react-multi-date-picker'
import { RootState, useReduxSelector } from '../redux'
import { getIotEvents, serverGetAnalysis } from '../services/serverApi'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { DateType, ChartDevice, ChartSeason, ChartSite, IGetAnalysis } from '../types/analysis.d'
import { AnalysisTabsOptions } from '../views/Analysis/Bees/AnalysisTabs/AnalysisTabs'
import { SensorType } from '../utils/helpers/deviceHelper'
import { Interval } from '../utils/consts/analysis'
import moment from 'moment'
import {
  IncrementValueByTenPercent,
  IncrementValueByTenPercentOneHour,
  renderAnnotation,
  roundToNearestHour,
  roundToNearestHourChart
} from '../utils/helpers/analysisHelper'
import { weekAgoEndDate, weekAgoStartDate } from '../utils/helpers/date'

const useAnalytics = () => {
  const { devices } = useReduxSelector((state: RootState) => state.devices)
  const { facilitiesGroups } = useReduxSelector((state: RootState) => state.facilitiesGroup)
  const { facilities } = useReduxSelector((state: RootState) => state.facilities)
  const { harvests } = useReduxSelector((state: RootState) => state.harvest)
  const { sites } = useReduxSelector((state: RootState) => state.site)
  const [selectedTab, setSelectedTab] = useState(AnalysisTabsOptions.Devices)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [date, setDate] = useState<DateObject[]>([weekAgoStartDate, weekAgoEndDate])
  const [selectedDateType, setSelectedDateType] = useState(DateType.ruler)
  const [selectedRuler, setSelectedRuler] = useState('1W')
  const [selectedDevices, setSelectedDevices] = useState<ChartDevice[]>([])
  const [selectedSeasons, setSelectedSeasons] = useState<ChartSeason[]>([])
  const [selectedSite, setSelectedSite] = useState<ChartSite[]>([])
  const [dailyAggregate, setDailyAggregate] = useState<boolean>(false)
  const [interval, setInterval] = useState<any>(Interval.Hour)

  const handleDateChange = (date: DateObject[], dateType: DateType, value: string) => {
    switch (dateType) {
      case DateType.ruler:
        setSelectedRuler(value)
        setSelectedDateType(DateType.ruler)
        break
      case DateType.picker:
        setSelectedDateType(DateType.picker)
        setSelectedRuler('')
    }
    setDate(JSON.parse(JSON.stringify(date)))
  }

  const handleChange = async (selectedDevices: ChartDevice[]) => setSelectedDevices(selectedDevices)
  const HandleSeasonChange = async (selectedSeasons: ChartSeason[]) => setSelectedSeasons(selectedSeasons)
  const handleAnnotationOneHour = (scheduledEvents: any, chartData: any) => {
    const filteredArray = scheduledEvents?.filter(
      (event: any, i: number, a: any) =>
        a.findIndex(
          (v2: any) => roundToNearestHour(new Date(event?.schedule)).getTime() === roundToNearestHour(new Date(v2?.schedule)).getTime()
        ) === i
    )
    const scheduleDateArray = filteredArray?.map((eventObject: any) => {
      const result = chartData?.filter((item: any) => {
        return item.facilityGroupId === eventObject.facilityGroupId
      })
      const tempResult = result
        .map((item: any) => {
          return item.data
        })
        .flat()
      const finalResult = tempResult?.find((item: any) => {
        return roundToNearestHour(new Date(eventObject?.schedule)).getTime() === roundToNearestHourChart(new Date(item?.x)).getTime()
      })

      return {
        x: eventObject?.schedule,
        y: Number(finalResult?.y)
      }
    })

    const finalArray = scheduleDateArray
      ?.map((values: any) => {
        const schedule = scheduledEvents.filter(
          (event: any) => roundToNearestHour(new Date(event?.schedule)).getTime() === roundToNearestHour(new Date(values?.x)).getTime()
        )

        return IncrementValueByTenPercentOneHour(schedule, chartData, values.y)
      })
      .flat()

    return renderAnnotation(finalArray, {}, false)
  }
  const handleAnnotationOneDay = (scheduledEvents: any, chartData: any) => {
    const filteredArray = scheduledEvents.filter(
      (event: any, i: number, a: any) =>
        a.findIndex((v2: any) => new Date(event.schedule).toDateString() === new Date(v2.schedule).toDateString()) === i
    )
    const scheduleDateArray = filteredArray
      ?.map((event: any) => {
        const result = chartData?.filter((item: any) => {
          return item.facilityGroupId === event.facilityGroupId
        })
        const tempResult = result
          .map((item: any) => {
            return item.data
          })
          .flat()

        const finalResult = tempResult.find((item: any) => {
          return new Date(item?.x).toDateString() === new Date(event?.schedule).toDateString()
        })

        return {
          x: event.schedule,
          y: Number(finalResult?.y)
        }
      })
      .flat()

    const finalArray = scheduleDateArray
      ?.map((values: any) => {
        const schedule = scheduledEvents.filter(
          (event: any) => new Date(event.schedule).toDateString() === new Date(values?.x).toDateString()
        )

        return IncrementValueByTenPercent(schedule, chartData, values.y)
      })
      .flat()
    return renderAnnotation(finalArray, {}, false)
  }

  const getDevicesData = async (devices: any) => {
    const startDate = new Date(Number(date[0])).getTime()
    const endDate = new Date(Number(date[1])).getTime()
    const devicesData = []
    for (let i = 0; i < devices.length; i++) {
      const deviceData = devices[i]
      const analysisInfo: IGetAnalysis = {
        startDate: startDate,
        endDate: endDate,
        sensors: 'weight',
        facilityZoneId: [],
        interval: interval,
        deviceId: deviceData.id
      }
      const serverDeviceDataResponse = await serverGetAnalysis(analysisInfo)
      const _data = serverDeviceDataResponse.map(({ captureTime, weight }: any) => ({ x: captureTime, y: weight }))

      devicesData.push({ id: deviceData.id, data: _data, ...deviceData })
    }
    setData(devicesData)
  }

  const setDevicesData = useCallback(async () => {
    const promiseArray: any[] = []
    const seasonsArray: any[] = []
    const sitesArray: any[] = []

    const startDate = new Date(Number(date[0])).getTime()
    const endDate = new Date(Number(date[1])).getTime()
    const sensors = ['weight', 'temperature', 'batteryChargeLevel'].join(',')

    let deviceIds = selectedDevices?.map(({ id }) => {
      return id
    })
    deviceIds = deviceIds.filter((id, index) => {
      return deviceIds.indexOf(id) === index
    })
    if (selectedTab === AnalysisTabsOptions.Devices) {
      deviceIds?.forEach((deviceId) => {
        if (deviceId) {
          const analysisInfo: IGetAnalysis = {
            startDate: startDate,
            endDate: endDate,
            sensors: sensors,
            facilityZoneId: [],
            interval: interval,
            deviceId: deviceId
          }
          let deviceObject = {
            id: deviceId,
            data: serverGetAnalysis(analysisInfo)
          }
          promiseArray.push(deviceObject)
        }
      })
    } else if (selectedTab === AnalysisTabsOptions.Seasons) {
      selectedSeasons.forEach(({ season }) => {
        const analysisInfo: IGetAnalysis = {
          startDate: new Date(season.startDate).getTime(),
          endDate: season.closeDate ? new Date(season.closeDate).getTime() : new Date().getTime(),
          sensors: ['weight', 'temperature'].join(','),
          facilityGroupId: facilitiesGroups.find((facilityGroup) => season.facilityGroupId === facilityGroup.id)?.id,
          interval: interval,
          facilityZoneId: []
        }
        if (analysisInfo.facilityGroupId) seasonsArray.push(serverGetAnalysis(analysisInfo))
      })
    } else {
      selectedSite.forEach(({ id }) => {
        const analysisInfo: IGetAnalysis = {
          startDate: new Date(Number(date[0])).getTime(),
          endDate: new Date(Number(date[1])).getTime(),
          sensors: ['weight', 'temperature'].join(','),
          facilityZoneId: [],
          interval: interval,
          facilityGroupId: facilitiesGroups.find((facilityGroup) => id === facilityGroup.id)?.id
        }
        if (analysisInfo.facilityGroupId) sitesArray.push(serverGetAnalysis(analysisInfo))
      })
    }

    const getDeviceData = async () => {
      let result: any = []
      for (let i = 0; i < promiseArray.length; i++) {
        const data = await promiseArray[i].data
        const deviceId = promiseArray[i].id

        result.push({ deviceId, data })
      }
      return result
    }
    const datadevice = await getDeviceData()
    setLoading(true)

    const _seasonsData = await Promise.all(seasonsArray)
    const _sitesData = await Promise.all(sitesArray)
    setTimeout(() => setLoading(false), 2000)
    if (selectedTab === AnalysisTabsOptions.Devices) {
      // getDevicesData(selectedDevices)
      const data = selectedDevices
        .map((device, index) => {
          return datadevice?.map(({ deviceId, data }: any) => {
            const deviceName = devices.find((device) => device.id === deviceId)?.name
            if (device.sensor === SensorType.weight && device.id === deviceId) {
              return {
                name: deviceName,
                color: selectedDevices[index].color,
                facilityGroupId: selectedDevices[index].facilityGroupId,
                data: data?.map(({ weight, captureTime }: any) => ({
                  x: new Date(captureTime),
                  y: Number(weight).toFixed(2)
                }))
              }
            }
            if (device.sensor === SensorType.temperature && device.id === deviceId) {
              return {
                name: deviceName,
                color: selectedDevices[index].color,
                facilityGroupId: selectedDevices[index].facilityGroupId,
                data: data?.map(({ temperature, captureTime }: any) => ({
                  x: new Date(captureTime),
                  y: Number(temperature).toFixed(2)
                }))
              }
            }
            if (device.sensor === SensorType.battery && device.id === deviceId) {
              return {
                name: deviceName,
                color: selectedDevices[index].color,
                facilityGroupId: selectedDevices[index].facilityGroupId,
                data: data?.map(({ batteryChargeLevel, captureTime }: any) => ({
                  x: new Date(captureTime),
                  y: Number(batteryChargeLevel).toFixed(2)
                }))
              }
            }
          })
        })
        .flat()
      setData(data.filter((data) => data !== undefined))
    } else if (selectedTab === AnalysisTabsOptions.Seasons) {
      setData(
        _seasonsData.map((series, index) => {
          return {
            name: selectedSeasons[index].season.code,
            color: selectedSeasons[index].color,
            facilityGroupId: selectedSeasons[index].season.facilityGroupId,
            data: series.map(({ weight, captureTime }: any, index: any) => ({
              x: index, //new Date(captureTime),
              y: Number(weight).toFixed(2),
              ss: true
            }))
          }
        })
      )
    }
    if (selectedTab === AnalysisTabsOptions.Sites) {
      setData(
        _sitesData.map((series, index) => {
          return {
            name: selectedSite[index].site.name,
            facilityGroupId: selectedSite[index].site.id,
            color: selectedSite[index].color,
            data: series.map(({ weight, temperature, captureTime }: any) => ({
              x: new Date(captureTime),
              y: Number(weight).toFixed(2)
            }))
          }
        })
      )
    }
  }, [selectedDevices, dailyAggregate, selectedSeasons, selectedSite, date, interval, selectedTab])

  useEffect(() => {
    if (selectedDevices.length > 0 || selectedSeasons.length > 0 || (selectedSite.length > 0 && typeof date[1] === 'number')) {
      setDevicesData()
    } else {
      setData([])
    }
  }, [selectedDevices, dailyAggregate, date, selectedSeasons, selectedSite, interval, selectedTab])

  return {
    handleDateChange,
    handleChange,
    HandleSeasonChange,
    setSelectedTab,
    setDailyAggregate,
    selectedTab,
    selectedRuler,
    selectedDateType,
    facilitiesGroups,
    facilities,
    devices,
    harvests,
    loading,
    data,
    date,
    sites,
    interval,
    setInterval,
    selectedSite,
    setSelectedSite,
    selectedSeasons,
    selectedDevices,
    handleAnnotationOneHour,
    handleAnnotationOneDay
  }
}

export default useAnalytics
