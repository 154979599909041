import { gateWaysConfig, HydroIntelDevicesConfig } from './Devices'
import { HydroIntelConfig } from './GlobleConfig'
import { HYDOSENSORS } from './Sensors'

export const Config = {
  global: HydroIntelConfig,
  devices: HydroIntelDevicesConfig,
  gateWays: gateWaysConfig,
  sensors: HYDOSENSORS,
  harvest: {},
  language: undefined
}
