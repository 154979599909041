import React, { useEffect, useRef, useState } from 'react'

export const useAppSelect = (options?: any[], value?: number[] | number, multiple?: boolean) => {
  const selectWrapperRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [popupHeight, setPopupHeight] = useState(0)
  const [showOptionsPopup, setShowOptionsPopup] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null) // Ref for the search input element
  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleBoxClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setShowOptionsPopup(!showOptionsPopup)
    setTimeout(() => {
      searchInputRef.current?.focus() // Focus on the search input with a slight delay
    }, 10)
  }
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    setSearchQuery(query)

    const filtered = options?.filter((option) => option.name.toLowerCase().includes(query.toLowerCase()))
    setFilteredOptions(filtered)
  }
  useEffect(() => {
    setFilteredOptions(options)
  }, [options])
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target as Node)) {
        setShowOptionsPopup(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (selectWrapperRef.current) {
      const dropdown: any = selectWrapperRef.current.querySelector('.dropdown')
      const height = dropdown ? dropdown.offsetHeight : 0
      setPopupHeight(height)
    }
  }, [showOptionsPopup])
  useEffect(() => {
    if (selectWrapperRef.current) {
      const { top, left, width } = selectWrapperRef.current.getBoundingClientRect()
      const windowWidth = window.innerWidth
      const dialogContainer = document.querySelector('.MuiDialog-container')
      const dialogScrollTop = dialogContainer ? dialogContainer.scrollTop : 0
      const dropdown: any = selectWrapperRef.current.querySelector('.dropdown')
      if (dropdown) {
        setPopupHeight(dropdown.offsetHeight)
      }
      let finalTop
      let finalLeft
      if (top - popupHeight >= 0) {
        if (dialogContainer) {
          // Dropdown opens upwards if there is enough space
          finalTop = top - popupHeight + dialogScrollTop
        } else {
          finalTop = top + selectWrapperRef.current.offsetHeight
        }
      } else {
        // Dropdown opens downwards if there is limited space
        finalTop = top + selectWrapperRef.current.offsetHeight - dialogScrollTop
      }

      if (left + width / 2 <= windowWidth) {
        finalLeft = left
      } else {
        finalLeft = left + width - selectWrapperRef.current.offsetWidth
      }

      setPosition({ top: finalTop, left: finalLeft })
    }
  }, [showOptionsPopup, popupHeight, searchQuery])
  return {
    showOptionsPopup,
    setShowOptionsPopup,
    searchInputRef,
    selectWrapperRef,
    position,
    searchQuery,
    handleBoxClick,
    handleSearchChange,
    setFilteredOptions,
    filteredOptions
  }
}
