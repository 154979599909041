import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton } from '@mui/material'
import CancelButton from '../../components/DialogCancelButton/CancelButton'
import AddEditButton from '../../components/AddEditButton/AddEditButton'
import { AddEventDialogContainer, Form } from './AddDeviceVersionDialog.styled'
import { addDeviceVersionFields, ADD_VERSION } from '../../utils/consts/devicesVersion'
import AppUploadFile from '../../components/AppUploadFile/AppUploadFile'
import useAddDeviceVersion from './useAddDeviceVersion'
import { GetFieldComponent } from '../../utils/helpers/dialog'
import InputDropdown from '../../components/AppInputDropDown/InputDropDown'
import { LabelContainer } from '../../views/ManagerReports/RuminTech/AddReportDetailesDialog/AddReportDialog.styled'
import { FieldTypes } from '../../utils/consts/facility'
import { Fragment } from 'react'
import themes from '../../styles'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  setIsOpen: any
  getDeviceUpdateVersion: any
}

const AddDeviceVersionDialog: React.FC<Props> = ({ open, setIsOpen, getDeviceUpdateVersion }) => {
  const {
    handleSubmit,
    isLoading,
    handleClose,
    eventObject,
    handleFileChange,
    handleInputChange,
    menuItems,
    fieldErrors,
    handleBulletPointClick
  } = useAddDeviceVersion(getDeviceUpdateVersion, setIsOpen)

  const { t } = useTranslation()

  const translatedAddDeviceVersionFields = addDeviceVersionFields.map((e) => ({
    ...e,
    label: t(`device-update.updateDialog.${e.tranlationKey.label}`),
    placeholder: t(`device-update.updateDialog.${e.tranlationKey.placeholder}`)
  }))

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{t('device-update.addVersion')}</DialogTitle>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault()
            handleSubmit(eventObject)
          }}
        >
          <DialogContent dividers={true}>
            <AddEventDialogContainer id="scroll-dialog-description" tabIndex={-1}>
              {translatedAddDeviceVersionFields.map((e) => (
                <Fragment key={e.field}>
                  {e.field === 'releaseNote' && (
                    <div
                      style={{
                        cursor: 'pointer',
                        color: themes().colors.primary,
                        position: 'absolute',
                        right: '2rem',
                        top: '17.5rem',
                        zIndex: 1
                      }}
                      onClick={handleBulletPointClick}
                    >
                      <IconButton edge="end" size="small">
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                  <GetFieldComponent
                    metaData={e}
                    onChangeHandler={handleInputChange}
                    valueObject={eventObject}
                    data={menuItems || []}
                    multiline={e?.multiline}
                    rows={e?.rows}
                    disabled={e.field !== 'deviceType' && eventObject.deviceType === ''}
                  />

                  <FormHelperText error>{fieldErrors[e.field]}</FormHelperText>
                </Fragment>
              ))}
              <AppUploadFile handleFileChange={handleFileChange} />
              <FormHelperText error>{fieldErrors['image']}</FormHelperText>
            </AddEventDialogContainer>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={handleClose} />
            <AddEditButton titleChanged={t('general.submit')} type="submit" loading={isLoading} cy="Submit" isValid={true} />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default AddDeviceVersionDialog
