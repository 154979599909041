import { BeesConfig } from './GlobalConfig'
import { BeesLanguage } from './Language'

export const Config = {
  global: BeesConfig,
  devices: {},
  gateWays: {},
  sensors: {},
  harvest: {},
  language: BeesLanguage
}
