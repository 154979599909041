import React, { ErrorInfo, useCallback, useEffect, useState } from 'react'
import { Form, Modal, Spinner, Card, Row, Col } from 'react-bootstrap'
import { RootState, useReduxSelector } from '../../../redux'
// import { serverGetNotifications } from '../../services/serverApi';
import AppPolygon from '../../../components/AppPolygon/AppPolygon'
import { nofiticationTypeImages } from '../../../assets/images/notificationTypeImages'
import AppTable from '../../../components/AppTable/AppTable'
// import { getDateByIsraelTimeZone } from '../../utils/helpers/date';
import useNotifications from './useNotifications'
import './NotificationDialog.scss'
import { Notification, NotificationHeader, NotificationWrapper } from './NotificationDialog.styled'
import { Box, Dialog, DialogContent } from '@mui/material'
import { materailTableOptions } from './Notification.option'
import themes from '../../../styles'
import { useTranslation } from 'react-i18next'

interface NotificationDialogProps {
  open: boolean
  onClose: any
  onSuccess: () => void
}

const PwNotificationDialog: React.FC<React.PropsWithChildren<NotificationDialogProps>> = ({ open, onClose, onSuccess }) => {
  const tableRef: any = React.createRef()
  const { notificationHeader, notificationActions, onRowClick, getNotificationData } = useNotifications()

  const handleClose = () => {
    onClose?.()
  }
  const { t } = useTranslation()
  const theme = themes()

  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} maxWidth={'xl'} PaperComponent={Box}>
      <DialogContent dividers={true} sx={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden', justifyContent: 'center' }}>
        <Notification>
          <NotificationWrapper>
            <AppPolygon className="badge-notification" backgroundColor={theme.colors.secondary}>
              <img src={nofiticationTypeImages.notificationBellImage} alt="Bell icone" className="notificationBell" />
            </AppPolygon>
            <NotificationHeader>Notifications</NotificationHeader>
          </NotificationWrapper>
          <AppTable
            headers={notificationHeader}
            rows={[]}
            actions={[
              {
                icon: 'refresh',
                tooltip: t('notification.refreshData'),
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange()
              }
            ]}
            options={materailTableOptions}
            handleRowClick={(event: any, rowData: any) => onRowClick(event, rowData, tableRef)}
            title={'Notification'}
            getData={(page: number, pageSize: number, search: string, orderBy: any, orderDirection: string) =>
              getNotificationData(page, pageSize, search, orderBy, orderDirection)
            }
            tableRef={tableRef}
          />
        </Notification>
      </DialogContent>
    </Dialog>
  )
}

export default PwNotificationDialog
