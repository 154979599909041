import { shedIcon } from '../../assets/images/crop'
import { addButtonIcon, BpacBuildingIcon, ShedLogIcon, shedsHouseIcon } from '../../assets/images/sheds'
import { sensorsBpacColor, sensorsBpacImages } from './Sensors'
import { AddDeviceButtonIcon, AddDeviceDialogIcon, AddGatewayButton, AddGatewayDialogIcon } from '../../assets/images/devices/Rumintech'
import { getBpacSidebarData } from '../../components/Sidebar/Bpac/BpacRoutes'
import { AppbarBpacLogo } from '../../assets/images/logo/Bpsc'
import { BpacLogo } from '../../assets/images/login'
import { BpacZoneIcon } from '../../assets/images/farms'

enum EPermissionFeature {
  IOT = 'IOT',
  Heatmap = 'Heatmap',
  Farm = 'Farm',
  Shed = 'Shed',
  Silo = 'Silo',
  Material = 'Material',
  AttachMaterial = 'Attach Material',
  Device = 'Device',
  Crop = 'Crop',
  Event = 'Event',
  Alert = 'Alert',
  User = 'User',
  Customer = 'Customer',
  Role = 'Role',
  Efficiency = 'Efficiency',
  Sensor = 'Sensors',
  Report = 'Reports'
}

export const BpacConfig: any = {
  logo: AppbarBpacLogo,
  sidebar: {
    routeData: getBpacSidebarData()
  },
  logoLogin: BpacLogo,
  title: 'Bpac',
  facilityGroup: {
    name: 'Area',
    PrimaryIcon: BpacZoneIcon,
    SecondaryIcon: ''
  },
  facility: {
    name: 'Building',
    PrimaryIcon: BpacBuildingIcon,
    SecondaryIcon: BpacBuildingIcon,
    WhiteIcon: shedIcon
  },
  facilityZones: {
    name: 'Unit',
    PrimaryIcon: '',
    SecondaryIcon: ''
  },
  device: {
    addGatewayButtonIcon: AddGatewayButton,
    addDeviceButtonIcon: AddDeviceButtonIcon,
    addDeviceDialogIcon: AddDeviceDialogIcon,
    addGatewayDialogIcon: AddGatewayDialogIcon
  },
  shedTable: {
    zoneLogsIcon: ShedLogIcon
  },
  sensors: {
    Images: sensorsBpacImages,
    color: sensorsBpacColor
  },
  material: {
    name: 'Material'
  },
  attachMaterial: {
    name: 'Attach Material'
  },
  EPermissionFeature
}
