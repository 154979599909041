import React, { useState } from 'react'
import { Chip, Popover, List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { ITaskStatus } from '../Dashboard.option'
import DoneIcon from '@mui/icons-material/Done'

type Props = {
  rowData: any
  onMenuItemClicked?: any
}

const TaskStatusChip = (props: Props) => {
  const { rowData, onMenuItemClicked } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const statusMenuItems = [
    {
      id: ITaskStatus.Pending,
      label: 'Pending'
    },
    {
      id: ITaskStatus.inProgress,
      label: 'In Progress'
    },
    {
      id: ITaskStatus.Complete,
      label: 'Complete'
    }
  ]

  return (
    <>
      <Chip
        label={
          rowData.statusTypeId === ITaskStatus.Pending
            ? 'Pending'
            : rowData.statusTypeId === ITaskStatus.Complete
            ? 'Complete'
            : rowData.statusTypeId === ITaskStatus.Overdue
            ? 'Overdue'
            : rowData.statusTypeId === ITaskStatus.inProgress
            ? 'In Progress'
            : ''
        }
        style={{
          backgroundColor:
            rowData.statusTypeId === ITaskStatus.Pending
              ? '#C7CEDA'
              : rowData.statusTypeId === ITaskStatus.Complete
              ? '#43a04726'
              : rowData.statusTypeId === ITaskStatus.Overdue
              ? '#dc702126'
              : rowData.statusTypeId === ITaskStatus.inProgress
              ? '#2d9ae926'
              : ''
        }}
        // {...(false && { onClick: handleClick })} // to disable click
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            minWidth: '150px'
          }
        }}
      >
        <List>
          {statusMenuItems.map((item) => (
            <ListItem
              button
              key={item.id}
              onClick={async () => {
                handleClose()
                await onMenuItemClicked(rowData.id, item.id)
              }}
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <ListItemText primary={item.label} />
              {item.id === rowData.statusTypeId && (
                <ListItemIcon
                  sx={{
                    minWidth: 0
                  }}
                >
                  <DoneIcon fontSize="small" />
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}

export default TaskStatusChip
