import IotSensorImage from './iotSensors.svg'
import ShedImage from './ShedIcon.svg'
import GroupsImage from './Groups.svg'
import MapImage from './MapIcon.svg'
import WaterImage from './WaterIcon.svg'
import AiImage from './Aicon.svg'
import UserImage from './Users.svg'
import ManagerReportImage from './managerReport.svg'
import LogoImage from './Logo.svg'
import myFarm from './myFarm.svg'
import SelectedIotSensorImage from './selected/SelectediotSensors.svg'
import SelectedShedImage from './selected/SelectedShedIcon.svg'
import SelectedGroupsImage from './selected/SelectedGroups.svg'
import SelectedMapImage from './selected/SelectedMapIcon.svg'
import SelectedWaterImage from './selected/SelectedWaterIcon.svg'
import SelectedAiImage from './selected/SelectedAicon.svg'
import SelectedManagerReportImage from './selected/SelectedmanagerReport.svg'
import SelectedmyFarm from './selected/SelectedmyFarm.svg'
import SelectedHerdImage from './selected/SelectedHerdIcon.svg'
import SelectedUserImage from './selected/SelectedUsersIcon.svg'
import ArrowImage from './Arrow.svg'
import closeArrow from './closeArrow.svg'
import openArrow from './openArrow.svg'
import reportImg from './reportIcon.svg'
import reportSelectedImg from './reportSelectedIcon.svg'
import openArrowWhite from './openArrowWhite.svg'

export const IotSensorsIcon = IotSensorImage
export const ShedIcon = ShedImage
export const GroupsIcon = GroupsImage
export const MapIcon = MapImage
export const WaterIcon = WaterImage
export const AiIcon = AiImage
export const ManagerReportIcon = ManagerReportImage
export const RumintechLogo = LogoImage
export const FarmIcon = myFarm
export const ArrowIcon = ArrowImage
export const UserIcon = UserImage
export const SelectedIotSensorsIcon = SelectedIotSensorImage
export const SelectedShedIcon = SelectedShedImage
export const SelectedGroupsIcon = SelectedGroupsImage
export const SelectedMapIcon = SelectedMapImage
export const SelectedWaterIcon = SelectedWaterImage
export const SelectedAiIcon = SelectedAiImage
export const SelectedManagerReportIcon = SelectedManagerReportImage
export const SelectedFarmIcon = SelectedmyFarm
export const SelectedHerdIcon = SelectedHerdImage
export const SelectedUserIcon = SelectedUserImage
export const closeArrowRuminTechIcon = closeArrow
export const openArrowRuminTechIcon = openArrow
export const reportIcon = reportImg
export const reportSelectedIcon = reportSelectedImg
export const openArrowWhiteIcon = openArrowWhite
