import * as React from 'react'
import { HexagonContainer, HexagonNameContainer, HexagonName } from './MapBuildingIcon.styled'

interface MapBuildingIconProps {
  BuildingName: string
  zoom: number
  score: number
}

const MapBuildingIcon: React.FC<MapBuildingIconProps> = ({ BuildingName, zoom, score }) => (
  <HexagonContainer zoom={zoom}>
    <svg width="54" height="124" viewBox="0 0 54 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27 0C12.114 0 0 12.198 0 27.195C0 48.504 24.462 70.506 25.503 71.43C25.932 71.811 26.466 72 27 72C27.534 72 28.068 71.811 28.497 71.433C29.538 70.506 54 48.504 54 27.195C54 12.198 41.886 0 27 0ZM27 42C18.729 42 12 35.271 12 27C12 18.729 18.729 12 27 12C35.271 12 42 18.729 42 27C42 35.271 35.271 42 27 42Z"
        fill="white"
      />
      <circle cx="27.0003" cy="27" r="14.2913" fill="white" stroke="#3E5943" />
      <path d="M27.5938 19.7664L30.8856 21.9163V34.7478H27.5938V19.7664Z" fill="#3E5943" />
      <path
        d="M31.1436 29.0146V34.6804H35.1739V30.3021L31.1436 29.0146ZM32.8452 33.4486L31.6137 33.0902V32.0273L32.8452 32.4742V33.4486ZM32.8452 31.5898L31.6137 31.2311V30.1687L32.8452 30.6162V31.5898ZM34.3902 33.8958L33.1582 33.5379V32.4745L34.3902 32.9222V33.8958ZM34.3902 32.0373L33.1585 31.6791V30.6162L34.3907 31.0631V32.0373H34.3902Z"
        fill="#3E5943"
      />
      <path
        d="M23.1491 22.3191V30.8267L22.5672 31.2069V28.3654L22.1979 28.5181V25.6838L19.5102 26.8703V29.6315L19.2529 29.7382V34.7478H27.2181V19.7217L23.1491 22.3191ZM19.7567 27.411L20.6637 26.9913V28.0828L19.7567 28.4859V27.411ZM19.824 29.18L20.7308 28.7598V29.852L19.824 30.2546V29.18ZM20.7583 33.1011L19.8515 33.5047V32.4293L20.7583 32.0096V33.1011ZM20.7583 31.438L19.8515 31.8419V30.7665L20.7583 30.3471V31.438ZM20.8875 26.8849L21.7946 26.4651V27.5569L20.8875 27.9597V26.8849ZM20.9548 28.6536L21.8617 28.2339V29.3256L20.9548 29.7289V28.6536ZM21.9678 32.581L21.0608 32.9843V31.9095L21.9678 31.4892V32.581ZM21.9678 30.9181L21.0608 31.321V30.2467L21.9678 29.8267V30.9181ZM24.8732 31.1073L23.6188 31.8926V30.7282L24.8732 29.9168V31.1073ZM24.8732 29.1399L23.6188 29.9247V28.7598L24.8732 27.9483V29.1399ZM24.8732 27.172L23.6188 27.9576V26.7924L24.8732 25.9804V27.172ZM24.8732 25.2043L23.6188 25.9889V24.8245L24.8732 24.0125V25.2043ZM24.8732 23.2362L23.6188 24.0212V22.8566L24.8732 22.0449V23.2362ZM26.7318 29.9445L25.2763 30.8553V29.6552L26.7318 28.7133V29.9445ZM26.7318 27.9766L25.2763 28.8876V27.6873L26.7318 26.7449V27.9766ZM26.7318 26.0087L25.2763 26.9197V25.72L26.7318 24.7772V26.0087ZM26.7318 24.0408L25.2763 24.9518V23.7518L26.7318 22.8093V24.0408ZM26.7318 22.0726L25.2763 22.9836V21.7836L26.7318 20.8411V22.0726Z"
        fill="#3E5943"
      />
      <circle cx="27" cy="104" r="19.5" fill="#FFF84C" stroke="white" />
      <text x="27" y="104" fill="black" font-size="20" font-weight="bold" text-anchor="middle" dominant-baseline="middle">
        {score}
      </text>
    </svg>

    <HexagonNameContainer>
      <HexagonName>{BuildingName}</HexagonName>
    </HexagonNameContainer>
  </HexagonContainer>
)

export default MapBuildingIcon
