import {
  // analysisIcon,
  analysisSelectIcon,
  apiIcon,
  apiSelectIcon,
  customerManagmentIcon,
  customerManagmentSelectIcon,
  devicesIcon,
  // iotIcon,
  iotSelectIcon,
  myFarmIcon,
  myFarmSelectIcon,
  naviAnalysisIcon,
  naviApiIcon,
  naviCustomerManagementIcon,
  naviIotIcon,
  naviMyFarmIcon,
  devicesSelectIcon,
  naviTaskManeger,
  naviTaskManegerSelect
} from '../../../assets/images/sideMenu/index'
import { IPermission } from 'permission'
import { INavbar, ISidebarMenu } from 'sidebarMenu'
import { checkPermissions } from '../../../utils/helpers/permissionHelper'
import {
  AiIcon,
  closeArrowRuminTechIcon,
  FarmIcon,
  GroupsIcon,
  IotSensorsIcon,
  ManagerReportIcon,
  MapIcon,
  openArrowRuminTechIcon,
  reportIcon,
  reportSelectedIcon,
  SelectedAiIcon,
  SelectedFarmIcon,
  SelectedGroupsIcon,
  SelectedHerdIcon,
  SelectedIotSensorsIcon,
  SelectedManagerReportIcon,
  SelectedMapIcon,
  SelectedShedIcon,
  SelectedUserIcon,
  SelectedWaterIcon,
  ShedIcon,
  UserIcon,
  WaterIcon
} from '../../../assets/images/sideMenu/RuminTech'
import { InventoryIcon, selectInventoryIcon } from '../../../assets/images/inventory'
import { useTranslation } from 'react-i18next'
import { analysisIcon, analysisSelectedIcon, crmIcon, crmSelectedIcon, farmIcon, farmSelectedIcon, inventoryIcon, inventorySelectedIcon, iotIcon, iotSelectedIcon, taskIcon, taskSelectedIcon } from '../../../assets/images/sidebar/RuminTech'

export const SidebarData: ISidebarMenu[] = [
  {
    title: 'IoT Sensors',
    path: '/Iot',
    image: iotIcon,
    navicon: iotIcon,
    selectImage: iotSelectedIcon,
    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,
    subNav: [
      {
        title: 'General',
        path: '/Iot/General'
      }
    ]
  },
  {
    title: 'CRM',
    path: '/CRM',
    navicon: crmIcon,
    image: crmIcon,
    selectImage: crmSelectedIcon,

    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,

    subNav: [
      {
        title: 'Users',
        path: '/CRM/users'
      },
      {
        title: 'Customer',
        path: '/CRM/customer'
      },
      {
        title: 'Roles',
        path: '/CRM/roles'
      }
    ]
  },
  {
    title: 'My Farm',
    path: '/MyFarm',
    image: farmIcon,
    navicon: farmIcon,
    selectImage: farmSelectedIcon,
    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,
    subNav: [
      {
        title: 'Farms',
        path: '/MyFarm/Farms'
      },
      {
        title: 'Extention',
        path: '/MyFarm/Extensions'
      },
      {
        title: 'Groups',
        path: '/MyFarm/Groups'
      },
      {
        title: 'Animal',
        path: '/MyFarm/Animals'
      },
      {
        title: 'Alert',
        path: '/MyFarm/Alert'
      },
      {
        title: 'Schedule',
        path: '/MyFarm/Schedule'
      }
    ]
  },
  {
    title: 'Devices',
    path: '/Devices',
    image: devicesIcon,
    navicon: naviMyFarmIcon,
    selectImage: devicesSelectIcon,
    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,
    subNav: [
      {
        title: 'Summary',
        path: '/Devices/Summary'
      },
      {
        title: 'Device Version',
        path: '/Devices/device-version'
      }
    ]
  },
  {
    title: 'Inventory',
    path: '/Inventory',
    image: inventoryIcon,
    navicon: inventoryIcon,
    selectImage: inventorySelectedIcon,
    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,
    subNav: [
      {
        title: 'Recipe',
        path: '/Inventory/Recipe'
      },
      {
        title: 'Ingredient',
        path: '/Inventory/Ingredient'
      },
      {
        title: 'Distribution',
        path: '/Inventory/Distribution'
      },
      {
        title: 'Cost',
        path: '/Inventory/Cost'
      }
    ]
  },
  {
    title: 'Analysis',
    path: '/Analysis',
    navicon: analysisIcon,
    image: analysisIcon,
    selectImage: analysisSelectedIcon,

    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,

    subNav: [
      {
        title: 'Graph',
        path: '/Analysis/Graph'
      },
      {
        title: 'Comparison',
        path: '/Analysis/Comparison'
      }
    ]
  },
  {
    title: 'Task Manager',
    path: '/task-manager',
    navicon: taskIcon,
    image: taskIcon,
    selectImage: taskSelectedIcon,

    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,

    subNav: [
      {
        title: 'Dashboard',
        path: '/task-manager/dashboard'
      },
      {
        title: 'Task',
        path: '/task-manager/task'
      },
      {
        title: 'Event',
        path: '/task-manager/event'
      }
    ]
  },
  {
    title: 'API',
    path: '/api',
    navicon: naviApiIcon,
    image: apiIcon,
    selectImage: apiSelectIcon,
    iconClosed: closeArrowRuminTechIcon,
    iconOpened: openArrowRuminTechIcon,
    subNav: [
      {
        title: 'Key',
        path: '/api/key'
      }
    ]
  }
]
export const getSidebarData = (routesdata: ISidebarMenu[], permissions: IPermission[]) => {
  if (permissions.length === 0) {
    return routesdata
  }
  ;[...routesdata].forEach((route: any, index: number) => {
    switch (route.path) {
      case '/ai/charts':
        return true
      case '/Iot/General':
        return checkPermissions('view-iot', permissions)
        break
      case '/Iot/Heatmap':
        return checkPermissions('view-heatmap', permissions)
        break
      case '/MyFarm/Farms':
        return checkPermissions('view-farm', permissions)
        break
      case '/MyFarm/Sheds':
        return checkPermissions('view-shed', permissions)
        break
      case '/MyFarm/Silos':
        return checkPermissions('view-silo', permissions)
        break
      case '/MySite/Sites':
        return checkPermissions('view-site', permissions)
        break
      case '/MySite/Pool':
        return checkPermissions('view-pool', permissions)
        break
      case '/MyFarm/Material':
        return checkPermissions('view-material', permissions)
        break
      case '/Devices/Summary':
        return checkPermissions('view-device', permissions)
        break
      case '/Devices/device-version':
        return checkPermissions('view-device', permissions)
        break
      case '/MySite/Summary':
        return checkPermissions('view-device', permissions)
        break
      case '/MySite/Harvest':
        return checkPermissions('view-crop', permissions)
        break
      case '/MySite/Harvest':
        return checkPermissions('view-crop', permissions)
        break

      case '/MyFarm/Crop':
        return checkPermissions('view-crop', permissions)
        break
      case '/MyFarm/ManualEvents':
        return checkPermissions('view-event', permissions)
        break
      case '/MyFarm/Alert':
        return checkPermissions('view-alert', permissions)
        break

      case '/MySite/Alert':
        return checkPermissions('view-alert', permissions)
        break
      case '/MyFarm/accessKey':
        return checkPermissions('view-material', permissions)
        break
      case '/MyFarm/Controller':
        return true
        break

      case '/customerMgmt/users':
        return checkPermissions('view-user', permissions)
        break
      case '/customerMgmt/customer':
        return checkPermissions('view-customer', permissions)
        break
      case '/customerMgmt/roles':
        return checkPermissions('view-role', permissions)
        break
      case '/Analysis/Efficiency':
        return checkPermissions('view-efficiency', permissions)
        break
      case '/Analysis/Sensors':
        return checkPermissions('view-sensor', permissions)
        break
      case '/Analysis/Comparison':
        return checkPermissions('view-sensor', permissions)
        break
      case '/managerReports/CropSequences':
        return checkPermissions('view-report', permissions)
        break
      case '/ai':
        return true
        break
      case '/api':
        return true
        break
      case '/api/key':
        return true
    }

    // if (route?.subNav.length === 0) {
    //   SidebarData.splice(index, 1)
    // }
  })
  return routesdata
}
