import { GasDevicesConfig } from './Devices'
import { GasConfig } from './GlobleConfig'
import { HARVEST, SENSORS } from './Sensors'

export const Config = {
  global: GasConfig,
  devices: GasDevicesConfig,
  gateWays: {},
  sensors: SENSORS,
  harvest: '',
  language: undefined
}
