import calendar from './calendar.svg'
import fish from './fish.svg'
import fishCouple from './fishCouple.svg'
import fishDead from './fishDead.svg'
import fishDeadFacility from './fishDeadFacility.svg'
import fishFacility from './fishFacility.svg'
import fishFood from './fishFood.svg'
import fishWeight from './fishWeight.svg'
import fishWeightFacility from './fishWeightFacility.svg'
import foodFacility from './foodFacility.svg'
import poolIcon from './pool.svg'
import temperatureIndicator from './temperatureIndicator.svg'
import temperatureScale from './temperatureScale.svg'
import weight from './weight.svg'
import weightFacility from './weightFacility.svg'

//facility images
import facilityCalendar from './facility/calendar.svg'
import facilityDeadFish from './facility/deadFish.svg'
import facilityfishNumber from './facility/fishNumber.svg'
import facilityFishWeight from './facility/fishWeight.svg';
import facilityFood from './facility/food.svg';
import facilityNumOfFish from './facility/numOfFish.svg';
import facilityPool from './facility/pool.svg';
import facilityWeight from './facility/weight.svg';
import facilityOxygen from './facility/oxygen.svg';
import facilityOxygenGradient from './facility/oxygenGradient.svg';
import facilityTarget from './facility/target.svg';
import facilityWarning from './facility/warning.svg';

export const facilityCalendarImage = facilityCalendar;
export const facilityDeadFishImage = facilityDeadFish;
export const facilityfishNumberImage = facilityfishNumber;
export const facilityFishWeightImage = facilityFishWeight;
export const facilityFoodImage = facilityFood;
export const facilityNumOfFishImage = facilityNumOfFish;
export const facilityPoolImage = facilityPool;
export const facilityWeightImage = facilityWeight;
export const facilityOxygenImage = facilityOxygen;
export const facilityOxygenGradientImage = facilityOxygenGradient;
export const facilityTargetImage = facilityTarget;
export const facilityWarningImage = facilityWarning;

export const calendarImage = calendar;
export const fishImage = fish;
export const fishCoupleImage = fishCouple;
export const fishDeadImage = fishDead;
export const fishDeadFacilityImage = fishDeadFacility;
export const fishFacilityImage = fishFacility;
export const fishFoodImage = fishFood;
export const fishWeightImage = fishWeight;
export const fishWeightFacilityImage = fishWeightFacility;
export const foodFacilityImage = foodFacility;
export const pool = poolIcon;
export const temperatureIndicatorImage = temperatureIndicator;
export const temperatureScaleImage = temperatureScale;
export const weightImage = weight;
export const weightFacilityImage = weightFacility;
