import { IHarvest } from 'harvest'
import { IotSiloData } from 'facility'

export enum FacilityType {
  Shed = 1,
  SiloHead = 3,
  Pool = 22
}

interface IFacility {
  extraDetails: string
  createDate: string
  facilityType: any
  facilityTypeId: number
  customerId: number
  height: number
  id: number
  internalZonesCount: number
  lat?: number | null
  length: number
  long: number
  name: string
  captureDate?: Date
  numberOfFeedLines: number
  numberOfWaterLines: number
  tagNumber: number
  width: number
  windowsArea: number
  zones: Array<IZone>
  facilityGroupId?: number
  relatedFacilityId?: number
  targetFacilityId?: number[]
  silos: Array<ISilo>
  harvest?: IHarvest
  weight?: number
  numOfJumps?: number
  temperature?: number
  humidity?: number
  co2Level?: number
  thi?: number
  waterTemperature?: number
  lux?: number
  airPressure?: number
  address?: string | null
  isRowOrder?:boolean
  avgWeight?: number
  avgTemperature?: number
  avgHumidity?: number
  avgCo2Level?: number
  avgThi?: number
  avgWaterTemperature?: number
  avgLux?: number
  avgAirPressure?: number
  feedWeight?: number
  totalDeaths?: number
  isBreeders?: boolean
}

interface ISilo {
  id?: number
  createDate?: string
  facilityGroupId?: number
  facilityTypeId: number
  facilityZoneId?: number
  width?: number
  height?: number
  lat?: number
  long?: number
  name?: string
  windowsArea?: number
  numberOfFeedLines?: number
  numberOfWaterLines?: number
  length?: number
  deactivationDate?: string
  numberOfSilos?: number
  parentFacilityId?: number
  tagNumber?: number
  extraDetails?: number
  address?: string
  zoneIndex?: number
  zoneName?: string
  siloName?: string
  type?: string
  manufacturer?: string
  coneHeight?: number
  cylinderHeight: number
  diametere?: number
  coneVolume?: number
  cylinderVolume?: number
  totalVolume?: number
  weight?: number
  iotSiloData: IotSiloData[]
}
interface IZone {
  id: number
  facilityId: number
  zoneIndex: number
  zoneTypeId: number
  iotEvent?: any
  name?: string
}
interface IEnvData {
  label: string
  code: string
  image: any
  current?: number
  target?: number
  unit: string
  metricTypeId: number
  zones?: IZone[]
  hidden?: boolean
}
