export interface IPostCustomer {
  name: string
  country: string
  email: string
  code: string
  address?: string
}

export interface ICustomer {
  id: number
  name: string
  typeId: number
  activeFrom?: Date
  activeTill?: Date
  createDate: Date
  numberOfAllowedFarms?: number
  numberOfAllowedUsers?: number
  creatorCustomerId: number
  contactUserId?: number
  isDeleted?: boolean
  phone: string
  email: string
  code: string
  emailTemplate?: string
  fax?: string
  address: string
  zipCode?: string
  country: string
  logoImage?: any
  userEmail?: string
  paymentId?: string | null
}

export enum CustomerTypes {
  residential = 'Business',
  Business = 'Residential'
}

export enum CustomerSubType {
  Agriculture = 17,
  Industry,
  Institutional,
  Restaurant,
  House,
  Apartment
}
export enum Customer {
  zeus = 2
}

export enum PiticCustomer {
  PititcId = 1320,
  PoultryId = 1206
}
