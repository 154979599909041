import { AvatarGroup, Backdrop, CircularProgress, IconButton, TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import AddEditButton from '../../../../components/AddEditButton/AddEditButton'
import SearchIcon from '@mui/icons-material/Search'
import { FieldTypes } from '../../../../utils/consts/facility'
import { GetFieldComponent } from '../../../../utils/helpers/dialog'
import AddTaskDialog from '../../AddTask'
import {
  CalendarWrapper,
  ContentWrapper,
  DayDetailsHeading,
  DayDetailsSubHeading,
  DayTasksWrapper,
  Header,
  LableContainer,
  TaskPageHeader
} from './DayWiseTask.styled'
import useDayWiseTask from './useDayWiseTask'
import { Avatar, Tooltip } from '@material-ui/core'
import TaskList from './TaskList'
import { stringAvatar } from '../../../../utils/helpers/taskmanagement'
import DatePickerMUI from '../../../DatePickerMUI'
import { ITaskStatus } from '../../Dashboard/Dashboard.option'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

type Props = {}

const DayWiseTask = (props: Props) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const day = searchParams.get('day')
  const {
    open,
    setOpen,
    users,
    date,
    setDate,
    isLoading,
    setIsLoading,
    typeList,
    onMenuItemClicked,
    statusTypes,
    handlerClear,
    handleInputChange,
    headerInputValue,
    filteredTaskList,
    getData
  } = useDayWiseTask(day)

  const maxAvatars = 6
  const navigate = useNavigate()

  const { t, i18n } = useTranslation()

  return (
    <>
      <Header>
        <AddEditButton
          onClick={() => {
            navigate('/task-manager/task')
          }}
          style={{
            height: '40px',
            width: '120px',
            fontSize: '14px'
          }}
          titleChanged={t('general.back')}
        />
        <TaskPageHeader>
          <AvatarGroup
            max={maxAvatars}
            sx={{
              '& .MuiAvatar-root': { width: 34, height: 34, fontSize: 15, alignSelf: 'center' }
            }}
          >
            {users.map((user: any) => (
              <Tooltip title={user.name} key={user?.id}>
                <Avatar {...stringAvatar(user.name)} />
              </Tooltip>
            ))}
          </AvatarGroup>
          <LableContainer>
            <GetFieldComponent
              metaData={{
                label: 'Status',
                field: 'statusTypeId',
                variant: null,
                isUpdateVisible: true,
                isUpdateDisabled: false,
                isValidCheck: true,
                type: FieldTypes.DropDown
              }}
              handleClear={() => {
                handlerClear()
              }}
              hideLabel={true}
              onChangeHandler={handleInputChange}
              valueObject={headerInputValue}
              data={statusTypes}
              defaultValue={ITaskStatus.All}
            />
          </LableContainer>
          <TextField
            placeholder={`${t('task-manager.searchPlaceholder')}`}
            variant="outlined"
            name="search"
            value={headerInputValue.search}
            style={{
              backgroundColor: '#fff'
            }}
            onChange={handleInputChange}
            onKeyPress={() => {}}
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => {}} edge="end">
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
          <AddEditButton
            onClick={() => {
              setOpen(true)
            }}
            style={{
              height: '40px',
              width: '120px',
              fontSize: '14px'
            }}
            titleChanged={t('task-manager.addTask')}
          />
        </TaskPageHeader>
      </Header>

      <ContentWrapper>
        <DayTasksWrapper>
          <DayDetailsHeading>{date?.format('MMMM') || ''}</DayDetailsHeading>
          <DayDetailsSubHeading>{date?.format('dddd, MMM D, YYYY') || ''}</DayDetailsSubHeading>
          <TaskList taskList={filteredTaskList} types={typeList} onMenuItemClicked={onMenuItemClicked} />
        </DayTasksWrapper>
        <CalendarWrapper>
          <DatePickerMUI
            val={date}
            onDateChangeHandler={(newValue: any) => {
              // setDate(newValue)
              setDate(dayjs(newValue).locale(i18n.language))
              getData(newValue)
            }}
          />
        </CalendarWrapper>
      </ContentWrapper> 
      <AddTaskDialog open={open} setIsOpen={setOpen} getTaskList={() => {}} editObject={{}} resetForm={() => {}} />
      <Backdrop sx={{ color: '#fff', zIndex: 1000 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default DayWiseTask
