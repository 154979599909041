export const DatePickerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '14px',
  '.MuiDayCalendar-header': {
    justifyContent: 'space-around'
  },
  '.MuiDayCalendar-weekContainer': {
    justifyContent: 'space-around'
  },
  '.MuiDayCalendar-weekDayLabel': {
    fontSize: '14px',
    fontWeight: '600',
    color: '#000000'
  },
  '.MuiPickersCalendarHeader-label': {
    fontSize: '18px',
    color: '#000000',
    fontWeight: '600'
  },
  '.MuiPickersCalendarHeader-root': {
    marginBottom: '40px'
  }
}
