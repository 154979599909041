import styled from 'styled-components'

export const BuildingMapContainer = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  grid-gap: ${({ theme }) => theme.spacing.md};
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: ${({ theme }) => theme.spacing.lg};
  padding: ${({ theme }) => theme.spacing.md};
`

export const DeviceMapeHeading = styled.span`
  margin-left: 7px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;

  /* Dark */

  color: #062a41;
`
export const BuildingDesWrapper = styled.div`
  color: black;
`
export const MapContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 0.01fr auto;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: ${({ theme }) => theme.spacing.lg};
  margin-bottom: 30px;
`
export const BuildingListContainer = styled.div`
  height: 70%;
  overflow: auto;
  padding-bottom: 30px;

  ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.typography.colors.white};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.typography.colors.secondary};
    border: none;
  }
`
export const BuildingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  background-color: white;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};
`
export const List = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
`
export const AdressHeading = styled.span<{ selected: boolean }>`
  text-overflow: ellipsis;
  font-size: 13px;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
`
export const ApartmentHeading = styled.span<{ selected: boolean }>`
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 700;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
`
export const InnerListContainer = styled.div<{ selected: boolean }>`
  height: 90px;
  display: grid;
  background-color: ${({ selected }) => (selected ? '#3E5943' : '#ebf3eb')};
  grid-template-columns: 0.2fr 0.8fr;
  border-radius: ${({ theme }) => theme.spacing.xs};
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ImageInnerContainer = styled.div`
  background-color: #d6ead8;
  width: 70%;
  height: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing.xs};
`

export const BuildingImage = styled.img`
  width: 30px;
  height: 30px;
`
export const DecContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`
