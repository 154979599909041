import { IMonitoredGroup } from 'monitoredGroup'
import { point } from '../../components/AppChart/AppChart'
import { ChartValues } from '../../views/Analysis/PoultryWeights/useAnalysis'
import { EVENTSTAGS } from '../consts/analysis'
import { IFacility } from 'facility'

export const getDataForChart = (events: any) => {
  if (!Array.isArray(events)) return []
  const firstEvent = events?.[0]

  const points = [...events].map(({ captureDate, weight }) => {
    return { x: captureDate, y: weight } as point
  })

  return [{ name: firstEvent?.name, data: points }]
}

// Helper
const StringIsNumber = (value: any) => isNaN(Number(value)) === false

// Turn enum into array

export const ToArray = (enumme: any) => {
  if (!enumme || typeof enumme !== 'object') {
    return []
  }
  return Object.keys(enumme)
    .filter(StringIsNumber)
    .map((key) => enumme[key])
}

// get roundToNearestHour

export function roundToNearestHour(date: Date) {
  date.setMinutes(date.getMinutes() + 30)
  date.setMinutes(0, 0, 0)

  return date
}
export function roundToNearestHourChart(date: Date) {
  date.setMinutes(date.getMinutes() + 120)
  date.setMinutes(0, 0, 0)

  return new Date(date)
}

export const aggregateOneHourData = (ChartValues: ChartValues[]) => {
  // console.log('aggregateOneHourData', ChartValues)
  let hourTempValues: any[] = []
  let aggregetedValues: any[] = []
  let currentDate = new Date(ChartValues[0]?.x)
  let currentDayData: ChartValues[] = []
  let currentHour = new Date(ChartValues[0]?.x)
  let currentHourData: ChartValues[] = []
  ChartValues.forEach((item: ChartValues) => {
    if (new Date(item.x).getDate() === currentDate.getDate()) {
      currentDayData.push(item)
      if (new Date(item.x).getHours() === currentHour.getHours()) {
        currentHourData.push(item)
        hourTempValues.push(item)
      } else {
        let avgWeight = currentHourData.map((item: ChartValues) => item.y).reduce((a, b) => a + b, 0) / currentHourData.length
        aggregetedValues.push({
          y: avgWeight,
          x: new Date(currentHour)
        })
        hourTempValues = []
        currentHour = new Date(item.x)
        currentHourData = []
        currentHourData.push(item)
      }
    } else {
      currentDate = new Date(item.x)
      currentDayData = []
      currentDayData.push(item)
      currentHour = new Date(item.x)
      currentHourData = []
      currentHourData.push(item)
    }
  })
  return aggregetedValues
}

export const aggregateOneDayTotalChicksWeigth = (TotalChicksData: ChartValues[], chicksWeightData: ChartValues[]) => {
  let aggregetedValues: ChartValues[] = []
  TotalChicksData.forEach((item: ChartValues) => {
    chicksWeightData.forEach((item2: ChartValues) => {
      if (new Date(item.x).getTime() === new Date(item2.x).getTime()) {
        aggregetedValues.push({
          y: (Number(item.y) * Number(item2.y)) / 1000,
          x: item.x
        })
      }
    })
  })
  return aggregetedValues
}

export const aggregateOneDayData = (ChartValues: ChartValues[]) => {
  let tempValues: ChartValues[] = []
  let aggregetedValues: any[] = []
  let currentDate = new Date(ChartValues[0]?.x).toDateString()
  let currentDayData: ChartValues[] = []
  ChartValues.forEach((item: ChartValues) => {
    if (new Date(item.x).toDateString() === currentDate) {
      currentDayData.push(item)
      tempValues.push(item)
    } else {
      let avgWeight = currentDayData.map((item: ChartValues) => item.y).reduce((a: number, b: number) => a + b, 0) / currentDayData.length
      aggregetedValues.push({
        y: avgWeight,
        x: currentDate
      })
      tempValues = []
      currentDate = new Date(item.x).toDateString()
      currentDayData = []
      currentDayData.push(item)
    }
  })
  return aggregetedValues
}

export const aggregateOneDayChicksData = (ChartValues: ChartValues[]) => {
  let tempValues: ChartValues[] = []
  let aggregetedValues: any[] = []
  let currentDate = new Date(ChartValues[0]?.x).toDateString()
  let currentDayData: ChartValues[] = []
  ChartValues.forEach((item: ChartValues) => {
    // aggregated values for each day
    if (new Date(item.x).toDateString() === currentDate) {
      currentDayData.push(item)
      tempValues.push(item)
    } else {
      // aggregated total chicks for each day
      let totalChicks = currentDayData.map((item: ChartValues) => item.y).reduce((a: number, b: number) => a + b, 0)
      aggregetedValues.push({
        y: totalChicks,
        x: currentDate
      })
      tempValues = []
      currentDate = new Date(item.x).toDateString()
      currentDayData = []
      currentDayData.push(item)
    }
  })
  return aggregetedValues
}

export const calculateConsumption = (data: any[]) => {
  const Cunsumptiondata: any[] = []
  data.forEach((item: ChartValues, index: number) => {
    let difference = 0
    if (index > 0) {
      difference = item.y - data[index - 1].y
    }
    Cunsumptiondata.push({
      y: difference < 0 ? 0 : difference,
      x: item.x
    })
  })
  return Cunsumptiondata
}

export const renderTag = (tag: string) => {
  switch (tag) {
    case EVENTSTAGS.FloorRemoved:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="A" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">A</tspan></text>
    </svg>
    `
    case EVENTSTAGS.HarvestingAdar:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="B" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">B</tspan></text>
    </svg>
    `

    case EVENTSTAGS.FloorAdded:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="C" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">C</tspan></text>
    </svg>
    `
    case EVENTSTAGS.VarroaTreatment:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="D" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">D</tspan></text>
    </svg>
    `
    case EVENTSTAGS.QueenAdd:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="E" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">E</tspan></text>
    </svg>
    `
    case EVENTSTAGS.FertilizationInspection:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="F" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">F</tspan></text>
    </svg>
    `
    case EVENTSTAGS.Smoke:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="G" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">G</tspan></text>
    </svg>
    `
    case EVENTSTAGS.WaterFilling:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="H" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">H</tspan></text>
    </svg>
    `
    case EVENTSTAGS.Feeding:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="I" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-7.055" y="0">I</tspan></text>
    </svg>
    `
    case EVENTSTAGS.Pesticides:
      return `<svg id="Group_6338" data-name="Group 6338" xmlns="http://www.w3.org/2000/svg" width="39.25" height="64.35" viewBox="0 0 39.25 64.35">
      <path id="Path_4252" data-name="Path 4252" d="M-13345.662-6749.862v-32.827" transform="translate(13365.287 6814.211)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Polygon_339" data-name="Polygon 339" transform="translate(0 0)" fill="none">
        <path d="M27.093,0a4,4,0,0,1,3.49,2.046l7.573,13.522a4,4,0,0,1,0,3.909L30.583,33a4,4,0,0,1-3.49,2.046H12.157A4,4,0,0,1,8.667,33L1.094,19.477a4,4,0,0,1,0-3.909L8.667,2.046A4,4,0,0,1,12.157,0Z" stroke="none"/>
        <path d="M 12.15701866149902 1 C 11.07240867614746 1 10.06942939758301 1.587871551513672 9.539499282836914 2.534191131591797 L 1.96697998046875 16.05657005310059 C 1.460659027099609 16.96072006225586 1.460659027099609 18.08404159545898 1.96697998046875 18.98819160461426 L 9.539499282836914 32.51057052612305 C 10.06942939758301 33.45689010620117 11.0723991394043 34.04476165771484 12.15700912475586 34.04476165771484 L 27.09304809570312 34.04476165771484 C 28.17764854431152 34.04476165771484 29.18061828613281 33.45689010620117 29.7105598449707 32.51057052612305 L 37.28307723999023 18.98819160461426 C 37.78939819335938 18.08404159545898 37.78939819335938 16.96072006225586 37.28307723999023 16.05657005310059 L 29.7105598449707 2.534191131591797 C 29.18061828613281 1.587871551513672 28.17764854431152 1 27.09304809570312 1 L 12.15701866149902 1 M 12.15701866149902 0 L 27.09304809570312 0 C 28.54085922241211 0 29.87565994262695 0.7823600769042969 30.58306884765625 2.045589447021484 L 38.15559005737305 15.56797027587891 C 38.8355598449707 16.78220176696777 38.8355598449707 18.26255989074707 38.15559005737305 19.47679138183594 L 30.58306884765625 32.99917221069336 C 29.87565994262695 34.26240158081055 28.54085922241211 35.04476165771484 27.09304809570312 35.04476165771484 L 12.15700912475586 35.04476165771484 C 10.70919799804688 35.04476165771484 9.374387741088867 34.26240158081055 8.666988372802734 32.99917221069336 L 1.094467163085938 19.47679138183594 C 0.4144973754882812 18.26255989074707 0.4144973754882812 16.78220176696777 1.094467163085938 15.56797027587891 L 8.666988372802734 2.045589447021484 C 9.374387741088867 0.7823600769042969 10.70919799804688 0 12.15701866149902 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <g id="Polygon_340" data-name="Polygon 340" transform="translate(0 0)" fill="#21ffac">
        <path d="M 28.26529121398926 34.54476165771484 L 10.98476028442383 34.54476165771484 C 10.44246006011963 34.54476165771484 9.940970420837402 34.25083160400391 9.676000595092773 33.77767181396484 L 0.9834805130958557 18.25528144836426 C 0.7303305268287659 17.8032112121582 0.7303305268287659 17.24155044555664 0.983490526676178 16.78947067260742 L 9.676000595092773 1.267090797424316 C 9.940980911254883 0.7939308285713196 10.44246006011963 0.5000008344650269 10.98476028442383 0.5000008344650269 L 28.26529121398926 0.5000008344650269 C 28.80759048461914 0.5000008344650269 29.30908012390137 0.7939308285713196 29.57406044006348 1.267100811004639 L 38.26657104492188 16.78947067260742 C 38.51972961425781 17.24155044555664 38.51972961425781 17.8032112121582 38.26657104492188 18.25529098510742 L 29.57406044006348 33.77767181396484 C 29.30908012390137 34.25083160400391 28.80759048461914 34.54476165771484 28.26529121398926 34.54476165771484 Z" stroke="none"/>
        <path d="M 10.98476028442383 1 C 10.62322998046875 1 10.28890991210938 1.195960998535156 10.11226081848145 1.511402130126953 L 1.41973876953125 17.03377151489258 C 1.250968933105469 17.33515167236328 1.250968933105469 17.7096004486084 1.41973876953125 18.0109806060791 L 10.11226081848145 33.53337097167969 C 10.28890037536621 33.84881210327148 10.62322044372559 34.04476165771484 10.98476028442383 34.04476165771484 L 28.26528930664062 34.04476165771484 C 28.6268310546875 34.04476165771484 28.96115112304688 33.84881210327148 29.13779067993164 33.53337097167969 L 37.830322265625 18.0109806060791 C 37.99909210205078 17.7096004486084 37.99909210205078 17.33516120910645 37.8303108215332 17.03377151489258 L 29.13779067993164 1.511390686035156 C 28.96115112304688 1.195949554443359 28.6268310546875 1 28.26528930664062 1 L 10.98476028442383 1 M 10.98476028442383 0 L 28.26528930664062 0 C 28.98920059204102 0 29.65660095214844 0.3911819458007812 30.01029968261719 1.022789001464844 L 38.70281982421875 16.5451717376709 C 39.04281234741211 17.15229034423828 39.04281234741211 17.89247131347656 38.70281982421875 18.49958992004395 L 30.01029968261719 34.02197265625 C 29.65660095214844 34.65357971191406 28.98920059204102 35.04476165771484 28.26528930664062 35.04476165771484 L 10.98476028442383 35.04476165771484 C 10.26085090637207 35.04476165771484 9.593450546264648 34.65357971191406 9.239749908447266 34.02197265625 L 0.5472297668457031 18.49958038330078 C 0.2072486877441406 17.89247131347656 0.2072486877441406 17.15229034423828 0.5472297668457031 16.5451717376709 L 9.239749908447266 1.022800445556641 C 9.593460083007812 0.3911819458007812 10.26086044311523 0 10.98476028442383 0 Z" stroke="none" fill="#7b7b7b"/>
      </g>
      <text id="J" transform="translate(19.624 25.522)" fill="#707070" font-size="21" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="-3.055" y="0">J</tspan></text>
    </svg>
    `
    default:
      return null
  }
}

export const IncrementValueByTenPercent = (schedule: any, chartData: any, yValue: number) => {
  let tempArray: any[] = []
  schedule?.map((event: any) => {
    const tempResult = chartData?.filter((item: any) => {
      return item.facilityGroupId === event.facilityGroupId
    })
    const result = tempResult
      .map((item: any) => {
        return item.data
      })
      .flat()
    result?.map((item: any) => {
      if (new Date(event.schedule).toDateString() === new Date(item.x).toDateString()) {
        tempArray.push({
          x: item.x,
          y: yValue,
          eventTypeName: event.eventTypeName,
          eventTag: event.eventTag
        })
      }
    })
    yValue = yValue + yValue / 10
  })
  return tempArray
}
export const IncrementValueByTenPercentOneHour = (schedule: any, chartData: any, yValue: number) => {
  let tempArray: any[] = []
  schedule?.map((event: any) => {
    const tempResult = chartData?.filter((item: any) => {
      return item.facilityGroupId === event.facilityGroupId
    })
    const result = tempResult
      .map((item: any) => {
        return item.data
      })
      .flat()
    result?.map((item: any) => {
      if (
        roundToNearestHour(new Date(event?.schedule)).getTime() ===
        roundToNearestHourChart(new Date(new Date(item?.x).toISOString())).getTime()
      ) {
        tempArray.push({
          x: item.x,
          y: yValue,
          eventTypeName: event.eventTypeName,
          eventTag: event.eventTag
        })
      }
    })
    yValue = yValue + yValue / 10
  })
  return tempArray
}
export const renderAnnotation = (data: any, event: any, hour: boolean) => {
  return data?.map((item: any) => {
    return {
      x: item?.x,
      y: item?.y,
      marker: {
        size: 0
      },
      label: {
        borderColor: '#FF4560',
        offsetY: -70,
        text: hour ? event?.eventTypeName : item?.eventTypeName,
        style: {
          color: '#fff',
          background: '#FF4560'
        }
      },

      customSVG: {
        SVG: renderTag(hour ? event?.eventTag : item?.eventTag),
        cssClass: undefined,
        offsetX: -20,
        offsetY: -65
      }
    }
  })
}
export const convertHexToRGBA = (hexCode: any, opacity: any) => {
  let hex = hexCode?.replace('#', '')

  if (hex?.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex?.substring(0, 2), 16)
  const g = parseInt(hex?.substring(2, 4), 16)
  const b = parseInt(hex?.substring(4, 6), 16)

  const color = `rgba(${r},${g},${b},${opacity})`
  return color
}
export const colorPallete = [
  '#9F2BC1',
  '#00AC61',
  '#800000',
  '#FF9347',
  '#538FFB',
  '#FD297A',
  '#9424F0',
  '#FF4331',
  '#D31A50',
  '#DB0B5F',
  '#6F00ED',
  '#E1A2B8',
  '#00BC40',
  '#4C3AE3'
]
export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
}
export const filterFacilityByFacilityGroupIds = (facilityGroupIds: number[], facilities: IFacility[]) => {
  const filteredFacilitiesIds = facilities
    ?.filter((facility) => facility.facilityGroupId && facilityGroupIds.includes(facility.facilityGroupId))
    .map((facility) => facility.id)
  return filteredFacilitiesIds
}
export const filteredMonitoredGroupIdsByFacilityIds = (filteredFacilitiesIds: number[], monitoredGroup: IMonitoredGroup[]) => {
  const filteredMonitoredGroupIds = monitoredGroup
    ?.filter((Group: any) => filteredFacilitiesIds?.includes(Group.facilityId))
    .map((Group: IMonitoredGroup) => Group.id)
  return filteredMonitoredGroupIds
}

export const filterFacilityGroupIdsByFacilityIds = (facilityIds: number[], facilities: IFacility[]) => {
  const facilityGroupIds = facilities
    .filter((facility: any) => facilityIds?.includes(facility.id))
    .map((facility: IFacility) => facility.facilityGroupId)

  const uniquefacilityGroupIds = facilityGroupIds.filter((value, index, self) => {
    return self.indexOf(value) === index
  })

  return uniquefacilityGroupIds ?? []
}

export const filterFacilityByMonitoredGroupId = (monitoredGroupIds: number[], monitoredGroup: IMonitoredGroup[]) => {
  const uniqueFacilityIdsSet = new Set<number>()

  monitoredGroup
    ?.filter((Group) => monitoredGroupIds?.includes(Group.id) && Group.facilityId)
    .forEach((Group: any) => {
      uniqueFacilityIdsSet.add(Group.facilityId)
    })

  const filteredExtensionIds = Array.from(uniqueFacilityIdsSet)
  return filteredExtensionIds
}
