import { Interface } from 'readline'

export const DashboardOptions = {
  maxBodyHeight: 'calc(100vh - 430px)',
  minBodyHeight: 'calc(100vh - 430px)',
  pageSize: 10,
  search: true,
  toolbar: true,
  columnsButton: true,
  exportButton: true,
  exportAllData: true,
  sorting: true,
  pageSizeOptions: [2, 5, 10, 20],
  showTextRowsSelected: false,
  showFirstLastPageButtons: false,
  paging: true,
  showTitle: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
  rowStyle: {
    color: '#7B7B7B',
    whiteSpace: 'noWrap'
  },
  headerStyle: {
    color: '#7B7B7B',
    fontWeight: '600'
  }
}

export enum ITaskStatus {
  'Complete' = 1,
  'Pending' = 2,
  'Overdue' = 3,
  'inProgress' = 4,
  'All' = 5
}

export enum ITabDashboard {
  'All' = 0,
  'Overdue' = 1
}
