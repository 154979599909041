import * as React from 'react'
import { SVGProps } from 'react'
import { BeesTheme } from '../../../../../styles/Bees/theme'

interface SiteTabProps {
  selected: boolean
}
const SiteTab: React.FC<SiteTabProps> = ({ selected }) => {
  const fill = selected ? 'white' : BeesTheme.colors.primary
  return (
    <svg transform="scale(0.7)" xmlns="http://www.w3.org/2000/svg" width={42} height={42}>
      <g data-name="Group 6413">
        <g data-name="Group 6359" fill={fill}>
          <path
            data-name="Subtraction 2"
            d="M5.767 21.001 0 17.699V11.09l4.464-2.558.236 1.314-2.291 1.314v3.848l3.358 1.922 3.355-1.922V11.16L6.7 9.769l.234-1.314 4.6 2.635v6.609l-5.767 3.3Z"
          />
          <path data-name="Polygon 348" d="M5.907 11.745 5 4.903h1.814Z" />
        </g>
        <g data-name="Group 6358" fill={fill}>
          <path
            data-name="Subtraction 2"
            d="M32.526 21.003 25 16.658v-8.69l5.826-3.365.31 1.728-2.992 1.729v5.059l4.382 2.527 4.374-2.527V8.06l-3.162-1.829.31-1.728 6 3.465v8.69l-7.522 4.345Z"
          />
          <path data-name="Polygon 348" d="M32.51 10.633 31 0h3.02Z" />
        </g>
        <g data-name="Group 6018">
          <path
            data-name="Subtraction 2"
            d="M19.122 43.301 8.284 37.043v-12.51l8.39-4.844.447 2.488-4.309 2.488v7.286l6.31 3.639 6.3-3.639v-7.286l-4.554-2.634.446-2.488 8.645 4.99v12.51l-10.837 6.258Z"
            fill={fill}
          />
        </g>
        <path data-name="Path 4342" d="M32.525 11.878 19.167 30.411 5.784 11.878 19.167 24.57l13.358-14.565Z" fill={fill} />
        <path data-name="Polygon 389" d="m18.982 28.37-2.895-18.194h5.789Z" fill={fill} />
      </g>
    </svg>
  )
}
export default SiteTab
