import moment from 'moment-timezone'
import { toDateObject } from 'react-multi-date-picker'
import { DefaultTheme } from 'styled-components'
import themes from '../../styles'
import { DataModel } from '../../types/dataModel.d'
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers'
import { Interval } from '../consts/analysis'

//Get the current date according to israel timezone.
export const DATE_ONE_SEC = 1000
export const DATE_ONE_MIN = DATE_ONE_SEC * 60
export const DATE_ONE_HOUR = DATE_ONE_MIN * 60
export const DATE_ONE_DAY = DATE_ONE_HOUR * 24
export const DATE_ONE_WEEK = DATE_ONE_DAY * 7
export const DATE_ONE_MINUTE = 1000 * 60

export const START_DATE_TITLE = 'Start Date'
export const END_DATE_TITLE = 'End Date'

export const MiliSecondsToMin = (miliSceonds: number) => {
  return miliSceonds / (1000 * 60)
}

export const weekAgoStartDate = JSON.parse(JSON.stringify(toDateObject(moment().subtract(1, 'week').toDate()).toDate().getTime()))
export const oneMonthAgoDate = JSON.parse(JSON.stringify(toDateObject(moment().subtract(1, 'month').toDate()).toDate().getTime()))

export const weekAgoEndDate = JSON.parse(JSON.stringify(toDateObject(moment().toDate()).toDate().getTime()))

export const getDateByIsraelTimeZone = (date: Date | string) => {
  if (!date) return 'Never'
  return moment(date).tz('Israel').format('YYYY-MM-DD HH:mm:ss')
}

export const getDateByLocalTimeZone = (date: Date) => {
  if (!date) return 'Never'
  return moment(date).format('YYYY-MM-DD - HH:mm:ss')
}

export const getWeekAgoDate = () => {
  const date = new Date()
  return moment(date).tz('Israel').subtract(7, 'd').toDate()
}

export const getFormatStandardDate = (date: Date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const getDateFormat = (date: Date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getDateFormatLikeMuiInput = (date: Date) => {
  const isoString = new Date(date).toISOString()
  const formattedString = `${isoString.slice(0, 10)}T${isoString.slice(11, 16)}`
  return formattedString
}

export const getReadableDate = (date?: Date, withSecs?: boolean, withTime?: boolean) => {
  if (withSecs) {
    return moment(date).format('YYYY-MM-DD - HH:mm:ss')
  } else if (withTime) {
    return moment(date).format('YYYY-MM-DD')
  }
  return moment(date).format('YYYY-MM-DD - HH:mm')
}

export const getStringDate = (date?: Date) => {
  const event = date ? new Date(date) : new Date()
  const options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  return event.toLocaleDateString(undefined, options)
}
export const formatDateToCustomFormat = (dateObj: Date | number, roundtoHour: boolean = false) => {
  let date = new Date(dateObj)
  if (roundtoHour) {
    // Round the date to the nearest hour
    date = new Date(Math.round(date.getTime() / (60 * 60 * 1000)) * (60 * 60 * 1000))
  }
  return moment(date).format('MMMM DD, YYYY, h:mm A')
}

export const getStringDateFromSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

export const getDateAfterDays = (date: Date, days: number) => {
  return date.setDate(date.getDate() + days)
}

export const getDateBeforeDays = (date: Date, days: number) => {
  return date.setDate(date.getDate() - days)
}

export const getMaterialDesignDateFormat = (date: Date) => {
  const d = new Date(date)
  let day: string | number = d.getDate()
  let month: string | number = d.getMonth()
  const year = d.getFullYear()
  let hours: string | number = d.getHours()
  let min: string | number = d.getMinutes()

  if (day < 10) day = '0' + day
  if (month < 10) month = 1 + month
  if (hours < 10) hours = '0' + hours
  if (min < 10) min = '0' + min

  return `${year}-${month}-${day}T${hours}:${min}`
}
export const getDateTimeFormatMui = (date: any) => {
  const finalDate = new Date(date)
  const year = finalDate.getFullYear()
  let month: any = finalDate.getMonth() + 1
  let day: any = finalDate.getDate()
  let hours: any = finalDate.getHours()
  let minutes: any = finalDate.getMinutes()

  if (month < 10) {
    month = `0${month}`
  }

  if (day < 10) {
    day = `0${day}`
  }

  if (hours < 10) {
    hours = `0${hours}`
  }

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const getOnlyDate = (date: Date) => {
  const d = new Date(date)
  let day: string | number = d.getDate()
  let month: string | number = d.getMonth()
  const year = d.getFullYear()

  if (day < 10) day = '0' + day
  if (month < 10) month = 1 + month

  return `${year}-${month}-${day}`
}

export const options: any = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}
export const GetServerDateFormate = (inputDate: any) => {
  let date = inputDate
  if (date === undefined || date === null) {
    date = new Date()
  }
  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }
  let day = date.getDate()
  if (day < 10) {
    day = '0' + day
  }
  return '' + date.getFullYear() + '-' + month + '-' + day
}

export const IntervalDropdown = [
  {
    id: 10,
    name: '10 Minute'
  },
  {
    id: 30,
    name: '30 Minute'
  },
  {
    id: 60,
    name: '1 Hour'
  },
  {
    id: 1440,
    name: '1 Day'
  }
]
export const IntervalDropdownGraph = [
  {
    id: 15,
    name: '15 Min',
    translationKey: 'fifteenMin'
  },
  {
    id: 30,
    name: '30 Min',
    translationKey: 'thirtyMin'
  },
  {
    id: 60,
    name: '1 H',
    translationKey: 'oneHour'
  },
  {
    id: 1440,
    name: '1 D',
    translationKey: 'oneDay'
  }
]

export const isDateOneDayOld = (date: Date) => {
  if (DATE_ONE_DAY >= Date.now() - new Date(date).getTime()) {
    return true
  }
  return false
}

export const isDateTwoDaysOld = (date: Date) => {
  if (DATE_ONE_DAY * 2 >= Date.now() - new Date(date).getTime()) {
    return true
  }
  return false
}
export const calculateAge = (dateOfBirth: string) => {
  const dob = new Date(dateOfBirth)
  const diff_ms = Date.now() - dob.getTime()
  const age_dt = new Date(diff_ms)
  return Math.abs(age_dt.getUTCFullYear() - 1970)
}

export const sleep = (ms: number) => {
  return new Promise((res) =>
    setTimeout(() => {
      res(null)
    }, ms)
  )
}

export const getStartDateByMinutes = (minutes: number) => {
  const currentDate = new Date() // get current date
  let startDate
  if (minutes <= 1440) {
    // if minutes is less than or equal to 1440, subtract one day
    startDate = new Date(currentDate.getTime() - 86400000).getTime()
  } else if (minutes <= 10080) {
    // if minutes is less than or equal to 10080, subtract one week
    startDate = new Date(currentDate.getTime() - 604800000).getTime()
  } else {
    // otherwise, subtract number of weeks
    const numWeeks = Math.ceil(minutes / 10080)
    startDate = new Date(currentDate.getTime() - numWeeks * 604800000).getTime()
  }
  return startDate
}
export const getBeforeMinutesDate = (startDate: Date, minute: number) => {
  return new Date(moment(startDate).utc().subtract(minute, 'minutes').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z').getTime()
}

export const getDatesBetween = (startDate: Date | number, endDate: Date | number): Date[] => {
  const dates = []
  let currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export const gettimeString = (date: Date, indicator?: boolean) => {
  const timeString = moment(date).format('HH:mm')
  const TimeWithIndicator = moment(date).format('hh:mm A')

  return indicator ? TimeWithIndicator : timeString
}
export const getStartAndEndDates = () => {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to 0 for start of day
  const endDate = new Date()
  return { startDate, endDate }
}
export const formatDate = (date: Date | number): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  return new Date(date).toLocaleDateString('en-US', options)
}

export const getAllHoursOfDay = (interval: number) => {
  const hours = []
  const totalPoints = Math.floor((24 * 60) / interval)

  const date = new Date()
  const TimeZoneDiff = Math.abs(date.getTimezoneOffset() % 60)

  for (let i = 0; i < totalPoints; i++) {
    let hour
    if (interval === Interval.Hour) {
      hour = TimeZoneDiff === 0 ? `${Math.floor(i)}:00:00` : `${Math.floor(i)}:30:00`
    } else {
      hour = `${Math.floor((i * interval) / 60)}:${(i * interval) % 60 === 0 ? '00' : (i * interval) % 60}:00`
    }
    hours.push(hour)
  }
  return hours
}

export const getDaysDifference = (startDate: Date | number, endDate: Date | number): number => {
  const startMoment = moment(startDate)
  const endMoment = moment(endDate)

  const days = endMoment.diff(startMoment, 'days')
  return days
}

export const getMinDiff = (startDate: Date | number, endDate: Date | number): number => {
  const startMoment = moment(startDate)
  const endMoment = moment(endDate)

  const mins = endMoment.diff(startMoment, 'minutes')
  return mins
}

export const GetTodayStartDateEndDate = () => {
  const startOfDay = new Date()
  startOfDay.setHours(0, 0, 0, 0)
  const endOfDay = new Date()

  return {
    startOfDay: startOfDay,
    endOfDay: endOfDay
  }
}

export const isDateBefore = (seconds: number, date: Date) => {
  if (!date) return false
  const currentDate = new Date()
  const dateToCompare = new Date(date)
  if (dateToCompare.getTime() - currentDate.getTime() < DATE_ONE_SEC * seconds) {
    return true
  } else {
    return false
  }
}

export const addNumberSuffix = (number: number): string => {
  const suffixes = ['st', 'nd', 'rd']
  const lastDigit = number % 10

  // Handle exceptions where "th" suffix is used
  if (number >= 11 && number <= 13) {
    return `${number}th`
  }

  // Use appropriate suffix or default to "th"
  const suffix = suffixes[lastDigit - 1] || 'th'
  return `${number}${suffix}`
}

export const getTimeStampBeforeMinutes = (timestamp: Date, minutes: number): string => {
  const date = new Date(timestamp)
  const newTimestamp = new Date(date.getTime() - minutes * 60000)
  return newTimestamp.toISOString()
}

export const isExpiryClose = (
  expiryDate: Date
): {
  isClose: boolean
  daysLeft: number
} => {
  const currentDate = new Date()
  const dateToCompare = new Date(expiryDate)
  const daysLeft = Math.ceil((dateToCompare.getTime() - currentDate.getTime()) / DATE_ONE_DAY)
  if (daysLeft <= 7) {
    return {
      isClose: true,
      daysLeft
    }
  } else {
    return {
      isClose: false,
      daysLeft
    }
  }
}
