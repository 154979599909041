import { BellWhiteIcon } from '../../../../assets/images/appBar/RumintechAppbar'
import { AppBarNotificationWrapper, AppBarNotificationIcon, AppBarNotificationText } from './AppBarNotification.styled'

interface AppBarNotificationProps {
  unSeenNotificaitonCount: number
}

const AppBarNotification: React.FC<AppBarNotificationProps> = ({ unSeenNotificaitonCount }) => {
  return (
    <AppBarNotificationWrapper>
      <AppBarNotificationIcon src={BellWhiteIcon} />
      {unSeenNotificaitonCount > 0 && (
        <AppBarNotificationText>
          <span className="">{unSeenNotificaitonCount > 99 ? '99+' : unSeenNotificaitonCount}</span>
        </AppBarNotificationText>
      )}
    </AppBarNotificationWrapper>
  )
}

export default AppBarNotification
