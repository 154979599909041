import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// export type AppDispatch = typeof store.dispatch
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>

export const useReduxDispatch = (): ThunkAppDispatch => useDispatch<ThunkAppDispatch>()
export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
