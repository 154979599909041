import styled from 'styled-components'
import themes from '../../../../styles'
import { ITaskStatus } from '../../Dashboard/Dashboard.option'

export const TaskPageHeader = styled.div`
  /* there will be add button and one input box in the right side of the flexbox */
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
`

export const LableContainer = styled.div`
  padding: 0.5rem;
  height: 2.5rem;
  width: 20%;
  border-radius: 0.75rem;
  background-color: #fff;
  padding-top: 0.3125rem;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  justify-content: space-between;
  background-color: #fff;
  height: 100%;
  border-radius: 0.75rem;
`

export const CalendarWrapper = styled.div`
  width: 30%;
  height: 100%;
  padding: 1.25rem;
`

export const DayTasksWrapper = styled.div`
  width: 70%;
  padding: 10px 20px;
`

export const DayDetailsHeading = styled.div`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
`

export const DayDetailsSubHeading = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #a9a9a9;
`

export const TaskCard = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
`

export const TaskTime = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 15%;
  border-right: 1px solid #e0e0e0;
`

export const TaskDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`

export const TaskDetails = styled.div<{ status?: number }>`
  border: 1px solid #e0e0e0;
  /* TODO: Remove the below height */
  background-color: ${({ status }) =>
    status === ITaskStatus.Complete
      ? '#43a04726'
      : status === ITaskStatus.Pending
      ? '#e5eeff'
      : status === ITaskStatus.inProgress
      ? '#2d9ae926'
      : status === ITaskStatus.Overdue
      ? '#dc702126'
      : ''};
  border-color: ${({ status }) =>
    status === ITaskStatus.Complete
      ? '#43A047'
      : status === ITaskStatus.Pending
      ? '#BBCDEF'
      : status === ITaskStatus.inProgress
      ? '#2D9AE9'
      : status === ITaskStatus.Overdue
      ? '#DC7021'
      : ''};
  border-radius: 4px;
  height: 120px;
  max-height: 120px;
  margin: 0px 0 0 20px;
  padding: 10px;
`

export const TaskListFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
`

export const TaskTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`

export const TextGapWrapper = styled.div<{ gap?: string }>`
  display: flex;
  font-weight: 400;
  gap: ${({ gap }) => gap || '10px'};
  font-size: 14px;
  align-items: center;
`

export const TaskDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #a9a9a9;
`

export const TaskDetailsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

export const TimeStamp = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #a9a9a9;
`

export const TaskType = styled.div`
  font-size: 14px;
  font-weight: 400;
`

export const AssignedTo = styled.div`
  font-size: 14px;
  font-weight: 400;
  gap: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const PlaceHolderText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #a9a9a9;
`

export const PlaceHolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #e0e0e0;
  height: 100%;
`
