import zoneAvilable from './zoneAvilableIcon.svg'
import zoneGrey from './zoneGreyIcon.svg'
import zoneSelect from './zoneSelectIcon.svg'
import newfarm from './newFarmIcon.svg'
import dropdown from './dropdowmIcon.svg'
import newdivice from './newDiviceIcon.svg'
import dropdownup from './dropdownupIcon.svg'
import dropdowndown from './dropdowndownIcon.svg'
import terminal from './terminalIcon.svg'
import zoneCircleAdd from './zoneCircleAddIcon.svg'
import zoneCircle2 from './zoneCircleSelectIcon.svg'
import zoneCircleGrey from './zoneCircleGreyIcon.svg'
import FullScreenImage from './FullScreenImage.svg'
import inviteIconImage from './inviteIcon.svg'

export const zoneAvilableIcon = zoneAvilable
export const zoneGreyIcon = zoneGrey
export const zoneSelectIcon = zoneSelect
export const newFarmIcon = newfarm
export const dropDownIcon = dropdown
export const newDeviceIcon = newdivice
export const dropdownupIcon = dropdownup
export const dropdowndownIcon = dropdowndown
export const terminalIcon = terminal
export const ZoneCircleAddIcon = zoneCircleAdd
export const ZoneCircleSelectIcon = zoneCircle2
export const ZoneCircleGreyIcon = zoneCircleGrey
export const FullScreenIcon = FullScreenImage
export const inviteIcon = inviteIconImage
