import { gateWaysConfig, PoultryDevicesConfig } from './Devices'
import { PoultryConfig } from './GlobleConfig'
import { PoultryWeightsLanguage } from './Language'
import { HARVEST, SENSORS } from './Sensors'

export const Config = {
  global: PoultryConfig,
  devices: PoultryDevicesConfig,
  gateWays: gateWaysConfig,
  sensors: SENSORS,
  harvest: HARVEST,
  language: PoultryWeightsLanguage
}
