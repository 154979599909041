import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useTranslation } from 'react-i18next'

type Props = {
  val: any
  onDateChangeHandler: any
  sx?: any
  defaultValue?: string
}

const DateTimeInputMUI = (props: Props) => {
  const { val, onDateChangeHandler, sx } = props
  const { i18n } = useTranslation()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <DateTimePicker value={val} onChange={onDateChangeHandler} sx={sx} format="DD/MM/YYYY - hh:mm A" />
    </LocalizationProvider>
  )
}

export default DateTimeInputMUI
