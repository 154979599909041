import MoniterGroupImg from './MoniterGroupIcon.svg'
import WeightImg from './WeightIcon.svg'
import BolusImg from './BolusIcon.svg'
import TemperatureImg from './TemperatureIcon.svg'
import HumidityImg from './HumidityIcon.svg'
import Co2Img from './Co2Icon.svg'
import ThiImg from './ThiIcon.svg'
import LuxImg from './LuxIcon.svg'
import WaterTempImg from './WaterTempIcon.svg'
import FoodConImg from './FoodConIcon.svg'
import WaterIntImg from './WaterIntIcon.svg'
import DownArrowImg from './DownArrowIcon.svg'
import MoniterGroupDarkImg from './MoniterGroupDarkIcon.svg'
import WeightDarkImg from './WeightDarkIcon.svg'
import BolusDarkImg from './BolusDarkIcon.svg'
import TemperatureDarkImg from './TemperatureDarkIcon.svg'
import HumidityDarkImg from './HumidityDarkIcon.svg'
import Co2DarkImg from './Co2DarkIcon.svg'
import ThiDarkImg from './ThiDarkIcon.svg'
import WaterTempDarkImg from './WaterTempDarkIcon.svg'
import LuxDarkImg from './LuxDarkIcon.svg'
import FoodConDarkImg from './FoodConDarkIcon.svg'
import UpArrowImg from './UpArrowIcon.svg'
import WaterIntakeDarkImg from './WaterIntakeDarkIcon.svg'
import CloseImg from './CloseIcon.svg'
import LeftArrowImg from './LeftArrowIcon.svg'
import RightArrowImg from './RightArrowIcon.svg'
import WaterMeterImg from './WaterMeterIcon.svg'

export const MoniterGroupIcon = MoniterGroupImg;
export const WeightIcon = WeightImg;
export const BolusIcon = BolusImg;
export const TemperatureIcon = TemperatureImg;
export const HumidityIcon = HumidityImg;
export const Co2Icon = Co2Img;
export const ThiIcon = ThiImg;
export const LuxIcon = LuxImg;
export const WaterTempIcon = WaterTempImg;
export const FoodConIcon = FoodConImg;
export const WaterIntIcon = WaterIntImg;
export const DownArrowIcon = DownArrowImg;
export const MoniterGroupDarkIcon = MoniterGroupDarkImg;
export const WeightDarkIcon = WeightDarkImg;
export const BolusDarkIcon = BolusDarkImg;
export const TemperatureDarkIcon = TemperatureDarkImg;
export const HumidityDarkIcon = HumidityDarkImg;
export const Co2DarkIcon = Co2DarkImg;
export const ThiDarkIcon = ThiDarkImg;
export const WaterTempDarkIcon = WaterTempDarkImg;
export const LuxDarkIcon = LuxDarkImg;
export const FoodConDarkIcon = FoodConDarkImg; 
export const UpArrowIcon = UpArrowImg;
export const WaterIntakeDarkIcon = WaterIntakeDarkImg;
export const CloseIcon = CloseImg;
export const LeftArrowIcon = LeftArrowImg;
export const RightArrowIcon = RightArrowImg;
export const WaterMeterIcon = WaterMeterImg;