import hiveLevel1 from './iotHiveLevel1.svg'
import hiveLevel2 from './iotHiveLevel2.svg'
import hiveLevel3 from './iotHiveLevel3.svg'
import co2CloudSensor from './iotCO2CloudSensor.svg'
import iotLocationSelector from './iotLocationSelector.svg'
import iotLog from './iotLog.svg'
import iotSensorDrop from './iotSensorDrop.svg'
import iotTemperatureSensor from './iotTemperatureSensor.svg'
import iotTrendDown from './iotTrendDown.svg'
import iotTrendUp from './iotTrendUp.svg'
import iotWeight from './iotWeight.svg'
import iotWeightGross from './iotWeightGross.svg'
import iotWeightNet from './iotWeightNet.svg'
import intervalHexagon from './intervalHexagon.svg'
import iotHex from './IotHex.svg'
import trendUp from './trendUp.svg'
import trendDown from './trendDown.svg'

import iotWeather from './iotWeather.svg'
import devicesImg from './devicesImg.svg'
import iotHumidityIcon from './IotHumidityIcon.svg'

export const BeesIotImages = {
  hiveLevel1Image: hiveLevel1,
  hiveLevel2Image: hiveLevel2,
  hiveLevel3Image: hiveLevel3,
  iotCO2CloudSensorImage: co2CloudSensor,
  iotHumidityIcon,
  iotLocationSelectorImage: iotLocationSelector,
  iotLogImage: iotLog,
  iotSensorDropImage: iotSensorDrop,
  iotTemperatureSensorImage: iotTemperatureSensor,
  iotTrendDownImage: iotTrendDown,
  iotTrendUpImage: iotTrendUp,
  iotWeightImage: iotWeight,
  iotWeightGrossImage: iotWeightGross,
  iotWeightNetImage: iotWeightNet,
  intervalHexagon,
  iotHex,
  iotWeather,
  trendUp,
  trendDown,
  devicesImg
}
