import styled from 'styled-components'

export const AppBarNotificationWrapper = styled.div`
  background-color: ${({ theme }) => theme.typography.colors.white};
  border-radius: 100%;
  width: 50px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`

export const AppBarNotificationIcon = styled.img``

export const AppBarNotificationText = styled.div`
  font-size: 12px;
  color: white;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 30px;
  margin-left: 30px;
  background-color: #ff5852;
  border-radius: 50%;
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  padding: 2px;
`

export const notificationNumber = styled.span``
