import React, { useEffect, Suspense, useState, useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState, useReduxDispatch, useReduxSelector } from '../../redux'
import { setLogin } from '../../redux/AuthReducer'
import { setMultipleSelect, setEnableZone } from '../../redux/LocationSlice'
import useSockets, { socket } from '../../hooks/useSockets'
import useLoaders from '../../hooks/useLoaders'
import AppSubNavigationComponent from '../../components/AppSubNavigation/AppSubNavigation'
// import Sidebar from '../../components/Sidebar/Sidebar'
const Sidebar = React.lazy(() => import('../../components/Sidebar/Sidebar'))
import { rightArrowIcon } from '../../assets/images/AppSubNavigation'
import './Main.scss'
import Appbar from '../../components/Appbar'
// const Appbar = React.lazy(() => import('../../components/Appbar'));
import { GetDataModelId } from '../../utils/helpers/dataModel'
import { DataModel } from '../../types/dataModel.d'
import AppBottomTabs from '../../components/AppBottomTabs/AppBottomTabs'
import { AppbarContainer, BottomTabsContainer, MainAreaContainer, MainContainer, SkeletonCss } from './Main.styled'
import Loader from '../../components/Loader/Loader'
import { GetConfig } from '../../config/config'
import { Skeleton, Stack } from '@mui/material'
import RuminTechRoutes from './RuminTechRoutes'
import ReactGA from 'react-ga4'
import SnackBar from './SnackBar'
import { SnackbarProvider } from 'notistack'
import { GETTRACKING_ID } from '../../utils/helpers/gaHelper'
import BpacRoutes from './BpacRoutes'
import GasRoutes from './GasRoutes'
import RadarRoutes from './RadarRoutes'
import i18n from '../../utils/helpers/i18nHelper'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { setUserLanguage } from '../../utils/helpers/user'
import rtlPlugin from 'stylis-plugin-rtl'
import { StyleSheetManager } from 'styled-components'
import LanguageOptions from './Language/Language'
import { set } from 'cypress/types/lodash'
import eventBus from '../../utils/helpers/eventBus'
import { getUserLocation } from '../../utils/helpers/location'
import SubMenu from './SubMenu'

const direction = localStorage.getItem('direction')
const isRTL = direction === 'rtl'

// import HydroRoutes from "./HydroRoutes";
// import PoultryRoutes from './PoultryRoutes'
const PoultryRoutes = React.lazy(() => import('./PoultryRoutes'))
// import BeesRoutes from './BeesRoutes'
const BeesRoutes = React.lazy(() => import('./BeesRoutes'))
// import HydroRoutes from './HydroRoutes'
const HydroRoutes = React.lazy(() => import('./HydroRoutes'))

interface Props {
  serverLoading?: any
}

const Main: React.FC<Props> = ({ serverLoading }) => {
  const config = GetConfig()?.global
  const { isLoggedIn } = useReduxSelector((state: RootState) => state.auth)
  const dispatch = useReduxDispatch()
  const { loadAppData } = useLoaders()
  const { socketDisconnect } = useSockets()
  const location = useLocation()
  const navigate = useNavigate()
  const dataModelId = GetDataModelId()
  const { t } = useTranslation()
  ReactGA.initialize(GETTRACKING_ID())

  //

  /**If user is exist in localstorage and the token is valid , load the data and get into the authorized views. */
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    if (Date.now() < user?.expDate * 1000) {
      dispatch(setLogin(true))
      loadAppData()
      // startSocketConnection()
      if (
        location.pathname === '/' ||
        location.pathname === '/login' ||
        location.pathname === '/login/token' ||
        location.pathname === '/expired'
      ) {
        if (dataModelId === DataModel.RuminTech) {
          navigate('/Analysis/Graph')
        } else if (dataModelId === DataModel.Gas) {
          navigate('/Devices/summary')
        } else {
          navigate('/Iot/General')
        }
      } else {
        navigate(location.pathname)
      }
    } else {
      if (
        location.pathname.indexOf('signup') !== -1 ||
        location.pathname.indexOf('reset') !== -1 ||
        location.pathname.indexOf('login/token') !== -1
      ) {
        //navigate(location.pathname + location.search)
      } else {
        dispatch(setLogin(false))
        navigate('/login')
      }
    }

    return () => {
      socketDisconnect()
    }
  }, [])

  const data = location.pathname
  const path = data.split('/')
  const Title = `/${path[1]}`
  const SubTitle = path[2]

  switch (data) {
    case '/MyFarm/Devices':
      dispatch(setMultipleSelect(false))
      break
    case `${config?.harvest?.route}`:
      // dispatch(setMultipleSelect(false))
      break
    case `${config?.harvest?.route}/CropHistory`:
      dispatch(setMultipleSelect(false))
      break
    case `${config?.harvest?.route}/MortalityHistory`:
      dispatch(setMultipleSelect(false))
      break
    case '/managerReports/CropSequences':
      dispatch(setMultipleSelect(false))
      break
    case '/MyFarm/Sensors':
      dispatch(setMultipleSelect(false))
      break
    case '/MyFarm/Controller':
      dispatch(setMultipleSelect(false))
      break
    case '/Analysis':
      // dispatch(setEnableZone(true))

      break
    default:
      dispatch(setMultipleSelect(true))
      dispatch(setEnableZone(false))
      break
  }

  const handleUnAuthorized = useCallback((e: any) => {
    const user = JSON.parse(localStorage.getItem('user') ?? 'undefined')
    if (e?.statusCode === 401 && user) {
      console.log('THis condition')
      dispatch(setLogin(false))
      navigate('/expired')
    }
  }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    if (
      location.pathname !== '/' &&
      location.pathname !== '/login' &&
      location.pathname !== '/login/token' &&
      location.pathname !== '/expired' &&
      user
    ) {
      getUserLocation()
    }
  }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    if (new Date().getTime() > user?.expDate * 1000) {
      dispatch(setLogin(false))
      // loadAppData()
      navigate('/expired')
    }
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: Title })
  }, [location.pathname])

  useEffect(() => {
    eventBus.on('unAuthorized', (e: any) => handleUnAuthorized(e))
  }, [])

  const subnavdata = []
  subnavdata.push(config?.sidebar?.routeData?.find((o: any) => o.path === Title))

  const getRoutes = () => {
    const dataModelId = GetDataModelId()
    switch (dataModelId) {
      case DataModel.PoultryWeights:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <PoultryRoutes />
          </Suspense>
        )
        break
      case DataModel.Bees:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <BeesRoutes />
          </Suspense>
        )
        break
      case DataModel.RuminTech:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <RuminTechRoutes />
          </Suspense>
        )
        break
      case DataModel.Bpac:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <BpacRoutes />
          </Suspense>
        )
        break
      case DataModel.Gas:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <GasRoutes />
          </Suspense>
        )
        break
      case DataModel.Radar:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <RadarRoutes />
          </Suspense>
        )
        break
      default:
        return (
          <Suspense fallback={<Loader size={20} />}>
            <HydroRoutes />
          </Suspense>
        )
        break
    }
  }

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <StyleSheetManager stylisPlugins={isRTL ? [rtlPlugin as any] : undefined}>
          <MainContainer isLoggedIn={isLoggedIn} dataModelId={dataModelId} location={location}>
            <Suspense fallback={<Loader size={20} />}>{isLoggedIn && <Sidebar serverLoading={serverLoading} />}</Suspense>
            {dataModelId === DataModel.Bees && isLoggedIn ? (
              <BottomTabsContainer>
                <AppBottomTabs currentRoute={location.pathname} />
              </BottomTabsContainer>
            ) : null}
            <MainAreaContainer dataModelId={dataModelId}>
              {(dataModelId === DataModel.PoultryWeights && isLoggedIn) ||
              (dataModelId === DataModel.Radar && isLoggedIn) ||
              (dataModelId === DataModel.hydroIntel && isLoggedIn) ||
              (dataModelId === DataModel.Bpac && isLoggedIn) ? (
                <AppSubNavigationComponent>
                  <h4>
                    {subnavdata.map((item: any, index) => {
                      return serverLoading ? (
                        <Stack sx={SkeletonCss.Stack} key={index}>
                          <Skeleton variant="circular" animation="wave" sx={SkeletonCss.NavIcon} />
                          <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.Title} />
                          <Skeleton variant="circular" animation="wave" sx={SkeletonCss.RightArrowIcon} />
                          <Skeleton variant="rectangular" animation="wave" sx={SkeletonCss.SubTitle} />
                        </Stack>
                      ) : (
                        <div className="navigation" key={index}>
                          <img className="navigate" src={item?.navicon}></img>
                          <div className="navigate">{path[1]}</div>
                          <div className="navigate">
                            <div className="subnavigation">
                              <img className="subnavigate" src={rightArrowIcon}></img>
                              <div className="subnavigate" data-cy={'subnavigate'}>
                                {SubTitle}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </h4>
                </AppSubNavigationComponent>
              ) : null}
              {serverLoading ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={'100%'} /> : getRoutes()}
            </MainAreaContainer>
            <AppbarContainer isLoggedIn={isLoggedIn}>
              <Suspense fallback={<Loader size={20} />}>
                <Appbar serverLoading={serverLoading} />
              </Suspense>
            </AppbarContainer>
            <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <SnackBar />
            </SnackbarProvider>
          </MainContainer>
        </StyleSheetManager>
      </I18nextProvider>
    </>
  )
}
export default Main
