import { AppLocationWrapper, AppSelectInputContainer } from './AppLocationFilter.styled'
import { useEffect, useState } from 'react'
import './TreeDropDown.scss'
import { setLocationData, setMultipleSelect, setType } from '../../../../redux/LocationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../redux'
import { ICustomer } from 'customers'
import { IFacilityGroup } from '../../../../types/facilityGroup.d'
import { FacilityType, IFacility, IZone } from '../../../../types/facility.d'
import { filteredZonesByFacilityIds } from '../../../../utils/helpers/treeSelection'
import { ITreeNode } from 'locationFilter'
import { GetConfig } from '../../../../config/config'
import { useLocation } from 'react-router-dom'
import AppSelect from '../../../AppSelect/AppSelect'
import { LocationType } from '../../../../utils/consts/login'

export enum HirarchyTypes {
  Customer,
  FaciltyGroup,
  Facility,
  Zone
}
interface ISelectedDataObject {
  customerIds: number[]
  facilityGroupIds: number[]
  facilityIds: number[]
  facilityZoneIds: number[]
}

export type NodeType = (ICustomer | IFacilityGroup | IFacility | IZone) & {
  checked?: boolean
  parentId?: number
  children?: NodeType
  type?: HirarchyTypes
  value?: number
  label?: string
}
interface ISelectedTree {
  value: string
  label: string
  parentId: string
  parentParentId: number
  type: HirarchyTypes
  checked: boolean
  id?: number
}
const AppbarLocationComponent = () => {
  const config = GetConfig()?.global
  const { customers } = useSelector((state: RootState) => state.customers)
  const { facilitiesGroups } = useSelector((state: RootState) => state.facilitiesGroup)
  const { facilities } = useSelector((state: RootState) => state.facilities)
  const { facilityZones } = useSelector((state: RootState) => state.facilityZones)
  const { onlyFacilities } = useSelector((state: RootState) => state.location)
  const { selectedData, onlyFacilitiesGroup, multipleSelect, noCustomer } = useSelector((state: RootState) => state.location)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (onlyFacilities) {
      const _allFacility = [...createAllFacilityTreeNodeObject()]
      const selectedDataItem: any = selectedData[0] // Access the first item in selectedData
      if (selectedDataItem && selectedDataItem.facilitiesIds && selectedDataItem.facilitiesIds !== undefined) {
        const fileredIds = facilities
          .filter((facility) => selectedDataItem.facilitiesIds.includes(facility.id) && facility.facilityTypeId === FacilityType.Shed)
          .map((item) => item.id)
        const selectedFacility = _allFacility.find((item) => item.id === fileredIds[0])
        if (selectedFacility) {
          dispatch(setLocationData([selectedFacility]))
          setSelectedDataObject((prev) => {
            return {
              ...prev,
              customerIds: [selectedFacility.parentParentId] as number[],
              facilityGroupIds: [selectedFacility?.parentId] as number[],
              facilityIds: selectedFacility.facilitiesIds as number[],
              facilityZoneIds: selectedFacility.zonesIds as number[]
            }
          })
        }
      }
    } else if (onlyFacilitiesGroup) {
      const _allFacilityGroup = [...createAllFacilityGroupTreeNodeObject()]
      const filtered = _allFacilityGroup.filter(({ checked }) => checked)
      if (filtered.length > 0 && multipleSelect) {
        dispatch(setLocationData(filtered))
        setSelectedDataObject((prev) => {
          return {
            ...prev,
            customerIds: [_allFacilityGroup[0].parentId] as number[],
            facilityGroupIds: _allFacilityGroup[0]?.facilitiesGroupIds as number[],
            facilityIds: _allFacilityGroup[0].facilitiesIds as number[],
            facilityZoneIds: _allFacilityGroup[0].zonesIds as number[]
          }
        })
      } else if (noCustomer) {
        const _allFacilityGroup = [...createFacilitiesGroupTreeNodeObject()]
        if (_allFacilityGroup.length) {
          _allFacilityGroup[0].checked = true
          const filtered = _allFacilityGroup.filter(({ checked }) => checked)
          dispatch(setLocationData(filtered))
        }
      }
    }
  }, [customers, facilitiesGroups, facilities, facilityZones, onlyFacilitiesGroup, onlyFacilities, multipleSelect, noCustomer])
  useEffect(() => {
    if (onlyFacilities) {
      const _allFacility = [...createAllFacilityTreeNodeObject()]
      if (_allFacility) {
        const filtered = _allFacility.filter(({ checked }) => checked)
        if (filtered.length > 0) {
          dispatch(setLocationData(filtered))
        }
      }
    } else if (onlyFacilitiesGroup) {
      const _allFacilityGroup = [...createAllFacilityGroupTreeNodeObject()]
      if (_allFacilityGroup.length) {
        _allFacilityGroup[0].checked = true
        const filtered = _allFacilityGroup.filter(({ checked }) => checked)
        if (filtered.length > 0) {
          dispatch(setLocationData(filtered))
        }
      }
    } else {
      const _customers = [...createCustomerTreeNodeObject()]
      if (_customers.length && !noCustomer && (selectedData[0]?.checked === undefined || selectedData[0].type === HirarchyTypes.Customer)) {
        _customers[0].checked = true
        const filtered = _customers.filter(({ checked }) => checked)
        setSelectedDataObject((prev) => {
          return {
            ...prev,
            customerIds: [_customers[0].id] as number[],
            facilityGroupIds: _customers[0]?.facilitiesGroupIds as number[],
            facilityIds: _customers[0].facilitiesIds as number[],
            facilityZoneIds: _customers[0].zonesIds as number[]
          }
        })
        dispatch(setLocationData(filtered))
      } else if (noCustomer) {
        const _allFacilityGroup = [...createFacilitiesGroupTreeNodeObject()]
        //handing no customer case
        if (_allFacilityGroup.length && (selectedData[0].checked !== undefined ? selectedData[0].type === HirarchyTypes.Customer : true)) {
          _allFacilityGroup[0].checked = true
          const filtered = _allFacilityGroup.filter(({ checked }) => checked)
          dispatch(setLocationData(filtered))
          setSelectedDataObject((prev) => {
            return {
              ...prev,
              customerIds: [_allFacilityGroup[0].parentId] as number[],
              facilityGroupIds: _allFacilityGroup[0]?.facilitiesGroupIds as number[],
              facilityIds: _allFacilityGroup[0].facilitiesIds as number[],
              facilityZoneIds: _allFacilityGroup[0].zonesIds as number[]
            }
          })
        }
      }
    }
  }, [customers, facilitiesGroups, facilities, noCustomer])

  useEffect(() => {
    if (location.pathname === '/Iot/Heatmap') {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: true,
          facilityGroup: false,
          facilityZone: false,
          noCustomer: false
        })
      )
    } else if (location.pathname === `${config?.harvest?.route}`) {
      dispatch(setMultipleSelect(true))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: true,
          facilityZone: false,
          noCustomer: false
        })
      )
    } else if (
      location.pathname === '/MyFarm/Sensors' ||
      location.pathname === '/MySite/Sensors' ||
      location.pathname === `${config?.harvest?.route}/CropHistory` ||
      location.pathname === `${config?.harvest?.route}/MortalityHistory` ||
      location.pathname === `${config?.harvest?.route}/Consumption`
    ) {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: true,
          facilityZone: false,
          noCustomer: false
        })
      )
    } else if (location.pathname === '/managerReports/CropSequences' || location.pathname === '/managerReports/CropSequences') {
      dispatch(
        setType({
          customer: false,
          facility: true,
          facilityGroup: false,
          facilityZone: false,
          noCustomer: false
        })
      )
    } else if (location.pathname === '/MyFarm/Controller') {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: true,
          facilityZone: false,
          noCustomer: false
        })
      )
    } else if (
      location.pathname === '/Analysis/Efficiency' ||
      location.pathname === '/Analysis/Sensors' ||
      location.pathname === '/Analysis/Sensors/Detailes'
    ) {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: false,
          facilityZone: false,
          noCustomer: true
        })
      )
    } else {
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: false,
          facilityZone: false,
          noCustomer: false
        })
      )
    }
  }, [location.pathname])
  /**
   * Get Facility zones ids by facility ID.
   * @param facilityId
   * @returns
   */
  const getFacilityZonesIds = (facilityId: number) => {
    return facilityZones?.filter((zone) => zone.facilityId === facilityId).map(({ id }) => id)
  }

  /**
   * Get Facilities Group zones ids, by facility group id.
   * @param facilityGroupId
   * @returns
   */
  const getFacilityGroupZones = (facilityGroupId: number) => {
    const zones: number[] = []
    const facilitiesGroup = facilitiesGroups?.find(({ id }) => facilityGroupId === id)
    facilities.forEach(({ id, facilityGroupId }) => {
      if (facilityGroupId === facilitiesGroup?.id) zones.push(...getFacilityZonesIds(id))
    })
    return zones
  }

  /**
   * Get zones ids by customer id.
   * @param id
   * @returns
   */
  const getCustomerZones = (id?: number) => {
    if (!id) return []
    const zones: number[] = []
    const customerFacilitiesGroups = facilitiesGroups?.filter(({ customerId }) => customerId === id)
    customerFacilitiesGroups?.forEach(({ id }) => {
      zones.push(...getFacilityGroupZones(id))
    })
    return zones
  }

  const getAllCustomerChildrens = (id?: number) => {
    const allCustomerChildren: any = []
    const customerChildrens = customers.filter((customer) => customer.creatorCustomerId === id)
    allCustomerChildren.push(...customerChildrens)
    const data = customerChildrens.forEach((customer) => {
      return allCustomerChildren.push(...getAllCustomerChildrens(customer.id))
    })
    return allCustomerChildren
  }

  const getAllFacilityIds = (customerIds: number[]) => {
    const allFacilities: any = []
    customerIds.forEach((customerId) => {
      const customerFacilities = facilities.filter((facility) => facility.customerId === customerId)
      allFacilities.push(...customerFacilities)
    })
    return allFacilities.map((facility: IFacility) => facility.id)
  }

  const getAllFacilityGroupIds = (customerIds: number[]) => {
    const allFacilityGroups: any = []
    customerIds.forEach((customerId) => {
      const customerFacilityGroups = facilitiesGroups.filter((facilityGroup) => facilityGroup.customerId === customerId)
      allFacilityGroups.push(...customerFacilityGroups)
    })
    return allFacilityGroups.map((facilityGroup: IFacilityGroup) => facilityGroup.id)
  }

  const createCustomerTreeNodeObject = (): ITreeNode[] => {
    const filteredcustomers = customers?.map((customer, index) => {
      const customersCustomer = getAllCustomerChildrens(customer.id)
      const allCustomerIds = customersCustomer.map((customer: ICustomer) => customer.id)
      const customerParent = customers?.find(({ id }) => customer.creatorCustomerId === id)
      const cusomerParentParent = customers.find(({ id }) => customerParent?.creatorCustomerId === id)
      const zonesIds = [allCustomerIds.map((id: number) => getCustomerZones(id)).flat(), ...getCustomerZones(customer.id)]
      const currentCustomerFacilitiesIds: any = facilities
        .filter((facility) => facility.customerId === customer.id)
        .map((facility) => facility.id)
      const currentCustomerFacilityGroupIds = facilitiesGroups
        ?.filter(({ customerId }) => customerId === customer.id)
        .map((facilityGroup) => facilityGroup.id)
      const customerFacilities = [...currentCustomerFacilitiesIds, ...getAllFacilityIds(allCustomerIds)]
      const facilitiesGroup = [...currentCustomerFacilityGroupIds, ...getAllFacilityGroupIds(allCustomerIds)]

      let checked = false
      if (selectedData[0]?.checked !== undefined) {
        checked = selectedData.find(({ id }) => id === customer.id)?.checked ?? false
      } else {
        checked = !index ? true : false
      }

      //no Customer case
      if (noCustomer) {
        checked = false
      }
      return {
        checked: checked ?? false,
        id: customer.id as number,
        parentId: customer.creatorCustomerId,
        parentParentId: cusomerParentParent?.id,
        parentName: customerParent?.name ?? '',
        parentParentName: cusomerParentParent?.name ?? '',
        label: customer.name,
        value: customer.id as number,
        disabled: noCustomer,
        name: customer.name,
        facilitiesGroupIds: facilitiesGroup.flat(),
        facilitiesIds: customerFacilities.flat(),
        zonesIds: zonesIds.flat(),
        children: [],
        type: HirarchyTypes.Customer
      }
    })
    return filteredcustomers
  }

  const createFacilitiesGroupTreeNodeObject = (): ITreeNode[] => {
    return facilitiesGroups?.map((facilityGroup, index) => {
      const customer = customers.find(({ id }) => facilityGroup.customerId === id)
      const cusomerParent = customers.find(({ id }) => customer?.creatorCustomerId === id)
      const facilityGroupFacilities = facilities.filter(({ facilityGroupId }) => facilityGroupId === facilityGroup.id)
      let defaultChecked = false
      //normal tree
      if (selectedData[0]?.checked !== undefined) {
        defaultChecked = selectedData.find(({ id }) => id === facilityGroup.id)?.checked ?? false
      } else {
        defaultChecked = false
      }
      //FacilityGoup No Customer Tree
      if (
        noCustomer &&
        (selectedData[0]?.type === HirarchyTypes.Customer ||
          selectedData[0]?.type === HirarchyTypes.Zone ||
          selectedData[0]?.checked === undefined)
      ) {
        if (index === 0) {
          defaultChecked = true
        }
      }
      return {
        checked: defaultChecked,
        id: facilityGroup.id as number,
        name: facilityGroup.name,
        label: facilityGroup.name,
        value: facilityGroup.id as number,
        parentId: facilityGroup.customerId,
        parentName: customer?.name ?? '',
        parentParentId: cusomerParent?.id,
        parentParentName: cusomerParent?.name ?? '',
        facilitiesIds: facilityGroupFacilities.map(({ id }) => id),
        facilitiesGroupIds: [facilityGroup.id],
        zonesIds: getFacilityGroupZones(facilityGroup.id),
        children: [],
        type: HirarchyTypes.FaciltyGroup
      }
    })
  }

  const createFacilityTreeNodeObject = (): ITreeNode[] => {
    return facilities.map((facility) => {
      const facilityGroup = facilitiesGroups.find(({ id }) => facility.facilityGroupId === id)
      const customer = customers.find(({ id }) => facility.customerId === id)
      const checked = selectedData.find(({ id }) => id === facility.id)?.checked
      return {
        checked: checked ?? false,
        id: facility.id,
        name: facility.name,
        parentId: facilityGroup?.id as number,
        parentParentId: facility.customerId,
        parentName: facilityGroup?.name ?? '',
        parentParentName: customer?.name ?? '',
        value: facility.id as number,
        label: facility.name,
        children: [],
        zonesIds: facility.zones.map(({ id }) => id),
        facilitiesIds: [facility.id],
        type: HirarchyTypes.Facility
      }
    })
  }

  const createZonesTreeNodeObject = (): ITreeNode[] => {
    return facilityZones.map((zone) => {
      const facility = facilities.find(({ id }) => id === zone.facilityId)
      const facilityGroup = facilitiesGroups.find(({ id }) => facility?.facilityGroupId === id)
      return {
        id: zone.id,
        name: zone.name ?? 'Zone' + zone.zoneIndex.toString(),
        parentId: zone.facilityId,
        parentParentId: facilityGroup?.id as number,
        parentName: facility?.name ?? '',
        parentParentName: facilityGroup?.name ?? '',
        value: zone.id as number,
        label: zone.name ?? '',
        children: [],
        zonesIds: [zone.id],
        type: HirarchyTypes.Zone
      }
    })
  }

  const createAllFacilityTreeNodeObject = (): ITreeNode[] => {
    return facilities
      .filter((facility) => facility.facilityTypeId === FacilityType.Shed)
      .map((facility, index) => {
        const facilityGroup = facilitiesGroups.find(({ id }) => facility.facilityGroupId === id)
        const customer = customers.find(({ id }) => facility.customerId === id)
        return {
          checked: !index ? true : false,
          id: facility.id,
          name: facility.name,
          parentId: (facilityGroup?.id ?? facility?.facilityGroupId) as number,
          parentParentId: facility.customerId,
          parentName: facilityGroup?.name ?? '',
          parentParentName: customer?.name ?? '',
          value: facility.id as number,
          label: facility.name,
          children: [],
          zonesIds: facility.zones.map(({ id }) => id),
          facilitiesIds: [facility.id],
          type: HirarchyTypes.Facility
        }
      })
  }

  const createAllFacilityGroupTreeNodeObject = (): ITreeNode[] => {
    return facilitiesGroups.map((facilityGroup, index) => {
      const customer = customers.find(({ id }) => facilityGroup.customerId === id)
      const cusomerParent = customers.find(({ id }) => customer?.creatorCustomerId === id)
      const facilityGroupFacilities = facilities.filter(({ facilityGroupId }) => facilityGroupId === facilityGroup.id)
      let defaultChecked = false
      if (selectedData[0].checked !== undefined) {
        defaultChecked = selectedData.find(({ id }) => id === facilityGroup.id)?.checked ?? false
      }
      if (!multipleSelect && HirarchyTypes.FaciltyGroup === selectedData[0].type) {
        defaultChecked = selectedData[0].id === facilityGroup.id ? true : false
      }
      if (HirarchyTypes.FaciltyGroup !== selectedData[0].type) {
        defaultChecked = !index ? true : false
      }
      return {
        checked: defaultChecked,
        id: facilityGroup.id as number,
        name: facilityGroup.name,
        label: facilityGroup.name,
        value: facilityGroup.id as number,
        parentId: facilityGroup.customerId,
        parentName: customer?.name ?? '',
        parentParentId: cusomerParent?.id,
        parentParentName: cusomerParent?.name ?? '',
        facilitiesIds: facilityGroupFacilities.map(({ id }) => id),
        facilitiesGroupIds: [facilityGroup.id],
        zonesIds: getFacilityGroupZones(facilityGroup.id),
        children: [],
        type: HirarchyTypes.FaciltyGroup
      }
    })
  }

  useEffect(() => {
    switch (selectedData[0]?.type) {
      case LocationType.Customer:
        setSelectedDataObject((prev) => {
          return {
            ...prev,
            customerIds: selectedData.map((item) => item.id),
            facilityGroupIds: selectedData.map((item) => item.facilitiesGroupIds).flat() as number[],
            facilityIds: selectedData.map((item) => item.facilitiesIds).flat() as number[],
            facilityZoneIds: selectedData.map((item) => item.zonesIds).flat() as number[]
          }
        })
        break
      case LocationType.FacilityGroup:
        setSelectedDataObject((prev) => {
          return {
            ...prev,
            facilityGroupIds: selectedData.map((item) => item.id),
            customerIds: Array.from(new Set(selectedData.map((item) => item.parentId))) as number[],
            facilityIds: selectedData.map((item) => item.facilitiesIds).flat() as number[],
            facilityZoneIds: selectedData.map((item) => item.zonesIds).flat() as number[]
          }
        })
        break
      case LocationType.Facility:
        setSelectedDataObject((prev) => {
          return {
            ...prev,
            facilityIds: selectedData.map((item) => item.id),
            customerIds: Array.from(new Set(selectedData.map((item) => item.parentParentId))) as number[],
            facilityGroupIds: Array.from(new Set(selectedData.map((item) => item.parentId))) as number[],
            facilityZoneIds: selectedData.map((item) => item.zonesIds).flat() as number[]
          }
        })
        break
      case LocationType.Zone:
        setSelectedDataObject((prev) => {
          return {
            ...prev,
            facilityZoneIds: selectedData.map((item) => item.id),
            facilityIds: Array.from(new Set(selectedData.map((item) => item.parentId))) as number[],
            // customerIds: Array.from(new Set(selectedData.map((item) => item.parentParentId))) as number[],
            facilityGroupIds: Array.from(new Set(selectedData.map((item) => item.parentParentId))) as number[]
          }
        })
        break
      default:
    }
  }, [selectedData])

  const [selectedDataObject, setSelectedDataObject] = useState<ISelectedDataObject>({
    customerIds: [],
    facilityGroupIds: [],
    facilityIds: [],
    facilityZoneIds: []
  })
  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    const custumerAllData = [...createCustomerTreeNodeObject()]

    if (value.length === 0) {
      dispatch(setLocationData([custumerAllData[0]]))
      setSelectedDataObject((prev) => {
        return {
          ...prev,
          customerIds: [custumerAllData[0].id] as number[],
          facilityGroupIds: custumerAllData[0]?.facilitiesGroupIds as number[],
          facilityIds: custumerAllData[0].facilitiesIds as number[],
          facilityZoneIds: custumerAllData[0].zonesIds as number[]
        }
      })
    } else {
      switch (name) {
        case 'customerIds':
          const _customers = custumerAllData.filter((item) => value.includes(item.id))
          if (_customers.length > 0) {
            dispatch(setLocationData(_customers))
            setSelectedDataObject((prev) => {
              return {
                ...prev,
                [name]: value,
                facilityGroupIds: _customers.map((item) => item.facilitiesGroupIds).flat() as number[],
                facilityIds: _customers.map((item) => item.facilitiesIds).flat() as number[],
                facilityZoneIds: _customers.map((item) => item.zonesIds).flat() as number[]
              }
            })
          }
          break
        case 'facilityGroupIds':
          const _facilityGroup = createFacilitiesGroupTreeNodeObject().filter((item) => value.includes(item.id))
          setSelectedDataObject((prev) => {
            return {
              ...prev,
              [name]: value,
              customerIds: Array.from(new Set(_facilityGroup.map((item) => item.parentId))) as number[],
              facilityIds: _facilityGroup.map((item) => item.facilitiesIds).flat() as number[],
              facilityZoneIds: _facilityGroup.map((item) => item.zonesIds).flat() as number[]
            }
          })
          dispatch(setLocationData(_facilityGroup))
          break
        case 'facilityIds':
          const _facilities = createFacilityTreeNodeObject().filter((item) => value.includes(item.id))
          setSelectedDataObject((prev) => {
            return {
              ...prev,
              [name]: value,
              customerIds: Array.from(new Set(_facilities.map((item) => item.parentParentId))) as number[],
              facilityGroupIds: Array.from(new Set(_facilities.map((item) => item.parentId))) as number[],
              facilityZoneIds: _facilities.map((item) => item.zonesIds).flat() as number[]
            }
          })
          dispatch(setLocationData(_facilities))
          break
        case 'facilityZoneIds':
          const _zones = createZonesTreeNodeObject().filter((item) => value.includes(item.id))
          setSelectedDataObject((prev) => {
            return {
              ...prev,
              [name]: value
            }
          })
          dispatch(setLocationData(_zones))
          break
        default:
          setSelectedDataObject({ ...selectedDataObject, [name]: value })
          break
      }
    }
  }

  const customersData = [...createCustomerTreeNodeObject()]

  const filteredfacilityGroupByCustomer = facilitiesGroups.filter((facilityGroup) => {
    const selectedFacilityGroupIds = Array.from(new Set(selectedData.map((item) => item.facilitiesGroupIds).flat())).filter((item) => item)
    const selectedIds =
      selectedData[0]?.type === HirarchyTypes.Customer
        ? selectedFacilityGroupIds.includes(facilityGroup.id)
        : selectedDataObject.customerIds.includes(facilityGroup.customerId)
    return noCustomer || onlyFacilitiesGroup ? true : selectedIds
  })

  const filteredFacilityByGroup = facilities.filter((facility) => {
    return onlyFacilities
      ? facility.facilityTypeId === FacilityType.Shed
      : facility.facilityGroupId && selectedDataObject.facilityGroupIds.includes(facility.facilityGroupId)
  })

  const filteredZonesByFacility = filteredZonesByFacilityIds(selectedDataObject.facilityIds, facilities)

  const noZones =
    location.pathname === '/Iot/General' ||
    location.pathname === '/Analysis/Efficiency' ||
    location.pathname === '/Analysis/Sensors' ||
    location.pathname === '/Analysis/Sensors/Detailes'
      ? false
      : true

  return (
    <>
      {
        <AppLocationWrapper>
          <AppSelectInputContainer>
            {!noCustomer && !onlyFacilities && !onlyFacilitiesGroup && (
              <AppSelect
                label="Select Customer"
                inputName="customerIds"
                onChange={handleInputChange}
                value={selectedDataObject.customerIds}
                options={customersData}
                ShowLabel={false}
                multiple={false}
              />
            )}
            {!onlyFacilities && (
              <AppSelect
                label="Select Farm"
                inputName="facilityGroupIds"
                onChange={handleInputChange}
                value={selectedDataObject.facilityGroupIds}
                options={filteredfacilityGroupByCustomer}
                ShowLabel={false}
                multiple={multipleSelect}
              />
            )}
            {!onlyFacilitiesGroup && (
              <AppSelect
                label="Select Shed"
                inputName="facilityIds"
                onChange={handleInputChange}
                value={selectedDataObject.facilityIds}
                options={filteredFacilityByGroup}
                ShowLabel={false}
                multiple={onlyFacilities ? false : true}
              />
            )}
            {!onlyFacilities && !onlyFacilitiesGroup && noZones && (
              <AppSelect
                label="Select Zone"
                inputName="facilityZoneIds"
                onChange={handleInputChange}
                value={selectedDataObject.facilityZoneIds}
                options={filteredZonesByFacility}
                ShowLabel={false}
              />
            )}
          </AppSelectInputContainer>
        </AppLocationWrapper>
      }
    </>
  )
}

export default AppbarLocationComponent
