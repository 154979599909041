import aiIcon from './aiIcon.svg'
import analysisIcon from './analysisIcon.svg'
import customerIcon from './customerIcon.svg'
import farmIcon from './farmIcon.svg'
import iotIcon from './iotIcon.svg'
import nutritionIcon from './nutritionIcon.svg'
import reportIcon from './reportIcon.svg'
import devicesIcon from './devicesIcon.svg'

export const AiIcon = aiIcon
export const AnalysisIcon = analysisIcon
export const CustomerIcon = customerIcon
export const FarmIcon = farmIcon
export const IotIcon = iotIcon
export const NutritionIcon = nutritionIcon
export const ReportIcon = reportIcon
export const DevicesIcon = devicesIcon
