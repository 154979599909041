import React from 'react'
import {
  AppLiveIcon,
  MobileDateFilterWrapper,
  MobileDateFilterOptions,
  TodayIcon,
  CropIcon,
  CustomDateIcon,
  CloseImg
} from './MobileDataFilter.styled'
import { memo } from 'react'
import { CropMobileIcon, CustomDateMobileIcon, TodayMobileIcon } from '../../../../../assets/images/appBar'
import Drawer from '../Drawer/Drawer'
import { CloseIconImg } from '../../../../../assets/images/UsersImages'

interface Props {
  isOpen: { [key: string]: boolean }
  selectedDateButton: DateButtons
  onClick: Function
  handleDateButtonChange: Function
}
export enum DateButtons {
  Live,
  Today,
  Crop,
  Custom
}

const MobileDataFilter: React.FC<Props> = ({ isOpen, selectedDateButton, onClick, handleDateButtonChange }) => {
  return (
    <>
      <Drawer show={isOpen.drawer}>
        <CloseImg src={CloseIconImg} onClick={() => onClick()} />
        <MobileDateFilterWrapper>
          <MobileDateFilterOptions
            isActive={selectedDateButton === DateButtons.Live}
            onClick={() => handleDateButtonChange(DateButtons.Live)}
          >
            <AppLiveIcon isActive={selectedDateButton === DateButtons.Live} />
            LIVE
          </MobileDateFilterOptions>
          <MobileDateFilterOptions
            isActive={selectedDateButton === DateButtons.Today}
            onClick={() => handleDateButtonChange(DateButtons.Today)}
          >
            <TodayIcon src={TodayMobileIcon} isActive={selectedDateButton === DateButtons.Today} />
            Today
          </MobileDateFilterOptions>
          <MobileDateFilterOptions
            isActive={selectedDateButton === DateButtons.Crop}
            onClick={() => handleDateButtonChange(DateButtons.Crop)}
          >
            <CropIcon src={CropMobileIcon} isActive={selectedDateButton === DateButtons.Crop} />
            Crop Select
          </MobileDateFilterOptions>
          <MobileDateFilterOptions
            isActive={selectedDateButton === DateButtons.Custom}
            onClick={() => handleDateButtonChange(DateButtons.Custom)}
          >
            <CustomDateIcon src={CustomDateMobileIcon} isActive={selectedDateButton === DateButtons.Custom} />
            Custom Date poultry
          </MobileDateFilterOptions>
        </MobileDateFilterWrapper>
      </Drawer>
    </>
  )
}

export default memo(MobileDataFilter)
