import { FieldTypes, FieldVariant } from "./facility"

export const siloHeadHeaders = [
  {
    title: 'Name'
  },
  {
    title: 'Tag'
  },
  {
    title: 'Attached devices'
  },
  {
    title: 'Parent Farm'
  },
  {
    title: 'W'
  },
  {
    title: 'L'
  },
  {
    title: 'H'
  },
  {
    title: '# of Zones'
  },
  {
    title: 'Water Lines'
  },
  {
    title: 'Feed Lines'
  },
  {
    title: 'Action'
  }
]

export const siloHeadFields = {
  name: { label: 'Name', field: 'name', plceholder: 'Silo Head 001' },
  tagNumber: { label: 'Tag', field: 'tagNumber', placeholder: '001' },
  customerId: { label: 'Customer', field: 'customerId' },
  facilityGroupId: { label: 'Farm', field: 'facilityGroupId' },
  relatedFacilityId: { label: 'Shed', field: 'relatedFacilityId' },
  noOfSilos: { label: '# of Silos', field: 'noOfSilos', placeholder: '0' },
  ZoneName: { label: 'name', field: 'ZoneName', placeholder: 'Silo Name' },
  zoneTypeId: { label: 'SiloType', field: 'zoneTypeId' },
  createCustom: { label: 'Create Custom' },
  manufacturer: { label: 'manufacturer', field: 'manufacturer', placeholder: 'manufacturer' },
  cylinderHeight: { label: 'cylinderHeight', field: 'cylinderHeight', placeholder: '0' },
  coneHeight: { label: 'coneHeight', field: 'coneHeight', placeholder: '0' },
  diameter: { label: 'diameter', field: 'diameter', placeholder: '0' },
  total: { label: 'total', field: 'total' }
}

export const siloHeadPoultryDialog = [
  { label: 'Name', field: 'name', dataName: 'Name', type: FieldTypes.Input, variant: null, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true, placeholder: 'Silo Head 001' },
  { label: 'Tag', field: 'tagNumber', dataName: 'Tag', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true, placeholder:'001' },
  { label: 'Customer', field: 'customerId', dataName: 'Customers', type: FieldTypes.DropDown, variant: null, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true },
  { label: 'Farm', field: 'facilityGroupId', dataName: 'FacilityGroups', type: FieldTypes.DropDown, variant: null, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true },
  { label: 'Shed', field: 'relatedFacilityId', dataName: 'Facilities', type: FieldTypes.DropDown, variant: null, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true }
]

export const customSiloPoultryDialog = [
  { label: 'Silo type name', field: 'name', dataName: 'name', type: FieldTypes.Input, variant: null, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true },
  { label: 'Manufacturer', field: 'manufacturer', dataName: 'manufacturer', type: FieldTypes.Input, variant: null, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true },
  { label: 'Cylinder height (m)', field: 'cylinderHeight', dataName: 'cylinderHeight', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true, placeholder: '0' },
  { label: 'Cone height (m)', field: 'coneHeight', dataName: 'coneHeight', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true, placeholder: '0' },
  { label: 'Diameter (m)', field: 'diameter', dataName: 'diameter', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true, placeholder: '0' },
  { label: 'Total Vol.', field: 'total', dataName: 'total', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: true, isValidCheck: false },
]
