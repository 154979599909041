import { useEffect, useState, useMemo, useRef } from 'react'

const useTimer = () => {
  const [times, setTimes] = useState<Record<string, number>>({})
  const timers = useRef<Record<string, NodeJS.Timeout | null>>({})

  const startTimer = (deviceId: number, duration: number, startFromNow = true) => {
    let endTime: number

    if (startFromNow) {
      const startTime = Math.floor(Date.now() / 1000)
      endTime = startTime + duration
    } else {
      endTime = duration
    }

    const checkTimer = () => {
      const currentTime = Math.floor(Date.now() / 1000)
      const remainingTime = endTime - currentTime

      if (remainingTime <= 0) {
        clearInterval(timers.current[deviceId]!)
        setTimes((prevTimes) => ({
          ...prevTimes,
          [deviceId]: 0
        }))
      } else {

        setTimes((prevTimes) => ({
          ...prevTimes,
          [deviceId]: remainingTime
        }))
      }
    }

    checkTimer()

    timers.current[deviceId] = setInterval(checkTimer, 1000)
  }

  const stopTimer = (deviceId: number) => {
    clearInterval(timers.current[deviceId]!)
    setTimes((prevTimes) => ({
      ...prevTimes,
      [deviceId]: 0
    }))
  }

  useEffect(() => {
    return () => {
      Object.values(timers.current).forEach((timer) => {
        clearInterval(timer!)
      })
    }
  }, [])

  return useMemo(() => {
    return { times, startTimer, stopTimer }
  }, [times])
}

export default useTimer
