import * as React from 'react'
import { SVGProps } from 'react'
import { BeesTheme } from '../../../../../styles/Bees/theme'

interface DevicesTabProps {
  selected: boolean
}
const DevicesTab: React.FC<DevicesTabProps> = ({ selected }) => {
  const fill = selected ? 'white' : BeesTheme.colors.primary
  return (
    <svg transform="scale(0.7)" xmlns="http://www.w3.org/2000/svg" width={37.802} height={33.959}>
      <g data-name="Group 6114" fill={fill}>
        <path
          data-name="Path 4260"
          d="m18.899 33.957-9.594-5.539V17.345l9.594-5.538 9.593 5.538v11.076Zm-8.378-6.24 8.378 4.837 8.377-4.837v-9.673l-8.377-4.836-8.378 4.836Z"
        />
        <path
          data-name="Path 4261"
          d="M7.756 15.146a.608.608 0 0 1-.305-1.135l9.3-5.371a.608.608 0 1 1 .608 1.053l-9.3 5.371a.606.606 0 0 1-.303.082Z"
        />
        <path
          data-name="Path 4262"
          d="M4.555 13.098a.608.608 0 0 1-.305-1.134l12.5-7.218a.608.608 0 1 1 .608 1.053l-12.5 7.218a.605.605 0 0 1-.303.081Z"
        />
        <path
          data-name="Path 4263"
          d="M.609 10.713a.608.608 0 0 1-.305-1.134l16.448-9.5a.608.608 0 1 1 .608 1.053l-16.448 9.5a.593.593 0 0 1-.303.081Z"
        />
        <path
          data-name="Path 4264"
          d="M29.994 15.29a.6.6 0 0 1-.3-.08l-9.252-5.343a.608.608 0 1 1 .608-1.053l9.252 5.342a.608.608 0 0 1-.305 1.134Z"
        />
        <path
          data-name="Path 4265"
          d="M33.181 13.231a.6.6 0 0 1-.3-.081L20.443 5.968a.608.608 0 1 1 .608-1.053l12.438 7.181a.608.608 0 0 1-.3 1.135Z"
        />
        <path
          data-name="Path 4266"
          d="M37.193 10.881a.593.593 0 0 1-.3-.081L20.442 1.3A.608.608 0 0 1 21.05.247l16.451 9.5a.608.608 0 0 1-.305 1.134Z"
        />
        <path data-name="Path 4223" d="m18.898 15.986-6.2 3.582v7.158l6.2 3.582 6.2-3.582v-7.165Z" />
      </g>
    </svg>
  )
}

export default DevicesTab
