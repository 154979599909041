import inputContainer from './inputContainer.svg'
import logo from './pwLogo.svg'
import hive from './hive.svg'
import edit from './editIcon.svg'
import deleteImg from './deleteIcon.svg'
import locationImg from './locationIcon.svg'
import logsImg from './logIcon.svg'
import searchIcon from './searchIcon.svg'
import trendUp from './trendUp.svg'
import trendDown from './trendDown.svg'
import BeesBackground from './bees-background.svg'
import enGooglePlayStore from './en_google_play_store.png'
import enApplePlayStore from './Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import plusButtonCircle from './plus-button-circle.svg'
import crossIcon from './crossIcon.svg'
import SelectDropDown from './SelectDropDownIcon.svg'
import uploadBG from './uploadBG.svg'
import rightArrowIcon from './rightArrowIcon.svg'
import MenuProductIcon from './Menu-ProductIcon.svg'
import MenuSettingIcon from './Menu-SettingIcon.svg'
import MenuAccountIcon from './Menu-AccountIcon.svg'
import MenuLogoutIcon from './Menu-LogoutIcon.svg'
import MenuGASIcon from './Menu-GASIcon.svg'
import MenuRadarIcon from './Menu-RadarIcon.svg'
import MenuBeesIcon from './Menu-BeesIcon.svg'
import MenuPoultryIcon from './Menu-PoultryIcon.svg'
import MenuHydroIcon from './Menu-HydroIcon.svg'
import MenuRuminTechIcon from './Menu-RuminTechIcon.svg'
import MenuNevatimIcon from './Menu-NevatimIcon.svg'
import MenuBPACIcon from './Menu-BPACIcon.svg'
import MenuRighArrowtIcon from './Menu-RightArrowIcon.svg'
import RectangleShapeImage from './RectangleShape.svg'
import HexagonShapeImage from './HexagonShape.svg'

export const enGooglePlayStoreImage = enGooglePlayStore
export const enApplePlayStoreImage = enApplePlayStore
export const inputHexagon = inputContainer
export const pwLogo = logo
export const hiveIcon = hive
export const editIcon = edit
export const deleteIcon = deleteImg
export const locationIcon = locationImg
export const logsIcon = logsImg
export const searchImg = searchIcon
export const trendUpImg = trendUp
export const trendDownImg = trendDown
export const BeesBackgroundImg = BeesBackground
export const PlusButtonCircleImg = plusButtonCircle
export const crossIconImg = crossIcon
export const SelectDropDownIcon = SelectDropDown
export const uploadBGImg = uploadBG
export const rightArrowImgIcon = rightArrowIcon
export const MenuProductIconImg = MenuProductIcon
export const MenuSettingIconImg = MenuSettingIcon
export const MenuAccountIconImg = MenuAccountIcon
export const MenuLogoutIconImg = MenuLogoutIcon
export const MenuGASIconImg = MenuGASIcon
export const MenuRadarIconImg = MenuRadarIcon
export const MenuBeesIconImg = MenuBeesIcon
export const MenuPoultryIconImg = MenuPoultryIcon
export const MenuHydroIconImg = MenuHydroIcon
export const MenuRuminTechIconImg = MenuRuminTechIcon
export const MenuNevatimIconImg = MenuNevatimIcon
export const MenuBPACIconImg = MenuBPACIcon
export const MenuRighArrowtIconImg = MenuRighArrowtIcon
export const RectangleIcon = RectangleShapeImage
export const HexagonIcon = HexagonShapeImage
