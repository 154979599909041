import logo from './beesLogo.svg'
import loginFormImage from './loginFormImage.svg'
import PoultryWeights from './PoultryWeights.svg'
import SmallLogo from './zeusSmallLogo.svg'
import Close from './Close.svg'
import RuminTechLogoImg from './RuminTechLogo.svg'
import RadarLogoImg from './RadarLogo.svg'
import BpacLogoImg from './logo_bpac.svg'

export const beesLogo = logo
export const loginFormBackground = loginFormImage
export const PoultryLogo = PoultryWeights
export const zeusSmallLogo = SmallLogo
export const CloseIcon = Close
export const RuminTechLogo = RuminTechLogoImg
export const BpacLogo = BpacLogoImg
export const RadarLogo = RadarLogoImg
