import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverGetFacilitiesGroups, serverCreateFacilityGroup } from '../services/serverApi'
import { IFacilityGroup } from '../types/facilityGroup'

type FacilityGroupState = {
  facilitiesGroups: Array<IFacilityGroup>
  loading: boolean
  error: string | null
}

const initialState: FacilityGroupState = {
  facilitiesGroups: [],
  loading: false,
  error: null
}

/**Get FacilitiesGroup */
export const getFacilitiesGroups = createAsyncThunk('getSite', async (s, thunkApi) => {
  try {
    const res = await serverGetFacilitiesGroups()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add device  */
export const addFacilityGroup = createAsyncThunk('addSite', async (facilityGroup: IFacilityGroup, thunkApi) => {
  try {
    const res = await serverCreateFacilityGroup(facilityGroup)
    thunkApi.dispatch(getFacilitiesGroups())
  } catch (e) {
    console.error(e)
  }
})

export const facilityGroupSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getFacilitiesGroups.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getFacilitiesGroups.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.facilitiesGroups = payload as IFacilityGroup[]
      }),
      builder.addCase(getFacilitiesGroups.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      builder.addCase(addFacilityGroup.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
      }),
      builder.addCase(addFacilityGroup.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addFacilityGroup.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const facilityGroupReducer = facilityGroupSlice.reducer
export const facilityGroupActions = facilityGroupSlice.actions
