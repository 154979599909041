import styled from 'styled-components'

export const UploadDiv = styled.div`
  border: 0.12rem dashed ${({ theme }) => theme.typography.colors.forth};
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;

  &:hover {
    border: 2px dashed ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary};
  }
  /*  on active */
  &:active {
    border: 2px dashed ${({ theme }) => theme.colors.tertiary};
    color: ${({ theme }) => theme.colors.tertiary};
  }
`

export const UploadFileImg = styled.img``

export const UploadFileText = styled.p``

export const UploadFileInput = styled.input``
