import { customerManagmentSelectIcon } from '../../../../assets/images/sideMenu'
import {
  iotIcon,
  devicesIcon,
  userIcon,
  analysisIcon,
  siteIcon,
  workPlanIcon,
  alertIcon
} from '../../../../assets/images/sideMenu/Bees/sideMenu/index'
import { INavbarBees } from 'sidebarMenu'
import { IPermission } from 'permission'
import { checkPermissions } from '../../../../utils/helpers/permissionHelper'
import { SidebarData } from '../../PoultryWeights/routes'

const sidebarData: INavbarBees[] = [
  {
    name: 'IoT',
    image: iotIcon,
    path: '/Iot/General'
  },

  {
    name: 'Users',
    image: userIcon,
    path: '/customerMgmt/users'
  },
  {
    name: 'Devices',
    image: devicesIcon,
    path: '/devices'
    // subMenu: [
    //   {
    //     name: 'Summary',
    //     image: devicesIcon,
    //     path: '/Myfarm/devices'
    //   },
    //   {
    //     name: 'Devices Version',
    //     image: devicesIcon,
    //     path: '/MyFarm/device-version'
    //   }
    // ]
  },
  // {
  //   name: 'Devices',
  //   image: devicesIcon,
  //   path: '/Myfarm/devices'
  // },
  // // TODO: Change ICON
  // {
  //   name: 'Devices Version',
  //   image: devicesIcon,
  //   path: '/MyFarm/device-version'
  // },
  {
    name: 'Alerts',
    image: alertIcon,
    path: '/alerts'
  },
  {
    name: 'Sites',
    image: siteIcon,
    path: '/sites'
  },
  {
    name: 'Analysis',
    image: analysisIcon,
    path: '/analysis/Efficiency'
  },
  {
    name: 'Customers',
    image: customerManagmentSelectIcon,
    path: '/customerMgmt/customer'
  },
  {
    name: 'WorkPlan',
    image: workPlanIcon,
    path: '/workPlan'
  },
  {
    name: 'Roles',
    image: customerManagmentSelectIcon,
    path: '/customerMgmt/role'
  }
  /*{
    name: 'Map',
    image: mapIcon,
    path: '/map'
  }*/
  /*{
    name: 'Settings',
    image: settingsImage,
    path: '/settings'
  },
  {
    name: 'Learnings',
    image: learnImage,
    path: '/learnings'
  }*/
]
export default sidebarData
export const getSidebarData = (routesdata: INavbarBees[], permissions: IPermission[]) => {
  if (permissions.length === 0) {
    return routesdata
  }
  routesdata = routesdata.filter((route: INavbarBees, index: number) => {
    switch (route.path) {
      case '/Iot/General':
        return checkPermissions('view-iot', permissions)
        break
      case '/customerMgmt/users':
        return checkPermissions('view-user', permissions)
        break
      case '/devices':
        return checkPermissions('view-device', permissions)
        break
      case '/alerts':
        return checkPermissions('view-alert', permissions)
        break
      case '/sites':
        return checkPermissions('view-site', permissions)
        break
      case '/analysis/Efficiency':
        return checkPermissions('view-analysis', permissions)
        break
      case '/customerMgmt/customer':
        return checkPermissions('view-customer', permissions)
        break
      case '/workPlan':
        return checkPermissions('view-workplan', permissions)
        break
      case '/customerMgmt/roles':
        return checkPermissions('view-role', permissions)
        break
    }
    return true
  })
  return routesdata
}
