import BolusIcon from './BolusDeviceIcon.svg'
import GatewayImage from './GatewayIcon.svg'
import ChartImage from './ChartImage.svg'
import AddDeviceImage from './AddDeviceDialogIcon.svg'
import AddGatewayDialogImage from './AddGatewayDialogIcon.svg'
import AddGatewayIcon from './AddGatewayIcon.svg'
import AddDeviceButton from './AddDeviceButton.svg'
import AppEuiImage from './AppEuiIcon.svg'
import AppKeyImage from './AppKeyIcon.svg'
import GatewayEuiImage from './GatewayEuiIcon.svg'
import SerialNumberImage from './SerialNumberIcon.svg'
import SimImage from './SimIcon.svg'
import StatusImage from './StatusIcon.svg'
import TransmittingImage from './TransmittingIcon.svg'
import UpTimeImage from './UptimeIcon.svg'
import VersionImage from './VersionIcon.svg'

export const BolusDeviceIcon = BolusIcon
export const GatewayIcon = GatewayImage
export const ChartIcon = ChartImage
export const AddDeviceDialogIcon = AddDeviceImage
export const AddGatewayDialogIcon = AddGatewayDialogImage
export const AddGatewayButton = AddGatewayIcon
export const AddDeviceButtonIcon = AddDeviceButton

export const AppEuiIcon = AppEuiImage
export const AppKeyIcon = AppKeyImage
export const GatewayEuiIcon = GatewayEuiImage
export const SerialNumberIcon = SerialNumberImage
export const SimIcon = SimImage
export const StatusIcon = StatusImage
export const TransmittingIcon = TransmittingImage
export const UpTimeIcon = UpTimeImage
export const VersionIcon = VersionImage
