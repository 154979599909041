import addDeviceIcon from './AddDeviceBtn.svg'
import addDeviceIconMobile from './addDeviceBtnMobile.svg'
import deviceTypeStatusOnline from './deviceTypeStatusOnline.svg'
import deviceTypeStatusAlert from './deviceTypeStatusAlert.svg'
import deviceTypeStatusError from './deviceTypeStatusError.svg'
import MapIcon from './Map.svg'
import GoogleMap from './GoogleMap.svg'
import Waze from './Waze.svg'
import GasBulkImg from './GasBulk.svg'
import AddDevice from './AddDeviceIcon.svg'
import DeviceCalibration from './deviceCalibration.svg'
import GasBulk from './Gas/GasBulk.svg'
import GasMeter from './Gas/GasMeter.svg'
import GasMulti from './Gas/GasMulti.svg'
import weightImg from './weightIcon.svg'
import climentImg from './climentIcon.svg'
import crossImg from './crossIcon.svg'

export const addDeviceBtn = addDeviceIcon
export const addDeviceMobileBtn = addDeviceIconMobile
export const deviceTypeStatusOnlineImage = deviceTypeStatusOnline
export const deviceTypeStatusAlertImage = deviceTypeStatusAlert
export const deviceTypeStatusErrorImage = deviceTypeStatusError
export const mapIcon = MapIcon
export const googleMapIcon = GoogleMap
export const wazeIcon = Waze
export const GasBulkIcon = GasBulkImg
export const AddDeviceIcon = AddDevice
export const DeviceCalibrationIcon = DeviceCalibration
export const GasBulkIcon2 = GasBulk
export const GasMeterIcon = GasMeter
export const GasMultiIcon = GasMulti
export const weightIcon = weightImg
export const climentIcon = climentImg
export const crossIcon = crossImg
