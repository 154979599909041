import * as React from 'react'
import { SVGProps } from 'react'
import { BeesTheme } from '../../../../../styles/Bees/theme'

interface SiteTabProps {
  selected: boolean
}
const SeasonsTab: React.FC<SiteTabProps> = ({ selected }) => {
  const fill = selected ? 'white' : BeesTheme.colors.primary
  return (
    <svg transform="scale(0.65)" xmlns="http://www.w3.org/2000/svg" width={34.749} height={44}>
      <path
        data-name="Path 4341"
        d="M20.895 13.288c.782-.6 1.252-.972 1.737-1.326 3.608-2.634 7.388-2.637 11.311-.767.832.4.984.857.62 1.71-1.81 4.247-4.876 6.711-9.583 6.93-.231.011-.461.041-.88.079.789.7 1.474 1.272 2.113 1.889 2.551 2.457 3.071 5.545 2.76 8.9-.1 1.044-.6 1.345-1.616 1.27a10.818 10.818 0 0 1-8.9-5.337v11.889c2.345-1.815 4.568-3.662 7.843-3.777a7.708 7.708 0 0 1-.566 1.459 10.067 10.067 0 0 1-5.683 4.218c-1.323.387-1.965.993-1.712 2.4a3.766 3.766 0 0 1-.079.973c-1.227.46-1.848.139-1.7-1.174.134-1.237-.417-1.706-1.607-2.05a10.535 10.535 0 0 1-6.881-5.816c3.561.065 5.885 1.978 8.337 4.072V26.12c-.566.83-.913 1.363-1.283 1.877a9.493 9.493 0 0 1-8.387 3.906c-.357-.005-.967-.758-1.011-1.208-.37-3.791.525-7.16 3.58-9.709l1.12-.936c-1.385-.287-2.626-.468-3.823-.806-3.462-.979-5.385-3.534-6.584-6.728-.111-.294.261-.977.6-1.2a10.243 10.243 0 0 1 11.4.63c.485.376.982.738 1.709 1.284-.282-1.02-.5-1.742-.68-2.475-1-4.119.379-7.451 3.372-10.278.741-.7 1.261-.592 1.939.067 3.32 3.225 4.45 6.988 2.92 11.441-.112.303-.188.615-.386 1.303Zm-3.6 8.414a2.862 2.862 0 1 0-2.851-2.859 2.979 2.979 0 0 0 2.854 2.859Z"
        fill={fill}
      />
    </svg>
  )
}

export default SeasonsTab
