import { CircularProgress } from '@mui/material'
import React from 'react'
import { LoaderWrapper } from './Loader.styled'

interface ILoader {
    size?: number
}
const Loader = ({size}: ILoader) => {
  return (
    <LoaderWrapper>
     <CircularProgress size={size ?? 80} />
    </LoaderWrapper>
  )
}

export default Loader
