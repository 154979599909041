import { Timer } from '@material-ui/icons'
import { IdeviceVersion } from 'deviceVersion'
import { useEffect } from 'react'
import { getStringDateFromSeconds } from '../../utils/helpers/date'
import { DeviceVersionStatus, installationTime } from './DeviceVersion.options'
import {
  InstallingTimer,
  InstallingTimerIcon,
  ProgressBarColumnWrapper,
  ProgressValuePercent,
  ProgressValWrapper,
  StyledLinearProgress
} from './DeviceVersion.styled'
import useTimer from '../../utils/helpers/useTimer'

type Props = {
  data: IdeviceVersion
  status?: DeviceVersionStatus
}

const ProgressIndicator = (props: Props) => {
  const { startTimer, stopTimer, times } = useTimer()
  const { data, status } = props

  useEffect(() => {
    times[data?.deviceId] && startTimer(4708, installationTime)
    return
  }, [])
  return (
    <ProgressBarColumnWrapper>
      <StyledLinearProgress variant="determinate" value={data?.progress || data.currentPercent || 0} />
      <ProgressValWrapper>
        {status === DeviceVersionStatus.INSTALLING && (
          <InstallingTimerIcon>
            <Timer fontSize="inherit" />
            <InstallingTimer>{getStringDateFromSeconds(times[String(data?.deviceId)] || 0)}</InstallingTimer>
          </InstallingTimerIcon>
        )}
        {status === DeviceVersionStatus.IN_PROGRESS && (
          <ProgressValuePercent>{data?.progress || data.currentPercent || 0}% Downloaded</ProgressValuePercent>
        )}
      </ProgressValWrapper>
    </ProgressBarColumnWrapper>
  )
}

export default ProgressIndicator
