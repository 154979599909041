import { FieldTypes } from './facility'

export const addDeviceVersionFields = [
  {
    label: 'Device Type',
    field: 'deviceType',
    select: true,
    variant: null,
    placeholder: 'deviceTypePlaceHolder',
    rows: 4,
    required: false,
    multiline: true,
    tranlationKey: { label: 'deviceType', placeholder: 'deviceTypePlaceHolder' },
    type: FieldTypes.DropDown
  },
  {
    label: 'Version No',
    field: 'versionNumber',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.Input,
    tranlationKey: { label: 'versionNumber', placeholder: 'versionNumberPlaceholder' },
    placeholder: 'Enter version no'
  },
  {
    label: 'Release Note',
    field: 'releaseNote',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Type your note here....',
    multiline: true,
    tranlationKey: { label: 'releaseNote', placeholder: 'releaseNotePlaceHolder' },
    type: FieldTypes.Input,
    rows: 4
  }
]

export const editDeviceVersionFields = [
  {
    label: 'Device type',
    field: 'deviceType',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    tranlationKey: { label: 'deviceType', placeholder: 'deviceTypePlaceHolder' },
    placeholder: 'Select device type'
  },
  {
    label: 'Customer',
    field: 'customer',
    select: true,
    variant: null,
    placeholder: 'Select a device type',
    rows: 4,
    multiline: true,
    tranlationKey: { label: 'customer', placeholder: 'customerPlaceHolder' },
    type: FieldTypes.DropDown
  },
  {
    label: 'Site',
    field: 'site',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    tranlationKey: { label: 'site', placeholder: 'sitePlaceHolder' },
    placeholder: 'Select site'
  },
  {
    label: 'Version no',
    field: 'versionNumber',
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    type: FieldTypes.DropDown,
    tranlationKey: { label: 'versionNumber', placeholder: 'versionNumberPlaceholder' },
    placeholder: 'Select version no'
  }
]

export const currentUpdating = 'Current updating devices'
export const updateHistory = 'Devices update history'
export const UPLOAD_FILE = 'Upload File'
export const SELECT_D_TYPE = 'Select a device type'
export const ADD_VERSION = 'Upload a new version'
export const UPDATE_VERSION = 'Choose devices for update'

export const deviceVersionErrMessages = {
  releaseNote: 'Release Note is required.',
  versionNumber: 'Version Number is required.',
  deviceType: 'Device Type is required.'
}
