import { DeviceTypes } from '../../types/device.d'
import { ClimateSensorIcon, WaterIcon } from '../../assets/images/crop'
import { gatewayIcon } from '../../assets/images/gateway'
export const BpacDevicesConfig = {
  ClimateStick: {
    name: 'Climate Sensors',
    Icon: ClimateSensorIcon,
    deviceType: DeviceTypes.ClimateStick
  },
  WaterMeter: {
    name: 'Water Meter',
    Icon: WaterIcon,
    deviceType: DeviceTypes.WaterMeter
  }
}

export const gateWaysConfig = {
  Gateway_Type_A: {
    name: 'Gateway Type A',
    Icon: gatewayIcon,
    deviceType: DeviceTypes.Gateway
  }
}
