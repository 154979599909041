import { SmallAppTableWrapper } from '../../components/AppTableWrapper/AppTableWrapper.styled'
import {
  HeaderTitle,
  DeviceVersionBodyContainer,
  DeviceVersionContainer,
  DeviceVersionHeader,
  DeviceVersionHeaderContainer,
  FlexColumnWrapper,
  ButtonWrapper
} from './DeviceVersion.styled'
import AppTable from '../../components/AppTable/AppTable'
import { deviceVersionTableOptions } from './DeviceVersion.options'
import useDV from './useDV'
import AddEditButton from '../../components/AddEditButton/AddEditButton'
import AddDeviceVersionDialog from '../../dialogs/AddDeviceVersionDialog/AddDeviceVersionDialog'
import themes from '../../styles'
import { currentUpdating, updateHistory } from '../../utils/consts/devicesVersion'
import UpdateDeviceVersionDialog from '../../dialogs/UpdateDeviceVersionDialog/UpdateDeviceVersionDialog'
import { GetDataModelId } from '../../utils/helpers/dataModel'
import { DataModel } from '../../types/dataModel.d'
import { useNavigate } from 'react-router-dom'
import { ShedsBackTitle } from '../Devices/RuminTech/Gateways/Gateways.styled'
import { padding } from '@mui/system'
import { ArrowImg, ShedsBackTitleIcon, SummaryBackIcon } from '../Devices/RuminTech/Devices.styled'
import { LeftArrowIcon } from '../../assets/images/sheds'
import { DELETE, DELETE_MESSAGE } from '../../utils/consts/globalConsts'
import MessageDialog from '../../dialogs/MessageDialog/MessageDialog'
import { useTranslation } from 'react-i18next'

const DeviceVersion = () => {
  const {
    versionUpdatingHeader,
    versionHistoryHeader,
    isOpen,
    setIsOpen,
    openMessageDialog,
    isLoading,
    getDeviceUpdateVersion,
    deviceUpdateVersionData,
    updatingDevices,
    onConfirm,
    onCancel,
    Actions,
    allDeviceUpdateData
  } = useDV()

  const { t } = useTranslation()

  const dataModelId = GetDataModelId()
  const navigate = useNavigate()

  return (
    <FlexColumnWrapper
      dataModelId={dataModelId}
      style={{
        backgroundColor: themes().colors.white,
        borderRadius: '12px'
      }}
    >
      <ButtonWrapper>
        {dataModelId === DataModel.Bees ? (
          <ShedsBackTitleIcon data-cy="BackToSummary" onClick={() => navigate('/devices')}>
            <SummaryBackIcon>
              <ArrowImg src={LeftArrowIcon} />
            </SummaryBackIcon>
            <ShedsBackTitle
              style={{
                marginLeft: '0.5rem'
              }}
            >
              {t(`device-update.updateDeviceVersion`)}
            </ShedsBackTitle>
          </ShedsBackTitleIcon>
        ) : (
          <div />
        )}
        <div>
          <AddEditButton
            style={{
              backgroundColor:
                dataModelId === DataModel.Bees
                  ? themes().colors.primary
                  : dataModelId === DataModel.Gas
                  ? themes().colors.white
                  : themes().colors.forth,
              color: dataModelId === DataModel.Bees ? themes().colors.white : themes().colors.primary,
              border: dataModelId === DataModel.Bees ? `1px solid ${themes().colors.secondary}` : `1px solid ${themes().colors.primary}`,
              height: '2.5rem',
              width: '7.5rem',
              fontSize: '0.875rem',
              margin: '0 0.625rem'
            }}
            onClick={() => {
              setIsOpen('edit')
            }}
            titleChanged={t('device-update.updateDialog.updateDevice')}
          />
          <AddEditButton
            onClick={() => {
              setIsOpen('add')
            }}
            style={{
              height: '40px',
              width: '120px',
              fontSize: '14px'
            }}
            titleChanged={t('device-update.updateDialog.addVersion')}
          />
        </div>
      </ButtonWrapper>

      <SmallAppTableWrapper
        style={{
          borderRadius: '0',
          borderTop: '0.5px solid #212529'
        }}
      >
        <DeviceVersionHeaderContainer>
          <DeviceVersionHeader>
            <HeaderTitle>{t('device-update.currentUpdating')}</HeaderTitle>
          </DeviceVersionHeader>
        </DeviceVersionHeaderContainer>
        <DeviceVersionBodyContainer>
          <AppTable
            options={deviceVersionTableOptions}
            isLoading={isLoading}
            rows={updatingDevices}
            headers={versionUpdatingHeader}
            actions={Actions}
          />
        </DeviceVersionBodyContainer>
      </SmallAppTableWrapper>

      <SmallAppTableWrapper
        style={{
          borderRadius: '0',
          borderTop: '1px solid #212529'
        }}
      >
        <DeviceVersionContainer>
          <DeviceVersionHeaderContainer>
            <DeviceVersionHeader>
              <HeaderTitle>{t('device-update.devicesUpdateHistory')}</HeaderTitle>
            </DeviceVersionHeader>
          </DeviceVersionHeaderContainer>
          <DeviceVersionBodyContainer>
            <AppTable
              options={deviceVersionTableOptions}
              isLoading={isLoading}
              rows={deviceUpdateVersionData.filter((e) => !e?.updating)}
              headers={versionHistoryHeader}
              actions={Actions}
            />
          </DeviceVersionBodyContainer>
        </DeviceVersionContainer>
      </SmallAppTableWrapper>
      <AddDeviceVersionDialog open={isOpen === 'add'} setIsOpen={setIsOpen} getDeviceUpdateVersion={getDeviceUpdateVersion} />
      <UpdateDeviceVersionDialog
        open={isOpen === 'edit'}
        setIsOpen={setIsOpen}
        getDeviceUpdateVersion={getDeviceUpdateVersion}
        allDeviceUpdateData={allDeviceUpdateData}
      />
      <MessageDialog
        open={openMessageDialog}
        title={t('device-update.deleteDeviceVersion')}
        message={t('device-update.deleteMessage')}
        showCancelButton={true}
        okButtonText={`${t('general.yes')}`}
        cancelButtonText={`${t('general.no')}`}
        onSuccess={onConfirm}
        onClose={onCancel}
      />
    </FlexColumnWrapper>
  )
}

export default DeviceVersion
