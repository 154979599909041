import themes from '../../../styles'

const theme = themes()
export const materailTableOptions = {
  maxBodyHeight: 'calc(100vh - 350px)',
  minBodyHeight: 0,
  pageSize: 10,
  search: true,
  toolbar: true,
  showTextRowsSelected: false,
  showFirstLastPageButtons: false,
  paging: true,
  pageSizeOptions: [2, 5, 10, 20],
  showTitle: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
  sorting: false,
  rowStyle: {
    color: '#7B7B7B',
    whiteSpace: 'noWrap'
  },
  headerStyle: {
    backgroundColor: theme.colors.primary,
    color: '#FFF',
    fontWeight: '600'
  }
}
