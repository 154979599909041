import { Button } from '@mui/material';
import React from 'react';

interface ButtonProps {
  children?: any;
  variant?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  cy?: string
  isWarning?: boolean
}

const BlockButton: React.FC<ButtonProps> = ({ children, disabled, variant, className, onClick, type, cy, isWarning }) => {
  return (
    <Button data-cy={cy} disabled={disabled} variant="contained" color={isWarning ? 'primary' : 'error'} type={type} className={'btn-block btnBlock ' + className} onClick={onClick}>
      {children}
    </Button>
  );
};

export default BlockButton;
