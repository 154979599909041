import { Title } from './../../views/Sensors/MobileSensors/SensorsSheds/WeightWaterFeedBox/WeightWaterFeedBox.styled'
import { Display } from './../FarmDialogs/AddShedDialog/ZoneDialog/ZoneDialog.styled'
import styled from 'styled-components'
import themes from '../../styles'

export const UpdateEventDialogContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  display: flex;
  gap: 1.25rem;
`

export const DropDownWrapper = styled.div`
  width: 40%;
`

export const ReleaseNoteWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.typography.colors.forth};
  border-radius: 8px;
  width: 40%;
  padding: 0.75rem;
`

export const TitleWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.size.md};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`

export const VersionHeader = styled.div`
  /* font-size mid from theme */
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0.25rem 0 1rem;
`

export const ListWrapper = styled.ul`
  max-height: 23rem;
  overflow: auto;
  font-size: ${({ theme }) => theme.typography.size.sm};
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Feature = styled.li`
  ::before {
    content: '';
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    margin-right: 0.5rem;
  }
`

export const NoNote = styled.div`
  text-align: center;
  color: #8f8f8f;
`

export const TableWrapper = styled.div`
  width: 100%;
  padding: 0.75rem;
  overflow: auto;
`

export const DeviceVersionHeaderContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.typography.colors.forth};
  display: flex;
  flex-direction: column;
`
export const DeviceVersionBodyContainer = styled.div`
  height: 435px;
`
export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => themes().colors.primary};
  font-weight: bold;
`

export const DeviceVersionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const Form = styled.form``
