import styled, { createGlobalStyle } from 'styled-components'
import { AppTypographyProps, FlexBoxGap, FlexBoxProps } from 'styles'

export const GlobalStyle = createGlobalStyle`
  body {
font-family: 'Open Sans', sans-serif;
padding:0px;
margin: 0px;
  }
 
`

export const OnlyMobileContainer = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: unset;
  }
`

export const OnlyBigScreens = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: unset;
    height: 100%;
    width: 100%;
  }
`

export const OnlySmallScreens = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: unset;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
`

export const OnlySmallScreensUsers = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: unset;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
`

export const SeperateLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: 0.2;
`

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  gap: ${({ gap }) => gap};
  align-items: center;
  justify-content: center;
`

export const AppTypography = styled.span<AppTypographyProps>`
  font-size: ${({ size, theme }) => size ?? theme.typography.size.sm};
  font-weight: ${({ bold, theme }) => bold ?? theme.typography.bold.sm};
  color: ${({ textColor, theme }) => textColor ?? theme.typography.colors.white};
  white-space: nowrap;
  /* margin-left: 30px; */
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ size, theme }) => size ?? theme.typography.size.xs};
    text-align: center;
    margin-left: ${({ theme }) => theme.spacing.sm};
  }
`

export const AppBoldText = styled.span`
  color: ${({ theme }) => theme.typography.colors.primary};
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: ${({ theme }) => theme.typography.bold.lg};
`

export const AppLightText = styled.span`
  color: ${({ theme }) => theme.typography.colors.primary};
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: ${({ theme }) => theme.typography.bold.sm};
  white-space: nowrap;
`

export const AppLightTextItalic = styled(AppLightText)`
  font-style: italic;
`
//to do style for site container
export const Dialogcontainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`
