import { FieldTypes, FieldVariant } from './facility'

export const EventRuminTechDialog = [
  {
    label: 'Group',
    field: 'monitoredGroupId',
    dataName: 'Groups',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    translationKey: { label: 'group', placeholder: 'groupPlaceHolder' },
    isValidCheck: true,
    placeholder: null
  },
  {
    label: 'Shower Start',
    field: 'startTime',
    dataName: 'Showers',
    type: FieldTypes.Input,
    variant: FieldVariant.Time,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    translationKey: { label: 'startTime', placeholder: 'startTimePlaceHolder' },
    isValidCheck: true,
    placeholder: null
  },
  {
    label: 'Shower End',
    field: 'endTime',
    dataName: 'Showers',
    type: FieldTypes.Input,
    variant: FieldVariant.Time,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    translationKey: { label: 'endTime', placeholder: 'endTimePlaceHolder' },
    placeholder: null
  }
]
