import farmSelectedImg from './farmSelectedIcon.svg'
import farmImg from './farmIcon.svg'
import inventorySelectedImg from './inventorySelectedIcon.svg'
import inventoryImg from './inventoryIcon.svg'
import analysisSelectedImg from './analysisSelectedIcon.svg'
import analysisImg from './analysisIcon.svg'
import crmImg from './crmIcon.svg'
import crmSelectedImg from './crmSelectedIcon.svg'
import taskSelectedImg from './taskSelectedIcon.svg'
import taskImg from './taskIcon.svg'
import iotSelectedImg from './iotSelectedIcon.svg'
import iotImg from './iotIcon.svg'
import reportSelectedImg from './reportSelectedIcon.svg'
import reportImg from './reportIcon.svg'

export const farmSelectedIcon = farmSelectedImg;
export const farmIcon = farmImg;
export const inventorySelectedIcon = inventorySelectedImg;
export const inventoryIcon = inventoryImg;
export const analysisSelectedIcon = analysisSelectedImg;
export const analysisIcon = analysisImg;
export const crmIcon = crmImg;
export const crmSelectedIcon = crmSelectedImg;
export const taskSelectedIcon = taskSelectedImg;
export const taskIcon = taskImg;
export const iotSelectedIcon = iotSelectedImg;
export const iotIcon = iotImg;
export const reportSelectedIcon = reportSelectedImg;
export const reportIcon = reportImg;