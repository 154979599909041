import React, { useCallback, useState } from 'react'
import AppGoogleMap from './AppGoogleMap/AppGoogleMap'
import {
  AdressHeading,
  ApartmentHeading,
  BuildingDesWrapper,
  BuildingImage,
  BuildingList,
  BuildingListContainer,
  BuildingMapContainer,
  DecContainer,
  DeviceMapeHeading,
  ImageContainer,
  ImageInnerContainer,
  InnerListContainer,
  List,
  MapContainer
} from './BuildingMap.styled'
import { AppTableWrapper } from '../../components/AppTableWrapper/AppTableWrapper.styled'
import { RootState, useReduxSelector } from '../../redux'
import { BpacBuildingIcon } from '../../assets/images/sheds'
import { IFacility } from 'facility'
import { calculateCenterusingLatLong } from '../../utils/helpers/map'

const BuildingMap = () => {
  const { facilities } = useReduxSelector((state: RootState) => state.facilities)
  const [buildingLatlong, setBuildingLatlong] = useState<{ lat: number; lng: number }>()
  const [selectedFacilityId, setSelectedfacilityId] = useState<number>(0)
  const BuidingPositions = facilities.map((facility) => {
    return {
      lat: facility?.lat,
      lng: facility?.long,
      name: facility?.name,
      id: facility?.id,
      units: facility.zones.length
    }
  })
  const BuildingLatLong = facilities
    .filter((facility) => facility?.lat !== undefined && facility?.long !== undefined)
    .map((facility) => {
      if (facility?.lat && facility?.long) {
        return {
          lat: facility.lat,
          lng: facility.long
        }
      }
      return null
    })
    .filter((coordinate) => coordinate !== null) as { lat: number; lng: number }[]

  const center = BuildingLatLong.length > 0 ? calculateCenterusingLatLong(BuildingLatLong) : null

  const handleBuildingClick = (facility: IFacility) => {
    setSelectedfacilityId(facility.id)
    if (facility.lat && facility.long) {
      setBuildingLatlong({
        lat: facility.lat,
        lng: facility.long
      })
    }
  }

  return (
    <AppTableWrapper>
      <BuildingMapContainer>
        <MapContainer>
          <DeviceMapeHeading>Building Map</DeviceMapeHeading>
          <AppGoogleMap
            width={'auto'}
            height={'100%'}
            devices={BuidingPositions as { lat: number; lng: number; id: number; name: string }[]}
            center={buildingLatlong ?? (center as any)}
          />
        </MapContainer>
        <BuildingDesWrapper>
          <DeviceMapeHeading>Building List</DeviceMapeHeading>
          <BuildingListContainer>
            {facilities.map((facility: IFacility) => {
              return (
                <BuildingList onClick={() => handleBuildingClick(facility)}>
                  <InnerListContainer selected={facility.id === selectedFacilityId}>
                    <ImageContainer>
                      <ImageInnerContainer>
                        <BuildingImage src={BpacBuildingIcon} />
                      </ImageInnerContainer>
                    </ImageContainer>
                    <DecContainer>
                      <List selected={facility.id === selectedFacilityId}>{facility.name}</List>
                      <AdressHeading selected={facility.id === selectedFacilityId}>Address: {facility.address ?? 'N/A'}</AdressHeading>
                      <ApartmentHeading selected={facility.id === selectedFacilityId}>Units: {facility.zones.length}</ApartmentHeading>
                    </DecContainer>
                  </InnerListContainer>
                </BuildingList>
              )
            })}
          </BuildingListContainer>
        </BuildingDesWrapper>
      </BuildingMapContainer>
    </AppTableWrapper>
  )
}

export default BuildingMap
