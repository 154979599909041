import { GetConfig } from '../../../../config/config'
import { RootState, useReduxSelector } from '../../../../redux'
import { AppBarWrapper, AppBarIcon } from './AppBarLogo.styled'

const AppBarLogo = () => {
  const config = GetConfig()?.global
  const { customers } = useReduxSelector((state: RootState) => state.customers)
  const logo = customers[0]?.logoImage ? customers[0].logoImage : config?.logo

  return (
    <AppBarWrapper>
      <AppBarIcon src={logo} />
    </AppBarWrapper>
  )
}

export default AppBarLogo
