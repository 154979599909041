import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledBurger = styled.button<{ open: boolean }>`
  position: absolute;
  top: 2.5%;
  left: ${({ open }) => (open ? '16rem' : '1rem')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #ffffff;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      display: ${({ open }) => (open ? 'none' : 'block')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
      display: ${({ open }) => (open ? 'none' : 'block')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      display: ${({ open }) => (open ? 'none' : 'block')};
    }
  }
`
export const CloseImage = styled.img<{ open: boolean }>`
  z-index: 12;
  display: ${({ open }) => (open ? 'block' : 'none')};
`
export const BackButton = styled.img`
  position: relative;
  top: 2.5%;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  padding: 0;
  z-index: 12;
  margin-bottom: 50px;
`

export const StyledMenu = styled.nav<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  background: #113252;
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 305px;
  z-index: 11;
  transition: 0.3s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transform: ${({ isActive }) => (isActive ? 'translateX(-0%)' : 'translateX(-100%)')};
`

export const SidebarLink = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-size: 22px;
  color: #ffffff;
  text-decoration: none;
  transition: color 3.3s linear;
  background: ${({ isActive }) => (isActive ? '#F2DDB8' : 'transparent')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};

  &:hover {
    color: #ffffff;
  }

  &:focus {
    background: #f2ddb8;
    color: #ffffff;
    font-weight: bold;
  }

  :nth-child(1) {
    margin-top: 70px;
  }
`

export const SidebarSubLink = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? '#F2DDB8' : 'transparent')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  text-decoration: none;
`

export const IconTitle = styled.div`
  cursor: pointer;
  display: flex;
  margin: 20px 15px 20px 30px;
  align-items: center;
`
export const Icon = styled.img`
  margin-right: 20px;
  width: 25px;
`
export const Title = styled.div``
export const SubTitle = styled.h3`
  color: ${({ theme }) => theme.typography.colors.white};
  text-decoration: none;
  margin: 0px 0px 0px 75px;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #707070;
`
export const SelectedIconTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px 20px 30px;
`

export const SelectedIcon = styled.img`
  margin-right: 20px;
  width: 25px;
`
export const SelectedTitle = styled.div`
  font-weight: bold;
`
