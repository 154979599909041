import { Avatar } from '@material-ui/core'
import { stringAvatar } from '../../../../../utils/helpers/taskmanagement'
import dayjs from 'dayjs'
import {
  AssignedTo,
  PlaceHolderText,
  PlaceHolderWrapper,
  TaskCard,
  TaskDescription,
  TaskDetails,
  TaskDetailsFooter,
  TaskDetailsWrapper,
  TaskListFlexWrapper,
  TaskTime,
  TaskTitle,
  TaskType,
  TextGapWrapper,
  TimeStamp
} from '../DayWiseTask.styled'
import TaskStatusChip from '../../../Dashboard/TaskStatusChip'
import { useReduxSelector } from '../../../../../redux'
import { useTranslation } from 'react-i18next'

const TaskList = (props: { taskList: any; types: any; onMenuItemClicked: any }) => {
  const { taskList, types, onMenuItemClicked } = props
  const users = useReduxSelector((state) => state.user.users)
  const getUsername = (id: string): string => {
    const user = users.find((e) => e.id === parseInt(id))
    return `${user?.firstName} ${user?.lastName}` || ''
  }
  const { t } = useTranslation()
  const getDifference = (started: any, endTime: any) => {
    const startedTime = dayjs(started)
    const taskEndTime = dayjs(endTime)
    const durationMinutes = taskEndTime.diff(startedTime, 'minutes')
    const durationHours = taskEndTime.diff(startedTime, 'hours')
    const durationDays = taskEndTime.diff(startedTime, 'days')
    const remainingHours = durationHours % 24
    const remainingMinutes = durationMinutes % 60
    let durationString = ''
    if (durationDays > 0) {
      durationString += `${durationDays} d${durationDays !== 1 ? 's' : ''} `
    }
    if (remainingHours > 0) {
      durationString += `${remainingHours} hr${remainingHours !== 1 ? 's' : ''} `
    }
    if (remainingMinutes > 0) {
      durationString += `${remainingMinutes} min${remainingMinutes !== 1 ? 's' : ''}`
    }
    return durationString
  }
  return (
    <TaskListFlexWrapper>
      {taskList.length === 0 && (
        <PlaceHolderWrapper>
          <PlaceHolderText>{t(`task-manager.noTaskFound`)}</PlaceHolderText>
        </PlaceHolderWrapper>
      )}
      {taskList.map((task: any, index: any) => (
        <TaskCard key={index}>
          <TaskTime>{dayjs(task.schedule).format('hh:mm A')}</TaskTime>
          <TaskDetailsWrapper>
            <TaskDetails status={task.statusTypeId}>
              <TaskTitle>
                {task.name}
                <TextGapWrapper>
                  {getDifference(task.schedule, task.endTime)}
                  <TaskStatusChip rowData={task} onMenuItemClicked={onMenuItemClicked} />
                </TextGapWrapper>
              </TaskTitle>
              <TaskDescription>{task.comments}</TaskDescription>
              <TaskDetailsFooter>
                <TextGapWrapper gap={'50px'}>
                  <TimeStamp>
                    {dayjs(task.schedule).format('DD-MM-YYYY') === dayjs(task.endTime).format('DD-MM-YYYY')
                      ? `${dayjs(task.schedule).format('hh:mm A')} - ${dayjs(task.endTime).format('hh:mm A')}`
                      : `${t(`task-manager.Ends`)} @ ${dayjs(task.endTime).format('MMM D, YYYY hh:mm A')}`}
                  </TimeStamp>
                  <TaskType>
                    {t(`task-manager.Type`)}: {types.find((e: any) => e.id === task.type)?.name || task.type}
                  </TaskType>
                </TextGapWrapper>
                <AssignedTo>
                  <Avatar
                    {...stringAvatar(getUsername(task.addedBy), {
                      fontSize: '10px',
                      width: '20px',
                      height: '20px'
                    })}
                  />
                  {getUsername(task.addedBy)}
                </AssignedTo>
              </TaskDetailsFooter>
            </TaskDetails>
          </TaskDetailsWrapper>
        </TaskCard>
      ))}
    </TaskListFlexWrapper>
  )
}

export default TaskList
