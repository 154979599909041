import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { RootState, useReduxSelector } from '../../redux'
// import RequireAuth from '../../components/RequireAuth/RequireAuth'
const RequireAuth = React.lazy(() => import('../../components/RequireAuth/RequireAuth'))
// import Analysis from '../Analysis/Analysis'
const Analysis = React.lazy(() => import('../Analysis/Analysis'))
// import Comparison from '../Analysis/PoultryWeights/Comparison/Comparison'
const Comparison = React.lazy(() => import('../Analysis/PoultryWeights/Comparison/Comparison'))
// import DetailedChart from '../Analysis/PoultryWeights/Sensors/DetailedChart/DetailedChart'
const DetailedChart = React.lazy(() => import('../Analysis/PoultryWeights/Sensors/DetailedChart/DetailedChart'))
// import AnalysisSensors from '../Analysis/PoultryWeights/Sensors/Sensors'
const AnalysisSensors = React.lazy(() => import('../Analysis/PoultryWeights/Sensors/Sensors'))
// import Crop from '../Crop/Crop'
const Crop = React.lazy(() => import('../Crop/Crop'))
// import Customers from '../Customers/Customers'
const Customers = React.lazy(() => import('../Customers/Customers'))
// import Devices from '../Devices/Devices'
const Devices = React.lazy(() => import('../Devices/Devices'))
// import DevicesSummary from '../Devices/PoultryWeights/DevicesSummary/DevicesSummary'
const DevicesSummary = React.lazy(() => import('../Devices/RuminTech/DevicesSummary/DevicesSummary'))
// import Farms from '../Farms/Farms'
const Farms = React.lazy(() => import('../Farms/Farms'))
// import Gateways from '../Gateways/Gateways'
const Gateways = React.lazy(() => import('../Devices/RuminTech/Gateways/Gateways'))
// const Iot = React.lazy(() => import('../IOT/IOT'))
import Iot from '../IOT/IOT'
// import HeatMap from '../IOT/PoultryWeights/HeatMap/HeatMap'
const HeatMap = React.lazy(() => import('../IOT/PoultryWeights/HeatMap/HeatMap'))
// import Login from '../Login/Login'
const Login = React.lazy(() => import('../Login/Login'))
// import ManagerReports from '../ManagerReports/ManagerReports'
const ManagerReports = React.lazy(() => import('../ManagerReports/ManagerReports'))
// import ManualEvents from '../ManualEvents/ManualEvents'
const ManualEvents = React.lazy(() => import('../ManualEvents/ManualEvents'))
// import Material from '../Material/Material'
const Material = React.lazy(() => import('../Material/Material'))
// import Alerts from '../MyFarm/Alerts/Alerts'
// const Alerts = React.lazy(() => import('../MyFarm/Alerts/Alerts'))
const Alerts = React.lazy(() => import('../Alert/Alert'))
// import NoCrop from '../NoCrop/NoCrop'
const NoCrop = React.lazy(() => import('../NoCrop/NoCrop'))
// import ResetPassword from '../ResetPassword/ResetPassword'
const ResetPassword = React.lazy(() => import('../ResetPassword/ResetPassword'))
// import Roles from '../Roles/Roles'
const Roles = React.lazy(() => import('../Roles/Roles'))
// import Sensors from '../Sensors/Sensors'
const Sensors = React.lazy(() => import('../Sensors/Sensors'))
// import Sheds from '../Sheds/Sheds'
const Sheds = React.lazy(() => import('../Sheds/Sheds'))
// import Silos from '../Silos/Silos'
const Silos = React.lazy(() => import('../Silos/Silos'))
// import Users from '../Users/Users'
const Users = React.lazy(() => import('../Users/Users'))
// import LoginWithToken from '../LoginWithToken/LoginWithToken'
const LoginWithToken = React.lazy(() => import('../LoginWithToken/LoginWithToken'))
// import Ai from '../Ai/Ai'
const Ai = React.lazy(() => import('../Ai/Ai'))
// import UserData from '../UserData/UserData'
const UserData = React.lazy(() => import('../UserData/UserData'))
// import Controller from '../Controller/Controller'
const Controller = React.lazy(() => import('../Controller/Controller'))
// import CropHistory from '../Crop/CropHistory/CropHistory'
const CropHistory = React.lazy(() => import('../Crop/CropHistory/CropHistory'))
// import MortalityHistory from '../Crop/MortalityHistory/MortalityHistory'
const MortalityHistory = React.lazy(() => import('../Crop/MortalityHistory/MortalityHistory'))
// import Consumption from '../Crop/Consumption/Consumption'
const Consumption = React.lazy(() => import('../Crop/Consumption/Consumption'))
// import Recipe from '../Recipe/Recipe'
const Recipe = React.lazy(() => import('../Recipe/Recipe'))
// import PiticConsumption from '../Crop/PiticConsumption/PiticConsumption'
const PiticConsumption = React.lazy(() => import('../Crop/PiticConsumption/PiticConsumption'))
// import Ingredient from '../Ingredient/Ingredient'
const Ingredient = React.lazy(() => import('../Ingredient/Ingredient'))
// import DeviceInfo from '../Devices/DeviceInfo/DeviceInfo'
const DeviceInfo = React.lazy(() => import('../Devices/DeviceInfo/DeviceInfo'))
// import SignUp from '../Signup/RuminTech/SignUp'
const SignUp = React.lazy(() => import('../Signup/RuminTech/SignUp'))
const Certificate = React.lazy(() => import('../Certificate/Certificate'))
const Performance = React.lazy(() => import('../Performance/Performance'))
const Esg = React.lazy(() => import('../Esg/Esg'))

import Loader from '../../components/Loader/Loader'
import { GetConfig } from '../../config/config'
import BuildingMap from '../Map/BuildingMap'
import DeviceVersion from '../DeviceVersion/DeviceVersion'
import Expired from '../Expired/Expired'

const BpacRoutes = () => {
  const location = useLocation()
  const config = GetConfig()?.global
  const { customers } = useReduxSelector((state: RootState) => state.customers)

  return (
    <Routes location={location}>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<Loader size={80} />}>
            {/* <Signup /> */}
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path="/reset"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/login/token"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <LoginWithToken />
          </Suspense>
        }
      />
      <Route
        path="/expired"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Expired />
          </Suspense>
        }
      />

      <Route
        path="/Iot/General"
        element={
          // <Suspense fallback={<Loader size={80} />}>
          <RequireAuth>
            <>
              <Iot />
            </>
          </RequireAuth>
          // </Suspense>
        }
      />

      <Route
        path="/MyZone/Areas"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Farms />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyZone/Buildings"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Sheds />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/summary"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DevicesSummary />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/device-version"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceVersion />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/devices"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Devices />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/Device"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceInfo />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path={`${config?.harvest?.route}`}
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Crop />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="MyZone/sensors"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Sensors />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyZone/Alert"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Alerts />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyZone/Map"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <BuildingMap />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/Gateways"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Gateways />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/customerMgmt/users"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Users />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/customerMgmt/Customer"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Customers />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/customerMgmt/Roles"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Roles />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Analysis/Sensors"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <AnalysisSensors />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Analysis/Comparison"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Comparison />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Analysis/Sensors/Detailes"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DetailedChart />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/report/certificate"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Certificate />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/report/performance"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Performance />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/report/esg"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Esg />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/ai/charts"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Ai />
            </RequireAuth>
          </Suspense>
        }
      />
    </Routes>
  )
}

export default BpacRoutes
