import styled from 'styled-components'

export const AppBarUserWrapper = styled.div`
  height: 56px;
  justify-content: space-between;
  align-items: center;
  position: reltive;
  width: 100px;
  margin-right: 0 auto;
  margin-top: 10px;
`

export const AppBarSpan = styled.span`
  color: white;
  font-size: 19px;
  padding: 30%;
`
