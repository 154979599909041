import { useEffect, useMemo, useState } from 'react'
import { deleteIcon, rightArrowImgIcon } from '../../assets/images/global'
import { serverDeleteDeviceUpdateVersion, serverGetDeviceUpdateVersion } from '../../services/serverApi'
import { getStringDate, isDateBefore } from '../../utils/helpers/date'
import { Chip } from '@mui/material'
import { IdeviceVersion } from 'deviceVersion'
import themes from '../../styles'
import useSockets from '../../hooks/useSockets'
import eventBus from '../../utils/helpers/eventBus'
import { firmwareUpdatePacket, SocketEndPoint } from '../../types/iot.d'
import { DeviceSRNumber, DeviceTitle, TableColumnWrapper, VersionWrapper } from './DeviceVersion.styled'
import { DeviceVersionStatus, installationTime } from './DeviceVersion.options'
import useTimer from '../../utils/helpers/useTimer'
import ProgressIndicator from './ProgressIndicator'
import { CrossIcon } from '../../assets/images/sheds'
import { useTranslation } from 'react-i18next'

// TODO:

type ModalName = 'edit' | 'add'

const useDV = () => {
  const { startTimer, stopTimer, times } = useTimer()
  const { startSocketConnection } = useSockets() as any
  const [deviceUpdateVersionData, setDeviceUpdateVersionData] = useState<IdeviceVersion[]>([])
  const [allDeviceUpdateData, setAllDeviceUpdateData] = useState<IdeviceVersion[]>([])
  const [isOpen, setIsOpen] = useState<boolean | ModalName>(false)
  const [editObject, setEditObject] = useState<IdeviceVersion>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [updatingDevices, setUpdatingDevices] = useState<IdeviceVersion[]>([])
  const [lastUpdateData, setLastUpdateData] = useState()
  const [idToDelete, setIdToDelete] = useState<number>()

  const { t } = useTranslation()

  const getDeviceUpdateVersion = async () => {
    try {
      setIsLoading(true)
      const res = await serverGetDeviceUpdateVersion()
      setAllDeviceUpdateData(res)

      const inProgressDevices = res.filter(
        (item: IdeviceVersion) =>
          checkStatus(item) === DeviceVersionStatus.IN_PROGRESS || checkStatus(item) === DeviceVersionStatus.INSTALLING
      )

      const otherDevices = res.filter((item: IdeviceVersion) => checkStatus(item) === DeviceVersionStatus.PENDING)

      setUpdatingDevices([...inProgressDevices, ...otherDevices])
      setDeviceUpdateVersionData(
        res.filter(
          (item: IdeviceVersion) => checkStatus(item) === DeviceVersionStatus.COMPLETED || checkStatus(item) === DeviceVersionStatus.FAILED
        )
      )
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const handleOpen = (name: ModalName, data?: IdeviceVersion) => {
    setIsOpen(name)
    setEditObject(data)
  }

  const checkStatus = (data: IdeviceVersion) => {
    let status

    // status = DeviceVersionStatus.INSTALLING

    if (data.updatedTime) {
      status = DeviceVersionStatus.COMPLETED
    } else if (!data.progress && (!data.updating || !data.downloading) && !data.startUpdateDate && !data.currentPercent) {
      status = DeviceVersionStatus.PENDING
    } else if (!data.updating && isDateBefore(1000, data.startUpdateDate) && !data.updatedTime && !data.downloading) {
      status = DeviceVersionStatus.FAILED
    } else if (((data?.progress === 100 || data?.currentPercent === 100) && !data?.downloading && !data.complete) || data?.downloadedTime) {
      status = DeviceVersionStatus.INSTALLING
    } else if ((data.updating && data.startUpdateDate) || data.currentPercent > 0 || data.downloading) {
      status = DeviceVersionStatus.IN_PROGRESS
    }

    return status
  }

  const getDataHeader = (table: number) => {
    const data = [
      {
        title: t('device-update.deviceName'),
        field: 'deviceName',
        render: (data: IdeviceVersion) => {
          return (
            <TableColumnWrapper>
              <DeviceTitle>{data?.deviceName}</DeviceTitle>
              <DeviceSRNumber>{data?.serialNumber}</DeviceSRNumber>
            </TableColumnWrapper>
          )
        }
      },
      {
        title: t('device-update.deviceType'),
        field: 'typeName',
        render: (data: IdeviceVersion) => data?.typeName,
        table: 1
      },
      {
        title: t('device-update.updateProgress'),
        field: 'progress',
        sorting: false,
        render: (data: IdeviceVersion) => {
          const status = checkStatus(data)
          return <ProgressIndicator data={data} status={status} />
        },
        table: 1
      },
      {
        title: t('device-update.version'),
        field: 'versionNumber',
        render: (data: IdeviceVersion) => {
          return (
            <VersionWrapper>
              {data?.prevVersion || `NA`}
              <img src={rightArrowImgIcon} />
              {data?.targetVersion || `NA`}
            </VersionWrapper>
          )
        }
      },
      {
        title: t('device-update.updateDate'),
        field: 'updatedTime',
        render: (data: IdeviceVersion) => getStringDate(data?.updatedTime),
        table: 2
      },
      {
        title: t('device-update.status'),
        field: 'status',
        sorting: false,
        render: (data: IdeviceVersion) => {
          const status = checkStatus(data)

          let color = ''
          let label = ''
          let fontColor = ''

          if (status === DeviceVersionStatus.IN_PROGRESS) {
            color = themes().statusColors.warningBG
            fontColor = themes().statusColors.warning
            label = DeviceVersionStatus.IN_PROGRESS
          } else if (status === DeviceVersionStatus.FAILED) {
            color = themes().statusColors.dangerBG
            fontColor = themes().statusColors.danger
            label = DeviceVersionStatus.FAILED
          } else if (status === DeviceVersionStatus.COMPLETED) {
            color = themes().statusColors.okBG
            fontColor = themes().statusColors.ok
            label = DeviceVersionStatus.COMPLETED
          } else if (status === DeviceVersionStatus.INSTALLING) {
            color = themes().statusColors.warningBG
            fontColor = themes().statusColors.warning
            label = DeviceVersionStatus.INSTALLING
          } else if (status === DeviceVersionStatus.PENDING) {
            color = themes().statusColors.warningBG
            fontColor = themes().statusColors.warning
            label = DeviceVersionStatus.PENDING
          }

          return (
            <Chip
              label={t(`device-update.${label}`)}
              style={{
                backgroundColor: color,
                borderRadius: '100px',
                color: fontColor
              }}
            />
          )
        }
      }
    ]
    if (table === 1) {
      return data.filter((item) => item.table !== 2)
    } else if (table === 2) {
      return data.filter((item) => item.table !== 1)
    }
  }

  const versionUpdatingHeader = useMemo(() => getDataHeader(1) as header[], [])
  const versionHistoryHeader = useMemo(() => getDataHeader(2) as header[], [])

  const Actions = useMemo(
    () => [
      {
        action: (rowData: IdeviceVersion) => ({
          icon: () => (
            <img
              src={CrossIcon}
              style={{
                width: '20px',
                height: '20px'
              }}
            />
          ),
          tooltip: t('device-update.deleteData'),
          onClick: (event: any, rowData: IdeviceVersion) => handleDeviceVersionDelete(rowData)
        })
      },
      {
        icon: 'refresh',
        tooltip: t('device-update.refreshData'),
        isFreeAction: true,
        onClick: () => getDeviceUpdateVersion()
      }
    ],
    []
  )

  const handleDeviceVersionDelete = (data: IdeviceVersion) => {
    setOpenMessageDialog(true)
    setIdToDelete(data.id)
  }

  const socketCB = async ({ deviceId, complete, captureDate, downloading, percent }: firmwareUpdatePacket) => {
    const deviceIndex = updatingDevices.findIndex((item: any) => item.deviceId === deviceId)

    if (deviceIndex === -1) {
      return
    }
    if (complete) {
      stopTimer(deviceId)
      getDeviceUpdateVersion()
    }

    if (percent === 100 && !complete) {
      setUpdatingDevices((prevState: any) => {
        const newState = [...prevState]
        newState[deviceIndex] = { ...newState[deviceIndex], progress: percent, complete, downloading, timer: installationTime }
        return newState
      })

      startTimer(deviceId, installationTime)

      return
    }
    setUpdatingDevices((prevState: any) => {
      const newState = [...prevState]
      newState[deviceIndex] = { ...newState[deviceIndex], progress: percent, complete, downloading }
      return newState
    })
  }

  const onConfirm = () => {
    idToDelete && serverDeleteDeviceUpdateVersion(idToDelete)
    getDeviceUpdateVersion()
    setOpenMessageDialog(false)
  }

  const onCancel = () => {
    setOpenMessageDialog(false)
  }

  useEffect(() => {
    getDeviceUpdateVersion()
    startSocketConnection(SocketEndPoint.firmwareUpdate)
    eventBus.on(SocketEndPoint.firmwareUpdate, setLastUpdateData)
  }, [])

  useEffect(() => {
    lastUpdateData && socketCB(lastUpdateData)
  }, [lastUpdateData])

  return {
    getDeviceUpdateVersion,
    deviceUpdateVersionData,
    versionUpdatingHeader,
    versionHistoryHeader,
    Actions,
    handleOpen,
    setIsOpen,
    isOpen,
    editObject,
    updatingDevices,
    isLoading,
    openMessageDialog,
    onConfirm,
    onCancel,
    allDeviceUpdateData
  }
}

export default useDV
