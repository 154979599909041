import { DeviceTypes } from '../../types/device.d'
import { ClimateSensorIcon, WaterIcon } from '../../assets/images/crop'
import { RadarIcon, TabletIcon, WeightIcon } from '../../assets/images/summary'
import { gatewayIcon } from '../../assets/images/gateway'
import { BolusIcon } from '../../assets/images/ManagerReports/RuminTech'
import { WeightDarkIcon } from '../../assets/images/iotImages/RuminTechIot'

export const RuminTechDevicesConfig = {
  Bolus: {
    name: 'Bolus',
    Icon: BolusIcon,
    deviceType: DeviceTypes.Bolus
  },
  CalfWeightUnit: {
    name: 'Calf Weight Unit',
    Icon: WeightDarkIcon,
    deviceType: DeviceTypes.CalfWeightUnit
  },
  WaterMeter: {
    name: 'Water Meter',
    Icon: WaterIcon,
    deviceType: DeviceTypes.WaterMeter
  },
  ClimateStick: {
    name: 'Climate Sensors',
    Icon: ClimateSensorIcon,
    deviceType: DeviceTypes.ClimateStick
  }
}

export const gateWaysConfig = {
  Gateway_Type_A: {
    name: 'Gateway Type A',
    Icon: gatewayIcon,
    deviceType: DeviceTypes.Gateway
  }
}
