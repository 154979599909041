import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setLogin } from '../../redux/AuthReducer'
import { socket } from '../../hooks/useSockets'
import { serverGetDataModelDetail } from '../../services/serverApi'
import { RootState, useReduxSelector } from '../../redux'
import {
  MenuAccountIconImg,
  MenuBeesIconImg,
  MenuBPACIconImg,
  MenuGASIconImg,
  MenuHydroIconImg,
  MenuLogoutIconImg,
  MenuNevatimIconImg,
  MenuPoultryIconImg,
  MenuProductIconImg,
  MenuRadarIconImg,
  MenuRuminTechIconImg,
  MenuSettingIconImg,
  MenuRighArrowtIconImg
} from '../../assets/images/global'

export const preloadIcons = () => {
  const imageUrls = [
    MenuBeesIconImg,
    MenuBPACIconImg,
    MenuGASIconImg,
    MenuHydroIconImg,
    MenuNevatimIconImg,
    MenuPoultryIconImg,
    MenuRadarIconImg,
    MenuRuminTechIconImg,
    MenuProductIconImg,
    MenuLogoutIconImg,
    MenuAccountIconImg,
    MenuSettingIconImg,
    MenuRighArrowtIconImg
  ]

  imageUrls.forEach((url) => {
    const img = new Image()
    img.src = url
  })
}

const useAppMenu = () => {
  const { otherDataModels, user }: any = useReduxSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleAccountClick = () => {
    navigate('Accounts/Profile')
    setOpenMenu(false)
  }
  const [openMenu, setOpenMenu] = useState(false)
  const [activeMenu, setActiveMenu] = useState<string | null>(null)

  useEffect(() => {
    preloadIcons()
  }, [])

  const handleLogout = () => {
    dispatch(setLogin(false))
    localStorage.removeItem('user')
    socket.disconnect()
    navigate('/login')
  }

  const handleLoginClick = async (id: number | any) => {
    const data = await serverGetDataModelDetail(id)
    if (window.location.hostname === 'localhost') {
      window.open(`http://localhost:${data[0].devPort}/login/token?token=${user?.token}`, '_self', 'noopener,noreferrer')
    } else {
      window.open(`https://${data[0].url}/login/token?token=${user?.token}`, '_self', 'noopener,noreferrer')
    }
  }

  const handleMenuItemClick = (menu: string) => {
    if (activeMenu === menu) {
      setActiveMenu(null)
    } else {
      setActiveMenu(menu)
    }
  }

  const onMenuItemsClickGeneral = (menu: string) => {
    switch (menu) {
      case 'settings':
        handleAccountClick()
        break
      case 'logout':
        handleLogout()
        break
      default:
        handleMenuItemClick(menu)
    }
  }

  const submenuTimeout = useRef<any>(null)

  const handleMenuMouseEnter = (menu: string) => {
    clearTimeout(submenuTimeout.current)
    setActiveMenu(menu)
  }

  const handleMenuMouseLeave = () => {
    submenuTimeout.current = setTimeout(() => {
      setActiveMenu(null)
    }, 200)
  }
  return {
    handleAccountClick,
    onMenuItemsClickGeneral,
    activeMenu,
    setActiveMenu,
    handleMenuItemClick,
    handleLoginClick,
    handleLogout,
    handleMenuMouseEnter,
    handleMenuMouseLeave,
    submenuTimeout,
    openMenu,
    setOpenMenu
  }
}

export default useAppMenu
