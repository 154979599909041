export const RumIntechLanguage = [
  {
    name: 'English',
    code: 'en-US',
    flag: 'US',
    direction: 'ltr',
    label: 'English'
  },
  {
    name: 'Hebrew',
    code: 'he-IL',
    flag: 'IL',
    direction: 'rtl',
    label: 'עברית'
  },
  {
    name: 'Arabic',
    code: 'ar-SA',
    flag: 'SA',
    direction: 'rtl',
    label: 'العربية'
  },
  {
    name: 'Thai',
    code: 'th-TH',
    flag: 'TH',
    direction: 'ltr',
    label: 'ไทย'
  }
]

export const customLabel = { US: 'English', IL: 'עברית', SA: 'العربية', TH: 'ไทย' }
