import { IFacilityGroup } from 'facilityGroup'
import React from 'react'
import { locationIcon, locationSecondaryIcon } from '../../../../../../assets/images/appBar'
import { CloseIconImg } from '../../../../../../assets/images/UsersImages'
import { RootState, useReduxSelector } from '../../../../../../redux'
import Modal from '../../../../../Model/ModalMobileView'
import { ModalContent, ModalTopWrapper } from '../../../../../Model/ModalMobileView.styled'
import {
  CloseImg,
  ContentTopWrapper,
  FarmId,
  FarmName,
  ModalIcon,
  ModelHeading,
  SelectCustomerContainer,
  TableList,
  TableWrapper
} from '../../MobileAppbar.styled'

interface IModalProps {
  handleChange: any
  farmId: number | undefined
  customerName: string | undefined
  customerId: number | undefined
  isOpen: { [key: string]: boolean }
  setIsOpen: (isOpen: { [key: string]: boolean }) => void
}

const FarmModal: React.FC<IModalProps> = ({ handleChange, farmId, customerName, customerId, isOpen, setIsOpen }) => {
  const { facilitiesGroups } = useReduxSelector((state: RootState) => state.facilitiesGroup)

  return (
    <>
      {isOpen.facilityGroup && (
        <Modal show={isOpen.facilityGroup}>
          <ModalTopWrapper>
            <ModalIcon src={locationSecondaryIcon} />
            {customerName}
            <CloseImg src={CloseIconImg} onClick={() => setIsOpen({ ...isOpen, facilityGroup: !isOpen.facilityGroup })} />
          </ModalTopWrapper>
          <ModalContent>
            <ContentTopWrapper>
              <ModalIcon src={locationIcon} />
              <ModelHeading>Select Farm</ModelHeading>
            </ContentTopWrapper>
            <SelectCustomerContainer>
              {facilitiesGroups
                .filter((farm: IFacilityGroup) => farm.customerId === customerId)
                .map((farm: IFacilityGroup) => {
                  return (
                    <TableWrapper key={farm.id}>
                      <TableList
                        isActive={farmId === farm.id}
                        onClick={() => handleChange({ lable: 'facilityGroup', id: farm.id, name: farm.name })}
                      >
                        <FarmId isActive={farmId === farm.id}>{farm.id}</FarmId>
                        <FarmName isActive={farmId === farm.id}>{farm.name}</FarmName>
                      </TableList>
                    </TableWrapper>
                  )
                })}
            </SelectCustomerContainer>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default FarmModal
