import styled from 'styled-components'

export const TodayDateContainer = styled('div')`
  width: 225px;
  height: 36px;
  border-radius: 5px;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  position: relative;
  margin-left: 10px;
  // @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
  //   margin-left: 80px;
  // }
`

export const TodayDate = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
`