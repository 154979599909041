import styled from "styled-components";

export const AddEditButtonWrapper = styled.button<{ isModelId: any, isValid: boolean }>`
width: 176px;
height: 66px;
color: #faf9f9;
border: 1px solid #ffffff;
border-radius: ${({ theme }) => theme.borderRadius.md};
background-color: ${({ theme, isModelId }) => isModelId === 8 ? '#ffd500' : theme.colors.primary};
cursor: ${({ isValid }) => isValid ? 'pointer' : 'not-allowed'};
pointer-events: ${({ isValid }) => isValid ? 'block' : 'none'};
opacity: ${({ isValid }) => isValid ? 1 : 0.5};
@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  width: 130px;
  height: 50px;
}
`