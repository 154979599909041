import { DATE_ONE_DAY, GetTodayStartDateEndDate } from '../helpers/date'
import moment from 'moment'
import { GetDataModelId } from '../helpers/dataModel'
import { DataModel } from '../../types/dataModel.d'
import { set } from 'cypress/types/lodash'

export const SITES_TAB = 'Sites'
export const SEASONS_TAB = 'Seasons'
export const DEVICES_TAB = 'Devices'
export const WATER_INTAKE = 'waterIntake'

export enum SENSORS {
  'humidity',
  'lux',
  'thi',
  'temperature',
  'weight',
  'co2Level'
}
export const DATE_RANGE = 'Date Range'
export const TWO_DAYS = 2
export const SEVEN_DAYS = 7
export const TWENTY_DAYS = 29
export const DEFAULT_GRAPH_TYPE = 'line'
export const ONEWEEK = '1W'

export const CHICK_WEIGHT_CHART_TITLE = 'Chick Weight'
export const NO_OF_BIRDS_CHART_TITLE = 'No.Birds'
export const FOOD_CONSUMPTION_CHART_TITLE = 'Food Consumption'
export const WATER_CONSUMPTION_CHART_TITLE = 'Water Consumption'
export const COMBINE_WEIGHT_CHART_TITLE = 'Combine Weight'
export const FOOD_CONVERSATION_RATE_CHART_TITLE = 'Food Conversation Rate'
export const COST_PER_KG_CHART_TITLE = 'Cost Per KG'

export const analysisRulerValues = [
  { title: '1D', startDate: moment().toDate(), endDate: moment().subtract(1, 'day').toDate() },
  { title: '1W', startDate: moment().toDate(), endDate: moment().subtract(1, 'week').toDate() },
  { title: '1M', startDate: moment().toDate(), endDate: moment().subtract(1, 'month').toDate() },
  { title: '3M', startDate: moment().toDate(), endDate: moment().subtract(3, 'month').toDate() },
  { title: '6M', startDate: moment().toDate(), endDate: moment().subtract(6, 'month').toDate() },
  { title: '1Y', startDate: moment().toDate(), endDate: moment().subtract(1, 'year').toDate() }
]

// Get the start of yesterday and end of yesterday
const startOfYesterday = moment().subtract(1, 'day').startOf('day').toDate()
const endOfYesterday = moment().subtract(1, 'day').endOf('day').toDate()

export const DurationInterval = [
  {
    title: 'Today',
    endDate: GetTodayStartDateEndDate().endOfDay,
    startDate: GetTodayStartDateEndDate().startOfDay,
    translationKey: 'today'
  },
  { title: 'Yesterday', endDate: endOfYesterday, startDate: startOfYesterday, translationKey: 'yesterday' },
  { title: '2D', endDate: moment().toDate(), startDate: moment().subtract(1, 'days').startOf('day').toDate(), translationKey: 'twoDays' },
  { title: '1W', endDate: moment().toDate(), startDate: moment().subtract(1, 'week').startOf('day').toDate(), translationKey: 'oneWeek' },
  { title: '1M', endDate: moment().toDate(), startDate: moment().subtract(1, 'month').startOf('day').toDate(), translationKey: 'oneMonth' }
]
export const DurationIntervalDistibution = [
  { title: 'Today', endDate: GetTodayStartDateEndDate().endOfDay, startDate: GetTodayStartDateEndDate().startOfDay },
  { title: 'Yesterday', endDate: endOfYesterday, startDate: startOfYesterday },
  { title: '1W', endDate: moment().toDate(), startDate: moment().subtract(1, 'week').toDate() },
  { title: '1M', endDate: moment().toDate(), startDate: moment().subtract(1, 'month').toDate() }
]

export enum PoultryRawInterval {
  hydroIntelRaw = 1,
  PoultryWeightsRaw = 0,
  RadarRaw = 0
}

const dataModelId = GetDataModelId()
const getData = () => {
  if (dataModelId === DataModel.hydroIntel) return PoultryRawInterval.hydroIntelRaw
  if (dataModelId === DataModel.Radar) return PoultryRawInterval.RadarRaw
  else dataModelId === DataModel.PoultryWeights
  return PoultryRawInterval.PoultryWeightsRaw
}

export enum Interval {
  Raw = Number(getData()),
  FifteenMinutes = 15,
  HalfAnHour = 30,
  Hour = 60,
  Day = 1440,
  Week = 10080,
  TenMinutes = 10
}

export enum Duration {
  Today = 'Today',
  Yesterday = 'Yesterday',
  TwoDay = '2D',
  OneWeek = '1W',
  OneMonth = '1M',
}

export enum ChartType {
  Line = 'line',
  Area = 'area',
  Column = 'column'
}

export const IntervalRadars = [
  {
    id: 0,
    name: 'Raw'
  },
  {
    id: 1,
    name: '1 Min'
  },
  {
    id: 10,
    name: '10 Min'
  },
  {
    id: 60,
    name: '1 H'
  },
  {
    id: 1440,
    name: '1 D'
  }
]

export enum EVENTSTAGS {
  FloorRemoved = 'A',
  HarvestingAdar = 'B',
  FloorAdded = 'C',
  VarroaTreatment = 'D',
  QueenAdd = 'E',
  FertilizationInspection = 'F',
  Smoke = 'G',
  WaterFilling = 'H',
  Feeding = 'I',
  Pesticides = 'J'
}

export const FCRDATA = [
  0, 0.245974201, 0.434906171, 0.5023123, 0.68135745, 0.666755326, 0.707242541, 0.7055965, 0.888013014, 0.929405071, 0.894820213,
  1.212594409, 1.2503465, 1.267779595, 1.240714979, 1.329551183, 1.482019012, 1.530431017, 1.535074205, 1.460650068, 1.534806847,
  1.594435725, 1.654358582, 1.657692, 1.656905732, 1.697178788, 1.690864842, 1.785930612, 1.84213655, 1.840388249, 1.910451806, 1.929467187,
  1.960184793
]

export const calculateAverageByKey = (arr: any, key: any) => {
  const sum = arr.reduce((total: any, obj: any) => total + obj[key], 0)
  const NotNullData = arr.filter((item: any) => item[key] !== null)
  const res = sum / NotNullData.length
  if (res) {
    return Number(res).toFixed(2)
  } else {
    return 'N/A'
  }
}

export const sumValueIfSameFacility = (arr: any, key: any) => {
  const reducedArr = arr.reduce((acc: any, obj: any) => {
    const existingObj = acc.find((item: any) => item[key] === obj[key])
    if (existingObj) {
      existingObj.value += obj.value
    } else {
      acc.push(obj)
    }
    return acc
  }, [])
  return reducedArr
}

export const getSumOfMatching = (objArray: any[], key: string) => {
  const monitoredUnitIds = objArray.map((ob: any) => ob.monitoredGroupId)
  const unique = new Set(monitoredUnitIds)

  const data = Array.from(unique).map((d) => {
    const similarGroup = objArray.filter((group: any) => group.monitoredGroupId === d)
    const total = similarGroup.reduce((acc, obj) => acc + obj.totalQuantity, 0)
    return {
      total,
      monitoredGroupId: d,
      facilityId: similarGroup[0].facilityId
    }
  })

  return data
}

export const GraphOverInputs = {
  Facility: {
    name: 'Extention',
    field: 'extensionId'
  },
  FacilityGroup: {
    name: 'Herd',
    field: 'herdId'
  },
  MonitoredGroup: {
    name: 'Group',
    field: 'monitoredGroupIds'
  },
  MonitoredUnit: {
    name: 'Animal',
    field: 'monitoredUnitIds'
  },
  Is24Hour: {
    name: '24Hour',
    field: 'is24Hour'
  },
  Interval: {
    name: 'Interval',
    field: 'interval'
  },
  StartDate: {
    name: 'StartDate',
    field: 'startDate'
  },
  EndDate: {
    name: 'EndDate',
    field: 'endDate'
  },
  milk: {
    name: 'Milk',
    field: 'milk'
  },
  shower: {
    name: 'Shower',
    field: 'shower'
  },
  IsRaw: {
    name: 'Raw',
    field: 'isRaw'
  }
}
