import styled from "styled-components";

export const ExpiredContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
`
export const ExpiredWrapper = styled.div`
width: 988.6px;
height: 444.34px;
border-radius: ${({ theme }) => theme.borderRadius.lg};
border: 1px solid #E3E3E3;
padding: 80px;
display: flex;
align-items: center;
justify-content: space-between;
gap: 40px;
background: ${({ theme }) => theme.colors.white};

@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    height: auto;
    width: auto;
}

@media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 40px;
}

@media screen and (max-width: 470px) {
    width: 100%;
    padding: 10px;
}
`
export const ExpiredImg = styled.img`
`
export const ExpiredNoticeContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
`
export const ExpiredTitle = styled.div`
font-weight: ${({ theme }) => theme.typography.bold.lg};
font-size: ${({ theme }) => theme.typography.size.lg};
color: ${({ theme }) => theme.colors.primary};
`
export const ExpiredTitleSpan = styled.span`
color: ${({ theme }) => theme.colors.tertiary};
`
export const ExpiredNotice = styled.div`
font-weight: ${({ theme }) => theme.typography.bold.sm};
font-size: ${({ theme }) => theme.typography.size.sm};
color: ${({ theme }) => theme.colors.primary};
opacity: 60%;
text-align: center;
`
export const LoginButton = styled.button`
width: 140px;
height: 48px;
border-radius: ${({ theme }) => theme.borderRadius.sm};
padding: 16px, 24px, 16px, 24px;
background: ${({ theme }) => theme.colors.secondary};
font-weight: ${({ theme }) => theme.typography.bold.md};
font-size: ${({ theme }) => theme.typography.size.sm};
display: flex;
align-items: center;
justify-content: center;
color: ${({ theme }) => theme.colors.white};
border: none;
`