import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BackIcon } from '../../../../assets/images/sideMenu/index'
import {
  BackButton,
  Icon,
  IconTitle,
  SelectedIcon,
  SelectedIconTitle,
  SelectedTitle,
  SidebarLink,
  StyledMenu,
  Title
} from './Sidebar.styled'
import Subnav from './Subnav'
interface submenuProps {
  item: {
    title: string
    path: string
    image: string
    subNav: {
      title: string
      path: string
    }[]
  }
  setOpen: (isOpen: boolean) => void
}
export enum Menu {
  Morality,
  Sensors
}
const Submenu: React.FC<submenuProps> = ({ item, setOpen }) => {
  const [subnav, setSubnav] = useState(false)
  const { pathname } = useLocation()
  return (
    <>
      <SidebarLink isActive={item.subNav.map((data) => data.path).includes(pathname) || pathname === item.path}>
        <IconTitle>
          <Icon src={item.image} />
          <Title onClick={() => setSubnav(!subnav)}>{item.title}</Title>
        </IconTitle>
        {/* {subnav && ( */}
        <StyledMenu isActive={subnav}>
          <BackButton onClick={() => setSubnav(!subnav)} src={BackIcon} />
          <SelectedIconTitle>
            <SelectedIcon src={item.image} />
            <SelectedTitle>{item.title}</SelectedTitle>
          </SelectedIconTitle>
          {item.subNav.map((item, index) => {
            return <Subnav item={item} key={index} setOpen={setOpen} />
          })}
        </StyledMenu>
        {/* )} */}
      </SidebarLink>
    </>
  )
}

export default Submenu
