import React from 'react'
import { AppSubNavigation } from './AppSubNavigation.styled'

interface IAppSubNavigationProps {
  children: JSX.Element
}
const AppSubNavigationComponent: React.FC<IAppSubNavigationProps> = ({ children }: { children: JSX.Element }) => {
  return <AppSubNavigation>{children}</AppSubNavigation>
}

export default AppSubNavigationComponent
