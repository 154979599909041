import { DataModel } from '../../../types/dataModel.d'
import styled from 'styled-components'
import themes from '../../../styles'

export const AppbarWrapper = styled.div<{ dataModelId: number | null }>`
  background-color: ${({ dataModelId }) => (dataModelId === DataModel.Gas ? themes().colors.white : themes().colors.forth)};
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-right: 20px;
  border-bottom: 1px solid #f7f9fb;
  height: 60px;
  width: 100%;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }
`

export const AppSearchMenuWrapper = styled.div`
  display: flex;
  gap: 32px;
`

export const DateInfoWrapper = styled.div``
export const AppNavigationWrapper = styled.div``
export const CustomDateInfoWrapper = styled.div``
export const LastGroupWrapper = styled.div`
  display: flex;
`
export const DateInfo = styled.div`
  font-size: 14px;
  color: black;
  font-weight: bold;
`

export const ApplocationWrapper = styled.div`
  margin-top: 10px;
  grid-area: locfilter;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AppDateWrapper = styled.div`
  grid-area: date1;
  width: auto;
  height: 40px;
  display: flex;
  flex-direction: row;
`

export const LocationIcon = styled.img`
  margin-right: 20px;
  height: 50px;
`

export const DateIcon = styled.img`
  margin-right: 15px;
  height: 35px;
  width: 35px;
`

export const DateFilter = styled.div`
  grid-area: date1;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.typography.colors.secondary};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f2ddb8;
    border: none;
    margin-right: 10px;
  }
  align-items: center;
`

export const AppBarNotificationWrapper = styled.div``
export const ProfileNotificationWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const SkeletonCss = {
  AppBarLogo: {
    borderRadius: '5px',
    width: '127px',
    height: '127px',
    marginLeft: '50px'
  },
  LocationIcon: {
    height: '42px',
    width: '50px',
    marginRight: '10px'
  },
  DateIcon: {
    marginRight: '15px',
    height: '35px',
    width: '35px',
    borderRadius: '30px',
    marginLeft: '5px'
  },
  AppbarLocationComponent: {
    height: '56px',
    width: '100%',
    borderRadius: '7px'
  },
  AppBarNotification: {
    borderRadius: '5px',
    width: '50px',
    height: '56px',
    marginLeft: '10px'
  },
  AppBarUser: {
    borderRadius: '5px',
    height: '56px',
    width: 'auto',
    minWidth: '70px',
    marginLeft: '10px'
  },
  AppDataFilter: {
    width: 'auto',
    height: '40px',
    minWidth: '320px',
    borderRadius: '7px'
  },
  CropdateDropdown: {
    width: '250px',
    borderRadius: '7px',
    height: '40px',
    marginLeft: '10px'
  },
  TodayDateContainer: {
    width: '225px',
    height: '40px',
    borderRadius: '7px',
    marginLeft: '10px'
  },
  CustomDateInfoWrapper: {
    width: '530px',
    height: '40px',
    borderRadius: '7px',
    marginLeft: '10px'
  },
  Stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  NavIcon: {
    width: '30px',
    height: '30px'
  },
  RightArrowIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '10px'
  },
  Title: {
    width: '120px',
    height: '25px',
    color: 'white',
    borderRadius: '7px',
    marginLeft: '10px'
  },
  SubTitle: {
    width: '120px',
    height: '25px',
    color: 'white',
    borderRadius: '7px',
    marginLeft: '10px'
  }
}
export const SearchImage = styled.img``
export const AppNavContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-left: ${({ theme }) => theme.spacing.md};
`
export const AppNavTitle = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.size.md};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.secondary};
`
export const AppSubNavTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.size.sm};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.primary};
`
