import React, { useCallback } from 'react'
import { useReduxDispatch } from '../redux'
import { getCustomers } from '../redux/CustomerSlice'
import { getDevices, getDeviceSupplier } from '../redux/DeviceSlice'
import { getOtherDataModels, getUsers, userActions } from '../redux/UserReducer'
import { getFacilitiesGroups } from '../redux/FacilitiesGroupSlice'
import { getHarvest } from '../redux/HarvestSlice'
import { getFacilities } from '../redux/Facilities'
import { getFacilityZones } from '../redux/FacilityZonesSlice'
import { getFacilityZonesTypes } from '../redux/FacilityZoneTypesSlice'
import { getAttachedMaterial, getMaterial } from '../redux/MaterialSlice'
import { getDeviceTypes } from '../redux/DeviceTypeSlice'
import { getRoles } from '../redux/RoleSlice'
import { getPermissions } from '../redux/PermissionSlice'
import { getAllDataModelPermissions } from '../redux/DataModelPermissionSlice'
import {
  getAlert,
  getNotificationCondition,
  getNotificationEventType,
  getNotificationFrequency,
  getNotificationGroup
} from '../redux/AlertSlice'
import { getMonitoredUnits } from '../redux/MonitoredUnitsSlice'
import { getMonitoredGroup } from '../redux/MonitoredGroupSlice'
import { getDuration } from '../redux/TImeSlice'
import { getHarvestBrand } from '../redux/BrandSlice'

const useLoaders = () => {
  const dispatch = useReduxDispatch()
  const loadAppData = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    await Promise.all([
      dispatch(getRoles()),
      dispatch(getPermissions()),
      dispatch(getAllDataModelPermissions()),
      dispatch(getCustomers()),
      dispatch(getDeviceSupplier()),
      dispatch(getDevices(user?.customerId)),
      dispatch(getDeviceTypes(0)),
      dispatch(getUsers()),
      dispatch(getFacilitiesGroups()),
      dispatch(getFacilities()),
      dispatch(getFacilityZones()),
      dispatch(getFacilityZonesTypes()),
      dispatch(getHarvest()),
      dispatch(getMaterial()),
      dispatch(getAttachedMaterial()),
      dispatch(userActions.setUser(user)),
      dispatch(getAlert()),
      dispatch(getNotificationCondition()),
      dispatch(getNotificationFrequency()),
      dispatch(getNotificationEventType()),
      dispatch(getNotificationGroup()),
      dispatch(getMonitoredUnits()),
      dispatch(getMonitoredGroup()),
      dispatch(getOtherDataModels()),
      dispatch(getDuration()),
      dispatch(getHarvestBrand())
    ])
  }, [])

  return { loadAppData }
}

export default useLoaders
