import WeightImg from '../../assets/images/IotFacilityImages/Group 346.svg'
import Co2Img from '../../assets/images/IotFacilityImages/Group 353.svg'
import HumidityImg from '../../assets/images/IotFacilityImages/Group 350.svg'
import LuxImg from '../../assets/images/IotFacilityImages/Group 349.svg'
import PressureImg from '../../assets/images/IotFacilityImages/Group 369.svg'
import TempImg from '../../assets/images/IotFacilityImages/Group 351.svg'
import ThiImg from '../../assets/images/IotFacilityImages/Group 348.svg'
export enum SENSORS {
  humidity = 2,
  temperature = 1,
  airPressure = 3,
  weight = 4,
  co2Level = 5,
  waterIntake = 6,
  cost = 7,
  foodConsumption = 8
}

export enum HARVEST {
  'noOfBirds',
  'dead',
  'legCulls',
  'otherCulls'
}

export const sensorsRuminTechColor: any = {
  weight: '#665bfe',
  co2Level: '#0153a5',
  humidity: '#017745',
  thi: '#eda701',
  lux: '#a5a61a',
  waterIntake: '#22c0d9',
  temperature: '#c65c20'
}

export const sensorsRuminTechImages = [
  {
    name: 'airPressure',
    img: PressureImg,
    symbol: 'kpa'
  },

  {
    name: 'humidity',
    img: HumidityImg,
    symbol: '%'
  },
  {
    name: 'lux',
    img: LuxImg,
    symbol: 'lx'
  },
  {
    name: 'thi',
    img: ThiImg,
    symbol: 'THI'
  },
  {
    name: 'temperature',
    img: TempImg,
    symbol: '°c'
  },
  {
    name: 'co2Level',
    img: Co2Img,
    symbol: 'ppm'
  },
  {
    name: 'weight',
    img: WeightImg,
    symbol: 'kg'
  }
]
