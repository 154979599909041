import leftarrow from './leftArrowIcon.svg'
import houseIcon from './shedsHouseIcon.svg'
import addbutton from './addButtonIcon.svg'
import shedView from './shed3d.png'
import ShedLogImage from './ShedLogIcon.svg'
import CrossImage from './crossIcon.svg'
import BpacBuildingImage from './BpacBuildingIcon.svg'
export const LeftArrowIcon = leftarrow
export const shedsHouseIcon = houseIcon
export const addButtonIcon = addbutton
export const shed3dView = shedView
export const ShedLogIcon = ShedLogImage
export const CrossIcon = CrossImage
export const BpacBuildingIcon = BpacBuildingImage
