import React from 'react';
import './AppPolygon.scss';

interface AppPolygonProps {
  color?: string;
  backgroundColor?: string;
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  className?: string;
  children: any;
  warpperClassName?: string;
}

const AppPolygon: React.FC<React.PropsWithChildren<AppPolygonProps>> = ({ color, warpperClassName, width, height, className, backgroundColor, children }) => {
  return (
    <div className={`polygon-wrap ${warpperClassName}`}>
      <div className={`inner-polygon ${className}`} style={{ color, width, height, backgroundColor }}>
        {children}
      </div>
    </div>
  );
};

export default AppPolygon;
