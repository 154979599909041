import { GetConfig } from '../../config/config'
import { FieldTypes } from './facility'

export const AddFarmDialogFields = {
  name: { label: 'name', field: 'name', namePlaceholder: 'Farm Name' },
  address: { label: 'address', field: 'address', addressPlaceholder: 'Address' },
  customer: { label: 'customer', field: 'customer' },
  customerId: { label: 'customerId', field: 'customerId' }
}

export enum FacilityGroupEventsType {
  COMPLETED = 1,
  NOTCOMPLETED = 2,
  LATE = 3
}

export const ADD_FARM_BUTTON = 'Add Farm'
export const EDIT_FARM_BUTTON = 'Edit farm'
export const CANCEL_FARM_BUTTON = 'Cancel Farm'

const config = GetConfig().global.facilityGroup

export const facilityGroupPoultryDialog = [
  {
    label: 'Name',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'NamePlaceholder'
  },
  {
    label: 'Address',
    field: 'address',
    dataName: 'Address',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'AddressPlaceholder'
  },
  {
    label: 'Polygon',
    type: FieldTypes.Custom,
    dataName: 'Polygon',
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: null
  },
  {
    label: 'IsBreeder',
    field: 'isBreeders',
    dataName: 'IsBreeder',
    type: FieldTypes.Custom,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: null
  }
]

export const facilityGroupHydroDialog = [
  {
    label: 'siteName',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'siteNamePlaceHolder'
  },
  {
    label: 'Address',
    field: 'address',
    dataName: 'Address',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'AddressPlaceholder'
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: null
  }
]

export const facilityGroupRuminTechDialog = [
  {
    label: 'farmName',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'farmNamePlaceHolder'
  },
  {
    label: 'Address',
    field: 'address',
    dataName: 'Address',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'AddressPlaceholder'
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: null
  }
]
