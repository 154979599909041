import iot from './iot.svg'
import users from './user.svg'
import devices from './devices.svg'
import map from './map.svg'
import analysis from './analysis.svg'
import sites from './sites.svg'
import workPlan from './workPlan.svg'
import alert from './alert.svg'
export const iotIcon = iot
export const userIcon = users
export const devicesIcon = devices
export const mapIcon = map
export const analysisIcon = analysis
export const siteIcon = sites
export const workPlanIcon = workPlan
export const alertIcon = alert