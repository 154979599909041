import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddDevice, Idevice, IDeviceSupplier } from 'device';
import { serverCreateDevice, serverGetDevice,serverGetDeviceSuppliers } from '../services/serverApi';

type DeviceState = {
  devices: Array<Idevice>;
  suppliers: Array<IDeviceSupplier>;
  loading: boolean;
  error: string | null;
};

const initialState: DeviceState = {
  devices: [],
  suppliers: [],
  loading: false,
  error: null
};

/**Get devices by customer Id */
export const getDevices = createAsyncThunk('device/getDevices', async (customerId: number, thunkApi) => {
  try {
    const res = await serverGetDevice();
    return res;
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit);
  }
});

/**Add device  */
export const addDevice = createAsyncThunk('device/addDevice', async (device: AddDevice, thunkApi) => {
  try {
    const res = await serverCreateDevice(device);
    thunkApi.dispatch(getDevices(device.customerId));
  } catch (e) {
    console.error(e);
  }
});

export const getDeviceSupplier = createAsyncThunk('device/getSuppliers', async (s, thunkApi) => {
  try {
    const res = await serverGetDeviceSuppliers();
    return res;
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit);
  }
}
);

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getDevices.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getDevices.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.devices = payload as Idevice[];
      }),
      builder.addCase(getDevices.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      }),
      builder.addCase(addDevice.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
      }),
      builder.addCase(addDevice.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(addDevice.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      });

    builder.addCase(getDeviceSupplier.pending, (state) => {
      state.loading = true;
    }
    ),
      builder.addCase(getDeviceSupplier.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.suppliers = payload as IDeviceSupplier[];
      }
      ),
      builder.addCase(getDeviceSupplier.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
      });
  }
});

export const deviceReducer = deviceSlice.reducer;
export const deviceActions = deviceSlice.actions;
