type ChartDevice = {
  id: number
  color: string
  sensor: SensorType
  facilityGroupId: number
}

type ChartSeason = {
  id: number
  color: string
  season: IHarvest
}
type ChartSite = {
  id: number
  color: string | undefined
  site: any
}
export enum DateType {
  crop,
  picker,
  ruler
}

export enum IntervalType {
  Raw,
  Hour,
  Day
}
export enum ChartType {
  Line = 3,
  Bar = 4,
  NoTimeLine
}

export interface IAnalysis {
  airPressure: number
  captureTime: Date
  height: number
  humidity: number
  lux: number
  temperature: number
  thi: number
  volume: number
  waterFlow: number
  waterIntake: number
  waterTemperature: number
  weight: number
  co2Level: number
  zoneId?: number
  zoneName?: string
  inZonePositionId?: number
}

export interface IAnalysisEfficiency {
  startDate: number
  endDate: number
  interval: number
  facilityGroupId?: number
  facilityId?: number
  facilityIds?: number[]
}

export interface IGetAnalysis {
  startDate: Date | number
  endDate: Date | number
  sensors: string
  facilityZoneId?: number[]
  includeZoneInfo?: boolean
  interval?: number | undefined
  facilityGroupId?: number | undefined | number[]
  deviceId?: number | undefined | number[]
  facilityId?: number | undefined | number[]
  monitoredGroupId?: number | undefined | number[]
  customerId?: number | undefined | number[]
}

interface IInputRadarObject {
  siloId: number[]
  isLine: boolean
  isArea: boolean
  interval: number
  data: any
  loading: boolean
  startDate: Date
  endDate: Date
}

export interface SiloData {
  height: number
  maxCapacityVolume: number
  maxCapacityWeight: number
  height2: number | null
  avgHeight: number
  coneVolume: number
  cylinderVolume: number
  totalVolume: number
  weight: number
  percent: number
  captureDate: Date
  captureTime?: Date
  zoneId: number
}
export interface ChartData {
  name: string
  data: Array<{
    x: number
    y: number
  }>
  facilityId?: number
}

export interface ChartValues {
  x: number
  y: number
  id?: number
}

export interface ChartValues {
  x: number
  y: number
  id?: number
}

export enum DateButtons {
  Live,
  Today,
  Crop,
  Custom
}
