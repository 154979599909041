import styled from 'styled-components'

export const AppSubNavigation = styled.div`
  height: 45px;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
  align-item: center;
  width: 100%;
  padding-left: 10px;
  padding-top: 6px;
  display: inline-block;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`
