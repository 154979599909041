import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  serverAddMaterial,
  serverDeleteMaterial,
  serverGetAttachedMaterials,
  serverAddAttachedMaterial,
  serverGetMaterials,
  serverDeleteAttachedMaterial,
  serverEditMaterial
} from '../services/serverApi'
import { IFacilityZones } from 'facilityZones'
import { IAttachedMaterial, IMaterial, IMaterialPost, IMaterialPut, IPostAttachedMaterial } from '../types/material.d'

type Materialstate = {
  material: Array<IMaterial>
  attachedMaterial: Array<IAttachedMaterial>
  loading: boolean
  error: string | null
}

const initialState: Materialstate = {
  material: [],
  attachedMaterial: [],
  loading: false,
  error: null
}

/**Get Materials */
export const getMaterial = createAsyncThunk('getMaterial', async (s, thunkApi) => {
  try {
    const res = await serverGetMaterials()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const getAttachedMaterial = createAsyncThunk('getAttachedMaterial', async (s, thunkApi) => {
  try {
    const res = await serverGetAttachedMaterials()
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const addMaterial = createAsyncThunk('addMaterial', async (material: IMaterialPost, thunkApi) => {
  try {
    await serverAddMaterial(material)
    const res = await thunkApi.dispatch(getMaterial())
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const addAttachMaterial = createAsyncThunk('addAttachMaterial', async (material: IPostAttachedMaterial, thunkApi) => {
  try {
    await serverAddAttachedMaterial(material)
    const res = await thunkApi.dispatch(getAttachedMaterial())
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const editMaterial = createAsyncThunk('Edit material', async (editMaterialObj: IMaterialPut, thunkApi) => {
  try {
    await serverEditMaterial(editMaterialObj)
    const res = await thunkApi.dispatch(getMaterial())
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const deleteMaterial = createAsyncThunk('delete material', async (materialId: number, thunkApi) => {  
  try {
    await serverDeleteMaterial(materialId)
    const res = await thunkApi.dispatch(getMaterial())
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const deleteAttachMaterial = createAsyncThunk('delete attach material', async (materialId: number, thunkApi) => {
  try {
    await serverDeleteAttachedMaterial(materialId)
    const res = await thunkApi.dispatch(getAttachedMaterial())
    return res
  } catch (e) {
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

/**Add device  */

export const materialSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    //Get material builders
    builder.addCase(getMaterial.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getMaterial.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.material = payload
      }),
      builder.addCase(getMaterial.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      //Add material builders
      builder.addCase(addMaterial.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addMaterial.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.material = payload.payload as unknown as IMaterial[]
      }),
      builder.addCase(addMaterial.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
    //Delete material builders
    builder.addCase(deleteMaterial.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(deleteMaterial.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
      }),
      builder.addCase(deleteMaterial.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      //Get attached Materials
      builder.addCase(getAttachedMaterial.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(getAttachedMaterial.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.attachedMaterial = payload as IAttachedMaterial[]
      }),
      builder.addCase(getAttachedMaterial.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      }),
      //Add attach material builders
      builder.addCase(addAttachMaterial.pending, (state) => {
        state.loading = true
      }),
      builder.addCase(addAttachMaterial.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.attachedMaterial = payload as unknown as IAttachedMaterial[]
      }),
      builder.addCase(addAttachMaterial.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
    //Edit material builders
    builder.addCase(editMaterial.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(editMaterial.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.material = payload.payload
      }),
      builder.addCase(editMaterial.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const materialReducer = materialSlice.reducer
export const materialActions = materialSlice.actions
