import { GetConfig } from '../../config/config'

export const FacilityHeaderInfo = [
  {
    title: 'Name'
  },
  {
    title: 'Tag'
  },
  {
    title: '# of devices'
  },
  {
    title: 'Parent Farm'
  },
  {
    title: 'W'
  },
  {
    title: 'L'
  },
  {
    title: 'H'
  },
  {
    title: '# of Zones'
  },
  {
    title: 'Water Lines'
  },
  {
    title: 'Feed Lines'
  },
  {
    title: 'Action'
  }
]
const config = GetConfig().global

export const FacilityFields = {
  name: { label: 'Name', field: 'name' },
  tagNumber: { label: 'Tag', field: 'tagNumber', placeholder: 'Tag Number' },
  customerId: { label: 'Customer', field: 'customerId' },
  facilityGroupId: { label: 'facilityGroupId', field: 'facilityGroupId' },
  facilitiesZones: { label: '# of Zones', field: 'facilitiesZones', placeholder: 'Number of Zones' },
  Dimensions: { label: 'Dimensions' },
  width: { label: 'W', field: 'width' },
  length: { label: 'L', field: 'length' },
  height: { label: 'H', field: 'height' }
}

export enum FieldTypes {
  DropDown = 'dropDown',
  Input = 'input',
  Custom = 'custom',
}

export const BuildingFacility = {
  Building: 1246
}

export const MATERIAL_CELL_TYPE = 'MaterialCell'

export enum FieldVariant {
  Multiple = 'multiple',
  Number = 'number',
  Date = 'date',
  Time = 'time',
  Checkbox = 'checkbox'
}

export const facilityPoultryDailog = [
  {
    label: 'Name',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: `${config?.facility?.name} Name`
  },
  {
    label: 'Tag',
    field: 'tagNumber',
    dataName: 'Tag',
    type: FieldTypes.Input,
    variant: FieldVariant.Number,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Tag Number'
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: `${config?.facilityGroup?.name}`,
    field: 'facilityGroupId',
    dataName: 'FacilityGroups',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: `# of ${config?.facilityZones?.name}`,
    field: 'facilitiesZones',
    dataName: 'facilitiesZones',
    type: FieldTypes.Input,
    variant: FieldVariant.Number,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: `Number of ${config?.facilityZones?.name}s`
  },
  {
    label: `Row Order`,
    field: 'isRowOrder',
    dataName: 'isRowOrder',
    type: FieldTypes.Input,
    variant: FieldVariant.Checkbox,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: false,
    placeholder: ``
  },
  { type: FieldTypes.Custom }
]
export const facilityBpacDailog = [
  {
    label: 'Name',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Building Name'
  },
  {
    label: 'Tag',
    field: 'tagNumber',
    dataName: 'Tag',
    type: FieldTypes.Input,
    variant: FieldVariant.Number,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Tag Number'
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: 'Zone',
    field: 'facilityGroupId',
    dataName: 'FacilityGroups',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: '# of Units',
    field: 'facilitiesZones',
    dataName: 'facilitiesZones',
    type: FieldTypes.Input,
    variant: FieldVariant.Number,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Number of Units'
  },
  { type: FieldTypes.Custom }
]

export const facilityHydroDialog = [
  {
    label: 'Name',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Shed Name'
  },
  {
    label: 'Tag',
    field: 'tagNumber',
    dataName: 'Tag',
    type: FieldTypes.Input,
    variant: FieldVariant.Number,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Tag Number'
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: 'Site',
    field: 'facilityGroupId',
    dataName: 'FacilityGroups',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  // { label: '# of Zones', field: 'facilitiesZones', dataName: 'facilitiesZones', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true },
  { type: FieldTypes.Custom }
]

export const facilityRuminTechDialog = [
  {
    label: 'Name',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Shed Name'
  },
  {
    label: 'Tag',
    field: 'tagNumber',
    dataName: 'Tag',
    type: FieldTypes.Input,
    variant: FieldVariant.Number,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Tag Number'
  },
  {
    label: 'Customer',
    field: 'customerId',
    dataName: 'Customers',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: 'Herd',
    field: 'facilityGroupId',
    dataName: 'FacilityGroups',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  // { label: '# of Zones', field: 'facilitiesZones', dataName: 'facilitiesZones', type: FieldTypes.Input, variant: FieldVariant.Number, isUpdateVisible: true, isUpdateDisabled: false, isValidCheck: true },
  { type: FieldTypes.Custom }
]
export const MAXZONES_NUMBER = 6

export const BPSC_MAXZONES_NUMBER = 48

export const CUSTOME_ZONE_TYPE_ID = -1

export const MaterialCellDialog = [
  {
    label: 'Name',
    field: 'name',
    dataName: 'Name',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Enter name'
  },
  {
    label: 'Shed',
    field: 'facilityId',
    dataName: 'Facilities',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: 'Type',
    field: 'facilityTypeId',
    dataName: 'Type',
    type: FieldTypes.DropDown,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true
  },
  {
    label: 'Type Name',
    field: 'typeName',
    dataName: 'TypeName',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Enter type name'
  },
  {
    label: 'Height',
    field: 'height',
    dataName: 'Height',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Height'
  },
  {
    label: 'Width',
    field: 'width',
    dataName: 'Width',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Width'
  },
  {
    label: 'Length',
    field: 'length',
    dataName: 'Length',
    type: FieldTypes.Input,
    variant: null,
    isUpdateVisible: true,
    isUpdateDisabled: false,
    isValidCheck: true,
    placeholder: 'Length'
  }
]
