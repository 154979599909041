import styled from 'styled-components'

export const DevicesContainer = styled.div`
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-template-rows: 30px 50px 1fr;
  height: 100%;
  // gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    overflow-x: hidden;
  }
`

export const ShedsBackTitleIcon = styled.div`
  width: fit-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const ShedsBackTitle = styled.div`
  margin-left: ${({ theme }) => theme.typography.size.xs};
`
export const ShedsBackIcon = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
`
export const MobileViewWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: unset;
    height: 500px;
    width: 100%;
    overflow: hidden;
  }
`
export const TableData = styled.td`
  width: 120px;
  color: #113252;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.size.md};
  font-weight: 900;
  img {
    margin-right: 10px;
  }
  :first-child {
    width: 10px;
    min-width: 8em;
    max-width: 8em;
  }

  :nth-child(4) {
    color: green;
  }
  :nth-child(5) {
    color: red;
  }
  :nth-child(6) {
    color: #feb200;
  }
`

export const RowHeadingComponent = styled.tr`
  width: 100%;
  margin-top: 100px;
`
export const ArrowImg = styled.img`
  width: 9px;
  margin: 8px 0 0 10px;
`
