import styled from "styled-components";

export const FarmDimensionsWrapper = styled.div`
  margin: 20px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const AddFarmLabel = styled.label`
  color: #7b7b7b;
  font-size: ${({ theme }) => theme.typography.size.sm};
`
export const TagInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const TagHeading = styled.span`
  padding-left: ${({ theme }) => theme.spacing.md};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: ${({ theme }) => theme.spacing.sm};
  }
`
export const AddFarmDimensionsInput = styled.input`
  width: 50.24px;
  border: 1px solid #707070;
  margin-left: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.forth};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 35px;
  }
`