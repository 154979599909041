import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { RootState, useReduxSelector } from '../../redux'
// import RequireAuth from '../../components/RequireAuth/RequireAuth'
const RequireAuth = React.lazy(() => import('../../components/RequireAuth/RequireAuth'))
// import Customers from '../Customers/Customers'
const Customers = React.lazy(() => import('../Customers/Customers'))
// import Devices from '../Devices/Devices'
const Devices = React.lazy(() => import('../Devices/Devices'))
// import DevicesSummary from '../Devices/PoultryWeights/DevicesSummary/DevicesSummary'
const DevicesSummary = React.lazy(() => import('../Devices/RuminTech/DevicesSummary/DevicesSummary'))
// import Farms from '../Farms/Farms'
const Farms = React.lazy(() => import('../Farms/Farms'))
// import Iot from '../IOT/IOT'
const Iot = React.lazy(() => import('../IOT/IOT'))
// import Login from '../Login/Login'
const Login = React.lazy(() => import('../Login/Login'))
// import ManagerReports from '../ManagerReports/ManagerReports'
const ManagerReports = React.lazy(() => import('../ManagerReports/ManagerReports'))
// import Alerts from '../MyFarm/Alerts/Alerts'
// const Alerts = React.lazy(() => import('../MyFarm/bees/Alerts/Alerts'))
const Alerts = React.lazy(() => import('../Alert/Alert'))
// import ResetPassword from '../ResetPassword/ResetPassword'
const ResetPassword = React.lazy(() => import('../ResetPassword/ResetPassword'))
// import Roles from '../Roles/Roles'
const Roles = React.lazy(() => import('../Roles/Roles'))
// import Sheds from '../Sheds/Sheds'
const Sheds = React.lazy(() => import('../Sheds/Sheds'))
// import Users from '../Users/Users'
const Users = React.lazy(() => import('../Users/Users'))
// import LoginWithToken from '../LoginWithToken/LoginWithToken'
const LoginWithToken = React.lazy(() => import('../LoginWithToken/LoginWithToken'))
// import Ai from '../Ai/Ai'
const Ai = React.lazy(() => import('../Ai/Ai'))
// import UserData from '../UserData/UserData'
const UserData = React.lazy(() => import('../UserData/UserData'))
// import Animals from '../Animals/Animals'
const Animals = React.lazy(() => import('../Animals/Animals'))
// import Groups from '../Groups/Groups'
const Groups = React.lazy(() => import('../Groups/Groups'))
// import RuminTechGateways from '../Devices/RuminTech/Gateways/Gateways'
const RuminTechGateways = React.lazy(() => import('../Devices/RuminTech/Gateways/Gateways'))
// import Recipe from '../Recipe/Recipe'
const Recipe = React.lazy(() => import('../Recipe/Recipe'))
// import Ingredient from '../Ingredient/Ingredient'
const Ingredient = React.lazy(() => import('../Ingredient/Ingredient'))
// import OverView from '../GraphOverView/OverView'
const OverView = React.lazy(() => import('../GraphOverView/OverView'))
// import Schedule from '../Schedule/Schedule'
const Schedule = React.lazy(() => import('../Schedule/Schedule'))
// import InventoryDistribution from '../InventoryDistribution/InventoryDistribution'
const InventoryDistribution = React.lazy(() => import('../InventoryDistribution/InventoryDistribution'))
// import IngredientCost from '../IngredientCost/IngredientCost'
const IngredientCost = React.lazy(() => import('../IngredientCost/IngredientCost'))
// import DeviceInfo from '../Devices/DeviceInfo/DeviceInfo'
const DeviceInfo = React.lazy(() => import('../Devices/DeviceInfo/DeviceInfo'))
// import SignUp from '../Signup/RuminTech/SignUp'
const SignUp = React.lazy(() => import('../Signup/RuminTech/SignUp'))
const TaskManagerEvent = React.lazy(() => import('../TaskManager/Event'))
const TaskManagerTask = React.lazy(() => import('../TaskManager/Task'))
const TaskManagerDashboard = React.lazy(() => import('../TaskManager/Dashboard'))
const Payment = React.lazy(() => import('../Payment/Payment'))

import Loader from '../../components/Loader/Loader'
import DeviceVersion from '../DeviceVersion/DeviceVersion'
import Expired from '../Expired/Expired'
import DayWiseTask from '../TaskManager/Task/DayWiseTask'

const RuminTechRoutes = () => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<Loader size={80} />}>
            {/* <Signup /> */}
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path="/reset"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/login/token"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <LoginWithToken />
          </Suspense>
        }
      />
      <Route
        path="/expired"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <Expired />
          </Suspense>
        }
      />

      <Route
        path="/Iot/General"
        element={
          <RequireAuth>
            <>
              <Iot />
            </>
          </RequireAuth>
        }
      />
      <Route
        path="/Devices/summary"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DevicesSummary />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Devices/Devices"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Devices />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/Device"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceInfo />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyFarm/Schedule"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Schedule />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/Gateways"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <RuminTechGateways />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyFarm/Groups"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <>
                <Groups />
              </>
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyFarm/Animals"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <>
                <Animals />
              </>
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/MyFarm/Farms"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Farms />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyFarm/Extensions"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Sheds />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/MyFarm/Alert"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Alerts />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Accounts/*"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Payment />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/Analysis/Comparison"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <ManagerReports />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Analysis/Graph"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <OverView />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/task-manager"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <OverView />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/task-manager/dashboard"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <TaskManagerDashboard />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/task-manager/event"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <TaskManagerEvent />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/task-manager/task"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <TaskManagerTask />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/task-manager/day-wise-tasks"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DayWiseTask />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="Inventory/Recipe"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Recipe />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="Inventory/Ingredient"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Ingredient />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/CRM/users"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Users />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/CRM/Customer"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Customers />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/CRM/Roles"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Roles />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/api/key"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <UserData />
            </RequireAuth>
          </Suspense>
        }
      />

      <Route
        path="/ai/charts"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <Ai />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/api/key"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <UserData />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Inventory/Distribution"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <InventoryDistribution />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="Inventory/cost"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <IngredientCost />
            </RequireAuth>
          </Suspense>
        }
      />
      <Route
        path="/Devices/device-version"
        element={
          <Suspense fallback={<Loader size={80} />}>
            <RequireAuth>
              <DeviceVersion />
            </RequireAuth>
          </Suspense>
        }
      />
    </Routes>
  )
}

export default RuminTechRoutes
