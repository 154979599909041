import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import store from './index'
import { LocationFilter } from '../types/locationFilter.d'

const initialState: LocationFilter = {
  selectedData: [],
  selectedCropData: [],
  onlyCustomers: false,
  onlyFacilities: false,
  onlyFacilitiesGroup: false,
  onlyFacilityZones: false,
  noCustomer: false,
  multipleSelect: true,
  enableZone: false,
  selectedSiteId: 0
}

export const locationSlice = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: {
    setLocationData: (state, action) => {
      state.selectedData = action.payload
    },
    setCropData: (state, action) => {
      state.selectedCropData = action.payload
    },
    setType: (state, action) => {
      state.onlyCustomers = action.payload.customer
      state.onlyFacilities = action.payload.facility
      state.onlyFacilitiesGroup = action.payload.facilityGroup
      state.onlyFacilityZones = action.payload.facilityZone
      state.noCustomer = action.payload.noCustomer
    },
    setMultipleSelect: (state, action) => {
      state.multipleSelect = action.payload
    },
    setEnableZone: (state, action) => {
      state.enableZone = action.payload
    },
    setSelectedSiteId: (state, action) => {
      state.selectedSiteId = action.payload
    }
  }
})

export const locationSliceReducer = locationSlice.reducer

export const { setLocationData, setType, setMultipleSelect, setEnableZone, setCropData, setSelectedSiteId } = locationSlice.actions
