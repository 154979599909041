import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { IUser } from 'user'

export const getUserRole = (user: any) => {
  let role: string = 'Viewer'
  if (user.dataModels && user.dataModels[0]) {
    role = user.dataModels[0].roleName
  }
  return role
}

export const exportCSV = (cols: any, datas: any, fileName?: string) => {
  if (cols && Array.isArray(datas) && datas.length > 0) {
    let csvData = ''

    // Get column headers
    const columnHeaders = cols.map((header: any) => header.title)
    csvData += columnHeaders.join(',') + '\n'

    // Get row data
    datas.forEach((row) => {
      const rowData = cols.map((header: any) => {
        const columnData = row[header.field]
        // Log the column-wise data for verification
        // console.log(`Column: ${header.title}, Data: ${columnData}`);
        return columnData
      })
      csvData += rowData.join(',') + '\n'
    })

    // Create a temporary link to initiate download
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    link.download = `${fileName ? fileName : 'data'}.csv`
    link.style.display = 'none'

    // Append the link to the DOM and trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const exportPDF = (cols: any, datas: any, fileName?: string) => {
  const filteredColumns = cols.filter((c: any) => typeof c.field === 'string' && typeof c.title === 'string')
  const fields = filteredColumns.map((c: any) => c.field)

  const docDefinition = {
    content: [
      {
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: filteredColumns.map(() => '*'),

          body: [filteredColumns.map((c: any) => c.title), ...datas.map((row: any) => fields.map((f: any) => row[f]))]
        }
      }
    ]
  }
  pdfMake.createPdf(docDefinition, undefined, undefined, pdfFonts.pdfMake.vfs).download('Data')
}

export const GetuserName = (user: IUser) => {
  if (user?.firstName && user?.lastName) {
    return user?.firstName[0] + user.lastName[0]
  } else if (user?.firstName && !user?.lastName) {
    return user?.firstName[0]
  } else if (!user?.firstName && user?.lastName) {
    return user?.lastName[0]
  } else {
    return ''
  }
}

// utils/language.js

export const setUserLanguage = () => {
  const userLanguage = navigator.language
  // Add any logic here to handle different language formats (e.g., 'en-US' vs. 'en').
  // For simplicity, let's assume the language code is always the first two characters.
  const languageCode = userLanguage.substring(0, 2)
  localStorage.setItem('languageCode', languageCode)
  return languageCode
}
