import { IHarvest } from 'harvest'
import React from 'react'
import { CropModalMobileIcon, locationIcon } from '../../../../../../assets/images/appBar'
import { CloseIconImg } from '../../../../../../assets/images/UsersImages'
import { RootState, useReduxSelector } from '../../../../../../redux'
import { getFormatStandardDate } from '../../../../../../utils/helpers/date'
import Modal from '../../../../../Model/ModalMobileView'
import { ModalContent, ModalTopWrapper } from '../../../../../Model/ModalMobileView.styled'
import {
  CloseImg,
  ContentTopWrapper,
  CropDate,
  CropId,
  ModalIcon,
  ModelHeading,
  SelectCustomerContainer,
  TableList,
  TableWrapper
} from '../../MobileAppbar.styled'

interface IModalProps {
  harvestHandler: (harvest: IHarvest) => void
  isOpen: { [key: string]: boolean }
  setIsOpen: (isOpen: { [key: string]: boolean }) => void
  harvestId: number | undefined
}

const CropModal: React.FC<IModalProps> = ({ harvestHandler, isOpen, setIsOpen, harvestId }) => {
  const { harvests } = useReduxSelector((state: RootState) => state.harvest)
  const getStartDate = (startDate: Date | undefined) => {
    if (startDate) {
      return getFormatStandardDate(startDate)
    }
  }
  const getEndDate = (endDate: Date | undefined) => {
    if (endDate) {
      return getFormatStandardDate(endDate)
    }
  }

  return (
    <>
      {isOpen.crop && (
        <Modal show={isOpen.crop}>
          <ModalTopWrapper>
            <CloseImg src={CloseIconImg} onClick={() => setIsOpen({ ...isOpen, crop: !isOpen.crop })} />
          </ModalTopWrapper>
          <ModalContent>
            <ContentTopWrapper>
              <ModalIcon src={CropModalMobileIcon} />
              <ModelHeading>Select Crop</ModelHeading>
            </ContentTopWrapper>
            <SelectCustomerContainer>
              {harvests.map((harvest: IHarvest) => {
                return (
                  <TableWrapper key={harvest.id}>
                    <TableList isActive={harvestId === harvest.id} onClick={() => harvestHandler(harvest)}>
                      <CropId isActive={harvestId === harvest.id}>#{harvest.id}</CropId>
                      <CropDate isActive={harvestId === harvest.id}>
                        {getStartDate(harvest.startDate)}-{harvest.closeDate === null ? 'Concurrent' : getEndDate(harvest.closeDate)}
                      </CropDate>
                    </TableList>
                  </TableWrapper>
                )
              })}
            </SelectCustomerContainer>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default CropModal
