import styled, { keyframes } from 'styled-components'

export const SelectWrapper = styled.div<{ disabled: boolean; width?: number }>`
  width: 220px;
  min-width: 200px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  pointer-events: ${({ disabled }) => (!disabled ? 'block' : 'none')};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: 150px;
    width: 150px;
  }
`

export const SelectedOptionBox = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xs};
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  min-width: 190px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: 130px;
  }
`

export const DropdownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  color: #999;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`
export const OptionsPopup = styled.div<{ show: boolean; width: any; top?: number | undefined; left?: number | undefined }>`
  ${({ top, left }) => (top !== undefined && left !== undefined ? `top: ${top}px;` : '')};
  position: absolute;
  min-width: 250px;
  width: ${({ width }) => width + 'px'}; /* Adjusted to 100% */
  max-height: 530px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 999;
  padding-top: 8px;
  padding-bottom: 8px;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: ${({ show }) => (show ? 'scale(1)' : 'scale(0.9)')};
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} 0.24s ease-in-out forwards;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`
export const OptionsContainer = styled.div`
  max-height: 350px;
  height: auto;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: auto;
    max-height: 270px;
  }
`

interface OptionProps {
  selected: boolean
  onMouseDown: React.MouseEventHandler<HTMLDivElement>
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  disabled: boolean
  customComponent: boolean
}

export const Option = styled.div<OptionProps>`
  display: grid;
  grid-template-columns: ${({ customComponent }) => (customComponent ? '1.5fr 1fr' : '1fr')};
  user-select: none;
  padding: 8px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'block')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  background-color: ${({ selected }) => (selected ? 'rgba(25, 118, 210, 0.08)' : 'transparent')};
  &:hover {
    background-color: ${({ selected }) => (!selected ? '#e0e0e0' : 'rgba(25, 118, 210, 0.2)')};
  }
`

export const SelectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  border-bottom: 0.1px dotted grey;
`

export const SelectAllOption = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 8px;
  /* border-bottom: 0.1px solid grey; */
  background-color: ${({ selected }) => (selected ? 'rgba(25, 118, 210, 0.08)' : 'transparent')};
  &:hover {
    background-color: ${({ selected }) => (!selected ? '#e0e0e0' : 'rgba(25, 118, 210, 0.2)')};
  }
`
export const ClearAllOption = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 8px;
  background-color: ${({ selected }) => (selected ? 'rgba(25, 118, 210, 0.08)' : 'transparent')};
  &:hover {
    background-color: ${({ selected }) => (!selected ? '#e0e0e0' : 'rgba(25, 118, 210, 0.2)')};
  }
`

export const SelectedOptionsContainer = styled.div`
  width: 100%;
  /* max-width: 150px; */
  padding: ${({ theme }) => theme.spacing.xs};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Show ellipsis for overflowed content */
  &:hover {
    background-color: #e0e0e0;
  }
`
export const ExpandMoreIcon = styled.img``
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.sm};
  margin: ${({ theme }) => theme.spacing.xs};
  border: 1px solid #d0d5dd;
  border-radius: 10px;
`
export const SearchInput = styled.input`
  height: auto;
  border: none;
  outline: none;
  background: none;
`
export const InputIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`
export const InputLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.size.xs};
`
