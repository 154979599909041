import { AppLocationWrapper } from './AppLocationFilter.styled'
import { useCallback, useEffect, useState } from 'react'
import './TreeDropDown.scss'
import DropdownTreeSelect from './DropDownTree/index'
import { setLocationData, setMultipleSelect, setType } from '../../../../redux/LocationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../redux'
import { ICustomer } from 'customers'
import { IFacilityGroup } from '../../../../types/facilityGroup.d'
import { FacilityType, IFacility, IZone } from '../../../../types/facility.d'
import { list2tree } from '../../../../utils/helpers/treeSelection'
import { ITreeNode } from 'locationFilter'
import { GetConfig } from '../../../../config/config'
import { useLocation } from 'react-router-dom'

export enum HirarchyTypes {
  Customer,
  FaciltyGroup,
  Facility,
  Zone
}

export type NodeType = (ICustomer | IFacilityGroup | IFacility | IZone) & {
  checked?: boolean
  parentId?: number
  children?: NodeType
  type?: HirarchyTypes
  value?: number
  label?: string
}
interface ISelectedTree {
  value: string
  label: string
  parentId: string
  parentParentId: number
  type: HirarchyTypes
  checked: boolean
  id?: number
}
const AppbarLocationComponent = () => {
  const config = GetConfig()?.global
  const { customers } = useSelector((state: RootState) => state.customers)
  const { user } = useSelector((state: RootState) => state.user)
  const { facilitiesGroups } = useSelector((state: RootState) => state.facilitiesGroup)
  const { facilities } = useSelector((state: RootState) => state.facilities)
  const { facilityZones } = useSelector((state: RootState) => state.facilityZones)
  const treeType = useSelector((state: RootState) => state.location.multipleSelect)
  const { onlyFacilities } = useSelector((state: RootState) => state.location)
  const { selectedData, onlyFacilitiesGroup, multipleSelect } = useSelector((state: RootState) => state.location)
  const [data, setData] = useState<ITreeNode[]>([])
  const location = useLocation()
  const dispatch = useDispatch()

  //Get the root id for the tree.
  const treeRootId = customers[0]?.creatorCustomerId

  useEffect(() => {
    if (onlyFacilities) {
      const _allFacility = [...createAllFacilityTreeNodeObject()]
      const filtered = _allFacility.filter(({ checked }) => checked)
      if (filtered.length > 0) {
        dispatch(setLocationData(filtered))
      }
    } else if (onlyFacilitiesGroup) {
      const _allFacilityGroup = [...createAllFacilityGroupTreeNodeObject()]
      const filtered = _allFacilityGroup.filter(({ checked }) => checked)
      if (filtered.length > 0 && multipleSelect) {
        dispatch(setLocationData(filtered))
      }
    }
  }, [customers, facilitiesGroups, facilities, facilityZones, onlyFacilitiesGroup, onlyFacilities, multipleSelect])
  useEffect(() => {
    if (onlyFacilities) {
      const _allFacility = [...createAllFacilityTreeNodeObject()]
      if (_allFacility) {
        const filtered = _allFacility.filter(({ checked }) => checked)
        if (filtered.length > 0) {
          dispatch(setLocationData(filtered))
        }
      }
    } else if (onlyFacilitiesGroup) {
      const _allFacilityGroup = [...createAllFacilityGroupTreeNodeObject()]
      if (_allFacilityGroup.length) {
        _allFacilityGroup[0].checked = true
        const filtered = _allFacilityGroup.filter(({ checked }) => checked)
        if (filtered.length > 0) {
          dispatch(setLocationData(filtered))
        }
      }
    } else {
      const _customers = [...createCustomerTreeNodeObject()]
      if (_customers.length) {
        _customers[0].checked = true
        const filtered = _customers.filter(({ checked }) => checked)
        dispatch(setLocationData(filtered))
      }
    }
  }, [customers, facilitiesGroups, facilities])

  useEffect(() => {
    if (location.pathname === '/Iot/Heatmap') {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: true,
          facilityGroup: false,
          facilityZone: false
        })
      )
    } else if (location.pathname === `${config?.harvest?.route}`) {
      dispatch(setMultipleSelect(true))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: true,
          facilityZone: false
        })
      )
    } else if (
      location.pathname === '/MyFarm/Sensors' ||
      location.pathname === '/MySite/Sensors' ||
      location.pathname === `${config?.harvest?.route}/CropHistory` ||
      location.pathname === `${config?.harvest?.route}/MortalityHistory` ||
      location.pathname === `${config?.harvest?.route}/Consumption`
    ) {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: true,
          facilityZone: false
        })
      )
    } else if (location.pathname === '/managerReports/CropSequences' || location.pathname === '/managerReports/CropSequences') {
      dispatch(
        setType({
          customer: false,
          facility: true,
          facilityGroup: false,
          facilityZone: false
        })
      )
    } else if (location.pathname === '/MyFarm/Controller') {
      dispatch(setMultipleSelect(false))
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: true,
          facilityZone: false
        })
      )
    } else {
      dispatch(
        setType({
          customer: false,
          facility: false,
          facilityGroup: false,
          facilityZone: false
        })
      )
    }
  }, [location.pathname])
  /**
   * Get Facility zones ids by facility ID.
   * @param facilityId
   * @returns
   */
  const getFacilityZonesIds = (facilityId: number) => {
    return facilityZones?.filter((zone) => zone.facilityId === facilityId).map(({ id }) => id)
  }

  /**
   * Get Facilities Group zones ids, by facility group id.
   * @param facilityGroupId
   * @returns
   */
  const getFacilityGroupZones = (facilityGroupId: number) => {
    const zones: number[] = []
    const facilitiesGroup = facilitiesGroups?.find(({ id }) => facilityGroupId === id)
    facilities.forEach(({ id, facilityGroupId }) => {
      if (facilityGroupId === facilitiesGroup?.id) zones.push(...getFacilityZonesIds(id))
    })
    return zones
  }

  /**
   * Get zones ids by customer id.
   * @param id
   * @returns
   */
  const getCustomerZones = (id?: number) => {
    if (!id) return []
    const zones: number[] = []
    const customerFacilitiesGroups = facilitiesGroups?.filter(({ customerId }) => customerId === id)
    customerFacilitiesGroups?.forEach(({ id }) => {
      zones.push(...getFacilityGroupZones(id))
    })
    return zones
  }

  const getAllCustomerChildrens = (id?: number) => {
    const allCustomerChildren: any = []
    const customerChildrens = customers.filter((customer) => customer.creatorCustomerId === id)
    allCustomerChildren.push(...customerChildrens)
    const data = customerChildrens.forEach((customer) => {
      return allCustomerChildren.push(...getAllCustomerChildrens(customer.id))
    })
    return allCustomerChildren
  }

  const getAllFacilityIds = (customerIds: number[]) => {
    const allFacilities: any = []
    customerIds.forEach((customerId) => {
      const customerFacilities = facilities.filter((facility) => facility.customerId === customerId)
      allFacilities.push(...customerFacilities)
    })
    return allFacilities.map((facility: IFacility) => facility.id)
  }

  const getAllFacilityGroupIds = (customerIds: number[]) => {
    const allFacilityGroups: any = []
    customerIds.forEach((customerId) => {
      const customerFacilityGroups = facilitiesGroups.filter((facilityGroup) => facilityGroup.customerId === customerId)
      allFacilityGroups.push(...customerFacilityGroups)
    })
    return allFacilityGroups.map((facilityGroup: IFacilityGroup) => facilityGroup.id)
  }

  const createCustomerTreeNodeObject = (): ITreeNode[] => {
    return customers?.map((customer, index) => {
      const customersCustomer = getAllCustomerChildrens(customer.id)
      const allCustomerIds = customersCustomer.map((customer: ICustomer) => customer.id)
      const customerParent = customers?.find(({ id }) => customer.creatorCustomerId === id)
      const cusomerParentParent = customers.find(({ id }) => customerParent?.creatorCustomerId === id)
      const zonesIds = [allCustomerIds.map((id: number) => getCustomerZones(id)).flat(), ...getCustomerZones(customer.id)]
      const currentCustomerFacilitiesIds: any = facilities
        .filter((facility) => facility.customerId === customer.id)
        .map((facility) => facility.id)
      const currentCustomerFacilityGroupIds = facilitiesGroups
        ?.filter(({ customerId }) => customerId === customer.id)
        .map((facilityGroup) => facilityGroup.id)
      const customerFacilities = [...currentCustomerFacilitiesIds, ...getAllFacilityIds(allCustomerIds)]
      const facilitiesGroup = [...currentCustomerFacilityGroupIds, ...getAllFacilityGroupIds(allCustomerIds)]

      let checked = false
      if (selectedData[0]?.checked !== undefined) {
        checked = selectedData.find(({ id }) => id === customer.id)?.checked ?? false
      } else {
        checked = !index ? true : false
      }

      return {
        checked: checked ?? false,
        id: customer.id as number,
        parentId: customer.creatorCustomerId,
        parentParentId: cusomerParentParent?.id,
        parentName: customerParent?.name ?? '',
        parentParentName: cusomerParentParent?.name ?? '',
        label: customer.name,
        value: customer.id as number,
        name: customer.name,
        facilitiesGroupIds: facilitiesGroup.flat(),
        facilitiesIds: customerFacilities.flat(),
        zonesIds: zonesIds.flat(),
        children: [],
        type: HirarchyTypes.Customer
      }
    })
  }

  const createFacilitiesGroupTreeNodeObject = (): ITreeNode[] => {
    return facilitiesGroups?.map((facilityGroup, index) => {
      const customer = customers.find(({ id }) => facilityGroup.customerId === id)
      const cusomerParent = customers.find(({ id }) => customer?.creatorCustomerId === id)
      const facilityGroupFacilities = facilities.filter(({ facilityGroupId }) => facilityGroupId === facilityGroup.id)
      let defaultChecked = false
      if (selectedData[0]?.checked !== undefined) {
        defaultChecked = selectedData.find(({ id }) => id === facilityGroup.id)?.checked ?? false
      } else {
        defaultChecked = false
      }
      return {
        checked: defaultChecked,
        id: facilityGroup.id as number,
        name: facilityGroup.name,
        label: facilityGroup.name,
        value: facilityGroup.id as number,
        parentId: facilityGroup.customerId,
        parentName: customer?.name ?? '',
        parentParentId: cusomerParent?.id,
        parentParentName: cusomerParent?.name ?? '',
        facilitiesIds: facilityGroupFacilities.map(({ id }) => id),
        facilitiesGroupIds: [facilityGroup.id],
        zonesIds: getFacilityGroupZones(facilityGroup.id),
        children: [],
        type: HirarchyTypes.FaciltyGroup
      }
    })
  }

  const createFacilityTreeNodeObject = (): ITreeNode[] => {
    return facilities.map((facility) => {
      const facilityGroup = facilitiesGroups.find(({ id }) => facility.facilityGroupId === id)
      const customer = customers.find(({ id }) => facility.customerId === id)
      const checked = selectedData.find(({ id }) => id === facility.id)?.checked
      return {
        checked: checked ?? false,
        id: facility.id,
        name: facility.name,
        parentId: facilityGroup?.id as number,
        parentParentId: facility.customerId,
        parentName: facilityGroup?.name ?? '',
        parentParentName: customer?.name ?? '',
        value: facility.id as number,
        label: facility.name,
        children: [],
        zonesIds: facility.zones.map(({ id }) => id),
        facilitiesIds: [facility.id],
        type: HirarchyTypes.Facility
      }
    })
  }

  const createZonesTreeNodeObject = (): ITreeNode[] => {
    return facilityZones.map((zone) => {
      const facility = facilities.find(({ id }) => id === zone.facilityId)
      const facilityGroup = facilitiesGroups.find(({ id }) => facility?.facilityGroupId === id)
      return {
        id: zone.id,
        name: zone.name ?? `${config?.facilityZones?.name} ` + zone.zoneIndex.toString(),
        parentId: zone.facilityId,
        parentParentId: facilityGroup?.id as number,
        parentName: facility?.name ?? '',
        parentParentName: facilityGroup?.name ?? '',
        value: zone.id as number,
        label: zone.name ?? '',
        children: [],
        zonesIds: [zone.id],
        type: HirarchyTypes.Zone
      }
    })
  }

  const createAllFacilityTreeNodeObject = (): ITreeNode[] => {
    return facilities
      .filter((facility) => facility.facilityTypeId === FacilityType.Shed)
      .map((facility, index) => {
        const facilityGroup = facilitiesGroups.find(({ id }) => facility.facilityGroupId === id)
        const customer = customers.find(({ id }) => facility.customerId === id)
        return {
          checked: !index ? true : false,
          id: facility.id,
          name: facility.name,
          parentId: (facilityGroup?.id ?? facility?.facilityGroupId) as number,
          parentParentId: facility.customerId,
          parentName: facilityGroup?.name ?? '',
          parentParentName: customer?.name ?? '',
          value: facility.id as number,
          label: facility.name,
          children: [],
          zonesIds: facility.zones.map(({ id }) => id),
          facilitiesIds: [facility.id],
          type: HirarchyTypes.Facility
        }
      })
  }

  const createAllFacilityGroupTreeNodeObject = (): ITreeNode[] => {
    return facilitiesGroups.map((facilityGroup, index) => {
      const customer = customers.find(({ id }) => facilityGroup.customerId === id)
      const cusomerParent = customers.find(({ id }) => customer?.creatorCustomerId === id)
      const facilityGroupFacilities = facilities.filter(({ facilityGroupId }) => facilityGroupId === facilityGroup.id)
      let defaultChecked = false
      if (selectedData[0].checked !== undefined) {
        defaultChecked = selectedData.find(({ id }) => id === facilityGroup.id)?.checked ?? false
      }
      if (!multipleSelect && HirarchyTypes.FaciltyGroup === selectedData[0].type) {
        defaultChecked = selectedData[0].id === facilityGroup.id ? true : false
      }
      if (HirarchyTypes.FaciltyGroup !== selectedData[0].type) {
        defaultChecked = !index ? true : false
      }
      return {
        checked: defaultChecked,
        id: facilityGroup.id as number,
        name: facilityGroup.name,
        label: facilityGroup.name,
        value: facilityGroup.id as number,
        parentId: facilityGroup.customerId,
        parentName: customer?.name ?? '',
        parentParentId: cusomerParent?.id,
        parentParentName: cusomerParent?.name ?? '',
        facilitiesIds: facilityGroupFacilities.map(({ id }) => id),
        facilitiesGroupIds: [facilityGroup.id],
        zonesIds: getFacilityGroupZones(facilityGroup.id),
        children: [],
        type: HirarchyTypes.FaciltyGroup
      }
    })
  }

  /**
   * Get the all tree nodes (customers,facility groups,facilities and zones.)
   * @returns
   */
  const getTreeNodes = () => {
    //Get the customers objects for the tree.
    const _customers = createCustomerTreeNodeObject()
    //Get the Facilities groups objects for the tree.
    const _facilitiesGroups = createFacilitiesGroupTreeNodeObject()
    //Get the Facilities groups objects for the tree.
    const _facilities = createFacilityTreeNodeObject()
    //Get the Zone groups objects for the tree.
    const _zones = createZonesTreeNodeObject()
    return [..._customers, ..._facilitiesGroups, ..._facilities, ..._zones]
  }

  const getAllFacility = () => {
    const _allFacility = createAllFacilityTreeNodeObject()
    return [..._allFacility]
  }

  const getAllFacilityGroup = () => {
    const _allFacilityGroup = createAllFacilityGroupTreeNodeObject()
    return [..._allFacilityGroup]
  }

  //Init the tree on mount or on redux store change in one of the hirarchy list
  useEffect(() => {
    let treeNodes
    if (onlyFacilities) {
      treeNodes = getAllFacility()
    } else if (onlyFacilitiesGroup) {
      treeNodes = getAllFacilityGroup()
    } else {
      treeNodes = getTreeNodes()
    }
    const treeHirarchy = onlyFacilities || onlyFacilitiesGroup ? treeNodes : list2tree(treeNodes, treeRootId)

    setData(treeHirarchy)
  }, [customers, facilitiesGroups, facilities, facilityZones, onlyFacilities, onlyFacilitiesGroup, multipleSelect])

  const onChange = (currentNode: NodeType, selectedNodes: Array<ISelectedTree>) => {
    //Get the all row data of hirarchy types.
    const treeNodes = onlyFacilities || onlyFacilitiesGroup ? data : getTreeNodes()
    //Update the previous selected nodes to be checked or unchecked.
    const updateTreeNodeSelected = treeNodes.map((node) => {
      const isNodeSelected = selectedNodes.find((selectedNode) => selectedNode.id === node.id)
      return { ...node, ...isNodeSelected }
    })
    // console.log("UPLOADED ",updateTreeNodeSelected)
    //Update the current node to be checked or unchecked.
    const treeNodesUpdated = updateTreeNodeSelected.map((node) => {
      if (onlyFacilities || !multipleSelect) {
        return { ...node, checked: currentNode.id === node.id ? true : false }
      } else {
        return node.id === currentNode.id ? { ...node, checked: currentNode.checked } : { ...node, expanded: currentNode.checked }
      }
    })
    //If no value into tree , set the first customer checked to true.
    if (!selectedNodes.length) {
      treeNodesUpdated[0].checked = true
      const treeHirarchy: any =
        onlyFacilities || onlyFacilitiesGroup ? treeNodesUpdated : list2tree(treeNodesUpdated as ITreeNode[], treeRootId)
      setData(treeHirarchy)
      if (onlyFacilities) {
        const _allFacility = [...createAllFacilityTreeNodeObject()]
        const filtered = _allFacility.filter(({ checked }) => checked)
        if (filtered.length > 0) {
          dispatch(setLocationData(filtered))
        }
      } else if (onlyFacilitiesGroup) {
        const _allFacilityGroup = [...createAllFacilityGroupTreeNodeObject()]
        _allFacilityGroup[0].checked = true
        const filtered = _allFacilityGroup.filter(({ checked }) => checked)
        if (filtered.length > 0) {
          dispatch(setLocationData([filtered[0]]))
        }
      } else {
        const _customers = [...createCustomerTreeNodeObject()]
        if (_customers.length && facilitiesGroups?.length) {
          _customers[0].checked = true
          const filtered = _customers.filter(({ checked }) => checked)
          dispatch(setLocationData(filtered))
        }
      }
      return
    }

    //Prevent from tree multi kind of hirarchy type.
    if (currentNode.checked || currentNode.checked === false) {
      const filterCheckedTreeNodeToOnlyCustomers = treeNodesUpdated.map((node) =>
        node.type !== currentNode.type ? { ...node, checked: false } : node
      )
      const treeHirarchy =
        onlyFacilities || onlyFacilitiesGroup
          ? filterCheckedTreeNodeToOnlyCustomers
          : list2tree(filterCheckedTreeNodeToOnlyCustomers as ITreeNode[], treeRootId)
      setData(treeHirarchy as any)
      updateSelectTree(filterCheckedTreeNodeToOnlyCustomers)
    }
  }

  const updateSelectTree = (nodesList: Array<any>) => {
    const filtered = nodesList.filter(({ checked }) => checked)
    dispatch(setLocationData(filtered))
  }
  return (
    <>
      {
        <AppLocationWrapper>
          <DropdownTreeSelect
            keepOpenOnSelect
            data={data ?? []}
            onChange={onChange}
            className="mdl-demo"
            mode={treeType ? 'hierarchical' : 'radioSelect'}
            onlyFacility={onlyFacilities}
          />
        </AppLocationWrapper>
      }
    </>
  )
}

export default AppbarLocationComponent
