import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IDeviceTypes } from 'device'
import { serverGetDeviceTypes } from '../services/serverApi'

type DeviceState = {
  deviceTypes: Array<IDeviceTypes>
  loading: boolean
  error: string | null
}

const initialState: DeviceState = {
  deviceTypes: [],
  loading: false,
  error: null
}

// Get Devices Types
export const getDeviceTypes = createAsyncThunk('device/getDeviceTypes', async (dataModelId: number, thunkApi) => {
  try {
    const res = await serverGetDeviceTypes()
    return res
  } catch (e) {
    console.log('catch', e)
    return thunkApi.rejectWithValue(e as ErrorEventInit)
  }
})

export const deviceTypeSlice = createSlice({
  name: 'deviceTypes',
  initialState,
  reducers: {},
  extraReducers: (builder): any => {
    builder.addCase(getDeviceTypes.pending, (state) => {
      state.loading = true
    }),
      builder.addCase(getDeviceTypes.fulfilled, (state, { payload }) => {
        state.error = null
        state.loading = false
        state.deviceTypes = payload as IDeviceTypes[]
      }),
      builder.addCase(getDeviceTypes.rejected, (state, { payload }) => {
        state.loading = false
        state.error = (payload as ErrorEventInit).error
      })
  }
})

export const deviceTypeReducer = deviceTypeSlice.reducer
export const deviceTypeActions = deviceTypeSlice.actions
