import React, { memo, useEffect, useRef, useState, useCallback } from 'react'
import {
  DropdownIcon,
  Option,
  OptionsPopup,
  SelectWrapper,
  SelectedOptionBox,
  SelectedOptionsContainer,
  ExpandMoreIcon,
  SearchContainer,
  SearchInput,
  InputIcon,
  InputLabel,
  OptionsContainer,
  SelectAllOption,
  ClearAllOption,
  SelectionContainer
} from './AppSelect.styled'
import dropDownArrowIcon from '../../assets/images/global/SelectDropDownIcon.svg'
import searchIcon from '../../assets/images/appBar/RumintechAppbar/searchIcon.svg'
import { useAppSelect } from './useAppSelect'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import _debounce from 'lodash/debounce'

interface AppSelectProps {
  label: string
  options: any[]
  onChange: any
  inputName: any
  value: number[] | number
  keyObject?: {
    nameKey?: any
    idKey?: any
  }
  serverFetch?: any
  disabled?: boolean
  multiple?: boolean
  ShowLabel?: boolean
  width?: number
  customComponent?: React.ReactNode
}

const AppSelect: React.FC<AppSelectProps> = ({
  label,
  options,
  onChange,
  inputName,
  value,
  keyObject,
  serverFetch,
  disabled,
  width,
  multiple = true, // Set the default value to true
  ShowLabel = true,
  customComponent
}) => {
  const {
    showOptionsPopup,
    setShowOptionsPopup,
    position,
    searchQuery,
    handleBoxClick,
    handleSearchChange,
    filteredOptions,
    setFilteredOptions,
    selectWrapperRef,
    searchInputRef
  } = useAppSelect(options)
  const [selectedOptions, setSelectedOptions] = useState<number[]>([])
  const [optionData, setOptionData] = useState(options)
  const [isCtrlPressed, setIsCtrlPressed] = useState(false)
  const selectedOptionsRef = useRef<number[]>(selectedOptions)
  const [preventMouseUp, setPreventMouseUp] = useState(false)

  useEffect(() => {
    selectedOptionsRef.current = selectedOptions
  }, [selectedOptions])

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Control' || event.key === 'Meta') {
      setIsCtrlPressed(true)
    }
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Control' || event.key === 'Meta') {
      setIsCtrlPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const isDraggingRef = useRef(false)
  const initialOptionRef = useRef<any>(null)

  const getData = useCallback(async () => {
    try {
      options = await serverFetch()
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    setOptionData(options)
    setFilteredOptions(options)
    setSelectedOptions(Array.isArray(value) ? value : [value])
  }, [options])

  useEffect(() => {
    if (!serverFetch) return
    getData().then(() => {
      setFilteredOptions(options)
      setOptionData(options)
      setSelectedOptions(Array.isArray(value) ? value : [value])
    })
  }, [])

  useEffect(() => {
    if (!isCtrlPressed) {
      setSelectedOptions(Array.isArray(value) ? value : [value])
    }
  }, [value])

  const handleSelectAll = () => {
    const allOptionIds = options.map((option) => option[keyObject?.idKey ?? 'id'])
    setSelectedOptions(allOptionIds)
    onChange({ target: { name: inputName, value: allOptionIds } })
  }
  // Function to handle "Clear Filter" option
  const handleClearFilter = () => {
    setSelectedOptions([])
    onChange({ target: { name: inputName, value: [] } })
  }

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()
    if (!event.metaKey && !event.ctrlKey) {
      isDraggingRef.current = true
      initialOptionRef.current = parseInt(event.currentTarget.getAttribute('data-id')!)
    }
  }

  const handleOptionClick = (option: any, event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (multiple && event && (event.metaKey || event.ctrlKey)) {
      if (selectedOptions.includes(option[keyObject?.idKey ?? 'id'])) {
        setSelectedOptions(selectedOptions.filter((item: any) => item !== option[keyObject?.idKey ?? 'id']))
        onChange({ target: { name: inputName, value: selectedOptions.filter((item: any) => item !== option[keyObject?.idKey ?? 'id']) } })
      } else {
        setSelectedOptions([...selectedOptions, option[keyObject?.idKey ?? 'id']])
        onChange({ target: { name: inputName, value: [...selectedOptions, option[keyObject?.idKey ?? 'id']] } })
      }
    } else {
      setSelectedOptions([option[keyObject?.idKey ?? 'id']])
      onChange({ target: { name: inputName, value: [option[keyObject?.idKey ?? 'id']] } })
      setShowOptionsPopup(false)
      isDraggingRef.current = false
    }
  }

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = _debounce((event) => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false
      onChange({ target: { name: inputName, value: selectedOptions } })
      initialOptionRef.current = null
    }
  }, 100)

  const { t } = useTranslation()

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()
    if (isDraggingRef.current && multiple) {
      const currentOptionId = parseInt(event.currentTarget.getAttribute('data-id')!)
      if (currentOptionId !== initialOptionRef.current) {
        const startIndex = options.findIndex((option) => option[keyObject?.idKey ?? 'id'] === initialOptionRef.current)
        const endIndex = options.findIndex((option) => option[keyObject?.idKey ?? 'id'] === currentOptionId)
        const selectedValues = options.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1)
        const updatedSelectedOptions = selectedValues.map((option) => option[keyObject?.idKey ?? 'id'])
        setSelectedOptions(updatedSelectedOptions)
      }
    }
  }
  return (
    <SelectWrapper ref={selectWrapperRef} disabled={disabled ?? false} width={width}>
      {ShowLabel && <InputLabel>{label}</InputLabel>}
      <SelectedOptionBox onClick={handleBoxClick} data-testid="selected-option-box" ref={selectWrapperRef}>
        <SelectedOptionsContainer>
          {selectedOptions.length > 0 && (selectedOptions[0] as any) !== ''
            ? selectedOptions
                .map((optionId) => {
                  const selectedOption = options.find((option) => option[keyObject?.idKey ?? 'id'] === optionId)
                  return selectedOption ? selectedOption[keyObject?.nameKey ?? 'name'] : ''
                })
                .join(', ')
            : label}
        </SelectedOptionsContainer>
        <DropdownIcon>
          <ExpandMoreIcon src={dropDownArrowIcon} />
        </DropdownIcon>
      </SelectedOptionBox>
      {showOptionsPopup && (
        <OptionsPopup
          show={showOptionsPopup}
          top={position.top}
          left={position.left}
          width={selectWrapperRef?.current?.offsetWidth}
          className="dropdown"
        >
          <SearchContainer>
            <SearchInput
              ref={searchInputRef}
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={`${t('general.search')}`}
            />
            <InputIcon src={searchIcon} alt="Search Icon" />
          </SearchContainer>
          <div>
            {/* Add "Select All" option at the top */}
            {multiple && (
              <SelectionContainer>
                <SelectAllOption
                  data-testid="option-select-all"
                  selected={selectedOptions.length === options.length}
                  onClick={handleSelectAll}
                >
                  {t('general.selectAll')}
                </SelectAllOption>
                <ClearAllOption data-testid="option-clear-filter" selected={selectedOptions.length === 0} onClick={handleClearFilter}>
                  {t('general.clear')}
                </ClearAllOption>
              </SelectionContainer>
            )}
            <OptionsContainer>
              {filteredOptions?.map((option: any) => (
                <Option
                  disabled={disabled ?? false}
                  key={option[keyObject?.idKey ?? 'id']}
                  data-id={option[keyObject?.idKey ?? 'id']}
                  data-testid="option-id"
                  selected={selectedOptions.includes(option[keyObject?.idKey ?? 'id'])}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseEnter={handleMouseEnter}
                  onClick={(event) => handleOptionClick(option, event)}
                  customComponent={customComponent ? true : false}
                >
                  {option[keyObject?.nameKey ?? 'name']}
                  {customComponent &&
                    React.isValidElement(customComponent) &&
                    React.cloneElement(customComponent, { id: option?.id } as any)}
                </Option>
              ))}
            </OptionsContainer>
          </div>
        </OptionsPopup>
      )}
    </SelectWrapper>
  )
}

export default memo(AppSelect)
