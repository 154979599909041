import {
  NavZoneIcon,
  aiIcon,
  aiSelectIcon,
  analysisIcon,
  analysisSelectIcon,
  apiIcon,
  apiSelectIcon,
  closeArrowIcon,
  customerManagmentIcon,
  customerManagmentSelectIcon,
  // iotIcon,
  iotSelectIcon,
  managerReportIcon,
  managerReportSelectIcon,
  myFarmIcon,
  myFarmSelectIcon,
  naviAiIcon,
  naviAnalysisIcon,
  naviApiIcon,
  naviCustomerManagementIcon,
  naviIotIcon,
  naviManagerReportIcon,
  naviMyFarmIcon,
  naviNutritionsIcon,
  nutritionsIcon,
  nutritionsSelectIcon,
  openArrowIcon,
  zoneSidebarIcon,
  devicesIcon,
  devicesWhiteIcon,
  navDeviceBpacIcon
} from '../../../assets/images/sideMenu/index'
import { RootState, useReduxSelector } from '../../../redux'
import { IPermission } from 'permission'
import { INavbar, ISidebarMenu } from 'sidebarMenu'
import { checkPermissions } from '../../../utils/helpers/permissionHelper'
import { InventoryIcon, selectInventoryIcon } from '../../../assets/images/inventory'
import { reportIcon, reportSelectedIcon } from '../../../assets/images/sideMenu/Bpac'
import { analysisSelectedIcon, crmIcon, crmSelectedIcon, iotIcon, iotSelectedIcon } from '../../../assets/images/sidebar/RuminTech'

export const getBpacSidebarData = () => {
  return [
    {
      title: 'IoT Sensors',
      path: '/Iot',
      image: iotSelectIcon,
      navicon: naviIotIcon,
      selectImage: iotSelectIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'General',
          path: '/Iot/General'
        }
      ]
    },
    {
      title: 'My Area',
      path: '/MyZone',
      image: zoneSidebarIcon,
      navicon: NavZoneIcon,
      selectImage: zoneSidebarIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Areas',
          path: '/MyZone/Areas'
        },
        {
          title: 'Buildings',
          path: '/MyZone/Buildings'
        },
        {
          title: 'Alert',
          path: '/MyZone/Alert'
        },
        {
          title: 'Map',
          path: '/MyZone/Map'
        }
      ]
    },
    {
      title: 'Devices',
      path: '/Devices',
      image: devicesWhiteIcon,
      navicon: navDeviceBpacIcon,
      selectImage: devicesWhiteIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Devices',
          path: '/Devices/Summary'
        },
        {
          title: 'Devices Versions',
          path: '/Devices/device-version'
        }
      ]
    },
    {
      title: 'CRM',
      path: '/customerMgmt',
      navicon: naviCustomerManagementIcon,
      image: crmSelectedIcon,
      selectImage: crmSelectedIcon,

      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,

      subNav: [
        {
          title: 'Users',
          path: '/customerMgmt/users'
        },
        {
          title: 'Customer',
          path: '/customerMgmt/customer'
        },
        {
          title: 'Roles',
          path: '/customerMgmt/roles'
        }
      ]
    },
    {
      title: 'Analysis',
      path: '/Analysis',
      navicon: naviAnalysisIcon,
      image: analysisSelectedIcon,
      selectImage: analysisSelectedIcon,

      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,

      subNav: [
        {
          title: 'Sensors',
          path: '/Analysis/Sensors'
        },
        {
          title: 'Comparison',
          path: '/Analysis/Comparison'
        }
      ]
    },
    {
      title: 'Ai',
      path: '/ai',
      navicon: naviAiIcon,
      image: aiSelectIcon,
      selectImage: aiSelectIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Ai Charts',
          path: '/ai/charts'
        }
      ]
    },
    {
      title: 'Report',
      path: '/report',
      navicon: reportSelectedIcon,
      image: reportIcon,
      selectImage: reportIcon,
      iconClosed: closeArrowIcon,
      iconOpened: openArrowIcon,
      subNav: [
        {
          title: 'Certificate',
          path: '/report/certificate'
        },
        {
          title: 'Performance',
          path: '/report/performance'
        },
        {
          title: 'Esg',
          path: '/report/esg'
        }
      ]
    }
  ]
}
export const getSidebarData = (routesdata: ISidebarMenu[], permissions: IPermission[]) => {
  if (permissions.length === 0) {
    return routesdata
  }
  ;[...routesdata].forEach((route: ISidebarMenu, index: number) => {
    route.subNav = route.subNav.filter((sb: INavbar) => {
      switch (sb.path) {
        case '/ai/charts':
          return true
        case '/Iot/General':
          return checkPermissions('view-iot', permissions)
          break
        case '/Iot/Heatmap':
          return checkPermissions('view-heatmap', permissions)
          break
        case '/MyZone/Areas':
          return checkPermissions('view-farm', permissions)
          break
        case '/MyZone/Areas':
          return true
          break
        case '/MyZone/Buildings':
          return true
          break
        case '/MyFarm/Sheds':
          return checkPermissions('view-shed', permissions)
          break
        case '/Devices/Summary':
          return checkPermissions('view-device', permissions)
          break

        case '/MyFarm/Alert':
          return checkPermissions('view-alert', permissions)
          break
        case '/customerMgmt/users':
          return checkPermissions('view-user', permissions)
          break
        case '/customerMgmt/customer':
          return checkPermissions('view-customer', permissions)
          break
        case '/customerMgmt/roles':
          return checkPermissions('view-role', permissions)
          break
        case '/Analysis/Efficiency':
          return checkPermissions('view-efficiency', permissions)
          break
        case '/Analysis/Sensors':
          return checkPermissions('view-sensor', permissions)
          break
        case '/Analysis/Comparison':
          return checkPermissions('view-sensor', permissions)
          break
        case '/managerReports/CropSequences':
          return checkPermissions('view-report', permissions)
          break
        case '/Inventory/Recipe':
          return true
          break
        case '/Inventory/Ingredient':
          return true
          break
        case '/ai':
          return true
          break
        case '/report':
          return true
          break
        case '/report/certificate':
          return true
          break
        case '/report/performance':
          return true
          break
        case '/report/esg':
          return true
          break
        case '/api':
          return true
          break
        case '/api/key':
          return true
      }
    })
    // if (route.subNav.length === 0) {
    //   SidebarData.splice(index, 1)
    // }
  })
  return routesdata
}
