import styled from 'styled-components'

export const AppLocationWrapper = styled.div`
  height: 56px;
  grid-area: locfilter;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
